import {
    AccessControlId,
    AccessControlEntityId,
    ActivityId,
    ActivityParentEntityId,
    ActivitySourceId,
    AnswerEntityId,
    AuditId,
    ClientId,
    CommunityId,
    CommunityLicenseId,
    CommunityMasterId,
    CommunityMasterLicenseTypeId,
    ContactId,
    CurrencyInCents,
    CustomListId,
    CustomListItemId,
    CustomListCategoryId,
    DisclosureAcknowledgementId,
    DisclosureId,
    EmailEntityId,
    EmailTemplateId,
    EvergreenLicenseTypeId,
    FileEntityId,
    FileId,
    HostedFormId,
    HostedFormEntityId,
    HostedFormResponseId,
    HostedFormTokenId,
    IdentityId,
    ImportId,
    InternalId,
    InvoiceId,
    InvoiceLineItemId,
    InvoicePaymentScheduleItemId,
    IsoTimestamp,
    LayoutContainerId,
    LayoutItemId,
    LayoutSectionId,
    LayoutSectionSettingId,
    LicenseId,
    NoteId,
    NoteEntityId,
    PhotoId,
    PromptId,
    PublicRecordId,
    PublicRecordContactId,
    QuestionId,
    ReferenceBusinessId,
    ReferenceContactId,
    ReferralId,
    RegionId,
    RegionLicenseMapId,
    ReportExportId,
    SavedQueryId,
    SentMessageId,
    SignatureRequestFieldId,
    SignatureRequestId,
    SignatureRequestRecipientId,
    SignatureTemplateId,
    SignatureTemplateFieldId,
    SignatureTemplateRecipientId,
    SmtpCredentialId,
    TaskId,
    TaskTypeId,
    TaskParentId,
    TenantCredentialId,
    TenantId,
    TokenId,
    Url,
    UserId,
    UserGroupId,
    UUID,
    WorkflowExecutionId,
    WorkflowId,
    WorkflowTargetId,
} from "@sp-crm/core";
import gql from "graphql-tag";
import {
    useQuery,
    useInfiniteQuery,
    useMutation,
    UseQueryOptions,
    UseInfiniteQueryOptions,
    UseMutationOptions,
    QueryFunctionContext,
} from "react-query";
import { graphqlFetcher } from "../backend/fetcher";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** EntityIdScalar(AccessControlEntityId) type */
    AccessControlEntityId: AccessControlEntityId;
    /** EntityIdScalar(AccessControlId) type */
    AccessControlId: AccessControlId;
    /** EntityIdScalar(ActivityId) type */
    ActivityId: ActivityId;
    /** EntityIdScalar(ActivityParentEntityId) type */
    ActivityParentEntityId: ActivityParentEntityId;
    /** EntityIdScalar(ActivitySourceId) type */
    ActivitySourceId: ActivitySourceId;
    /** EntityIdScalar(AnswerEntityId) type */
    AnswerEntityId: AnswerEntityId;
    /** EntityIdScalar(ClientId) type */
    ClientId: ClientId;
    /** EntityIdScalar(CommunityId) type */
    CommunityId: CommunityId;
    /** EntityIdScalar(CommunityLicenseId) type */
    CommunityLicenseId: CommunityLicenseId;
    /** EntityIdScalar(CommunityMasterId) type */
    CommunityMasterId: CommunityMasterId;
    /** EntityIdScalar(ContactId) type */
    ContactId: ContactId;
    /** Whole number depicting a currency amount in cents */
    CurrencyInCents: CurrencyInCents;
    /** EntityIdScalar(CustomListCategoryId) type */
    CustomListCategoryId: CustomListCategoryId;
    /** EntityIdScalar(CustomListId) type */
    CustomListId: CustomListId;
    /** EntityIdScalar(CustomListItemId) type */
    CustomListItemId: CustomListItemId;
    /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
    DateTime: any;
    /** EntityIdScalar(DisclosureAcknowledgementId) type */
    DisclosureAcknowledgementId: DisclosureAcknowledgementId;
    /** EntityIdScalar(DisclosureId) type */
    DisclosureId: DisclosureId;
    /** EntityIdScalar(EmailEntityId) type */
    EmailEntityId: EmailEntityId;
    /** EntityIdScalar(EmailTemplateId) type */
    EmailTemplateId: EmailTemplateId;
    /** EntityIdScalar(EvergreenLicenseTypeId) type */
    EvergreenLicenseTypeId: EvergreenLicenseTypeId;
    /** EntityIdScalar(FileEntityId) type */
    FileEntityId: FileEntityId;
    /** EntityIdScalar(FileId) type */
    FileId: FileId;
    /** EntityIdScalar(HostedFormEntityId) type */
    HostedFormEntityId: HostedFormEntityId;
    /** EntityIdScalar(HostedFormId) type */
    HostedFormId: HostedFormId;
    /** EntityIdScalar(HostedFormTokenId) type */
    HostedFormTokenId: HostedFormTokenId;
    /** EntityIdScalar(IdentityId) type */
    IdentityId: IdentityId;
    /** EntityIdScalar(ImportId) type */
    ImportId: ImportId;
    /** EntityIdScalar(InvoiceId) type */
    InvoiceId: InvoiceId;
    /** EntityIdScalar(InvoiceLineItemId) type */
    InvoiceLineItemId: InvoiceLineItemId;
    /** EntityIdScalar(InvoicePaymentScheduleItemId) type */
    InvoicePaymentScheduleItemId: InvoicePaymentScheduleItemId;
    /** Timestamp in ISO 8601 format */
    IsoTimestamp: IsoTimestamp;
    /** EntityIdScalar(LayoutContainerId) type */
    LayoutContainerId: LayoutContainerId;
    /** EntityIdScalar(LayoutItemId) type */
    LayoutItemId: LayoutItemId;
    /** EntityIdScalar(LayoutSectionId) type */
    LayoutSectionId: LayoutSectionId;
    /** EntityIdScalar(LayoutSectionSettingId) type */
    LayoutSectionSettingId: LayoutSectionSettingId;
    /** EntityIdScalar(LicenseId) type */
    LicenseId: LicenseId;
    /** EntityIdScalar(NoteEntityId) type */
    NoteEntityId: NoteEntityId;
    /** EntityIdScalar(NoteId) type */
    NoteId: NoteId;
    /** EntityIdScalar(PhotoId) type */
    PhotoId: PhotoId;
    /** EntityIdScalar(PromptId) type */
    PromptId: PromptId;
    /** EntityIdScalar(PublicRecordContactId) type */
    PublicRecordContactId: PublicRecordContactId;
    /** EntityIdScalar(PublicRecordId) type */
    PublicRecordId: PublicRecordId;
    /** EntityIdScalar(QuestionId) type */
    QuestionId: QuestionId;
    /** EntityIdScalar(ReferenceBusinessId) type */
    ReferenceBusinessId: ReferenceBusinessId;
    /** EntityIdScalar(ReferenceContactId) type */
    ReferenceContactId: ReferenceContactId;
    /** EntityIdScalar(ReferralId) type */
    ReferralId: ReferralId;
    /** EntityIdScalar(RegionId) type */
    RegionId: RegionId;
    /** EntityIdScalar(RegionLicenseMapId) type */
    RegionLicenseMapId: RegionLicenseMapId;
    /** EntityIdScalar(ReportExportId) type */
    ReportExportId: ReportExportId;
    /** EntityIdScalar(SavedQueryId) type */
    SavedQueryId: SavedQueryId;
    /** EntityIdScalar(SentMessageId) type */
    SentMessageId: SentMessageId;
    /** EntityIdScalar(SignatureRequestFieldId) type */
    SignatureRequestFieldId: SignatureRequestFieldId;
    /** EntityIdScalar(SignatureRequestId) type */
    SignatureRequestId: SignatureRequestId;
    /** EntityIdScalar(SignatureRequestRecipientId) type */
    SignatureRequestRecipientId: SignatureRequestRecipientId;
    /** EntityIdScalar(SignatureTemplateFieldId) type */
    SignatureTemplateFieldId: SignatureTemplateFieldId;
    /** EntityIdScalar(SignatureTemplateId) type */
    SignatureTemplateId: SignatureTemplateId;
    /** EntityIdScalar(SignatureTemplateRecipientId) type */
    SignatureTemplateRecipientId: SignatureTemplateRecipientId;
    /** EntityIdScalar(SmtpCredentialId) type */
    SmtpCredentialId: SmtpCredentialId;
    /** EntityIdScalar(TaskId) type */
    TaskId: TaskId;
    /** EntityIdScalar(TaskParentId) type */
    TaskParentId: TaskParentId;
    /** EntityIdScalar(TaskTypeId) type */
    TaskTypeId: TaskTypeId;
    /** EntityIdScalar(TenantCredentialId) type */
    TenantCredentialId: TenantCredentialId;
    /** EntityIdScalar(TenantId) type */
    TenantId: TenantId;
    /** EntityIdScalar(TokenId) type */
    TokenId: TokenId;
    /** UUID Scalar type */
    UUID: InternalId;
    /** EntityIdScalar(UserGroupId) type */
    UserGroupId: UserGroupId;
    /** EntityIdScalar(UserId) type */
    UserId: UserId;
    /** EntityIdScalar(WorkflowExecutionId) type */
    WorkflowExecutionId: WorkflowExecutionId;
    /** EntityIdScalar(WorkflowId) type */
    WorkflowId: WorkflowId;
    /** EntityIdScalar(WorkflowTargetId) type */
    WorkflowTargetId: WorkflowTargetId;
};

export type AccessControlInput = {
    groupId: Scalars["UserGroupId"];
    id: Scalars["AccessControlId"];
};

export type AccessControlResult = {
    __typename?: "AccessControlResult";
    entityId: Scalars["AccessControlEntityId"];
    groupId: Scalars["UserGroupId"];
    id: Scalars["AccessControlId"];
};

export enum ActionType {
    AddAnswer = "AddAnswer",
    AddExistingExternalInvoice = "AddExistingExternalInvoice",
    AddNote = "AddNote",
    BulkImport = "BulkImport",
    BypassAcls = "BypassAcls",
    ClientCommunitiesPdf = "ClientCommunitiesPdf",
    ClientPdf = "ClientPdf",
    CreateAudience = "CreateAudience",
    CreateBulkMessage = "CreateBulkMessage",
    CreateClient = "CreateClient",
    CreateCommunity = "CreateCommunity",
    CreateEmailTemplate = "CreateEmailTemplate",
    CreateHostedForm = "CreateHostedForm",
    CreateModifyInvoice = "CreateModifyInvoice",
    CreateOrEditSignatureTemplate = "CreateOrEditSignatureTemplate",
    CreateQuestion = "CreateQuestion",
    CreateReference = "CreateReference",
    CreateReferral = "CreateReferral",
    CreateTask = "CreateTask",
    CreateTenant = "CreateTenant",
    CreateUser = "CreateUser",
    CreateWorkflow = "CreateWorkflow",
    DeleteAudience = "DeleteAudience",
    DeleteBulkMessage = "DeleteBulkMessage",
    DeleteEmailTemplate = "DeleteEmailTemplate",
    DeleteHostedForm = "DeleteHostedForm",
    DeleteNote = "DeleteNote",
    DeleteQuestion = "DeleteQuestion",
    DeleteReferral = "DeleteReferral",
    DeleteSignatureTemplate = "DeleteSignatureTemplate",
    DeleteTask = "DeleteTask",
    DeleteWorkflow = "DeleteWorkflow",
    EvergreenPublicRecordCreate = "EvergreenPublicRecordCreate",
    EvergreenReadDataSources = "EvergreenReadDataSources",
    EvergreenSchedulePropagate = "EvergreenSchedulePropagate",
    EvergreenThumbnail = "EvergreenThumbnail",
    ExportCsv = "ExportCsv",
    FailedLogin = "FailedLogin",
    Geocode = "Geocode",
    InternalReportingLoadAllUsers = "InternalReportingLoadAllUsers",
    InternalReportingShowActivity = "InternalReportingShowActivity",
    InternalReportingViewTenant = "InternalReportingViewTenant",
    ListAudiences = "ListAudiences",
    ListBulkMessages = "ListBulkMessages",
    LoadClients = "LoadClients",
    LoadCommunities = "LoadCommunities",
    LoadEmailTemplates = "LoadEmailTemplates",
    LoadOwnClients = "LoadOwnClients",
    LoadOwnSentMessages = "LoadOwnSentMessages",
    LoadQuestions = "LoadQuestions",
    LoadReferences = "LoadReferences",
    LoadReferrals = "LoadReferrals",
    LoadReferralsForEntity = "LoadReferralsForEntity",
    LoadSentMessages = "LoadSentMessages",
    LoadTasks = "LoadTasks",
    LoadTenant = "LoadTenant",
    LoadUsers = "LoadUsers",
    ManageIntegrations = "ManageIntegrations",
    MigrateFile = "MigrateFile",
    ModifyAcls = "ModifyAcls",
    ModifyEmailTemplate = "ModifyEmailTemplate",
    ModifySharedEmailTemplate = "ModifySharedEmailTemplate",
    ModifyTaskType = "ModifyTaskType",
    MomsHouseReferral = "MomsHouseReferral",
    MultiAccountSwitch = "MultiAccountSwitch",
    Noop = "Noop",
    ReadHostedForm = "ReadHostedForm",
    ReadWorkflow = "ReadWorkflow",
    ResetPassword = "ResetPassword",
    SelfManageCreateUser = "SelfManageCreateUser",
    SelfManageDisableUser = "SelfManageDisableUser",
    SelfManageModifyUser = "SelfManageModifyUser",
    SelfManageViewApiKey = "SelfManageViewApiKey",
    SelfManageViewUser = "SelfManageViewUser",
    SetPassword = "SetPassword",
    SuccessfulLogin = "SuccessfulLogin",
    SuperuserCloneUser = "SuperuserCloneUser",
    SuperuserGenerateApiKey = "SuperuserGenerateApiKey",
    SuperuserGeneric = "SuperuserGeneric",
    SuperuserModifyCommunityMaster = "SuperuserModifyCommunityMaster",
    SuperuserModifyTenant = "SuperuserModifyTenant",
    SuperuserModifyUser = "SuperuserModifyUser",
    SuperuserResetTenant = "SuperuserResetTenant",
    UpdateAllSavedQueries = "UpdateAllSavedQueries",
    UpdateAudience = "UpdateAudience",
    UpdateBulkMessage = "UpdateBulkMessage",
    UpdateClient = "UpdateClient",
    UpdateClientAddCommunity = "UpdateClientAddCommunity",
    UpdateClientAddContactPerson = "UpdateClientAddContactPerson",
    UpdateClientDeleteContactPerson = "UpdateClientDeleteContactPerson",
    UpdateClientRemoveCommunity = "UpdateClientRemoveCommunity",
    UpdateClientUpdateContactPerson = "UpdateClientUpdateContactPerson",
    UpdateCommunity = "UpdateCommunity",
    UpdateCommunityAddContact = "UpdateCommunityAddContact",
    UpdateCommunityRemoveContact = "UpdateCommunityRemoveContact",
    UpdateCommunitySearch = "UpdateCommunitySearch",
    UpdateCommunityUpdateContact = "UpdateCommunityUpdateContact",
    UpdateCustomList = "UpdateCustomList",
    UpdateEmailTemplate = "UpdateEmailTemplate",
    UpdateLayout = "UpdateLayout",
    UpdateNote = "UpdateNote",
    UpdateOwnClient = "UpdateOwnClient",
    UpdateQuestion = "UpdateQuestion",
    UpdateReference = "UpdateReference",
    UpdateReferral = "UpdateReferral",
    UpdateTask = "UpdateTask",
    UpdateTenant = "UpdateTenant",
    UpdateUser = "UpdateUser",
    ViewAllInvoices = "ViewAllInvoices",
    ViewAudience = "ViewAudience",
    ViewBulkMessage = "ViewBulkMessage",
    ViewClient = "ViewClient",
    ViewCommunity = "ViewCommunity",
    ViewCommunityContract = "ViewCommunityContract",
    ViewCustomList = "ViewCustomList",
    ViewLayout = "ViewLayout",
    ViewOwnClient = "ViewOwnClient",
    ViewOwnInvoices = "ViewOwnInvoices",
    ViewReference = "ViewReference",
    ViewReferral = "ViewReferral",
    ViewReports = "ViewReports",
    ViewRestrictedWhiteLabelContent = "ViewRestrictedWhiteLabelContent",
    ViewTask = "ViewTask",
    ViewTenant = "ViewTenant",
}

export type Activity = {
    __typename?: "Activity";
    activityType: ActivityType;
    appLink: Scalars["String"];
    createdAt: Scalars["DateTime"];
    createdBy: Scalars["UserId"];
    createdByEntity: User;
    id: Scalars["ActivityId"];
    metadata?: Maybe<ActivityMetadata>;
    parentClient?: Maybe<Client>;
    parentClientId?: Maybe<Scalars["ClientId"]>;
    parentCommunity?: Maybe<Community>;
    parentCommunityId?: Maybe<Scalars["CommunityId"]>;
    parentEntityId: Scalars["ActivityParentEntityId"];
    parentReferenceBusiness?: Maybe<ReferenceBusiness>;
    parentReferenceBusinessId?: Maybe<Scalars["ReferenceBusinessId"]>;
    parentReferenceContact?: Maybe<ReferenceContact>;
    parentReferenceContactId?: Maybe<Scalars["ReferenceContactId"]>;
    sourceFile?: Maybe<File>;
    sourceFileId?: Maybe<Scalars["FileId"]>;
    sourceId: Scalars["ActivitySourceId"];
    sourceMessage?: Maybe<SentMessage>;
    sourceMessageId?: Maybe<Scalars["SentMessageId"]>;
    sourceNote?: Maybe<Note>;
    sourceNoteId?: Maybe<Scalars["NoteId"]>;
    sourceTask?: Maybe<Task>;
    sourceTaskId?: Maybe<Scalars["TaskId"]>;
    updatedAt: Scalars["DateTime"];
    updatedBy: Scalars["UserId"];
    updatedByEntity: User;
};

export type ActivityFeed = {
    __typename?: "ActivityFeed";
    activities: Array<ActivityFeedEvent>;
};

export type ActivityFeedEvent = {
    __typename?: "ActivityFeedEvent";
    activity: Activity;
    file?: Maybe<File>;
    message?: Maybe<SentMessage>;
    note?: Maybe<Note>;
    sourceType: ActivitySourceType;
    task?: Maybe<Task>;
};

export type ActivityMetadata = {
    __typename?: "ActivityMetadata";
    events: Array<ActivityMetadataEvent>;
    version: Scalars["Int"];
};

export type ActivityMetadataEvent = {
    __typename?: "ActivityMetadataEvent";
    entityFieldChange?: Maybe<EntityFieldChange>;
    entityReassign?: Maybe<EntityReassign>;
    type: ActivityMetadataEventType;
};

export enum ActivityMetadataEventType {
    EntityFieldChange = "EntityFieldChange",
    EntityReassign = "EntityReassign",
}

export enum ActivitySourceType {
    Entity = "Entity",
    File = "File",
    Message = "Message",
    Note = "Note",
    Task = "Task",
}

export enum ActivityType {
    EntityCreate = "EntityCreate",
    EntityFieldChange = "EntityFieldChange",
    EntityReassign = "EntityReassign",
    FileUpload = "FileUpload",
    MessageSent = "MessageSent",
    NoteCreate = "NoteCreate",
    NoteDelete = "NoteDelete",
    NoteUpdate = "NoteUpdate",
    OrganizationMemberAdded = "OrganizationMemberAdded",
    OrganizationMemberRemoved = "OrganizationMemberRemoved",
    TaskComplete = "TaskComplete",
    TaskCreate = "TaskCreate",
    TaskDelete = "TaskDelete",
    TaskReassign = "TaskReassign",
    TaskUncomplete = "TaskUncomplete",
    TaskUpdate = "TaskUpdate",
}

export type AddClientCommunityRequest = {
    communityId: Scalars["CommunityId"];
    familyView: Scalars["Boolean"];
};

export type AddExternalInvoiceRequest = {
    clientId: Scalars["ClientId"];
    externalInvoiceId: Scalars["String"];
};

export type AdditionalNotesImpl = {
    additionalClientNotes?: Maybe<Scalars["String"]>;
    primaryClientNotes?: Maybe<Scalars["String"]>;
    sameForBoth: Scalars["Boolean"];
};

export type AdvancedSearchAggregate = {
    __typename?: "AdvancedSearchAggregate";
    column: Scalars["String"];
    operator: AdvancedSearchAggregateOperator;
};

export type AdvancedSearchAggregateInput = {
    column: Scalars["String"];
    operator: AdvancedSearchAggregateOperator;
};

export enum AdvancedSearchAggregateOperator {
    Average = "Average",
    Count = "Count",
    Max = "Max",
    Min = "Min",
    Sum = "Sum",
}

export type AdvancedSearchAggregateResult = {
    __typename?: "AdvancedSearchAggregateResult";
    dateValue?: Maybe<Scalars["DateTime"]>;
    formattedValue?: Maybe<Scalars["String"]>;
    numberValue?: Maybe<Scalars["Float"]>;
    textValue?: Maybe<Scalars["String"]>;
};

export type AdvancedSearchCondition = {
    __typename?: "AdvancedSearchCondition";
    calendarDateValue?: Maybe<Scalars["String"]>;
    children?: Maybe<Array<AdvancedSearchCondition>>;
    dateValue?: Maybe<Scalars["DateTime"]>;
    fieldName?: Maybe<Scalars["String"]>;
    nodeType: AdvancedSearchConditionNodeType;
    numberValue?: Maybe<Scalars["Float"]>;
    operator?: Maybe<FieldConditionOperator>;
    promptId?: Maybe<Scalars["PromptId"]>;
    questionId?: Maybe<Scalars["QuestionId"]>;
    relativeDate?: Maybe<AdvancedSearchRelativeDate>;
    textValue?: Maybe<Scalars["String"]>;
};

/** Type of entity to search for */
export enum AdvancedSearchConditionNodeType {
    And = "And",
    FieldCondition = "FieldCondition",
    Or = "Or",
    RelationCondition = "RelationCondition",
}

/** Type of entity to search for */
export enum AdvancedSearchEntityType {
    Activity = "Activity",
    Client = "Client",
    Community = "Community",
    CommunityContact = "CommunityContact",
    Invoice = "Invoice",
    ReferenceBusiness = "ReferenceBusiness",
    ReferenceContact = "ReferenceContact",
    Task = "Task",
    WorkflowExecution = "WorkflowExecution",
}

export type AdvancedSearchGroupKey = {
    __typename?: "AdvancedSearchGroupKey";
    appLink?: Maybe<Scalars["String"]>;
    formattedValue?: Maybe<Scalars["String"]>;
    textValue?: Maybe<Scalars["String"]>;
};

export type AdvancedSearchRelativeDate = {
    __typename?: "AdvancedSearchRelativeDate";
    interval: AdvancedSearchRelativeDateInterval;
    value: Scalars["Int"];
};

/** Interval of the value in an AdvancedSearchRelativeDate */
export enum AdvancedSearchRelativeDateInterval {
    Day = "day",
    Hour = "hour",
    Minute = "minute",
    Month = "month",
    Second = "second",
    Thismonth = "thismonth",
    Today = "today",
    Week = "week",
    Year = "year",
}

export type AdvancedSearchRequest = {
    condition: AdvancedSearchRequestCondition;
    entityType: AdvancedSearchEntityType;
    page?: Maybe<Scalars["Int"]>;
    pageSize?: Maybe<Scalars["Int"]>;
    select: Array<AdvancedSearchSelectInput>;
    sort: AdvancedSearchRequestSort;
    summaries?: Maybe<Array<AdvancedSearchRequestSummaryInput>>;
};

export type AdvancedSearchRequestCondition = {
    calendarDateValue?: Maybe<Scalars["String"]>;
    children?: Maybe<Array<AdvancedSearchRequestCondition>>;
    dateValue?: Maybe<Scalars["DateTime"]>;
    fieldName?: Maybe<Scalars["String"]>;
    nodeType: AdvancedSearchConditionNodeType;
    numberValue?: Maybe<Scalars["Float"]>;
    operator?: Maybe<FieldConditionOperator>;
    promptId?: Maybe<Scalars["PromptId"]>;
    questionId?: Maybe<Scalars["QuestionId"]>;
    relativeDate?: Maybe<AdvancedSearchRequestRelativeDate>;
    textValue?: Maybe<Scalars["String"]>;
};

export type AdvancedSearchRequestRelativeDate = {
    interval: AdvancedSearchRelativeDateInterval;
    value: Scalars["Int"];
};

export type AdvancedSearchRequestSort = {
    ascending: Scalars["Boolean"];
    column?: Maybe<Scalars["String"]>;
    questionId?: Maybe<Scalars["QuestionId"]>;
};

export type AdvancedSearchRequestSummary = {
    __typename?: "AdvancedSearchRequestSummary";
    aggregates: Array<AdvancedSearchAggregate>;
    groupBy?: Maybe<Array<Scalars["String"]>>;
    page?: Maybe<Scalars["Int"]>;
    pageSize?: Maybe<Scalars["Int"]>;
    sort?: Maybe<AdvancedSearchSummarySort>;
};

export type AdvancedSearchRequestSummaryInput = {
    aggregates: Array<AdvancedSearchAggregateInput>;
    groupBy?: Maybe<Array<Scalars["String"]>>;
    page?: Maybe<Scalars["Int"]>;
    pageSize?: Maybe<Scalars["Int"]>;
    sort?: Maybe<AdvancedSearchSummarySortInput>;
};

export type AdvancedSearchResult = {
    __typename?: "AdvancedSearchResult";
    entities: Array<AdvancedSearchResultEntity>;
    page?: Maybe<Scalars["Int"]>;
    pageSize?: Maybe<Scalars["Int"]>;
    summaries?: Maybe<Array<AdvancedSearchResultSummary>>;
    total: Scalars["Int"];
};

export type AdvancedSearchResultEntity =
    | Activity
    | Client
    | Community
    | CommunityContact
    | Invoice
    | ReferenceBusiness
    | ReferenceContact
    | Task
    | WorkflowExecution;

export type AdvancedSearchResultSummary = {
    __typename?: "AdvancedSearchResultSummary";
    groupTotal: Scalars["Int"];
    groups: Array<AdvancedSearchResultSummaryGroup>;
    totals: Array<AdvancedSearchTotalResult>;
};

export type AdvancedSearchResultSummaryGroup = {
    __typename?: "AdvancedSearchResultSummaryGroup";
    aggregates: Array<AdvancedSearchAggregateResult>;
    groupKey?: Maybe<Array<AdvancedSearchGroupKey>>;
};

export type AdvancedSearchSelect = {
    __typename?: "AdvancedSearchSelect";
    fieldName?: Maybe<Scalars["String"]>;
    questionId?: Maybe<Scalars["QuestionId"]>;
    title?: Maybe<Scalars["String"]>;
};

export type AdvancedSearchSelectInput = {
    fieldName?: Maybe<Scalars["String"]>;
    questionId?: Maybe<Scalars["QuestionId"]>;
    title?: Maybe<Scalars["String"]>;
};

export type AdvancedSearchSort = {
    __typename?: "AdvancedSearchSort";
    ascending: Scalars["Boolean"];
    column?: Maybe<Scalars["String"]>;
    questionId?: Maybe<Scalars["QuestionId"]>;
};

export type AdvancedSearchSummarySort = {
    __typename?: "AdvancedSearchSummarySort";
    aggregateIndex: Scalars["Float"];
    ascending: Scalars["Boolean"];
};

export type AdvancedSearchSummarySortInput = {
    aggregateIndex: Scalars["Float"];
    ascending: Scalars["Boolean"];
};

export type AdvancedSearchTotalResult = {
    __typename?: "AdvancedSearchTotalResult";
    dateValue?: Maybe<Scalars["DateTime"]>;
    formattedValue?: Maybe<Scalars["String"]>;
    numberValue?: Maybe<Scalars["Float"]>;
    textValue?: Maybe<Scalars["String"]>;
};

/** Individual answer */
export type Answer = {
    __typename?: "Answer";
    booleanAnswer?: Maybe<Scalars["Boolean"]>;
    calendarDateAnswer?: Maybe<Scalars["String"]>;
    dateAnswer?: Maybe<Scalars["String"]>;
    idReferenceAnswer?: Maybe<Scalars["UserId"]>;
    numberAnswer?: Maybe<Scalars["Float"]>;
    numberAnswerHigh?: Maybe<Scalars["Float"]>;
    questionId: Scalars["QuestionId"];
    selectionAnnotations?: Maybe<Array<AnswerSelectionAnnotation>>;
    selections?: Maybe<Array<Scalars["String"]>>;
    textAnswer?: Maybe<Scalars["String"]>;
};

export type AnswerInput = {
    booleanAnswer?: Maybe<Scalars["Boolean"]>;
    calendarDateAnswer?: Maybe<Scalars["String"]>;
    dateAnswer?: Maybe<Scalars["String"]>;
    idReferenceAnswer?: Maybe<Scalars["UserId"]>;
    numberAnswer?: Maybe<Scalars["Float"]>;
    numberAnswerHigh?: Maybe<Scalars["Float"]>;
    questionId?: Maybe<Scalars["QuestionId"]>;
    selectionAnnotations?: Maybe<Array<AnswerSelectionAnnotationInput>>;
    selections?: Maybe<Array<Scalars["String"]>>;
    textAnswer?: Maybe<Scalars["String"]>;
};

/** Answer selection annotation */
export type AnswerSelectionAnnotation = {
    __typename?: "AnswerSelectionAnnotation";
    selectionId?: Maybe<Scalars["String"]>;
    text?: Maybe<Scalars["String"]>;
};

export type AnswerSelectionAnnotationInput = {
    selectionId?: Maybe<Scalars["String"]>;
    text?: Maybe<Scalars["String"]>;
};

/** Attachment scopes */
export type AttachmentScope = {
    __typename?: "AttachmentScope";
    entityName: Scalars["String"];
    files: Array<File>;
};

export type AuditRecord = {
    __typename?: "AuditRecord";
    actorTenantId: Scalars["TenantId"];
    actorUserId: Scalars["UserId"];
    affectedTenantId?: Maybe<Scalars["TenantId"]>;
    affectedUserId?: Maybe<Scalars["UserId"]>;
    auditId: Scalars["UUID"];
    newUser?: Maybe<AuditRecordUpdateUserDetails>;
    oldUser?: Maybe<AuditRecordUpdateUserDetails>;
    operation: ActionType;
    subOperation?: Maybe<Scalars["String"]>;
    when: Scalars["DateTime"];
};

export type AuditRecordUpdateUserDetails = {
    __typename?: "AuditRecordUpdateUserDetails";
    disabled: Scalars["Boolean"];
    email: Scalars["String"];
    firstName?: Maybe<Scalars["String"]>;
    hidden: Scalars["Boolean"];
};

/** Result of batchUpdateCommunityMaster operation */
export type BatchUpdateCommunityMasterResult = {
    __typename?: "BatchUpdateCommunityMasterResult";
    communitiesCreated: Scalars["Int"];
    duplicates: Scalars["Int"];
    error: Scalars["Int"];
    message?: Maybe<Scalars["String"]>;
    skipped: Scalars["Int"];
};

export type BoundsInput = {
    northWest: CoordinatesInput;
    southEast: CoordinatesInput;
};

export type BridgeEntityResult = {
    __typename?: "BridgeEntityResult";
    allowAnswers: Scalars["Boolean"];
    defaultSelect?: Maybe<Array<AdvancedSearchSelect>>;
    fields: Array<BridgeFieldResult>;
    identifierFields: Array<Scalars["String"]>;
    layoutParents: Array<LayoutSectionParentKey>;
    name: Scalars["String"];
    preferredDisplay?: Maybe<BridgePreferredDisplay>;
    preferredFilterField?: Maybe<Scalars["String"]>;
    preferredGroupBy?: Maybe<Array<Scalars["String"]>>;
    priorityFields: Array<AdvancedSearchSelect>;
    questions: Array<BridgeQuestion>;
    relations: Array<BridgeRelationResult>;
    title: Scalars["String"];
};

/** Strategy to format this field */
export enum BridgeFieldFormatter {
    Computed = "Computed",
    CurrencyInCents = "CurrencyInCents",
    DateAndTime = "DateAndTime",
    Percentage = "Percentage",
    Type = "Type",
}

export type BridgeFieldResult = {
    __typename?: "BridgeFieldResult";
    aliases?: Maybe<Array<Scalars["String"]>>;
    category: Scalars["Float"];
    customListKey?: Maybe<CustomListKey>;
    dependsOn?: Maybe<Array<Scalars["String"]>>;
    formatter: BridgeFieldFormatter;
    name: Scalars["String"];
    naryValues?: Maybe<Array<BridgeNaryValueResult>>;
    questionType: Scalars["Float"];
    reportBehavior: BridgeReportBehaviorResult;
    title: Scalars["String"];
};

export type BridgeNaryValueResult = {
    __typename?: "BridgeNaryValueResult";
    label: Scalars["String"];
    value: Scalars["String"];
};

export type BridgePreferredDisplay = {
    __typename?: "BridgePreferredDisplay";
    fieldName?: Maybe<Scalars["String"]>;
    relation?: Maybe<BridgePreferredRelationDisplay>;
};

export type BridgePreferredRelationDisplay = {
    __typename?: "BridgePreferredRelationDisplay";
    name: Scalars["String"];
};

export type BridgePromptInput = {
    id: Scalars["String"];
    key: Scalars["String"];
    order: Scalars["Float"];
    title: Scalars["String"];
};

export type BridgeQuestion = {
    __typename?: "BridgeQuestion";
    annotation: Scalars["String"];
    category: Scalars["String"];
    editable: Scalars["Boolean"];
    id: Scalars["QuestionId"];
    interaction: Scalars["String"];
    key: Scalars["String"];
    order: Scalars["Float"];
    prompts?: Maybe<Array<BridgeQuestionPrompt>>;
    questionType: Scalars["String"];
    regionMask: Array<Scalars["String"]>;
    regions: Array<Scalars["RegionId"]>;
    rulesString?: Maybe<Scalars["String"]>;
    searchBehavior: Scalars["String"];
    source: QuestionSource;
    title: Scalars["String"];
};

export type BridgeQuestionInput = {
    annotation: Scalars["String"];
    category: Scalars["String"];
    id: Scalars["QuestionId"];
    interaction: Scalars["String"];
    key: Scalars["String"];
    order: Scalars["Float"];
    prompts?: Maybe<Array<BridgePromptInput>>;
    questionType: Scalars["String"];
    regions: Array<Scalars["RegionId"]>;
    title: Scalars["String"];
};

export type BridgeQuestionPermissions = {
    __typename?: "BridgeQuestionPermissions";
    canEditAllRegions: Scalars["Boolean"];
    editableRegions: Array<BridgeQuestionRegion>;
};

export type BridgeQuestionPrompt = {
    __typename?: "BridgeQuestionPrompt";
    annotation: Scalars["Boolean"];
    id: Scalars["PromptId"];
    key: Scalars["String"];
    order: Scalars["Float"];
    title: Scalars["String"];
};

export type BridgeQuestionRegion = {
    __typename?: "BridgeQuestionRegion";
    id: Scalars["RegionId"];
    key: Scalars["String"];
    name: Scalars["String"];
};

/** Relation type */
export enum BridgeRelationKind {
    ManyToOne = "ManyToOne",
    OneToMany = "OneToMany",
    OneToOne = "OneToOne",
}

export type BridgeRelationResult = {
    __typename?: "BridgeRelationResult";
    kind: BridgeRelationKind;
    name: Scalars["String"];
    otherEntityName: Scalars["String"];
    selectable: Scalars["Boolean"];
    title: Scalars["String"];
};

export type BridgeReportBehaviorResult = {
    __typename?: "BridgeReportBehaviorResult";
    canAggregate: Scalars["Boolean"];
    filterable: Scalars["Boolean"];
    newReportSelectOrder?: Maybe<Scalars["Float"]>;
    selectable: Scalars["Boolean"];
    sortable: Scalars["Boolean"];
    summaryGroupable: Scalars["Boolean"];
};

export type CalendarSubscription = {
    __typename?: "CalendarSubscription";
    calendarUrl: Scalars["String"];
};

export type ClickedLink = {
    __typename?: "ClickedLink";
    events: Array<Scalars["String"]>;
    eventsV2?: Maybe<Array<ClickedLinkEvent>>;
    link: Scalars["String"];
    redacted?: Maybe<Scalars["Boolean"]>;
};

export type ClickedLinkEvent = {
    __typename?: "ClickedLinkEvent";
    recipient?: Maybe<Scalars["String"]>;
    timestamp: Scalars["String"];
};

/** Client */
export type Client = {
    __typename?: "Client";
    activityEntities?: Maybe<Array<Activity>>;
    additionalClientBirthday?: Maybe<Scalars["String"]>;
    additionalClientIsBirthdayFake?: Maybe<Scalars["Boolean"]>;
    additionalClientName?: Maybe<Scalars["String"]>;
    address?: Maybe<Scalars["String"]>;
    allContacts: Array<Contact>;
    answers: Array<Answer>;
    appLink: Scalars["String"];
    assignedUser?: Maybe<User>;
    assignedUserId?: Maybe<Scalars["UserId"]>;
    baseLocation?: Maybe<Coordinates>;
    bestContactEmail?: Maybe<Scalars["String"]>;
    bestContactEntity?: Maybe<ContactEntity>;
    bestContactName?: Maybe<Scalars["String"]>;
    bestContactPhone?: Maybe<Scalars["String"]>;
    bestLocationAddress?: Maybe<Scalars["String"]>;
    bestLocationCity?: Maybe<Scalars["String"]>;
    bestLocationState?: Maybe<Scalars["String"]>;
    bestLocationZip?: Maybe<Scalars["String"]>;
    birthday?: Maybe<Scalars["String"]>;
    budgetMonthly?: Maybe<Scalars["String"]>;
    budgetTotal?: Maybe<Scalars["String"]>;
    cellPhone?: Maybe<Scalars["String"]>;
    chosenCommunity?: Maybe<Community>;
    chosenCommunityId?: Maybe<Scalars["CommunityId"]>;
    city?: Maybe<Scalars["String"]>;
    clientCommunities: Array<ClientCommunity>;
    contactedOtherAgency?: Maybe<Scalars["Boolean"]>;
    contactedOtherAgencyNotes?: Maybe<Scalars["String"]>;
    createdBy?: Maybe<Scalars["UserId"]>;
    currentLocationNotes?: Maybe<Scalars["String"]>;
    currentLocationType?: Maybe<Scalars["String"]>;
    dateAdded: Scalars["DateTime"];
    dateAddedManualOverride?: Maybe<Scalars["String"]>;
    dischargeDate?: Maybe<Scalars["String"]>;
    disclosures?: Maybe<Array<Disclosure>>;
    email?: Maybe<Scalars["String"]>;
    emailOptOut?: Maybe<Scalars["Boolean"]>;
    gender?: Maybe<Scalars["String"]>;
    height?: Maybe<Scalars["String"]>;
    id: Scalars["ClientId"];
    insuranceAdditionalCoverage?: Maybe<Scalars["String"]>;
    insuranceAdditionalPhone?: Maybe<Scalars["String"]>;
    insuranceAdditionalPolicyNumber?: Maybe<Scalars["String"]>;
    insuranceCompany?: Maybe<Scalars["String"]>;
    insuranceNotes?: Maybe<Scalars["String"]>;
    insurancePhone?: Maybe<Scalars["String"]>;
    insurancePolicyNumber?: Maybe<Scalars["String"]>;
    insurancePreAuthRequired?: Maybe<Scalars["Boolean"]>;
    invoiceSentDate?: Maybe<Scalars["String"]>;
    invoices?: Maybe<Array<Invoice>>;
    isBirthdayFake?: Maybe<Scalars["Boolean"]>;
    locationName?: Maybe<Scalars["String"]>;
    maritalStatus?: Maybe<Scalars["String"]>;
    moveTimeline?: Maybe<Scalars["String"]>;
    moveTimelineActual?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    nextTask?: Maybe<Task>;
    noteEntities?: Maybe<Array<Note>>;
    otherHealthNotes?: Maybe<Scalars["String"]>;
    paymentReceivedDate?: Maybe<Scalars["String"]>;
    phone?: Maybe<Scalars["String"]>;
    potentialDuplicates: Array<ClientMatchResponse>;
    preferredName: Scalars["String"];
    referralBusiness?: Maybe<ReferenceBusiness>;
    referralCommunity?: Maybe<Community>;
    referralContact?: Maybe<ReferenceContact>;
    referralNotes?: Maybe<Scalars["String"]>;
    regionId: Scalars["RegionId"];
    serial?: Maybe<Scalars["Int"]>;
    signedAgencyAgreement?: Maybe<Scalars["Boolean"]>;
    state?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
    statusCheckLaterDate?: Maybe<Scalars["String"]>;
    statusClosedDate?: Maybe<Scalars["String"]>;
    statusIntakeCompletedDate?: Maybe<Scalars["String"]>;
    statusListItem?: Maybe<CustomListItem>;
    statusListItemId: Scalars["CustomListItemId"];
    totalInvoiceAmountCents: Scalars["Int"];
    touredPreviously?: Maybe<Scalars["Boolean"]>;
    touredPreviouslyNotes?: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
    updatedBy?: Maybe<Scalars["UserId"]>;
    weight?: Maybe<Scalars["String"]>;
    zip?: Maybe<Scalars["String"]>;
};

export type ClientCommunity = {
    __typename?: "ClientCommunity";
    client: Client;
    clientId: Scalars["ClientId"];
    community: Community;
    communityId: Scalars["CommunityId"];
    createdBy?: Maybe<Scalars["UserId"]>;
    dateAdded: Scalars["DateTime"];
    order?: Maybe<Scalars["Int"]>;
    privateNotes?: Maybe<Scalars["String"]>;
    publicNotes?: Maybe<Scalars["String"]>;
    referred: Scalars["Boolean"];
    relationship: Scalars["Int"];
    updatedAt: Scalars["DateTime"];
    updatedBy?: Maybe<Scalars["UserId"]>;
};

/** client contact entity */
export type ClientContact = {
    __typename?: "ClientContact";
    client: Client;
    clientId: Scalars["ClientId"];
    contact: ContactEntity;
    contactId: Scalars["ContactId"];
    order: Scalars["Float"];
    powerOfAttorney?: Maybe<Array<Scalars["String"]>>;
    relationship: ClientContactRelationship;
};

export enum ClientContactRelationship {
    CareProvider = "CareProvider",
    MoreContact = "MoreContact",
}

export type ClientEntity = CrmEntity & {
    __typename?: "ClientEntity";
    contact?: Maybe<CrmEntityContact>;
    displayName: Scalars["String"];
    kind: Scalars["String"];
    kindTitle: Scalars["String"];
    path: Scalars["String"];
};

export type ClientIntakeFaxRecipient = {
    __typename?: "ClientIntakeFaxRecipient";
    communityId: Scalars["CommunityId"];
    communityName: Scalars["String"];
    faxNumber?: Maybe<Scalars["String"]>;
};

export type ClientMatchResponse = {
    __typename?: "ClientMatchResponse";
    assignedUser?: Maybe<User>;
    bestContactName?: Maybe<Scalars["String"]>;
    dateAdded: Scalars["DateTime"];
    id: Scalars["ClientId"];
    name?: Maybe<Scalars["String"]>;
    viewable: Scalars["Boolean"];
};

/** Client placement revenue by time period breakdown */
export type ClientPlacementRevenueResult = {
    __typename?: "ClientPlacementRevenueResult";
    segments: Array<ReportSegment>;
};

export type ClientSearchRequest = {
    archived?: Maybe<Scalars["Boolean"]>;
    assignedReferralUserId?: Maybe<Scalars["UserId"]>;
    assignedUserId?: Maybe<Scalars["UserId"]>;
    excludeStatuses?: Maybe<Array<Scalars["String"]>>;
    includeStatuses?: Maybe<Array<Scalars["String"]>>;
    moveTimelineWindow?: Maybe<Scalars["String"]>;
    openClosed?: Maybe<Scalars["String"]>;
    /** 1-indexed page number */
    page: Scalars["Int"];
    perPage: Scalars["Int"];
    referralBusinessId?: Maybe<Scalars["ReferenceBusinessId"]>;
    referralCommunityId?: Maybe<Scalars["CommunityId"]>;
    referralContactId?: Maybe<Scalars["ReferenceContactId"]>;
    regionId: Scalars["RegionId"];
    relatedTo?: Maybe<Array<Scalars["ClientId"]>>;
    searchString?: Maybe<Scalars["String"]>;
    searchType?: Maybe<Scalars["String"]>;
    sort: ClientSearchSort;
    status?: Maybe<Scalars["String"]>;
    statusAtOrEarlier?: Maybe<Scalars["String"]>;
    statusAtOrLater?: Maybe<Scalars["String"]>;
};

export enum ClientSearchSort {
    BestContactAsc = "BestContactAsc",
    BestContactDesc = "BestContactDesc",
    CreatedAtAsc = "CreatedAtAsc",
    CreatedAtDesc = "CreatedAtDesc",
    NameAsc = "NameAsc",
    NameDesc = "NameDesc",
    TaskDueAsc = "TaskDueAsc",
    TaskDueDesc = "TaskDueDesc",
    UpdatedAtAsc = "UpdatedAtAsc",
    UpdatedAtDesc = "UpdatedAtDesc",
}

/** result of getClients */
export type ClientsSearchResult = {
    __typename?: "ClientsSearchResult";
    activeCount: Scalars["Int"];
    clients: Array<Client>;
    closedCount: Scalars["Int"];
    total: Scalars["Int"];
};

/** Tenant's community instance */
export type Community = {
    __typename?: "Community";
    activityEntities?: Maybe<Array<Activity>>;
    address?: Maybe<Scalars["String"]>;
    answers: Array<Answer>;
    appLink: Scalars["String"];
    caregiverRatio?: Maybe<Scalars["Float"]>;
    city?: Maybe<Scalars["String"]>;
    communityContacts: Array<CommunityContact>;
    communityMasterId: Scalars["CommunityMasterId"];
    communityMasterVersion?: Maybe<Scalars["Int"]>;
    contacts: Array<ContactEntity>;
    contractExpiresDate?: Maybe<Scalars["DateTime"]>;
    contractIssuedDate?: Maybe<Scalars["DateTime"]>;
    contractNotes?: Maybe<Scalars["String"]>;
    contractStatus?: Maybe<Scalars["String"]>;
    county?: Maybe<Scalars["String"]>;
    createdBy: Scalars["UserId"];
    dateAdded: Scalars["DateTime"];
    email?: Maybe<Scalars["String"]>;
    emailOptOut: Scalars["Boolean"];
    faxNumber?: Maybe<Scalars["String"]>;
    feeType?: Maybe<Scalars["String"]>;
    gender?: Maybe<Scalars["String"]>;
    id: Scalars["CommunityId"];
    invoices?: Maybe<Array<Invoice>>;
    latitude?: Maybe<Scalars["String"]>;
    licenseStatus?: Maybe<Scalars["String"]>;
    licenses: Array<CommunityLicense>;
    longitude?: Maybe<Scalars["String"]>;
    mainPhone?: Maybe<Scalars["String"]>;
    matchInfo: CommunityMatchInfo;
    monthlyPrice?: Maybe<Scalars["Float"]>;
    moveInDeposit?: Maybe<Scalars["Float"]>;
    name?: Maybe<Scalars["String"]>;
    noteEntities?: Maybe<Array<Note>>;
    paymentFee?: Maybe<Scalars["String"]>;
    paymentPercentage?: Maybe<Scalars["String"]>;
    paymentType?: Maybe<Scalars["String"]>;
    petDeposit?: Maybe<Scalars["String"]>;
    photoEntities: Array<Photo>;
    photos?: Maybe<Array<File>>;
    priceDetails?: Maybe<Scalars["String"]>;
    priceHighEnd?: Maybe<Scalars["Float"]>;
    primaryContacts: Array<ContactEntity>;
    rating?: Maybe<Scalars["Float"]>;
    regionId: Scalars["RegionId"];
    secondPersonFee?: Maybe<Scalars["String"]>;
    state?: Maybe<Scalars["String"]>;
    summary?: Maybe<Scalars["String"]>;
    thumbnailUrl?: Maybe<Scalars["String"]>;
    unitsOrCapacity?: Maybe<Scalars["Int"]>;
    updatedAt: Scalars["DateTime"];
    updatedBy: Scalars["UserId"];
    virtualTourURL?: Maybe<Scalars["String"]>;
    website?: Maybe<Scalars["String"]>;
    zip?: Maybe<Scalars["String"]>;
};

export type CommunityContact = {
    __typename?: "CommunityContact";
    appLink: Scalars["String"];
    community: Community;
    communityId: Scalars["CommunityId"];
    contact: ContactEntity;
    contactId: Scalars["ContactId"];
    primary: Scalars["Boolean"];
};

export type CommunityContactDto = {
    __typename?: "CommunityContactDto";
    communityId: Scalars["CommunityId"];
    contact: ContactEntity;
    contactId: Scalars["ContactId"];
    primary: Scalars["Boolean"];
    referenceContact?: Maybe<ReferenceContact>;
};

export type CommunityCoordinates = {
    __typename?: "CommunityCoordinates";
    appLink: Scalars["String"];
    contractStatus?: Maybe<Scalars["String"]>;
    id: Scalars["CommunityId"];
    lat: Scalars["Float"];
    lng: Scalars["Float"];
    name?: Maybe<Scalars["String"]>;
};

export type CommunityEntity = CrmEntity & {
    __typename?: "CommunityEntity";
    contact?: Maybe<CrmEntityContact>;
    displayName?: Maybe<Scalars["String"]>;
    kind: Scalars["String"];
    kindTitle: Scalars["String"];
    path: Scalars["String"];
};

export type CommunityFaxNumber = {
    communityId: Scalars["CommunityId"];
    faxNumber: Scalars["String"];
};

/** Individual license record for community */
export type CommunityLicense = {
    __typename?: "CommunityLicense";
    communityId: Scalars["CommunityId"];
    dateAdded?: Maybe<Scalars["DateTime"]>;
    evergreenLicensesId?: Maybe<Scalars["LicenseId"]>;
    href?: Maybe<Scalars["String"]>;
    id: Scalars["CommunityLicenseId"];
    licenseEndDate?: Maybe<Scalars["String"]>;
    licenseNumber?: Maybe<Scalars["String"]>;
    licenseStartDate?: Maybe<Scalars["String"]>;
    licensee?: Maybe<Scalars["String"]>;
    status?: Maybe<Scalars["String"]>;
    tenantId: Scalars["TenantId"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CommunityLicenseInput = {
    communityId: Scalars["CommunityId"];
    dateAdded?: Maybe<Scalars["DateTime"]>;
    evergreenLicensesId?: Maybe<Scalars["LicenseId"]>;
    href?: Maybe<Scalars["String"]>;
    id: Scalars["CommunityLicenseId"];
    licenseEndDate?: Maybe<Scalars["String"]>;
    licenseNumber?: Maybe<Scalars["String"]>;
    licenseStartDate?: Maybe<Scalars["String"]>;
    licensee?: Maybe<Scalars["String"]>;
    status?: Maybe<Scalars["String"]>;
    tenantId: Scalars["TenantId"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CommunityMarkerCollection = {
    __typename?: "CommunityMarkerCollection";
    communityClusters: Array<MapCluster>;
    communityCoordinates: Array<CommunityCoordinates>;
    /** lat/lng coordinates if applicable */
    coordinates?: Maybe<Coordinates>;
    recommendedZoom: Scalars["Float"];
};

/** Community <-> CommunityMaster match details */
export type CommunityMatchInfo = {
    __typename?: "CommunityMatchInfo";
    matchRule?: Maybe<Scalars["String"]>;
    matchScore?: Maybe<Scalars["Float"]>;
    originalMatchDate?: Maybe<Scalars["String"]>;
};

/** Individual community name */
export type CommunityName = {
    __typename?: "CommunityName";
    id: Scalars["CommunityId"];
    /** name of community */
    name?: Maybe<Scalars["String"]>;
};

/** All community names and IDs */
export type CommunityNamesResult = {
    __typename?: "CommunityNamesResult";
    communities: Array<CommunityName>;
};

export type CommunityOrdering = {
    communityId: Scalars["CommunityId"];
    order: Scalars["Int"];
};

/** Individual community with metadata */
export type CommunitySearchHit = {
    __typename?: "CommunitySearchHit";
    address?: Maybe<Scalars["String"]>;
    answers: Array<Answer>;
    caregiverRatio?: Maybe<Scalars["Float"]>;
    city?: Maybe<Scalars["String"]>;
    contacts: Array<ContactObject>;
    contractExpiresDate?: Maybe<Scalars["DateTime"]>;
    contractIssuedDate?: Maybe<Scalars["DateTime"]>;
    contractNotes?: Maybe<Scalars["String"]>;
    /** contractStatus */
    contractStatus?: Maybe<Scalars["String"]>;
    county?: Maybe<Scalars["String"]>;
    dateAdded?: Maybe<Scalars["DateTime"]>;
    email?: Maybe<Scalars["String"]>;
    feeType?: Maybe<Scalars["String"]>;
    gender?: Maybe<Scalars["String"]>;
    id: Scalars["CommunityId"];
    /** latitude */
    lat?: Maybe<Scalars["String"]>;
    /** licenseStatus */
    licenseStatus?: Maybe<Scalars["String"]>;
    licenses?: Maybe<Array<CommunityLicense>>;
    /** longitude */
    lng?: Maybe<Scalars["String"]>;
    mainPhone?: Maybe<Scalars["String"]>;
    monthlyPrice?: Maybe<Scalars["String"]>;
    /** Most recent placement as CalendarDate-parseable */
    mostRecentPlacement?: Maybe<Scalars["String"]>;
    moveInDeposit?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    paymentFee?: Maybe<Scalars["String"]>;
    paymentPercentage?: Maybe<Scalars["String"]>;
    petDeposit?: Maybe<Scalars["String"]>;
    phone?: Maybe<Scalars["String"]>;
    /** Count of placements */
    placements: Scalars["Int"];
    priceDetails?: Maybe<Scalars["String"]>;
    priceHighEnd?: Maybe<Scalars["String"]>;
    primaryContacts: Array<ContactObject>;
    /** rating */
    rating?: Maybe<Scalars["Float"]>;
    referralCount: Scalars["Int"];
    regionId?: Maybe<Scalars["RegionId"]>;
    secondPersonFee?: Maybe<Scalars["String"]>;
    showInSearchResults: Scalars["Boolean"];
    state?: Maybe<Scalars["String"]>;
    summary?: Maybe<Scalars["String"]>;
    /** thumbnail */
    thumbnail?: Maybe<Scalars["String"]>;
    /** Capactity */
    unitsOrCapacity?: Maybe<Scalars["Int"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    url?: Maybe<Scalars["String"]>;
    zip?: Maybe<Scalars["String"]>;
};

export type CommunitySearchRangeFilter = {
    max: Scalars["Int"];
    min: Scalars["Int"];
    questionId: Scalars["QuestionId"];
};

export type CommunitySearchRequest = {
    /** geo lookup address */
    address?: Maybe<Scalars["String"]>;
    /** (advanced) Maximum number of beds */
    bedCountMax?: Maybe<Scalars["Int"]>;
    /** (advanced) Minimum number of beds */
    bedCountMin?: Maybe<Scalars["Int"]>;
    /** List of boolean answers that must be true */
    booleans?: Maybe<Array<Scalars["String"]>>;
    /** geo lookup bounds */
    bounds?: Maybe<BoundsInput>;
    /** geo lookup city */
    city?: Maybe<Scalars["String"]>;
    /** Only include results from this list of IDs */
    communityIdFilter?: Maybe<Array<Scalars["CommunityId"]>>;
    /** Contract status (comma separated) */
    contract?: Maybe<Scalars["String"]>;
    /** geo lookup county */
    county?: Maybe<Scalars["String"]>;
    /** geo lookup distance */
    distance?: Maybe<Scalars["Int"]>;
    gender?: Maybe<Array<Scalars["String"]>>;
    /** Hide "showInSearchResults=false" (aka !"Allow clients to be matched with this community") hits */
    hideDontShow: Scalars["Boolean"];
    includeLicenses?: Maybe<Scalars["Boolean"]>;
    /** Include communities with no base price */
    includeNoPrice: Scalars["Boolean"];
    includeQuestionIds?: Maybe<Array<Scalars["QuestionId"]>>;
    /** Include unrated */
    includeUnrated: Scalars["Boolean"];
    /** License status (comma separated) */
    license?: Maybe<Scalars["String"]>;
    /** (advanced) Matched high score */
    matchHighScore?: Maybe<Scalars["Int"]>;
    /** (advanced) Matched low score */
    matchLowScore?: Maybe<Scalars["Int"]>;
    /** (advanced) Matched rule */
    matchRule?: Maybe<Scalars["String"]>;
    /** geo lookup type (one of distance | zip | city | bounds | county) */
    matchType?: Maybe<Scalars["String"]>;
    /** List of nary values, one of which must be found for a community to be returned (format questionId:val1,val2) */
    nary?: Maybe<Array<Scalars["String"]>>;
    /** Page of results */
    page: Scalars["Int"];
    /** Number of results per page */
    perPage: Scalars["Int"];
    /** Price high end */
    priceMax: Scalars["Int"];
    /** Price low end */
    priceMin: Scalars["Int"];
    /** Advanced range filters */
    rangeFilters?: Maybe<Array<CommunitySearchRangeFilter>>;
    /** Minimum rating */
    rating: Scalars["Int"];
    /** Region for search */
    regionId: Scalars["RegionId"];
    /** Search string */
    searchString?: Maybe<Scalars["String"]>;
    /** Sort key ("name" | "distance" | "number" | "recent") */
    sortKey?: Maybe<Scalars["String"]>;
    /** geo lookup state */
    state?: Maybe<Scalars["String"]>;
    /** geo lookup zip */
    zip?: Maybe<Scalars["String"]>;
};

export type CommunitySearchRequestMapComponent = {
    bounds?: Maybe<BoundsInput>;
    viewportHeight: Scalars["Float"];
    viewportWidth: Scalars["Float"];
    zoomLevel?: Maybe<Scalars["Float"]>;
};

/** Community search results */
export type CommunitySearchResult = {
    __typename?: "CommunitySearchResult";
    /** lat/lng coordinates if applicable */
    coordinates?: Maybe<Coordinates>;
    hits: Array<CommunitySearchHit>;
    /** Total number of results */
    total: Scalars["Int"];
};

/** Community update response */
export type CommunityUpdateResult = {
    __typename?: "CommunityUpdateResult";
    propagations: Array<PropagationResult>;
};

/** User */
export type ConcreteUser = {
    __typename?: "ConcreteUser";
    /** Human readable name */
    displayName: Scalars["String"];
    /** First name for bulk emails to our users */
    friendlyFirstName: Scalars["String"];
    /** User ID */
    id: Scalars["UserId"];
};

/** contact entity */
export type Contact = {
    __typename?: "Contact";
    address1?: Maybe<Scalars["String"]>;
    address2?: Maybe<Scalars["String"]>;
    cellPhone?: Maybe<Scalars["String"]>;
    city?: Maybe<Scalars["String"]>;
    clientPowerOfAttorney?: Maybe<Array<Scalars["String"]>>;
    contactNotes?: Maybe<Scalars["String"]>;
    contactType?: Maybe<Scalars["String"]>;
    email1?: Maybe<Scalars["String"]>;
    fax?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    phone1?: Maybe<Scalars["String"]>;
    preferredName?: Maybe<Scalars["String"]>;
    relationship?: Maybe<Scalars["String"]>;
    role?: Maybe<Scalars["String"]>;
    state?: Maybe<Scalars["String"]>;
    type?: Maybe<Scalars["String"]>;
    website?: Maybe<Scalars["String"]>;
    zip?: Maybe<Scalars["String"]>;
};

/** contact entity */
export type ContactEntity = {
    __typename?: "ContactEntity";
    address1?: Maybe<Scalars["String"]>;
    address2?: Maybe<Scalars["String"]>;
    cellPhone?: Maybe<Scalars["String"]>;
    city?: Maybe<Scalars["String"]>;
    clientContacts: Array<ClientContact>;
    clientPowerOfAttorney?: Maybe<Array<Scalars["String"]>>;
    communityContacts: Array<CommunityContact>;
    contactNotes?: Maybe<Scalars["String"]>;
    contactType?: Maybe<Scalars["String"]>;
    email1?: Maybe<Scalars["String"]>;
    email1OptOut: Scalars["Boolean"];
    fax?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["ContactId"]>;
    latitude?: Maybe<Scalars["String"]>;
    longitude?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    phone1?: Maybe<Scalars["String"]>;
    phoneNumbers: Array<PhoneNumber>;
    preferredName?: Maybe<Scalars["String"]>;
    referenceContacts: Array<ReferenceContact>;
    relationship?: Maybe<Scalars["String"]>;
    role?: Maybe<Scalars["String"]>;
    state?: Maybe<Scalars["String"]>;
    type?: Maybe<Scalars["String"]>;
    website?: Maybe<Scalars["String"]>;
    zip?: Maybe<Scalars["String"]>;
};

/** contact entity */
export type ContactInput = {
    address1?: Maybe<Scalars["String"]>;
    address2?: Maybe<Scalars["String"]>;
    cellPhone?: Maybe<Scalars["String"]>;
    city?: Maybe<Scalars["String"]>;
    clientPowerOfAttorney?: Maybe<Scalars["String"]>;
    contactNotes?: Maybe<Scalars["String"]>;
    contactType?: Maybe<Scalars["String"]>;
    email1?: Maybe<Scalars["String"]>;
    email1OptOut?: Maybe<Scalars["Boolean"]>;
    fax?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["ContactId"]>;
    name?: Maybe<Scalars["String"]>;
    phone1?: Maybe<Scalars["String"]>;
    phoneNumbers?: Maybe<Array<PhoneNumberInput>>;
    preferredName?: Maybe<Scalars["String"]>;
    primary?: Maybe<Scalars["Boolean"]>;
    relationship?: Maybe<Scalars["String"]>;
    role?: Maybe<Scalars["String"]>;
    state?: Maybe<Scalars["String"]>;
    type?: Maybe<Scalars["String"]>;
    website?: Maybe<Scalars["String"]>;
    zip?: Maybe<Scalars["String"]>;
};

/** Individual contact */
export type ContactObject = {
    __typename?: "ContactObject";
    cellPhone?: Maybe<Scalars["String"]>;
    email1?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    phone1?: Maybe<Scalars["String"]>;
    phoneNumbers: Array<PhoneNumber>;
    role?: Maybe<Scalars["String"]>;
};

/** latitude/longitude coordinates */
export type Coordinates = {
    __typename?: "Coordinates";
    lat: Scalars["String"];
    lng: Scalars["String"];
};

export type CoordinatesInput = {
    lat: Scalars["String"];
    lng: Scalars["String"];
};

export type CreateClientContactInput = {
    relationship: ClientContactRelationship;
};

export type CreateContactInput = {
    clientContactParams?: Maybe<CreateClientContactInput>;
    linkedContactId?: Maybe<Scalars["ContactId"]>;
    linkedEntityId: Scalars["AnswerEntityId"];
    linkedEntityType: AdvancedSearchEntityType;
    referenceContactParams?: Maybe<ReferenceContactInput>;
};

export type CreateCustomListItemRequest = {
    customListId: Scalars["CustomListId"];
    name: Scalars["String"];
};

export type CreateExternalInvoiceCustomerRequest = {
    name: Scalars["String"];
};

export type CreateLayoutSectionInput = {
    entityType: Scalars["String"];
    layoutContainerId: Scalars["LayoutContainerId"];
};

export type CreateNoteInput = {
    text: Scalars["String"];
};

export type CreateSmtpCredentialRequest = {
    host: Scalars["String"];
    password: Scalars["String"];
    port: Scalars["Int"];
    username: Scalars["String"];
};

export type CreateTaskPayload = {
    completedAt?: Maybe<Scalars["DateTime"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    dueDate?: Maybe<Scalars["String"]>;
    dueDateTime?: Maybe<Scalars["DateTime"]>;
    notes?: Maybe<Scalars["String"]>;
    ownerId?: Maybe<Scalars["UserId"]>;
    status?: Maybe<Scalars["String"]>;
    taskTypeId?: Maybe<Scalars["TaskTypeId"]>;
    text?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type CreateTaskTypePayload = {
    name: Scalars["String"];
};

export type CrmEntity = {
    contact?: Maybe<CrmEntityContact>;
    displayName?: Maybe<Scalars["String"]>;
    kind: Scalars["String"];
    kindTitle: Scalars["String"];
    path: Scalars["String"];
};

export type CrmEntityContact = {
    __typename?: "CrmEntityContact";
    contactType: CrmEntityContactType;
    name?: Maybe<Scalars["String"]>;
    phoneNumber?: Maybe<Scalars["String"]>;
};

export enum CrmEntityContactType {
    BestContact = "BestContact",
    Cell = "Cell",
    Main = "Main",
    Other = "Other",
}

export type CustomList = {
    __typename?: "CustomList";
    categories: Array<CustomListCategory>;
    id: Scalars["CustomListId"];
    items: Array<CustomListItem>;
    key: CustomListKey;
};

export type CustomListCategory = {
    __typename?: "CustomListCategory";
    customListId: Scalars["CustomListId"];
    id: Scalars["CustomListCategoryId"];
    key: Scalars["String"];
    name: Scalars["String"];
    order: Scalars["Int"];
};

export type CustomListItem = {
    __typename?: "CustomListItem";
    customListCategoryId?: Maybe<Scalars["CustomListCategoryId"]>;
    customListId: Scalars["CustomListId"];
    icon?: Maybe<Scalars["String"]>;
    id: Scalars["CustomListItemId"];
    isArchived: Scalars["Boolean"];
    key: Scalars["String"];
    name: Scalars["String"];
    order: Scalars["Int"];
    pipelineDateField?: Maybe<Scalars["String"]>;
    pipelineDateLabel?: Maybe<Scalars["String"]>;
    pipelineVisibility?: Maybe<PipelineVisibility>;
    updateBehavior: CustomListItemUpdateBehavior;
};

export enum CustomListItemUpdateBehavior {
    Full = "Full",
    Limited = "Limited",
}

export enum CustomListKey {
    ClientStatus = "ClientStatus",
}

export type DeleteClientContactResult = {
    __typename?: "DeleteClientContactResult";
    clientId: Scalars["ClientId"];
    contactId: Scalars["ContactId"];
};

export type DeleteCommunityContactResult = {
    __typename?: "DeleteCommunityContactResult";
    communityId: Scalars["CommunityId"];
    contactId: Scalars["ContactId"];
};

export type DeleteNoteResult = {
    __typename?: "DeleteNoteResult";
    _id: Scalars["NoteId"];
};

export type DeletePhotoInput = {
    entityId: Scalars["FileEntityId"];
    id: Scalars["PhotoId"];
};

export type DeleteQuestionResult = {
    __typename?: "DeleteQuestionResult";
    questionId: Scalars["QuestionId"];
};

export type Dictionary = {
    __typename?: "Dictionary";
    entries: Array<DictionaryEntry>;
};

export type DictionaryEntry = {
    __typename?: "DictionaryEntry";
    key: Scalars["String"];
    value?: Maybe<Scalars["String"]>;
};

/** A key-value pair. Set a key with a nullish value to unset the key. */
export type DictionaryEntryInput = {
    key: Scalars["String"];
    value?: Maybe<Scalars["String"]>;
};

export type DictionaryInput = {
    entries: Array<DictionaryEntryInput>;
};

export type Disclosure = {
    __typename?: "Disclosure";
    acknowledgements: Array<DisclosureAcknowledgement>;
    dateAdded: Scalars["DateTime"];
    id: Scalars["DisclosureId"];
    publicUrl: Scalars["String"];
};

export type DisclosureAcknowledgement = {
    __typename?: "DisclosureAcknowledgement";
    agreed: Scalars["Boolean"];
    createdBy?: Maybe<Scalars["UserId"]>;
    dateAdded: Scalars["DateTime"];
    email: Scalars["String"];
    id: Scalars["DisclosureAcknowledgementId"];
    ipAddress: Scalars["String"];
    name: Scalars["String"];
    phone: Scalars["String"];
    response?: Maybe<DisclosureAcknowledgementResponse>;
};

export type DisclosureAcknowledgementInput = {
    agreed: Scalars["Boolean"];
    dateAdded?: Maybe<Scalars["DateTime"]>;
    email: Scalars["String"];
    name: Scalars["String"];
    phone: Scalars["String"];
    response: DisclosureAcknowledgementResponse;
};

export enum DisclosureAcknowledgementResponse {
    Agreed = "Agreed",
    Declined = "Declined",
    NotRequired = "NotRequired",
}

export type DraftSignatureRequest = {
    __typename?: "DraftSignatureRequest";
    actualRecipients: Array<SignatureRequestRecipientPayloadResponse>;
    ccRecipients: Array<SignatureRequestCcRecipient>;
    draftFields?: Maybe<Array<PrefilledSignatureRequestFieldPayload>>;
    emailTemplate: DraftSignatureRequestEmailContent;
    entityType?: Maybe<AdvancedSearchEntityType>;
    potentialRecipients: Array<SignaturePotentialRecipient>;
    signatureTemplate: SignatureTemplate;
};

export type DraftSignatureRequestEmailContent = {
    __typename?: "DraftSignatureRequestEmailContent";
    attachments: Array<Scalars["FileId"]>;
    body: Scalars["String"];
    subject: Scalars["String"];
};

export type EmailContentInput = {
    attachments?: Maybe<Array<Scalars["FileId"]>>;
    body?: Maybe<Scalars["String"]>;
    subject?: Maybe<Scalars["String"]>;
};

export type EmailOpenedTriggerConfigurationEntity = {
    __typename?: "EmailOpenedTriggerConfigurationEntity";
    activityThresholdDays: Scalars["Int"];
    entityFieldMatchers: Array<TriggerFieldMatcherEntity>;
};

export type EmailOpenedTriggerConfigurationInput = {
    activityThresholdDays: Scalars["Int"];
    entityFieldMatchers: Array<TriggerFieldMatcherEntityInput>;
};

/** Email template */
export type EmailTemplate = {
    __typename?: "EmailTemplate";
    attachments?: Maybe<Array<Scalars["FileId"]>>;
    body?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    id: Scalars["EmailTemplateId"];
    isShared: Scalars["Boolean"];
    isSignature: Scalars["Boolean"];
    migrationVersion: Scalars["Int"];
    regionVisibility?: Maybe<EntityRegionVisibility>;
    subject?: Maybe<Scalars["String"]>;
    type: Scalars["String"];
};

export type EntityFieldChange = {
    __typename?: "EntityFieldChange";
    field: Scalars["String"];
    newStringValue?: Maybe<Scalars["String"]>;
    oldStringValue?: Maybe<Scalars["String"]>;
};

export type EntityReassign = {
    __typename?: "EntityReassign";
    from?: Maybe<Scalars["UserId"]>;
    to?: Maybe<Scalars["UserId"]>;
};

export type EntityRegionPermission = {
    __typename?: "EntityRegionPermission";
    id: Scalars["RegionId"];
    key: Scalars["String"];
    name: Scalars["String"];
};

export type EntityRegionPermissions = {
    __typename?: "EntityRegionPermissions";
    editableRegions: Array<EntityRegionPermission>;
};

export type EntityRegionVisibility = {
    __typename?: "EntityRegionVisibility";
    editable: Scalars["Boolean"];
    regions: Array<Scalars["RegionId"]>;
};

export type EntityRegionVisibilityInput = {
    regions: Array<Scalars["RegionId"]>;
};

/** A batch of requested changes for a specific entity */
export type EntityUpdateRequest = {
    /** ID of entity */
    id: Scalars["UUID"];
    stringUpdates?: Maybe<Array<StringColumnUpdate>>;
    version: Scalars["Int"];
};

export type EntityUpdatedFieldMatcherEntity = {
    __typename?: "EntityUpdatedFieldMatcherEntity";
    current?: Maybe<WorkflowTriggerFieldValueEntityValueType>;
    fieldName: Scalars["String"];
    previous?: Maybe<WorkflowTriggerFieldValueEntityValueType>;
};

export type EntityUpdatedFieldMatcherInput = {
    current?: Maybe<WorkflowTriggerFieldValueEntityValueTypeInput>;
    fieldName: Scalars["String"];
    previous?: Maybe<WorkflowTriggerFieldValueEntityValueTypeInput>;
};

export type EntityUpdatedTriggerConfigurationEntity = {
    __typename?: "EntityUpdatedTriggerConfigurationEntity";
    fieldMatchers: Array<EntityUpdatedFieldMatcherEntity>;
};

export type EntityUpdatedTriggerConfigurationInput = {
    fieldMatchers: Array<EntityUpdatedFieldMatcherInput>;
};

export type ErrorField = {
    __typename?: "ErrorField";
    message: Scalars["String"];
    name: Scalars["String"];
};

export type ErrorResult = {
    __typename?: "ErrorResult";
    fields: Array<ErrorField>;
    message?: Maybe<Scalars["String"]>;
};

export type EvergreenDataSource = {
    __typename?: "EvergreenDataSource";
    dateAdded?: Maybe<Scalars["DateTime"]>;
    duplicatesDetectionWindowInDays?: Maybe<Scalars["Int"]>;
    id: Scalars["UUID"];
    imports?: Maybe<Array<EvergreenImport>>;
    lastSuccessfulRun?: Maybe<Scalars["DateTime"]>;
    name: Scalars["ID"];
    updateFrequencyInDays?: Maybe<Scalars["Int"]>;
    updateRequested?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    warningsSilenced?: Maybe<Scalars["DateTime"]>;
};

export type EvergreenDataSourceInput = {
    dateAdded?: Maybe<Scalars["DateTime"]>;
    duplicatesDetectionWindowInDays?: Maybe<Scalars["Int"]>;
    id: Scalars["UUID"];
    imports?: Maybe<Array<EvergreenImportInput>>;
    lastSuccessfulRun?: Maybe<Scalars["DateTime"]>;
    name: Scalars["ID"];
    updateFrequencyInDays?: Maybe<Scalars["Int"]>;
    updateRequested?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    warningsSilenced?: Maybe<Scalars["DateTime"]>;
};

export type EvergreenImport = {
    __typename?: "EvergreenImport";
    created?: Maybe<Scalars["Int"]>;
    dateAdded?: Maybe<Scalars["DateTime"]>;
    delisted?: Maybe<Scalars["Int"]>;
    duplicates?: Maybe<Scalars["Int"]>;
    endedAt?: Maybe<Scalars["DateTime"]>;
    errors?: Maybe<Scalars["Int"]>;
    id: Scalars["UUID"];
    message?: Maybe<Scalars["String"]>;
    skips?: Maybe<Scalars["Int"]>;
    sourceName: Scalars["ID"];
    startedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type EvergreenImportInput = {
    created?: Maybe<Scalars["Int"]>;
    dateAdded?: Maybe<Scalars["DateTime"]>;
    delisted?: Maybe<Scalars["Int"]>;
    duplicates?: Maybe<Scalars["Int"]>;
    endedAt?: Maybe<Scalars["DateTime"]>;
    errors?: Maybe<Scalars["Int"]>;
    id: Scalars["UUID"];
    message?: Maybe<Scalars["String"]>;
    skips?: Maybe<Scalars["Int"]>;
    sourceName: Scalars["ID"];
    startedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type EvergreenLicenseType = {
    __typename?: "EvergreenLicenseType";
    dateAdded?: Maybe<Scalars["DateTime"]>;
    id: Scalars["EvergreenLicenseTypeId"];
    licenseType: Scalars["String"];
    state: Scalars["String"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type EvergreenLicenseTypeInput = {
    dateAdded?: Maybe<Scalars["DateTime"]>;
    id: Scalars["EvergreenLicenseTypeId"];
    licenseType: Scalars["String"];
    state: Scalars["String"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export enum ExternalEmailProvider {
    SendGrid = "SendGrid",
    Ses = "Ses",
}

export type ExternalInvoiceAccountPaymentTerm = {
    __typename?: "ExternalInvoiceAccountPaymentTerm";
    default: Scalars["Boolean"];
    dueDays?: Maybe<Scalars["Int"]>;
    id: Scalars["String"];
    name: Scalars["String"];
};

export type ExternalInvoiceAccountSettings = {
    __typename?: "ExternalInvoiceAccountSettings";
    paymentTerms: Array<ExternalInvoiceAccountPaymentTerm>;
};

export type ExternalInvoiceCustomer = {
    __typename?: "ExternalInvoiceCustomer";
    active: Scalars["Boolean"];
    id: Scalars["String"];
    name: Scalars["String"];
};

export type ExternalInvoiceCustomerSearchRequest = {
    searchString: Scalars["String"];
};

export type ExternalInvoiceCustomerSearchResponse = {
    __typename?: "ExternalInvoiceCustomerSearchResponse";
    count: Scalars["Int"];
    hits: Array<ExternalInvoiceCustomer>;
};

export type ExternalInvoiceInitialParameters = {
    __typename?: "ExternalInvoiceInitialParameters";
    createCustomerName?: Maybe<Scalars["String"]>;
    customer?: Maybe<ExternalInvoiceCustomer>;
};

export type ExternalInvoiceInput = {
    customerId: Scalars["String"];
    dueDate: Scalars["String"];
    lineItems: Array<InvoiceLineItemInput>;
    message?: Maybe<Scalars["String"]>;
    recipients?: Maybe<Array<Scalars["String"]>>;
    sendOnSave?: Maybe<Scalars["Boolean"]>;
    termId?: Maybe<Scalars["String"]>;
};

export type ExternalInvoiceSearchHit = {
    __typename?: "ExternalInvoiceSearchHit";
    amountInCents: Scalars["Int"];
    customer: Scalars["String"];
    dueDate: Scalars["String"];
    externalId: Scalars["String"];
    invoiceDate: Scalars["String"];
    serial: Scalars["String"];
};

export type ExternalInvoiceSearchRequest = {
    serial?: Maybe<Scalars["String"]>;
};

export type ExternalInvoiceSearchResponse = {
    __typename?: "ExternalInvoiceSearchResponse";
    count: Scalars["Int"];
    hits: Array<ExternalInvoiceSearchHit>;
};

export type ExternalInvoicesCreateRequest = {
    /** Client to attach to invoice */
    clientId: Scalars["ClientId"];
    invoices: Array<ExternalInvoiceInput>;
};

/** Single feature description */
export type FeatureDescription = {
    __typename?: "FeatureDescription";
    actual: FeatureFlag;
    createdOn: Scalars["String"];
    description: Scalars["String"];
    friendlyTitle: Scalars["String"];
    intent: Scalars["String"];
    key: Scalars["String"];
    packageEnabled?: Maybe<Scalars["Boolean"]>;
    skus?: Maybe<Array<Scalars["String"]>>;
};

/** FeatureFlag */
export type FeatureFlag = {
    __typename?: "FeatureFlag";
    enabled: Scalars["Boolean"];
    name: Scalars["ID"];
};

/** Operator to use for field condition */
export enum FieldConditionOperator {
    CountEquals = "CountEquals",
    CountGreaterThan = "CountGreaterThan",
    CountGreaterThanOrEqual = "CountGreaterThanOrEqual",
    CountLessThan = "CountLessThan",
    CountLessThanOrEqual = "CountLessThanOrEqual",
    CustomListAfter = "CustomListAfter",
    CustomListAfterOrEqual = "CustomListAfterOrEqual",
    CustomListBefore = "CustomListBefore",
    CustomListBeforeOrEqual = "CustomListBeforeOrEqual",
    CustomListDoesNotEqual = "CustomListDoesNotEqual",
    CustomListEquals = "CustomListEquals",
    CustomListInCategory = "CustomListInCategory",
    DateAfter = "DateAfter",
    DateBefore = "DateBefore",
    DateMonthEquals = "DateMonthEquals",
    DateOn = "DateOn",
    DateOnOrAfter = "DateOnOrAfter",
    DateOnOrBefore = "DateOnOrBefore",
    IdEquals = "IdEquals",
    IsFalse = "IsFalse",
    IsNotSet = "IsNotSet",
    IsSet = "IsSet",
    IsTrue = "IsTrue",
    NaryContains = "NaryContains",
    NaryDoesNotContain = "NaryDoesNotContain",
    NumberEquals = "NumberEquals",
    NumberGreaterThan = "NumberGreaterThan",
    NumberGreaterThanOrEqual = "NumberGreaterThanOrEqual",
    NumberLessThan = "NumberLessThan",
    NumberLessThanOrEqual = "NumberLessThanOrEqual",
    RangeAbove = "RangeAbove",
    RangeBelow = "RangeBelow",
    RangeContains = "RangeContains",
    RangeContainsOrAbove = "RangeContainsOrAbove",
    RangeContainsOrBelow = "RangeContainsOrBelow",
    RangeDoesNotContain = "RangeDoesNotContain",
    RelationMatches = "RelationMatches",
    StringContains = "StringContains",
    StringDoesNotContain = "StringDoesNotContain",
    StringEquals = "StringEquals",
    UserIsMe = "UserIsMe",
}

/** Uploaded file */
export type File = {
    __typename?: "File";
    accessControls: Array<AccessControlResult>;
    activityEntities?: Maybe<Array<Activity>>;
    byteLength?: Maybe<Scalars["Int"]>;
    createdAt: Scalars["DateTime"];
    createdBy?: Maybe<Scalars["UserId"]>;
    deleted: Scalars["Boolean"];
    description?: Maybe<Scalars["String"]>;
    entityId: Scalars["FileEntityId"];
    hasSignatureTemplate: Scalars["Boolean"];
    id: Scalars["FileId"];
    isGlobal: Scalars["Boolean"];
    mimeType?: Maybe<Scalars["String"]>;
    originalFilename?: Maybe<Scalars["String"]>;
    outstandingSignatureRequestsCount: Scalars["Int"];
    purpose: FilePurpose;
    regionVisibility?: Maybe<EntityRegionVisibility>;
    shareWithClient: Scalars["Boolean"];
    shareWithCommunity: Scalars["Boolean"];
    shareWithReferral: Scalars["Boolean"];
    slideShowOrder?: Maybe<Scalars["Int"]>;
    updatedAt: Scalars["DateTime"];
    updatedBy?: Maybe<Scalars["UserId"]>;
};

export type FileEntityList = {
    __typename?: "FileEntityList";
    files: Array<File>;
};

export enum FileEntityType {
    Client = "Client",
    Community = "Community",
    Referral = "Referral",
}

export enum FilePurpose {
    Branding = "Branding",
    File = "File",
    Photo = "Photo",
    Thumbnail = "Thumbnail",
}

export type FileUpdateInput = {
    description?: Maybe<Scalars["String"]>;
    originalFilename: Scalars["String"];
    shareWithClient: Scalars["Boolean"];
    shareWithCommunity: Scalars["Boolean"];
    shareWithReferral: Scalars["Boolean"];
};

/** An entry in the geocache lookup table */
export type GeoCacheEntry = {
    city?: Maybe<Scalars["String"]>;
    country?: Maybe<Scalars["String"]>;
    county?: Maybe<Scalars["String"]>;
    formatted_address?: Maybe<Scalars["String"]>;
    formatted_street?: Maybe<Scalars["String"]>;
    found: Scalars["Boolean"];
    lat: Scalars["String"];
    lng: Scalars["String"];
    number?: Maybe<Scalars["String"]>;
    predirectional?: Maybe<Scalars["String"]>;
    provider?: Maybe<Scalars["String"]>;
    search: Scalars["String"];
    source?: Maybe<Scalars["String"]>;
    state?: Maybe<Scalars["String"]>;
    street?: Maybe<Scalars["String"]>;
    suffix?: Maybe<Scalars["String"]>;
    zip?: Maybe<Scalars["String"]>;
};

export type GetApiKeyResponse = {
    __typename?: "GetApiKeyResponse";
    apiKey: Scalars["String"];
};

export type GetClientContactsRequest = {
    clientId: Scalars["ClientId"];
    relationships: Array<ClientContactRelationship>;
};

export type GetClientOptions = {
    includeCommunityQuestionIds?: Maybe<Array<Scalars["QuestionId"]>>;
};

export type GetEntityByIdResult = {
    __typename?: "GetEntityByIdResult";
    entity?: Maybe<AdvancedSearchResultEntity>;
};

export type GetEntityRegionPermissionsParams = {
    actionType: ActionType;
};

export type GetExternalInvoiceInitialParametersRequest = {
    clientId: Scalars["ClientId"];
};

export type GetNotesForEntityFilter = {
    isDraft?: Maybe<Scalars["Boolean"]>;
};

export type GetTasksRequest = {
    completedAt: Scalars["IsoTimestamp"];
    ownerId: Scalars["UserId"];
    regionId: Scalars["RegionId"];
};

export type GetUsersParameters = {
    userTypes?: Maybe<Array<UserType>>;
};

/** Summary of feature flags */
export type GlobalFeaturesResponse = {
    __typename?: "GlobalFeaturesResponse";
    features: Array<FeatureDescription>;
    skus: Array<Scalars["String"]>;
};

export type GraphqlDebugResult = {
    __typename?: "GraphqlDebugResult";
    message: Scalars["String"];
};

export type HostedForm = {
    __typename?: "HostedForm";
    configuration: HostedFormConfiguration;
    customizable: Scalars["Boolean"];
    dateAdded: Scalars["IsoTimestamp"];
    displayName: Scalars["String"];
    fields: Array<BridgeFieldResult>;
    id: Scalars["HostedFormId"];
    layoutSections: Array<LayoutSection>;
    name?: Maybe<Scalars["String"]>;
    questions: Array<BridgeQuestion>;
    url: Scalars["String"];
};

export type HostedFormAssigneeInput = {
    /** The list of users that will receive notifications when a form is submitted */
    recipientUserIds?: Maybe<Array<Scalars["UserId"]>>;
    regionId: Scalars["RegionId"];
    /** The user assigned to the new client */
    userId?: Maybe<Scalars["UserId"]>;
};

export type HostedFormConfiguration = {
    __typename?: "HostedFormConfiguration";
    entityType: Scalars["String"];
    regions?: Maybe<Array<HostedFormRegionConfiguration>>;
    style?: Maybe<HostedFormStyle>;
};

export type HostedFormRegionConfiguration = {
    __typename?: "HostedFormRegionConfiguration";
    /** The list of users that will receive notifications when a form is submitted */
    recipientUserIds?: Maybe<Array<Scalars["UserId"]>>;
    regionId: Scalars["RegionId"];
    /** The user assigned to the new client */
    userId?: Maybe<Scalars["UserId"]>;
};

export enum HostedFormStyle {
    Embedded = "Embedded",
    Standalone = "Standalone",
}

export type HostedFormToken = {
    __typename?: "HostedFormToken";
    completedAt?: Maybe<Scalars["DateTime"]>;
    createdBy: Scalars["UserId"];
    dateAdded: Scalars["DateTime"];
    expiresAt: Scalars["DateTime"];
    hostedFormId: Scalars["HostedFormId"];
    id: Scalars["HostedFormTokenId"];
    sentMessages?: Maybe<Array<SentMessage>>;
    status: HostedFormTokenStatus;
    updatedAt: Scalars["DateTime"];
    updatedBy: Scalars["UserId"];
};

export enum HostedFormTokenStatus {
    Active = "Active",
    Completed = "Completed",
    Expired = "Expired",
}

export type Identity = {
    __typename?: "Identity";
    id: Scalars["IdentityId"];
    licenses?: Maybe<Array<License>>;
    name?: Maybe<Scalars["String"]>;
    propagateableLicenseTypes?: Maybe<Array<Scalars["String"]>>;
    successorId?: Maybe<Scalars["IdentityId"]>;
    thumbnailUrl?: Maybe<Scalars["String"]>;
};

export type IdentityFilterInput = {
    county?: Maybe<Scalars["String"]>;
    state: Scalars["String"];
    zip?: Maybe<Scalars["String"]>;
};

export type IdentityFilterObject = {
    __typename?: "IdentityFilterObject";
    county?: Maybe<Scalars["String"]>;
    state: Scalars["String"];
    zip?: Maybe<Scalars["String"]>;
};

export type Import = {
    __typename?: "Import";
    dateAdded: Scalars["DateTime"];
    id: Scalars["ImportId"];
    name: Scalars["String"];
    plan?: Maybe<ImportPlan>;
    regionId?: Maybe<Scalars["RegionId"]>;
    result?: Maybe<ImportResult>;
    runAs: Scalars["UserId"];
    status: ImportStatus;
};

export type ImportEntityResult = {
    __typename?: "ImportEntityResult";
    entityType: Scalars["String"];
    errors: Array<ImportResultError>;
    inserts: Array<ImportResultInsert>;
};

export type ImportPlan = {
    __typename?: "ImportPlan";
    errors?: Maybe<Array<ImportPlanError>>;
    inserts: Array<ImportPlanInsert>;
};

export type ImportPlanError = {
    __typename?: "ImportPlanError";
    message: Scalars["String"];
    rowNumber?: Maybe<Scalars["Float"]>;
    sheetName: Scalars["String"];
};

export type ImportPlanInsert = {
    __typename?: "ImportPlanInsert";
    entityType: Scalars["String"];
    records: Array<Scalars["String"]>;
};

export type ImportResult = {
    __typename?: "ImportResult";
    error?: Maybe<Scalars["String"]>;
    results: Array<ImportEntityResult>;
};

export type ImportResultError = {
    __typename?: "ImportResultError";
    message: Scalars["String"];
    rowNumber: Scalars["Float"];
    sheetName: Scalars["String"];
};

export type ImportResultInsert = {
    __typename?: "ImportResultInsert";
    insertedId?: Maybe<Scalars["String"]>;
    rowNumber: Scalars["Float"];
    warnings?: Maybe<Array<Scalars["String"]>>;
};

export enum ImportStatus {
    Failed = "Failed",
    Finished = "Finished",
    Planning = "Planning",
    Ready = "Ready",
    Running = "Running",
}

/** Inbound referral sources (businesses) */
export type InboundReferralsBusinessReferral = {
    __typename?: "InboundReferralsBusinessReferral";
    business: ReferenceBusiness;
    count: Scalars["Float"];
};

/** Inbound referral sources (contacts) */
export type InboundReferralsContactReferral = {
    __typename?: "InboundReferralsContactReferral";
    contact: ReferenceContact;
    count: Scalars["Float"];
};

/** Generic inbound referral sources */
export type InboundReferralsGenericReferral = {
    __typename?: "InboundReferralsGenericReferral";
    count: Scalars["Float"];
    referralToken: Scalars["String"];
};

/** Client placement revenue by time period breakdown */
export type InboundReferralsResult = {
    __typename?: "InboundReferralsResult";
    businesses: Array<InboundReferralsBusinessReferral>;
    contacts: Array<InboundReferralsContactReferral>;
    generic: Array<InboundReferralsGenericReferral>;
};

export type IntakeOptions = {
    dateStamp: Scalars["DateTime"];
    freeformNotes?: Maybe<Scalars["String"]>;
    includeAdditionalContacts: Scalars["Boolean"];
    includeCareProviders: Scalars["Boolean"];
    includeClientBudget: Scalars["Boolean"];
    includeClientLocation: Scalars["Boolean"];
    includeContactEmailAddrs: Scalars["Boolean"];
    includeContactLocations: Scalars["Boolean"];
    includeContactPhoneNumbers: Scalars["Boolean"];
    includeHealthInsurance: Scalars["Boolean"];
    includeMyContact: Scalars["Boolean"];
    notesOption: Scalars["String"];
    prebuiltNotes?: Maybe<AdditionalNotesImpl>;
};

/** Invoice for a client (and optionally a community) */
export type Invoice = {
    __typename?: "Invoice";
    amountCents: Scalars["CurrencyInCents"];
    appLink: Scalars["String"];
    client?: Maybe<Client>;
    clientId: Scalars["ClientId"];
    clientName?: Maybe<Scalars["String"]>;
    community?: Maybe<Community>;
    communityId?: Maybe<Scalars["CommunityId"]>;
    communityName?: Maybe<Scalars["String"]>;
    dateAdded: Scalars["DateTime"];
    dueDate?: Maybe<Scalars["String"]>;
    externalReference?: Maybe<Scalars["String"]>;
    fileId?: Maybe<Scalars["FileId"]>;
    hasOutstandingBalance: Scalars["Boolean"];
    id: Scalars["InvoiceId"];
    isExternallyManaged: Scalars["Boolean"];
    lineItems?: Maybe<Array<InvoiceLineItem>>;
    notes?: Maybe<Scalars["String"]>;
    paidDate?: Maybe<Scalars["String"]>;
    paymentSchedule?: Maybe<InvoicePaymentSchedule>;
    remainingBalanceCents: Scalars["Float"];
    sentDate?: Maybe<Scalars["String"]>;
    serial: Scalars["String"];
    status: Scalars["String"];
    totalPaidCents: Scalars["Float"];
    updatedAt: Scalars["DateTime"];
};

export enum InvoiceCreateExperience {
    External = "External",
    Internal = "Internal",
}

export type InvoiceCreateExperienceResponse = {
    __typename?: "InvoiceCreateExperienceResponse";
    experience: InvoiceCreateExperience;
};

export type InvoiceLineItem = {
    __typename?: "InvoiceLineItem";
    description: Scalars["String"];
    id: Scalars["InvoiceLineItemId"];
    quantity: Scalars["Float"];
    unitPriceInCents: Scalars["CurrencyInCents"];
};

export type InvoiceLineItemInput = {
    description: Scalars["String"];
    id: Scalars["InvoiceLineItemId"];
    quantity: Scalars["Float"];
    unitPriceInCents: Scalars["CurrencyInCents"];
};

export type InvoicePaymentSchedule = {
    __typename?: "InvoicePaymentSchedule";
    items: Array<InvoicePaymentScheduleItem>;
};

export type InvoicePaymentScheduleInput = {
    items: Array<InvoicePaymentScheduleItemInput>;
};

export type InvoicePaymentScheduleItem = {
    __typename?: "InvoicePaymentScheduleItem";
    amountInCents: Scalars["CurrencyInCents"];
    dueDate: Scalars["String"];
    id: Scalars["InvoicePaymentScheduleItemId"];
    paidDate?: Maybe<Scalars["String"]>;
    status: InvoicePaymentScheduleItemStatus;
};

export type InvoicePaymentScheduleItemInput = {
    amountInCents: Scalars["CurrencyInCents"];
    dueDate: Scalars["String"];
    id: Scalars["InvoicePaymentScheduleItemId"];
    paidDate?: Maybe<Scalars["String"]>;
    status: InvoicePaymentScheduleItemStatus;
};

export enum InvoicePaymentScheduleItemStatus {
    Due = "Due",
    Paid = "Paid",
}

/** Invoice creation response */
export type InvoiceResult = {
    __typename?: "InvoiceResult";
    errors?: Maybe<ErrorResult>;
    invoices?: Maybe<Array<Invoice>>;
    result: Scalars["String"];
};

/** Backend job statistics  */
export type JobStats = {
    __typename?: "JobStats";
    available: Scalars["Int"];
    complete: Scalars["Int"];
    failed: Scalars["Int"];
    pending: Scalars["Int"];
    started: Scalars["Int"];
};

export type LayoutContainer = {
    __typename?: "LayoutContainer";
    id: Scalars["LayoutContainerId"];
    key: Scalars["String"];
    layoutSections: Array<LayoutSection>;
    title: Scalars["String"];
};

/** LayoutItem */
export type LayoutItem = {
    __typename?: "LayoutItem";
    customTitle?: Maybe<Scalars["String"]>;
    entityFieldName?: Maybe<Scalars["String"]>;
    id: Scalars["LayoutItemId"];
    layoutSectionId: Scalars["LayoutSectionId"];
    naryFormat?: Maybe<NaryFormat>;
    order: Scalars["Int"];
    questionId?: Maybe<Scalars["QuestionId"]>;
    showIfBlank: Scalars["Boolean"];
};

export type LayoutItemInput = {
    customTitle?: Maybe<Scalars["String"]>;
    entityFieldName?: Maybe<Scalars["String"]>;
    id: Scalars["LayoutItemId"];
    naryFormat?: Maybe<NaryFormat>;
    order: Scalars["Int"];
    questionId?: Maybe<Scalars["QuestionId"]>;
    showIfBlank: Scalars["Boolean"];
};

/** LayoutSection */
export type LayoutSection = {
    __typename?: "LayoutSection";
    defaultStandardFields?: Maybe<Array<Scalars["String"]>>;
    entityType: Scalars["String"];
    icon?: Maybe<Scalars["String"]>;
    id: Scalars["LayoutSectionId"];
    includeRelations?: Maybe<Array<Scalars["String"]>>;
    layoutContainerId: Scalars["LayoutContainerId"];
    layoutItems: Array<LayoutItem>;
    legacyPerspective?: Maybe<LayoutSectionLegacyPerspective>;
    order: Scalars["Int"];
    questionCategory?: Maybe<Scalars["String"]>;
    renderBehavior: SectionRenderBehaviorType;
    sectionKey: Scalars["String"];
    settings: Array<LayoutSectionSetting>;
    singlePropertyName?: Maybe<Scalars["String"]>;
    title: Scalars["String"];
    visible: Scalars["Boolean"];
};

/** The UI section where the layout item will be placed */
export enum LayoutSectionKey {
    CommunityCardKeyAttributes = "CommunityCardKeyAttributes",
    CommunityComparisonActivitiesRow = "CommunityComparisonActivitiesRow",
    CommunityComparisonAmenitiesRow = "CommunityComparisonAmenitiesRow",
    CommunityComparisonCommunityRecordsRow = "CommunityComparisonCommunityRecordsRow",
    CommunityComparisonCommunityRow = "CommunityComparisonCommunityRow",
    CommunityComparisonCommunityTypeRow = "CommunityComparisonCommunityTypeRow",
    CommunityComparisonDistanceRow = "CommunityComparisonDistanceRow",
    CommunityComparisonFinanceRow = "CommunityComparisonFinanceRow",
    CommunityComparisonHealthServicesRow = "CommunityComparisonHealthServicesRow",
    CommunityComparisonNotesRow = "CommunityComparisonNotesRow",
    CommunityComparisonOtherDetailsRow = "CommunityComparisonOtherDetailsRow",
    CommunityComparisonPhotosRow = "CommunityComparisonPhotosRow",
    CommunityComparisonPrimaryContactRow = "CommunityComparisonPrimaryContactRow",
    CommunityComparisonStaffRow = "CommunityComparisonStaffRow",
    CommunityComparisonSummaryRow = "CommunityComparisonSummaryRow",
    CommunityDetailsCommunityContacts = "CommunityDetailsCommunityContacts",
    CommunityDetailsCommunityInfo = "CommunityDetailsCommunityInfo",
    CommunityDetailsNotes = "CommunityDetailsNotes",
    CommunityDetailsPhotos = "CommunityDetailsPhotos",
    HostedFormClientBestContact = "HostedFormClientBestContact",
    HostedFormClientClient = "HostedFormClientClient",
    HostedFormClientOther = "HostedFormClientOther",
    ReferenceBusinessAdditionalInformation = "ReferenceBusinessAdditionalInformation",
    ReferenceBusinessContactInformation = "ReferenceBusinessContactInformation",
    ReferenceContactAdditionalInformation = "ReferenceContactAdditionalInformation",
    ReferenceContactContactInformation = "ReferenceContactContactInformation",
}

export enum LayoutSectionLegacyPerspective {
    Client = "Client",
    Community = "Community",
}

/** The UI section where the layout section will be placed (e.g. a document) */
export enum LayoutSectionParentKey {
    CommunityCard = "CommunityCard",
    CommunityComparisonTable = "CommunityComparisonTable",
    CommunityDetailsColumn1 = "CommunityDetailsColumn1",
    CommunityDetailsColumn2 = "CommunityDetailsColumn2",
    HostedFormClient = "HostedFormClient",
    ReferenceBusiness = "ReferenceBusiness",
    ReferenceContact = "ReferenceContact",
}

export type LayoutSectionSetting = {
    __typename?: "LayoutSectionSetting";
    id: Scalars["LayoutSectionSettingId"];
    key: Scalars["String"];
    label: Scalars["String"];
    order: Scalars["Int"];
    type: LayoutSectionSettingType;
};

export enum LayoutSectionSettingType {
    Boolean = "Boolean",
}

/** LayoutSections */
export type LayoutSectionsOutput = {
    __typename?: "LayoutSectionsOutput";
    layoutSections: Array<LayoutSection>;
};

export type License = {
    __typename?: "License";
    id: Scalars["LicenseId"];
    identity?: Maybe<Identity>;
    jurisdiction: Scalars["String"];
    licenseNumber: Scalars["String"];
    licenseType?: Maybe<Array<Scalars["String"]>>;
    publicRecords?: Maybe<Array<PublicRecord>>;
    status: Scalars["String"];
};

export type LicenseMappingInput = {
    annotationOnly?: Maybe<Scalars["Boolean"]>;
    licenseIdentifier: Scalars["String"];
    promptId?: Maybe<Scalars["PromptId"]>;
    questionId?: Maybe<Scalars["QuestionId"]>;
};

export type LicenseMappingObject = {
    __typename?: "LicenseMappingObject";
    annotationOnly?: Maybe<Scalars["Boolean"]>;
    licenseIdentifier: Scalars["String"];
    promptId?: Maybe<Scalars["PromptId"]>;
    questionId?: Maybe<Scalars["QuestionId"]>;
};

export type LoadSavedQueryResult = {
    __typename?: "LoadSavedQueryResult";
    canSaveAdditional: Scalars["Boolean"];
    savedQueries: Array<SavedQueryResult>;
};

export type ManageableUser = {
    __typename?: "ManageableUser";
    canDisable: Scalars["Boolean"];
    user: User;
};

export type MapCluster = {
    __typename?: "MapCluster";
    count: Scalars["Float"];
    lat: Scalars["Float"];
    lng: Scalars["Float"];
};

/** Message compose information */
export type MessageCompose = {
    __typename?: "MessageCompose";
    files: Array<AttachmentScope>;
    recipients: Array<Scalars["String"]>;
    specialCommunityComparisonDefaults?: Maybe<SpecialCommunityComparisonDefaults>;
};

/** Level of tracking for messages */
export enum MessageTrackingLevel {
    Message = "Message",
    Recipient = "Recipient",
}

export type MessagingConfiguration = {
    __typename?: "MessagingConfiguration";
    tenant: TenantMessagingConfiguration;
    user: UserMessagingConfiguration;
};

export type Mutation = {
    __typename?: "Mutation";
    abandonSignatureRequest: Scalars["Boolean"];
    addClientCommunities: Client;
    addExistingExternalInvoice: Invoice;
    adminUpdateUser: User;
    batchCreateCommunityMaster: BatchUpdateCommunityMasterResult;
    bulkCreateLegacyQuestions: Array<Question>;
    bulkUpdateIdentities: Array<Identity>;
    cancelWorkflowExecution: WorkflowExecution;
    cloneUser: Scalars["Boolean"];
    createClient: Client;
    createClientReferral: Referral;
    createCommunitiesInAllRegions: Scalars["Boolean"];
    createCommunitiesInRegion: CommunityUpdateResult;
    createCommunityLicense: CommunityLicense;
    createContact: ContactEntity;
    createCustomListItem: CustomListItem;
    createDisclosure: Disclosure;
    createDisclosureAcknowledgement: DisclosureAcknowledgement;
    createExternalInvoiceCustomer: ExternalInvoiceCustomer;
    createExternalInvoices: InvoiceResult;
    createHostedForm: HostedForm;
    createInvoice: InvoiceResult;
    createLayoutSection: LayoutSection;
    createNote: NoteDto;
    createOrUpdateRegionLicenseMap: RegionLicenseMap;
    createOrUpdateTemplate: TemplateResponse;
    createQuestion: Question;
    createReferenceBusiness: ReferenceBusiness;
    createReferenceContact: ReferenceContact;
    createSavedQuery: SavedQueryResult;
    createSignatureRequest: SignatureRequest;
    createSmtpCredential: SmtpSaveResult;
    createTask: Task;
    createTaskType: TaskType;
    createTenant: Tenant;
    createTenantRegion: Tenant;
    createUser: User;
    createWorkflow: Workflow;
    createWorkflowForTenant: Workflow;
    deleteClientContact: DeleteClientContactResult;
    deleteClientMatches: Client;
    deleteCommunityContact: DeleteCommunityContactResult;
    deleteCommunityLicense: Scalars["Boolean"];
    deleteEvergreenLicenseType: Scalars["Boolean"];
    deleteFile: Scalars["Boolean"];
    deleteHostedForm: Scalars["Boolean"];
    deleteHostedFormToken: Scalars["Boolean"];
    deleteInvoice: Scalars["Boolean"];
    deleteLayoutSection: Scalars["Boolean"];
    deleteNote: DeleteNoteResult;
    deletePhoto: Scalars["Boolean"];
    deleteQuestion: DeleteQuestionResult;
    deleteRegionLicenseMap: Scalars["Boolean"];
    deleteSavedQuery: Scalars["Boolean"];
    deleteSavedQuerySchedule: Scalars["Boolean"];
    deleteSignatureTemplate: SignatureTemplateDeleteResponse;
    deleteSmtpCredentialForUser: Scalars["Boolean"];
    deleteTask: Scalars["Boolean"];
    deleteTemplate: Scalars["Boolean"];
    deleteTenantCredential: Scalars["Boolean"];
    deleteWorkflow: Scalars["Boolean"];
    deleteWorkflowForTenant: Scalars["Boolean"];
    generateInvoice: InvoiceResult;
    geocodeReferenceBusinesses: Scalars["Boolean"];
    modifyUserRole: Scalars["Boolean"];
    patchClientReferral: Referral;
    patchContact: ContactEntity;
    patchCustomListItem: CustomListItem;
    patchCustomListItems: Array<CustomListItem>;
    patchHostedForm: HostedForm;
    patchLayoutItem: LayoutItem;
    patchLayoutSection: LayoutSection;
    patchLayoutSections: Array<LayoutSection>;
    patchPhoto: Photo;
    patchPhotos: Array<Photo>;
    patchReferenceBusiness: ReferenceBusiness;
    patchReferenceContact: ReferenceContact;
    populateGeoCache: PopulateGeoCacheResult;
    promoteCommunityContact: CommunityContactDto;
    relocateImage: RelocateImageResponse;
    removeClientCommunities: Client;
    reorderLegacyQuestions: Scalars["Boolean"];
    requestClientTransfer: Scalars["Boolean"];
    resendInvoice: InvoiceResult;
    resendSignatureRequest: Scalars["Boolean"];
    resetTenant: Scalars["Boolean"];
    scheduleImport: Import;
    sendClientIntakeFax: Scalars["Boolean"];
    sendHostedFormWithPermalink: Scalars["Boolean"];
    sendHostedFormWithToken: SendHostedFormWithTokenResponse;
    sendMessage: SendMessageResult;
    sendSavedQueryEmail: Scalars["Boolean"];
    setCommunityOrder: Client;
    setEntityAnswer: Answer;
    setSignatureTemplate: SignatureTemplate;
    superuserUpdateUser: User;
    tenantInternalUpdate: Scalars["Boolean"];
    updateCommunityLicense: Scalars["Boolean"];
    updateEvergreenDataSource: EvergreenDataSource;
    updateEvergreenImport: EvergreenImport;
    updateEvergreenLicenseType: EvergreenLicenseType;
    updateFeatureFlag: FeatureFlag;
    updateFile: File;
    updateInvoice: InvoiceResult;
    updateNote: NoteDto;
    updateQueryFavoriteStatus: Scalars["Boolean"];
    updateRegion: Region;
    updateSavedQuery: SavedQueryResult;
    updateTask: Task;
    updateTaskType: TaskType;
    updateTenant: Tenant;
    updateToken: Token;
    updateWorkflow: Workflow;
    updateWorkflowForTenant: Workflow;
    upsertEntityQuestion: BridgeQuestion;
    upsertSavedQuerySchedule: SavedQueryScheduleResult;
    userManagementCreateSystemUser: Scalars["Boolean"];
    userManagementCreateUser: Scalars["Boolean"];
    userManagementDisableUser: Scalars["Boolean"];
    userManagementEnableUser: Scalars["Boolean"];
    userManagementHideUser: Scalars["Boolean"];
    userManagementShowUser: Scalars["Boolean"];
    userManagementUpdatePermission: Scalars["Boolean"];
};

export type MutationAbandonSignatureRequestArgs = {
    signatureRequestId: Scalars["SignatureRequestId"];
};

export type MutationAddClientCommunitiesArgs = {
    clientId: Scalars["ClientId"];
    includeCommunityQuestionIds?: Maybe<Array<Scalars["QuestionId"]>>;
    params: Array<AddClientCommunityRequest>;
};

export type MutationAddExistingExternalInvoiceArgs = {
    params: AddExternalInvoiceRequest;
};

export type MutationAdminUpdateUserArgs = {
    name: Scalars["String"];
    userId: Scalars["UserId"];
};

export type MutationBatchCreateCommunityMasterArgs = {
    communities: Array<PublicRecordInput>;
};

export type MutationBulkCreateLegacyQuestionsArgs = {
    bulkPayload: QuestionBulkCreatePayload;
};

export type MutationBulkUpdateIdentitiesArgs = {
    updates: Array<EntityUpdateRequest>;
};

export type MutationCancelWorkflowExecutionArgs = {
    workflowExecutionId: Scalars["WorkflowExecutionId"];
};

export type MutationCloneUserArgs = {
    tenantId: Scalars["TenantId"];
    userId: Scalars["UserId"];
};

export type MutationCreateClientArgs = {
    regionId: Scalars["RegionId"];
};

export type MutationCreateClientReferralArgs = {
    clientId: Scalars["ClientId"];
    referralType: Scalars["String"];
};

export type MutationCreateCommunitiesInRegionArgs = {
    regionId: Scalars["RegionId"];
    tenantId: Scalars["TenantId"];
};

export type MutationCreateCommunityLicenseArgs = {
    communityId: Scalars["CommunityId"];
};

export type MutationCreateContactArgs = {
    params: CreateContactInput;
};

export type MutationCreateCustomListItemArgs = {
    params: CreateCustomListItemRequest;
};

export type MutationCreateDisclosureArgs = {
    clientId: Scalars["ClientId"];
};

export type MutationCreateDisclosureAcknowledgementArgs = {
    acknowledgement: DisclosureAcknowledgementInput;
    disclosureId: Scalars["DisclosureId"];
};

export type MutationCreateExternalInvoiceCustomerArgs = {
    params: CreateExternalInvoiceCustomerRequest;
};

export type MutationCreateExternalInvoicesArgs = {
    params: ExternalInvoicesCreateRequest;
};

export type MutationCreateInvoiceArgs = {
    amountCents: Scalars["CurrencyInCents"];
    clientId: Scalars["ClientId"];
    dueDate?: Maybe<Scalars["String"]>;
    externalReference?: Maybe<Scalars["String"]>;
    generatePdf: Scalars["Boolean"];
    lineItems?: Maybe<Array<InvoiceLineItemInput>>;
    message?: Maybe<OutboundMessage>;
    notes?: Maybe<Scalars["String"]>;
    paymentSchedule?: Maybe<InvoicePaymentScheduleInput>;
    requireCommunity?: Maybe<Scalars["Boolean"]>;
    sentDate?: Maybe<Scalars["String"]>;
    serial?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
};

export type MutationCreateLayoutSectionArgs = {
    params: CreateLayoutSectionInput;
};

export type MutationCreateNoteArgs = {
    entityId: Scalars["NoteEntityId"];
    entityType: Scalars["String"];
    params: CreateNoteInput;
};

export type MutationCreateOrUpdateRegionLicenseMapArgs = {
    params: RegionLicenseMapUpdateRequest;
};

export type MutationCreateOrUpdateTemplateArgs = {
    template: TemplateUpdate;
};

export type MutationCreateQuestionArgs = {
    question: QuestionCreatePayload;
};

export type MutationCreateReferenceBusinessArgs = {
    regionId: Scalars["RegionId"];
};

export type MutationCreateReferenceContactArgs = {
    regionId: Scalars["RegionId"];
};

export type MutationCreateSavedQueryArgs = {
    savedQuery: SavedQueryInput;
};

export type MutationCreateSignatureRequestArgs = {
    signatureRequestPayload: SignatureRequestPayload;
};

export type MutationCreateSmtpCredentialArgs = {
    params: CreateSmtpCredentialRequest;
};

export type MutationCreateTaskArgs = {
    params: CreateTaskPayload;
    parentId: Scalars["TaskParentId"];
};

export type MutationCreateTaskTypeArgs = {
    payload: CreateTaskTypePayload;
};

export type MutationCreateTenantRegionArgs = {
    key: Scalars["String"];
    name: Scalars["String"];
    tenantId: Scalars["TenantId"];
};

export type MutationCreateUserArgs = {
    email: Scalars["String"];
    password: Scalars["String"];
    tenantId: Scalars["TenantId"];
};

export type MutationCreateWorkflowArgs = {
    workflow: WorkflowPayload;
};

export type MutationCreateWorkflowForTenantArgs = {
    tenantId: Scalars["TenantId"];
    workflow: WorkflowPayload;
};

export type MutationDeleteClientContactArgs = {
    clientId: Scalars["ClientId"];
    contactId: Scalars["ContactId"];
    relationship: ClientContactRelationship;
};

export type MutationDeleteClientMatchesArgs = {
    clientId: Scalars["ClientId"];
};

export type MutationDeleteCommunityContactArgs = {
    communityId: Scalars["CommunityId"];
    contactId: Scalars["ContactId"];
};

export type MutationDeleteCommunityLicenseArgs = {
    communityId: Scalars["CommunityId"];
    licenseId: Scalars["CommunityLicenseId"];
};

export type MutationDeleteEvergreenLicenseTypeArgs = {
    licenseTypeId: Scalars["EvergreenLicenseTypeId"];
};

export type MutationDeleteFileArgs = {
    fileId: Scalars["FileId"];
};

export type MutationDeleteHostedFormArgs = {
    hostedFormId: Scalars["HostedFormId"];
};

export type MutationDeleteHostedFormTokenArgs = {
    tokenId: Scalars["HostedFormTokenId"];
};

export type MutationDeleteInvoiceArgs = {
    invoiceId: Scalars["InvoiceId"];
};

export type MutationDeleteLayoutSectionArgs = {
    id: Scalars["LayoutSectionId"];
};

export type MutationDeleteNoteArgs = {
    entityId: Scalars["NoteEntityId"];
    entityType: Scalars["String"];
    noteId: Scalars["NoteId"];
};

export type MutationDeletePhotoArgs = {
    params: DeletePhotoInput;
};

export type MutationDeleteQuestionArgs = {
    questionId: Scalars["QuestionId"];
};

export type MutationDeleteRegionLicenseMapArgs = {
    regionLicenseMapId: Scalars["RegionLicenseMapId"];
};

export type MutationDeleteSavedQueryArgs = {
    savedQueryId: Scalars["SavedQueryId"];
};

export type MutationDeleteSavedQueryScheduleArgs = {
    savedQueryId: Scalars["SavedQueryId"];
};

export type MutationDeleteSignatureTemplateArgs = {
    signatureTemplateId: Scalars["SignatureTemplateId"];
};

export type MutationDeleteTaskArgs = {
    taskId: Scalars["TaskId"];
};

export type MutationDeleteTemplateArgs = {
    emailTemplateId: Scalars["EmailTemplateId"];
};

export type MutationDeleteTenantCredentialArgs = {
    id: Scalars["TenantCredentialId"];
};

export type MutationDeleteWorkflowArgs = {
    workflowId: Scalars["WorkflowId"];
};

export type MutationDeleteWorkflowForTenantArgs = {
    tenantId: Scalars["TenantId"];
    workflowId: Scalars["WorkflowId"];
};

export type MutationGenerateInvoiceArgs = {
    invoiceId: Scalars["InvoiceId"];
};

export type MutationModifyUserRoleArgs = {
    regionKey: Scalars["ID"];
    role: Scalars["String"];
    tenantId: Scalars["TenantId"];
    userId: Scalars["UserId"];
};

export type MutationPatchClientReferralArgs = {
    clientId: Scalars["ClientId"];
    communityId?: Maybe<Scalars["CommunityId"]>;
    deleted?: Maybe<Scalars["Boolean"]>;
    id: Scalars["ReferralId"];
    notes?: Maybe<Scalars["String"]>;
    referenceBusinessId?: Maybe<Scalars["ReferenceBusinessId"]>;
    referenceContactId?: Maybe<Scalars["ReferenceContactId"]>;
    when?: Maybe<Scalars["DateTime"]>;
};

export type MutationPatchContactArgs = {
    params: PatchContactInput;
};

export type MutationPatchCustomListItemArgs = {
    params: PatchCustomListItemRequest;
};

export type MutationPatchCustomListItemsArgs = {
    params: Array<PatchCustomListItemRequest>;
};

export type MutationPatchHostedFormArgs = {
    params: PatchHostedFormRequest;
};

export type MutationPatchLayoutItemArgs = {
    params: PatchLayoutItemInput;
};

export type MutationPatchLayoutSectionArgs = {
    params: PatchLayoutSectionInput;
};

export type MutationPatchLayoutSectionsArgs = {
    params: PatchLayoutSectionsInput;
};

export type MutationPatchPhotoArgs = {
    params: PatchPhotoInput;
};

export type MutationPatchPhotosArgs = {
    params: Array<PatchPhotoInput>;
};

export type MutationPatchReferenceBusinessArgs = {
    businessContactEntity?: Maybe<ContactInput>;
    deleted?: Maybe<Scalars["Boolean"]>;
    draftNote?: Maybe<Scalars["String"]>;
    id: Scalars["ReferenceBusinessId"];
    ownerId?: Maybe<Scalars["UserId"]>;
    showInSearchResults?: Maybe<Scalars["Boolean"]>;
    summary?: Maybe<Scalars["String"]>;
};

export type MutationPatchReferenceContactArgs = {
    businessId?: Maybe<Scalars["ReferenceBusinessId"]>;
    communityId?: Maybe<Scalars["CommunityId"]>;
    contactEntity?: Maybe<ContactInput>;
    deleted?: Maybe<Scalars["Boolean"]>;
    draftNote?: Maybe<Scalars["String"]>;
    id: Scalars["ReferenceContactId"];
    ownerId?: Maybe<Scalars["UserId"]>;
    showInSearchResults?: Maybe<Scalars["Boolean"]>;
    summary?: Maybe<Scalars["String"]>;
};

export type MutationPopulateGeoCacheArgs = {
    entries: Array<GeoCacheEntry>;
};

export type MutationPromoteCommunityContactArgs = {
    communityId: Scalars["CommunityId"];
    contactId: Scalars["ContactId"];
    regionId: Scalars["RegionId"];
};

export type MutationRelocateImageArgs = {
    payload: RelocateImageInput;
};

export type MutationRemoveClientCommunitiesArgs = {
    clientId: Scalars["ClientId"];
    params: Array<RemoveClientCommunityRequest>;
};

export type MutationReorderLegacyQuestionsArgs = {
    questionReorderPayload: QuestionReorderPayload;
};

export type MutationRequestClientTransferArgs = {
    params: TransferClientRequest;
};

export type MutationResendInvoiceArgs = {
    invoiceId: Scalars["InvoiceId"];
    message: OutboundMessage;
};

export type MutationResendSignatureRequestArgs = {
    signatureRequestId: Scalars["SignatureRequestId"];
};

export type MutationResetTenantArgs = {
    options: ResetTenantOptions;
};

export type MutationScheduleImportArgs = {
    importId: Scalars["ImportId"];
    tenantId: Scalars["TenantId"];
};

export type MutationSendClientIntakeFaxArgs = {
    message: OutboundClientIntakeFax;
};

export type MutationSendHostedFormWithPermalinkArgs = {
    params: SendHostedFormWithPermalinkRequest;
};

export type MutationSendHostedFormWithTokenArgs = {
    params: SendHostedFormWithTokenRequest;
};

export type MutationSendMessageArgs = {
    message: OutboundMessage;
};

export type MutationSendSavedQueryEmailArgs = {
    payload: SavedQuerySendEmailInput;
};

export type MutationSetCommunityOrderArgs = {
    clientId: Scalars["ClientId"];
    includeCommunityQuestionIds?: Maybe<Array<Scalars["QuestionId"]>>;
    ordering: Array<CommunityOrdering>;
};

export type MutationSetEntityAnswerArgs = {
    answer: AnswerInput;
    entityId: Scalars["AnswerEntityId"];
};

export type MutationSetSignatureTemplateArgs = {
    fileId: Scalars["FileId"];
    signatureTemplate: SignatureTemplateInput;
};

export type MutationSuperuserUpdateUserArgs = {
    disabled: Scalars["Boolean"];
    email: Scalars["String"];
    firstName?: Maybe<Scalars["String"]>;
    hidden: Scalars["Boolean"];
    name?: Maybe<Scalars["String"]>;
    userId: Scalars["UserId"];
};

export type MutationTenantInternalUpdateArgs = {
    internalAttributes?: Maybe<DictionaryInput>;
    messages?: Maybe<Array<Scalars["String"]>>;
    tenantId: Scalars["TenantId"];
};

export type MutationUpdateCommunityLicenseArgs = {
    license: CommunityLicenseInput;
    licenseId: Scalars["CommunityLicenseId"];
};

export type MutationUpdateEvergreenDataSourceArgs = {
    input: EvergreenDataSourceInput;
};

export type MutationUpdateEvergreenImportArgs = {
    input: EvergreenImportInput;
};

export type MutationUpdateEvergreenLicenseTypeArgs = {
    input: EvergreenLicenseTypeInput;
};

export type MutationUpdateFeatureFlagArgs = {
    enabled: Scalars["Boolean"];
    name: Scalars["ID"];
};

export type MutationUpdateFileArgs = {
    accessControls?: Maybe<Array<AccessControlInput>>;
    details: FileUpdateInput;
    fileId: Scalars["FileId"];
    regionVisibility?: Maybe<EntityRegionVisibilityInput>;
};

export type MutationUpdateInvoiceArgs = {
    amountCents: Scalars["CurrencyInCents"];
    dueDate?: Maybe<Scalars["String"]>;
    externalReference?: Maybe<Scalars["String"]>;
    invoiceId: Scalars["InvoiceId"];
    lineItems?: Maybe<Array<InvoiceLineItemInput>>;
    notes?: Maybe<Scalars["String"]>;
    paidDate?: Maybe<Scalars["String"]>;
    paymentSchedule?: Maybe<InvoicePaymentScheduleInput>;
    sentDate?: Maybe<Scalars["String"]>;
    serial?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
};

export type MutationUpdateNoteArgs = {
    entityId: Scalars["NoteEntityId"];
    noteId: Scalars["NoteId"];
    params: UpdateNoteInput;
};

export type MutationUpdateQueryFavoriteStatusArgs = {
    isFavorite: Scalars["Boolean"];
    savedQueryId: Scalars["SavedQueryId"];
};

export type MutationUpdateRegionArgs = {
    name?: Maybe<Scalars["String"]>;
    regionId: Scalars["RegionId"];
    tenantId: Scalars["TenantId"];
};

export type MutationUpdateSavedQueryArgs = {
    savedQuery: SavedQueryInput;
};

export type MutationUpdateTaskArgs = {
    params: UpdateTaskPayload;
    taskId: Scalars["TaskId"];
};

export type MutationUpdateTaskTypeArgs = {
    payload: UpdateTaskTypePayload;
};

export type MutationUpdateTenantArgs = {
    activatedAt?: Maybe<Scalars["String"]>;
    billing?: Maybe<TenantBillingPayloadInput>;
    customAppDomain?: Maybe<Scalars["String"]>;
    customDomains?: Maybe<Array<Scalars["String"]>>;
    customProductName?: Maybe<Scalars["String"]>;
    dateAdded?: Maybe<Scalars["String"]>;
    deactivatedAt?: Maybe<Scalars["String"]>;
    demoEndedAt?: Maybe<Scalars["String"]>;
    demoStartedAt?: Maybe<Scalars["String"]>;
    emailProvider?: Maybe<ExternalEmailProvider>;
    features?: Maybe<Array<Scalars["String"]>>;
    id: Scalars["TenantId"];
    internalEmailSender?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    onboardingStartedAt?: Maybe<Scalars["String"]>;
    premiumActivatedAt?: Maybe<Scalars["String"]>;
    premiumDeactivatedAt?: Maybe<Scalars["String"]>;
    sku?: Maybe<Scalars["String"]>;
    supportEmailAddress?: Maybe<Scalars["String"]>;
    whiteLabelContentMode?: Maybe<WhiteLabelContentMode>;
};

export type MutationUpdateTokenArgs = {
    updateRequest: UpdateTokenRequest;
};

export type MutationUpdateWorkflowArgs = {
    workflow: WorkflowPayload;
    workflowId: Scalars["WorkflowId"];
};

export type MutationUpdateWorkflowForTenantArgs = {
    tenantId: Scalars["TenantId"];
    workflow: WorkflowPayload;
    workflowId: Scalars["WorkflowId"];
};

export type MutationUpsertEntityQuestionArgs = {
    entityType: Scalars["String"];
    question: BridgeQuestionInput;
};

export type MutationUpsertSavedQueryScheduleArgs = {
    schedule: SavedQueryScheduleInput;
};

export type MutationUserManagementCreateSystemUserArgs = {
    name: Scalars["String"];
    regionId: Scalars["RegionId"];
    roleKey: Scalars["String"];
};

export type MutationUserManagementCreateUserArgs = {
    regionId: Scalars["RegionId"];
    roleKey: Scalars["String"];
    userEmail: Scalars["String"];
};

export type MutationUserManagementDisableUserArgs = {
    userId: Scalars["UserId"];
};

export type MutationUserManagementEnableUserArgs = {
    userId: Scalars["UserId"];
};

export type MutationUserManagementHideUserArgs = {
    userId: Scalars["UserId"];
};

export type MutationUserManagementShowUserArgs = {
    userId: Scalars["UserId"];
};

export type MutationUserManagementUpdatePermissionArgs = {
    regionId: Scalars["RegionId"];
    roleKey?: Maybe<Scalars["String"]>;
    userId: Scalars["UserId"];
};

export enum NaryFormat {
    OnePerLine = "OnePerLine",
    SemicolonSeparated = "SemicolonSeparated",
}

export type Note = {
    __typename?: "Note";
    activityEntities?: Maybe<Array<Activity>>;
    client?: Maybe<Client>;
    clientId?: Maybe<Scalars["ClientId"]>;
    community?: Maybe<Community>;
    communityId?: Maybe<Scalars["CommunityId"]>;
    createdAt: Scalars["DateTime"];
    createdAtOverride?: Maybe<Scalars["DateTime"]>;
    createdBy: Scalars["UserId"];
    createdByEntity: User;
    effectiveCreatedAt: Scalars["DateTime"];
    entityId: Scalars["NoteEntityId"];
    id: Scalars["NoteId"];
    publishedAt?: Maybe<Scalars["DateTime"]>;
    referenceBusiness?: Maybe<ReferenceBusiness>;
    referenceBusinessId?: Maybe<Scalars["ReferenceBusinessId"]>;
    referenceContact?: Maybe<ReferenceContact>;
    referenceContactId?: Maybe<Scalars["ReferenceContactId"]>;
    text: Scalars["String"];
    updatedAt: Scalars["DateTime"];
    updatedBy: Scalars["UserId"];
};

export type NoteDto = {
    __typename?: "NoteDto";
    _id: Scalars["NoteId"];
    authorId: Scalars["UserId"];
    dateAdded: Scalars["DateTime"];
    isDraft: Scalars["Boolean"];
    text: Scalars["String"];
    wasEdited: Scalars["Boolean"];
};

export type NoteTriggerConfigurationEntity = {
    __typename?: "NoteTriggerConfigurationEntity";
    entityFieldMatchers: Array<TriggerFieldMatcherEntity>;
};

export type NoteTriggerConfigurationInput = {
    entityFieldMatchers: Array<TriggerFieldMatcherEntityInput>;
};

export type OutboundClientIntakeFax = {
    clientId: Scalars["ClientId"];
    coverPageTemplateId?: Maybe<Scalars["EmailTemplateId"]>;
    options: IntakeOptions;
    recipients: Array<CommunityFaxNumber>;
};

/** Outbound email composed from within app */
export type OutboundMessage = {
    attachmentIds: Array<Scalars["FileId"]>;
    bccMyself: Scalars["Boolean"];
    body: Scalars["String"];
    cc?: Maybe<Array<Scalars["String"]>>;
    entityId: Scalars["EmailEntityId"];
    recipients: Array<Scalars["String"]>;
    specialCommunityComparison?: Maybe<OutboundMessageSpecialCommunityComparison>;
    subject: Scalars["String"];
};

/** Community comparison outbound message details */
export type OutboundMessageSpecialCommunityComparison = {
    coverPageTemplate?: Maybe<Scalars["EmailTemplateId"]>;
    includeLiveLink: Scalars["Boolean"];
    includePDF: Scalars["Boolean"];
    sectionKeys?: Maybe<Array<LayoutSectionKey>>;
    showCommunityRecords: Scalars["Boolean"];
    showCoverPage: Scalars["Boolean"];
    showFinancialDetails: Scalars["Boolean"];
    showPrimaryContacts: Scalars["Boolean"];
};

export type PatchAttachedReferenceContactInput = {
    communityId?: Maybe<Scalars["CommunityId"]>;
    deleted?: Maybe<Scalars["Boolean"]>;
    regionId: Scalars["RegionId"];
    summary?: Maybe<Scalars["String"]>;
};

export type PatchClientContactInput = {
    clientId: Scalars["ClientId"];
    powerOfAttorney?: Maybe<Array<Scalars["String"]>>;
    relationship: ClientContactRelationship;
};

export type PatchCommunityContactInput = {
    communityId: Scalars["CommunityId"];
    primary?: Maybe<Scalars["Boolean"]>;
};

export type PatchContactInput = {
    address1?: Maybe<Scalars["String"]>;
    address2?: Maybe<Scalars["String"]>;
    cellPhone?: Maybe<Scalars["String"]>;
    city?: Maybe<Scalars["String"]>;
    clientContacts?: Maybe<Array<PatchClientContactInput>>;
    communityContacts?: Maybe<Array<PatchCommunityContactInput>>;
    contactNotes?: Maybe<Scalars["String"]>;
    contactType?: Maybe<Scalars["String"]>;
    email1?: Maybe<Scalars["String"]>;
    email1OptOut?: Maybe<Scalars["Boolean"]>;
    fax?: Maybe<Scalars["String"]>;
    id: Scalars["ContactId"];
    name?: Maybe<Scalars["String"]>;
    phone1?: Maybe<Scalars["String"]>;
    preferredName?: Maybe<Scalars["String"]>;
    referenceContacts?: Maybe<Array<PatchAttachedReferenceContactInput>>;
    relationship?: Maybe<Scalars["String"]>;
    role?: Maybe<Scalars["String"]>;
    state?: Maybe<Scalars["String"]>;
    type?: Maybe<Scalars["String"]>;
    website?: Maybe<Scalars["String"]>;
    zip?: Maybe<Scalars["String"]>;
};

export type PatchCustomListItemRequest = {
    categoryId?: Maybe<Scalars["CustomListCategoryId"]>;
    icon?: Maybe<Scalars["String"]>;
    id: Scalars["CustomListItemId"];
    isArchived?: Maybe<Scalars["Boolean"]>;
    name?: Maybe<Scalars["String"]>;
    order?: Maybe<Scalars["Int"]>;
    pipelineVisibility?: Maybe<PipelineVisibility>;
};

export type PatchHostedFormRequest = {
    id: Scalars["HostedFormId"];
    name?: Maybe<Scalars["String"]>;
    regions?: Maybe<Array<HostedFormAssigneeInput>>;
    style?: Maybe<HostedFormStyle>;
};

export type PatchLayoutItemInput = {
    customTitle?: Maybe<Scalars["String"]>;
    id: Scalars["LayoutItemId"];
};

export type PatchLayoutSectionInput = {
    icon?: Maybe<Scalars["String"]>;
    id: Scalars["LayoutSectionId"];
    layoutItems?: Maybe<Array<LayoutItemInput>>;
    order?: Maybe<Scalars["Int"]>;
    title?: Maybe<Scalars["String"]>;
    visible?: Maybe<Scalars["Boolean"]>;
};

export type PatchLayoutSectionsInput = {
    layoutSections: Array<PatchLayoutSectionInput>;
};

export type PatchPhotoInput = {
    entityId: Scalars["FileEntityId"];
    id: Scalars["PhotoId"];
    order?: Maybe<Scalars["Int"]>;
    originalFile?: Maybe<PhotoFileInput>;
};

export type Permission = {
    __typename?: "Permission";
    region: Region;
    role: Scalars["String"];
};

/** User/role mapping */
export type PermissionRecord = {
    __typename?: "PermissionRecord";
    role: Scalars["String"];
    user: User;
    userId: Scalars["UserId"];
};

/** contact phone number */
export type PhoneNumber = {
    __typename?: "PhoneNumber";
    id?: Maybe<Scalars["UUID"]>;
    isPrimary: Scalars["Boolean"];
    number?: Maybe<Scalars["String"]>;
    phoneNumberType?: Maybe<Scalars["Float"]>;
};

export type PhoneNumberInput = {
    id?: Maybe<Scalars["UUID"]>;
    isPrimary: Scalars["Boolean"];
    number?: Maybe<Scalars["String"]>;
    phoneNumberType?: Maybe<Scalars["Float"]>;
};

export type Photo = {
    __typename?: "Photo";
    createdBy: Scalars["UserId"];
    dateAdded: Scalars["DateTime"];
    entityId: Scalars["FileEntityId"];
    id: Scalars["PhotoId"];
    order: Scalars["Int"];
    originalFile: File;
    originalFileId: Scalars["FileId"];
    updatedAt: Scalars["DateTime"];
    updatedBy: Scalars["UserId"];
    variations: Array<Scalars["FileId"]>;
};

export type PhotoFileInput = {
    description?: Maybe<Scalars["String"]>;
};

export enum PipelineVisibility {
    Hide = "Hide",
    Show = "Show",
}

export type PopulateGeoCacheResult = {
    __typename?: "PopulateGeoCacheResult";
    count: Scalars["Int"];
};

export type PopulatedTask = {
    __typename?: "PopulatedTask";
    entity: CrmEntity;
    task: Task;
};

export type PopulatedTaskSet = {
    __typename?: "PopulatedTaskSet";
    page: Scalars["Int"];
    tasks: Array<PopulatedTask>;
    totalPages: Scalars["Int"];
};

export type PrefilledSignatureRequestFieldPayload = {
    __typename?: "PrefilledSignatureRequestFieldPayload";
    booleanValue?: Maybe<Scalars["Boolean"]>;
    isRequired?: Maybe<Scalars["Boolean"]>;
    placeholder?: Maybe<Scalars["String"]>;
    signatureTemplateFieldId: Scalars["SignatureTemplateFieldId"];
    textValue?: Maybe<Scalars["String"]>;
};

export type Prompt = {
    __typename?: "Prompt";
    client?: Maybe<Scalars["String"]>;
    community?: Maybe<Scalars["String"]>;
    id: Scalars["PromptId"];
    key: Scalars["String"];
    order?: Maybe<Scalars["Int"]>;
    title?: Maybe<Scalars["String"]>;
};

/** Propagation information */
export type PropagationResult = {
    __typename?: "PropagationResult";
    communitiesCreated: Scalars["Float"];
    communitiesMatched: Scalars["Float"];
    communitiesWithExistingLinks: Scalars["Float"];
    regionLicenseMapId: Scalars["RegionLicenseMapId"];
};

export type PublicRecord = {
    __typename?: "PublicRecord";
    address: Scalars["String"];
    administrator: Scalars["String"];
    capacity: Scalars["Float"];
    city: Scalars["String"];
    closedDate: Scalars["String"];
    contacts?: Maybe<Array<ContactEntity>>;
    country: Scalars["String"];
    county: Scalars["String"];
    dataSourceName?: Maybe<Scalars["String"]>;
    email: Scalars["String"];
    id?: Maybe<Scalars["PublicRecordId"]>;
    lastSeenImportId?: Maybe<Scalars["UUID"]>;
    lat?: Maybe<Scalars["String"]>;
    license?: Maybe<License>;
    licenseDate: Scalars["String"];
    licenseNumber: Scalars["String"];
    licenseStatus: Scalars["String"];
    licenseType: Array<Scalars["String"]>;
    licenseUrl: Scalars["String"];
    licensee: Scalars["String"];
    lng?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    phone: Scalars["String"];
    publicRecordContacts?: Maybe<Array<PublicRecordContact>>;
    state: Scalars["String"];
    zip: Scalars["String"];
};

/** public record contact entity */
export type PublicRecordContact = {
    __typename?: "PublicRecordContact";
    address1?: Maybe<Scalars["String"]>;
    address2?: Maybe<Scalars["String"]>;
    cellPhone?: Maybe<Scalars["String"]>;
    city?: Maybe<Scalars["String"]>;
    contactNotes?: Maybe<Scalars["String"]>;
    email1?: Maybe<Scalars["String"]>;
    fax?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["PublicRecordContactId"]>;
    name?: Maybe<Scalars["String"]>;
    phone1?: Maybe<Scalars["String"]>;
    primary: Scalars["Boolean"];
    relationship?: Maybe<Scalars["String"]>;
    role?: Maybe<Scalars["String"]>;
    state?: Maybe<Scalars["String"]>;
    website?: Maybe<Scalars["String"]>;
    zip?: Maybe<Scalars["String"]>;
};

export type PublicRecordInput = {
    address?: Maybe<Scalars["String"]>;
    administrator?: Maybe<Scalars["String"]>;
    capacity?: Maybe<Scalars["Float"]>;
    city?: Maybe<Scalars["String"]>;
    closedDate?: Maybe<Scalars["String"]>;
    contacts: Array<ContactInput>;
    country?: Maybe<Scalars["String"]>;
    county?: Maybe<Scalars["String"]>;
    dataSourceName?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
    id?: Maybe<Scalars["PublicRecordId"]>;
    lastSeenImportId?: Maybe<Scalars["String"]>;
    licenseDate?: Maybe<Scalars["String"]>;
    licenseNumber?: Maybe<Scalars["String"]>;
    licenseStatus?: Maybe<Scalars["String"]>;
    licenseType: Array<Scalars["String"]>;
    licenseUrl?: Maybe<Scalars["String"]>;
    licensee?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    phone?: Maybe<Scalars["String"]>;
    state?: Maybe<Scalars["String"]>;
    zip?: Maybe<Scalars["String"]>;
};

export type PublicRecordSearchRequest = {
    address?: Maybe<Scalars["String"]>;
    county?: Maybe<Scalars["String"]>;
    licenseStatus?: Maybe<Scalars["String"]>;
    licenseType?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    state?: Maybe<Scalars["String"]>;
    zip?: Maybe<Scalars["String"]>;
};

export type PublicRecordSearchResult = {
    __typename?: "PublicRecordSearchResult";
    count: Scalars["Int"];
    records: Array<PublicRecord>;
};

export type Query = {
    __typename?: "Query";
    advancedSearch: AdvancedSearchResult;
    canCreateSignatureTemplate: SignatureCreatePermission;
    communityNames: CommunityNamesResult;
    communitySearch: CommunitySearchResult;
    communitySearchMapView: CommunityMarkerCollection;
    composeMessage: MessageCompose;
    doTestRequest: TestResponse;
    getActivityFeed: ActivityFeed;
    getAuditableEvents: Array<AuditRecord>;
    getCalendarSubscription: CalendarSubscription;
    getClient: Client;
    getClientContacts: Array<ClientContact>;
    getClientIntakeFaxRecipients: Array<ClientIntakeFaxRecipient>;
    getClientReferrals: Array<Referral>;
    getClients: ClientsSearchResult;
    getCommunity: Community;
    getCommunityContacts: Array<CommunityContactDto>;
    getContact: ContactEntity;
    getCustomList: CustomList;
    getCustomLists: Array<CustomList>;
    getDraftSignatureRequest: DraftSignatureRequest;
    getEntities: Array<BridgeEntityResult>;
    getEntitiesForTenant: Array<BridgeEntityResult>;
    getEntityById: GetEntityByIdResult;
    getEntityRegionPermissions: EntityRegionPermissions;
    getEvergreenDataSource: EvergreenDataSource;
    getEvergreenDataSources: Array<EvergreenDataSource>;
    getEvergreenDataSourcesForUpdate: Array<EvergreenDataSource>;
    getEvergreenImports: Array<EvergreenImport>;
    getEvergreenLicenseTypes: Array<EvergreenLicenseType>;
    getExternalInvoiceAccountSettings: ExternalInvoiceAccountSettings;
    getExternalInvoiceInitialParameters: ExternalInvoiceInitialParameters;
    getFile: File;
    getFiles: FileEntityList;
    getGlobalFeatures: GlobalFeaturesResponse;
    getHostedFormTokensForEntity: Array<HostedFormToken>;
    getHostedForms: Array<HostedForm>;
    getIdentitiesWithoutThumbnail: Array<Identity>;
    getIdentity?: Maybe<Identity>;
    getImport: Import;
    getImports: Array<Import>;
    getInvoiceCreateExperience: InvoiceCreateExperienceResponse;
    getLayoutContainerByKey: LayoutContainer;
    getLayoutContainersByKey: Array<LayoutContainer>;
    getLayoutSection: LayoutSection;
    getLayoutSections: LayoutSectionsOutput;
    getLegacyQuestionsForTenant: Array<Question>;
    getLicense?: Maybe<License>;
    getMessage: SentMessage;
    getMessagesForEntity: Array<SentMessage>;
    getMessagingConfiguration: MessagingConfiguration;
    getNextTasksForParentIds: Array<Task>;
    getNotesForEntity: Array<NoteDto>;
    getPhotosForEntity: Array<Photo>;
    getPublicRecord?: Maybe<PublicRecord>;
    getQuestionPermissions: BridgeQuestionPermissions;
    getReferenceBusiness: ReferenceBusiness;
    getReferenceContact: ReferenceContact;
    getReferralsToBusiness: Array<Referral>;
    getReferralsToCommunity: Array<Referral>;
    getReferralsToContact: Array<Referral>;
    getRegion: Region;
    getRolesWithActions: Array<RoleWithActions>;
    getSavedQueries: LoadSavedQueryResult;
    getSignableTemplates: Array<File>;
    getSignatureRequest: SignatureRequest;
    getSignatureRequestsForEntity: Array<SignatureRequest>;
    getSignatureTemplate?: Maybe<SignatureTemplate>;
    getSmtpCredentialForUser?: Maybe<SmtpCredential>;
    getStateReport: Array<StateReportQueryRow>;
    getTask: PopulatedTask;
    getTaskTypes: Array<TaskType>;
    getTasks: PopulatedTaskSet;
    getTasksForEntity: Array<Task>;
    getTasksv2: Array<PopulatedTask>;
    getTemplate: TemplateResponse;
    getTemplates: TemplateList;
    getTenant: Tenant;
    getTenantCredentials: Array<TenantCredential>;
    getTenantHistory: Array<TenantHistory>;
    getTenants: Array<Tenant>;
    getTokenForClient: Token;
    getUser: User;
    getUserGroups: Array<UserGroupResult>;
    getUserTenantRegionOptions: UserTenantRegionOptions;
    getUsers: Array<User>;
    getUsersForRegion: Array<UserDescriptor>;
    getWorkflow: Workflow;
    getWorkflowExecutionsForEntity: Array<WorkflowExecution>;
    getWorkflows: Array<Workflow>;
    getWorkflowsForTenant: Array<Workflow>;
    graphqlDebug: GraphqlDebugResult;
    /** Initiate a report export (not intended for client-side use) */
    initiateReportExport: Scalars["ReportExportId"];
    jobStats: JobStats;
    placeholderPerspective: SignatureRequestPerspective;
    publicRecordSearch: PublicRecordSearchResult;
    referenceBusinessMapSearch: ReferenceBusinessMarkerCollection;
    referenceBusinessSearch: ReferenceBusinessSearchResult;
    referenceCommunityMapSearch: ReferenceCommunityMarkerCollection;
    referenceCommunitySearch: ReferenceCommunitySearchResult;
    referenceContactMapSearch: ReferenceContactMarkerCollection;
    referenceContactSearch: ReferenceContactSearchResult;
    reportClientPlacementRevenue: ClientPlacementRevenueResult;
    reportInboundReferrals: InboundReferralsResult;
    reportReferrals: ReferralsResult;
    searchExternalInvoiceCustomers: ExternalInvoiceCustomerSearchResponse;
    searchExternalInvoices: ExternalInvoiceSearchResponse;
    systemConfiguration: SystemConfiguration;
    userManagementGetApiKey: GetApiKeyResponse;
    userManagementGetRoles: Array<RoleDescription>;
    userManagementGetUser: ManageableUser;
    userManagementGetUsers: Array<ManageableUser>;
    validateTemplate: TemplateValidationResponse;
};

export type QueryAdvancedSearchArgs = {
    params: AdvancedSearchRequest;
};

export type QueryCanCreateSignatureTemplateArgs = {
    fileId?: Maybe<Scalars["FileId"]>;
};

export type QueryCommunityNamesArgs = {
    ids?: Maybe<Array<Scalars["CommunityId"]>>;
    regionKey: Scalars["String"];
};

export type QueryCommunitySearchArgs = {
    params: CommunitySearchRequest;
};

export type QueryCommunitySearchMapViewArgs = {
    mapView: CommunitySearchRequestMapComponent;
    searchParams: CommunitySearchRequest;
};

export type QueryComposeMessageArgs = {
    id: Scalars["EmailEntityId"];
    specialMessageExperience?: Maybe<Scalars["String"]>;
};

export type QueryDoTestRequestArgs = {
    startingVal: Scalars["Int"];
};

export type QueryGetActivityFeedArgs = {
    entityId: Scalars["ActivityParentEntityId"];
};

export type QueryGetAuditableEventsArgs = {
    actionTypes: Array<ActionType>;
    after: Scalars["String"];
    before?: Maybe<Scalars["String"]>;
};

export type QueryGetClientArgs = {
    clientId: Scalars["ClientId"];
    options?: Maybe<GetClientOptions>;
};

export type QueryGetClientContactsArgs = {
    params: GetClientContactsRequest;
};

export type QueryGetClientIntakeFaxRecipientsArgs = {
    clientId: Scalars["ClientId"];
};

export type QueryGetClientReferralsArgs = {
    clientId: Scalars["ClientId"];
};

export type QueryGetClientsArgs = {
    params: ClientSearchRequest;
};

export type QueryGetCommunityArgs = {
    communityId: Scalars["CommunityId"];
};

export type QueryGetCommunityContactsArgs = {
    communityId: Scalars["CommunityId"];
    regionId: Scalars["RegionId"];
};

export type QueryGetContactArgs = {
    contactId: Scalars["ContactId"];
};

export type QueryGetCustomListArgs = {
    key: CustomListKey;
};

export type QueryGetDraftSignatureRequestArgs = {
    entityId: Scalars["FileEntityId"];
    fileId: Scalars["FileId"];
    signatureRequestId?: Maybe<Scalars["SignatureRequestId"]>;
};

export type QueryGetEntitiesForTenantArgs = {
    tenantId: Scalars["TenantId"];
};

export type QueryGetEntityByIdArgs = {
    entityId: Scalars["AnswerEntityId"];
    entityType: AdvancedSearchEntityType;
    select: Array<AdvancedSearchSelectInput>;
};

export type QueryGetEntityRegionPermissionsArgs = {
    params: GetEntityRegionPermissionsParams;
};

export type QueryGetEvergreenDataSourceArgs = {
    sourceName: Scalars["String"];
};

export type QueryGetEvergreenImportsArgs = {
    skip: Scalars["Int"];
    take: Scalars["Int"];
};

export type QueryGetExternalInvoiceInitialParametersArgs = {
    params: GetExternalInvoiceInitialParametersRequest;
};

export type QueryGetFileArgs = {
    fileId: Scalars["FileId"];
};

export type QueryGetFilesArgs = {
    entityId: Scalars["FileEntityId"];
};

export type QueryGetHostedFormTokensForEntityArgs = {
    entityId: Scalars["HostedFormEntityId"];
};

export type QueryGetIdentityArgs = {
    identityId: Scalars["IdentityId"];
};

export type QueryGetImportArgs = {
    importId: Scalars["ImportId"];
    tenantId: Scalars["TenantId"];
};

export type QueryGetImportsArgs = {
    tenantId: Scalars["TenantId"];
};

export type QueryGetLayoutContainerByKeyArgs = {
    key: Scalars["String"];
};

export type QueryGetLayoutContainersByKeyArgs = {
    keys: Array<LayoutSectionParentKey>;
};

export type QueryGetLayoutSectionArgs = {
    id: Scalars["LayoutSectionId"];
};

export type QueryGetLayoutSectionsArgs = {
    parentKey: LayoutSectionParentKey;
};

export type QueryGetLegacyQuestionsForTenantArgs = {
    tenantId: Scalars["TenantId"];
};

export type QueryGetLicenseArgs = {
    licenseId: Scalars["LicenseId"];
};

export type QueryGetMessageArgs = {
    sentMessageId: Scalars["SentMessageId"];
};

export type QueryGetMessagesForEntityArgs = {
    entityId: Scalars["EmailEntityId"];
};

export type QueryGetNextTasksForParentIdsArgs = {
    parentIds: Array<Scalars["TaskParentId"]>;
};

export type QueryGetNotesForEntityArgs = {
    entityId: Scalars["NoteEntityId"];
    filter?: Maybe<GetNotesForEntityFilter>;
};

export type QueryGetPhotosForEntityArgs = {
    entityId: Scalars["FileEntityId"];
};

export type QueryGetPublicRecordArgs = {
    publicRecordId: Scalars["PublicRecordId"];
};

export type QueryGetReferenceBusinessArgs = {
    referenceBusinessId: Scalars["ReferenceBusinessId"];
};

export type QueryGetReferenceContactArgs = {
    referenceContactId: Scalars["ReferenceContactId"];
};

export type QueryGetReferralsToBusinessArgs = {
    referenceBusinessId: Scalars["ReferenceBusinessId"];
};

export type QueryGetReferralsToCommunityArgs = {
    communityId: Scalars["CommunityId"];
};

export type QueryGetReferralsToContactArgs = {
    referenceContactId: Scalars["ReferenceContactId"];
};

export type QueryGetRegionArgs = {
    regionId: Scalars["RegionId"];
    tenantId: Scalars["TenantId"];
};

export type QueryGetSignableTemplatesArgs = {
    entityType: FileEntityType;
};

export type QueryGetSignatureRequestArgs = {
    signatureRequestId: Scalars["SignatureRequestId"];
};

export type QueryGetSignatureRequestsForEntityArgs = {
    entityId: Scalars["FileEntityId"];
};

export type QueryGetSignatureTemplateArgs = {
    fileId: Scalars["FileId"];
};

export type QueryGetTaskArgs = {
    regionId: Scalars["RegionId"];
    taskId: Scalars["TaskId"];
};

export type QueryGetTasksArgs = {
    activeOnly: Scalars["Boolean"];
    page: Scalars["Int"];
    regionId: Scalars["ID"];
};

export type QueryGetTasksForEntityArgs = {
    parentId: Scalars["TaskParentId"];
};

export type QueryGetTasksv2Args = {
    params: GetTasksRequest;
};

export type QueryGetTemplateArgs = {
    emailTemplateId: Scalars["EmailTemplateId"];
};

export type QueryGetTenantArgs = {
    tenantId: Scalars["TenantId"];
};

export type QueryGetTenantHistoryArgs = {
    tenantId: Scalars["TenantId"];
};

export type QueryGetTokenForClientArgs = {
    clientId: Scalars["ClientId"];
};

export type QueryGetUserArgs = {
    userId: Scalars["UserId"];
};

export type QueryGetUsersForRegionArgs = {
    regionId: Scalars["RegionId"];
};

export type QueryGetWorkflowArgs = {
    tenantId: Scalars["TenantId"];
    workflowId: Scalars["WorkflowId"];
};

export type QueryGetWorkflowExecutionsForEntityArgs = {
    workflowTargetId: Scalars["WorkflowTargetId"];
};

export type QueryGetWorkflowsForTenantArgs = {
    tenantId: Scalars["TenantId"];
};

export type QueryGraphqlDebugArgs = {
    expectedResult: Scalars["String"];
};

export type QueryInitiateReportExportArgs = {
    params: AdvancedSearchRequest;
};

export type QueryPublicRecordSearchArgs = {
    params: PublicRecordSearchRequest;
};

export type QueryReferenceBusinessMapSearchArgs = {
    mapView: CommunitySearchRequestMapComponent;
    searchParams: ReferenceBusinessSearchRequest;
};

export type QueryReferenceBusinessSearchArgs = {
    search: ReferenceBusinessSearchRequest;
};

export type QueryReferenceCommunityMapSearchArgs = {
    mapView: CommunitySearchRequestMapComponent;
    searchParams: ReferenceCommunitySearchRequest;
};

export type QueryReferenceCommunitySearchArgs = {
    search: ReferenceCommunitySearchRequest;
};

export type QueryReferenceContactMapSearchArgs = {
    mapView: CommunitySearchRequestMapComponent;
    searchParams: ReferenceContactSearchRequest;
};

export type QueryReferenceContactSearchArgs = {
    search: ReferenceContactSearchRequest;
};

export type QueryReportClientPlacementRevenueArgs = {
    endDate: Scalars["String"];
    region: Scalars["String"];
    startDate: Scalars["String"];
};

export type QueryReportInboundReferralsArgs = {
    endDate: Scalars["String"];
    region: Scalars["String"];
    startDate: Scalars["String"];
};

export type QueryReportReferralsArgs = {
    endDate: Scalars["String"];
    region: Scalars["String"];
    startDate: Scalars["String"];
};

export type QuerySearchExternalInvoiceCustomersArgs = {
    params: ExternalInvoiceCustomerSearchRequest;
};

export type QuerySearchExternalInvoicesArgs = {
    params: ExternalInvoiceSearchRequest;
};

export type QueryUserManagementGetApiKeyArgs = {
    userId: Scalars["UserId"];
};

export type QueryUserManagementGetUserArgs = {
    userId: Scalars["UserId"];
};

export type QueryUserManagementGetUsersArgs = {
    params?: Maybe<GetUsersParameters>;
};

export type QueryValidateTemplateArgs = {
    emailTemplateId: Scalars["EmailTemplateId"];
    messageType: Scalars["String"];
};

export type Question = {
    __typename?: "Question";
    annotation?: Maybe<Scalars["String"]>;
    category?: Maybe<Scalars["String"]>;
    clientAnnotation?: Maybe<Scalars["String"]>;
    clientInteraction?: Maybe<Scalars["String"]>;
    clientPdfBehavior?: Maybe<Scalars["String"]>;
    clientRulesString?: Maybe<Scalars["String"]>;
    clientSearchBehavior?: Maybe<Scalars["String"]>;
    clientTitle?: Maybe<Scalars["String"]>;
    communityAnnotation?: Maybe<Scalars["String"]>;
    communityInteraction?: Maybe<Scalars["String"]>;
    communityPdfBehavior?: Maybe<Scalars["String"]>;
    communityRulesString?: Maybe<Scalars["String"]>;
    communitySearchBehavior?: Maybe<Scalars["String"]>;
    communityTitle?: Maybe<Scalars["String"]>;
    comparisonBehavior?: Maybe<Scalars["String"]>;
    entityType?: Maybe<Scalars["String"]>;
    id: Scalars["QuestionId"];
    interaction?: Maybe<Scalars["String"]>;
    key: Scalars["String"];
    order?: Maybe<Scalars["Int"]>;
    prompts?: Maybe<Array<Prompt>>;
    questionType?: Maybe<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
};

/** A string-based payload of questions */
export type QuestionBulkCreatePayload = {
    serializedQuestions: Scalars["String"];
    tenantId: Scalars["TenantId"];
};

/** An individual question */
export type QuestionCreatePayload = {
    communityTitle: Scalars["String"];
    prompts: Array<Scalars["String"]>;
    tenantId: Scalars["TenantId"];
};

/** A payload for reordering questions */
export type QuestionReorderPayload = {
    questionIds: Array<Scalars["QuestionId"]>;
};

export enum QuestionSource {
    Entity = "Entity",
    Legacy = "Legacy",
}

/** QuickBooks Credential Details */
export type QuickBooksDetails = {
    __typename?: "QuickBooksDetails";
    realmId: Scalars["String"];
};

/** Reference contact */
export type ReferenceBusiness = {
    __typename?: "ReferenceBusiness";
    activityEntities?: Maybe<Array<Activity>>;
    answers: Array<Answer>;
    appLink: Scalars["String"];
    business: ContactEntity;
    businessContactEntity?: Maybe<ContactEntity>;
    contacts: Array<ReferenceContact>;
    createdBy?: Maybe<Scalars["UserId"]>;
    dateAdded: Scalars["DateTime"];
    deleted: Scalars["Boolean"];
    draftNote?: Maybe<Scalars["String"]>;
    id: Scalars["ReferenceBusinessId"];
    latitude?: Maybe<Scalars["String"]>;
    longitude?: Maybe<Scalars["String"]>;
    noteEntities?: Maybe<Array<Note>>;
    notes: Array<NoteDto>;
    ownerId?: Maybe<Scalars["UserId"]>;
    regionId: Scalars["RegionId"];
    regions: Array<Scalars["String"]>;
    summary?: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
    updatedBy?: Maybe<Scalars["UserId"]>;
};

export type ReferenceBusinessCoordinates = {
    __typename?: "ReferenceBusinessCoordinates";
    appLink?: Maybe<Scalars["String"]>;
    id: Scalars["ReferenceBusinessId"];
    lat: Scalars["Float"];
    lng: Scalars["Float"];
    name?: Maybe<Scalars["String"]>;
};

export type ReferenceBusinessEntity = CrmEntity & {
    __typename?: "ReferenceBusinessEntity";
    contact?: Maybe<CrmEntityContact>;
    displayName?: Maybe<Scalars["String"]>;
    kind: Scalars["String"];
    kindTitle: Scalars["String"];
    path: Scalars["String"];
};

export type ReferenceBusinessMarkerCollection = {
    __typename?: "ReferenceBusinessMarkerCollection";
    coordinates?: Maybe<Coordinates>;
    recommendedZoom: Scalars["Float"];
    referenceBusinessClusters: Array<MapCluster>;
    referenceBusinessCoordinates: Array<ReferenceBusinessCoordinates>;
};

export type ReferenceBusinessSearchHit = {
    __typename?: "ReferenceBusinessSearchHit";
    address?: Maybe<Scalars["String"]>;
    assignedTo?: Maybe<Scalars["String"]>;
    city?: Maybe<Scalars["String"]>;
    fax?: Maybe<Scalars["String"]>;
    id: Scalars["ReferenceBusinessId"];
    latitude?: Maybe<Scalars["String"]>;
    longitude?: Maybe<Scalars["String"]>;
    mostRecentReferral?: Maybe<Scalars["DateTime"]>;
    name?: Maybe<Scalars["String"]>;
    numberOfReferrals: Scalars["Float"];
    organizationType?: Maybe<Scalars["String"]>;
    phone?: Maybe<Scalars["String"]>;
    state?: Maybe<Scalars["String"]>;
    summary?: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
    zip?: Maybe<Scalars["String"]>;
};

export type ReferenceBusinessSearchRequest = {
    assignedUserId?: Maybe<Scalars["UserId"]>;
    businessCondition?: Maybe<AdvancedSearchRequestCondition>;
    geoParams?: Maybe<SearchGeoParams>;
    ids?: Maybe<Array<Scalars["ReferenceBusinessId"]>>;
    organizationTypes?: Maybe<Array<Scalars["String"]>>;
    page: Scalars["Float"];
    perPage: Scalars["Float"];
    regionId?: Maybe<Scalars["RegionId"]>;
    regionIds?: Maybe<Array<Scalars["RegionId"]>>;
    search: Scalars["String"];
    sort: Scalars["String"];
    sortDirection: Scalars["String"];
};

export type ReferenceBusinessSearchResult = {
    __typename?: "ReferenceBusinessSearchResult";
    coordinates?: Maybe<Coordinates>;
    hits: Array<ReferenceBusinessSearchHit>;
    total: Scalars["Float"];
};

export type ReferenceCommunityMarkerCollection = {
    __typename?: "ReferenceCommunityMarkerCollection";
    communityClusters: Array<MapCluster>;
    communityCoordinates: Array<CommunityCoordinates>;
    coordinates?: Maybe<Coordinates>;
    recommendedZoom: Scalars["Float"];
};

export type ReferenceCommunitySearchHit = {
    __typename?: "ReferenceCommunitySearchHit";
    address?: Maybe<Scalars["String"]>;
    city?: Maybe<Scalars["String"]>;
    fax?: Maybe<Scalars["String"]>;
    id: Scalars["CommunityId"];
    latitude?: Maybe<Scalars["String"]>;
    longitude?: Maybe<Scalars["String"]>;
    mostRecentReferral?: Maybe<Scalars["DateTime"]>;
    name?: Maybe<Scalars["String"]>;
    numberOfReferrals: Scalars["Float"];
    phone?: Maybe<Scalars["String"]>;
    state?: Maybe<Scalars["String"]>;
    summary?: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
    zip?: Maybe<Scalars["String"]>;
};

export type ReferenceCommunitySearchRequest = {
    geoParams?: Maybe<SearchGeoParams>;
    page: Scalars["Float"];
    perPage: Scalars["Float"];
    regionId: Scalars["RegionId"];
    search: Scalars["String"];
    sort: Scalars["String"];
    sortDirection: Scalars["String"];
};

export type ReferenceCommunitySearchResult = {
    __typename?: "ReferenceCommunitySearchResult";
    coordinates?: Maybe<Coordinates>;
    hits: Array<ReferenceCommunitySearchHit>;
    total: Scalars["Float"];
};

/** Reference contact */
export type ReferenceContact = {
    __typename?: "ReferenceContact";
    activityEntities?: Maybe<Array<Activity>>;
    answers: Array<Answer>;
    appLink: Scalars["String"];
    business?: Maybe<ReferenceBusiness>;
    businessId?: Maybe<Scalars["ReferenceBusinessId"]>;
    community?: Maybe<Community>;
    communityId?: Maybe<Scalars["CommunityId"]>;
    contact?: Maybe<ContactEntity>;
    contactEntity?: Maybe<ContactEntity>;
    createdBy: Scalars["UserId"];
    dateAdded: Scalars["DateTime"];
    deleted: Scalars["Boolean"];
    draftNote?: Maybe<Scalars["String"]>;
    id: Scalars["ReferenceContactId"];
    noteEntities?: Maybe<Array<Note>>;
    notes: Array<NoteDto>;
    ownerId?: Maybe<Scalars["UserId"]>;
    regionId: Scalars["RegionId"];
    regions: Array<Scalars["String"]>;
    summary?: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
    updatedBy: Scalars["UserId"];
};

export type ReferenceContactCoordinates = {
    __typename?: "ReferenceContactCoordinates";
    appLink?: Maybe<Scalars["String"]>;
    businessId?: Maybe<Scalars["ReferenceBusinessId"]>;
    communityId?: Maybe<Scalars["CommunityId"]>;
    contacts: Array<ReferenceContactCoordinatesContact>;
    lat: Scalars["Float"];
    lng: Scalars["Float"];
    name?: Maybe<Scalars["String"]>;
};

export type ReferenceContactCoordinatesContact = {
    __typename?: "ReferenceContactCoordinatesContact";
    appLink: Scalars["String"];
    id: Scalars["ReferenceContactId"];
    name?: Maybe<Scalars["String"]>;
};

export type ReferenceContactEntity = CrmEntity & {
    __typename?: "ReferenceContactEntity";
    contact?: Maybe<CrmEntityContact>;
    displayName?: Maybe<Scalars["String"]>;
    kind: Scalars["String"];
    kindTitle: Scalars["String"];
    path: Scalars["String"];
};

export type ReferenceContactInput = {
    deleted?: Maybe<Scalars["Boolean"]>;
    regionId: Scalars["RegionId"];
    summary?: Maybe<Scalars["String"]>;
};

export type ReferenceContactMarkerCollection = {
    __typename?: "ReferenceContactMarkerCollection";
    coordinates?: Maybe<Coordinates>;
    recommendedZoom: Scalars["Float"];
    referenceContactClusters: Array<MapCluster>;
    referenceContactCoordinates: Array<ReferenceContactCoordinates>;
};

export type ReferenceContactSearchHit = {
    __typename?: "ReferenceContactSearchHit";
    address?: Maybe<Scalars["String"]>;
    assignedTo?: Maybe<Scalars["String"]>;
    businessId?: Maybe<Scalars["ReferenceBusinessId"]>;
    cellPhone?: Maybe<Scalars["String"]>;
    city?: Maybe<Scalars["String"]>;
    communityId?: Maybe<Scalars["CommunityId"]>;
    email?: Maybe<Scalars["String"]>;
    id: Scalars["ReferenceContactId"];
    latitude?: Maybe<Scalars["String"]>;
    longitude?: Maybe<Scalars["String"]>;
    mostRecentReferral?: Maybe<Scalars["DateTime"]>;
    name?: Maybe<Scalars["String"]>;
    numberOfReferrals: Scalars["Float"];
    organizationFax?: Maybe<Scalars["String"]>;
    organizationName?: Maybe<Scalars["String"]>;
    organizationPhone?: Maybe<Scalars["String"]>;
    organizationType?: Maybe<Scalars["String"]>;
    phone?: Maybe<Scalars["String"]>;
    role?: Maybe<Scalars["String"]>;
    state?: Maybe<Scalars["String"]>;
    summary?: Maybe<Scalars["String"]>;
    updatedAt: Scalars["DateTime"];
    zip?: Maybe<Scalars["String"]>;
};

export type ReferenceContactSearchRequest = {
    assignedUserId?: Maybe<Scalars["UserId"]>;
    businessCondition?: Maybe<AdvancedSearchRequestCondition>;
    businessId?: Maybe<Scalars["ReferenceBusinessId"]>;
    contactCondition?: Maybe<AdvancedSearchRequestCondition>;
    geoParams?: Maybe<SearchGeoParams>;
    ids?: Maybe<Array<Scalars["ReferenceContactId"]>>;
    organizationTypes?: Maybe<Array<Scalars["String"]>>;
    page: Scalars["Float"];
    perPage: Scalars["Float"];
    regionId?: Maybe<Scalars["RegionId"]>;
    regionIds?: Maybe<Array<Scalars["RegionId"]>>;
    search: Scalars["String"];
    sort: Scalars["String"];
    sortDirection: Scalars["String"];
};

export type ReferenceContactSearchResult = {
    __typename?: "ReferenceContactSearchResult";
    coordinates?: Maybe<Coordinates>;
    hits: Array<ReferenceContactSearchHit>;
    total: Scalars["Float"];
};

export type Referral = {
    __typename?: "Referral";
    client?: Maybe<Client>;
    clientId: Scalars["ClientId"];
    community?: Maybe<Community>;
    communityId?: Maybe<Scalars["CommunityId"]>;
    createdBy: Scalars["UserId"];
    dateAdded: Scalars["DateTime"];
    id: Scalars["ReferralId"];
    notes?: Maybe<Scalars["String"]>;
    referenceBusiness?: Maybe<ReferenceBusiness>;
    referenceBusinessId?: Maybe<Scalars["ReferenceBusinessId"]>;
    referenceContact?: Maybe<ReferenceContact>;
    referenceContactId?: Maybe<Scalars["ReferenceContactId"]>;
    referralType: Scalars["String"];
    sentMessage?: Maybe<SentMessage>;
    sentMessageId?: Maybe<Scalars["SentMessageId"]>;
    updatedAt: Scalars["DateTime"];
    updatedBy: Scalars["UserId"];
    when: Scalars["DateTime"];
};

export type ReferralsBusinessReferral = {
    __typename?: "ReferralsBusinessReferral";
    business: ReferenceBusiness;
    inboundCount: Scalars["Float"];
    outboundCount: Scalars["Float"];
};

export type ReferralsContactReferral = {
    __typename?: "ReferralsContactReferral";
    contact: ReferenceContact;
    inboundCount: Scalars["Float"];
    outboundCount: Scalars["Float"];
};

export type ReferralsResult = {
    __typename?: "ReferralsResult";
    businesses: Array<ReferralsBusinessReferral>;
    contacts: Array<ReferralsContactReferral>;
    generic: Array<InboundReferralsGenericReferral>;
};

/** Region */
export type Region = {
    __typename?: "Region";
    /** Identifier */
    id: Scalars["RegionId"];
    /** Machine friendly name */
    key: Scalars["String"];
    /** Human readable name */
    name: Scalars["String"];
    /** User permissions in region */
    permissions: Array<PermissionRecord>;
    regionLicenseMaps: Array<RegionLicenseMap>;
};

/** Region/license type mapping */
export type RegionLicenseMap = {
    __typename?: "RegionLicenseMap";
    autoPropagateEnabled: Scalars["Boolean"];
    id: Scalars["RegionLicenseMapId"];
    identityFilterObjects: Array<IdentityFilterObject>;
    licenseMappingObjects: Array<LicenseMappingObject>;
    region: Region;
    settings?: Maybe<RegionLicenseMapSettings>;
};

export type RegionLicenseMapSettings = {
    __typename?: "RegionLicenseMapSettings";
    closedHandling?: Maybe<RegionLicenseMapSettingsClosedHandling>;
    mode?: Maybe<RegionLicenseMapSettingsMode>;
    updateAttributes?: Maybe<RegionLicenseMapSettingsUpdateAttributes>;
};

export enum RegionLicenseMapSettingsClosedHandling {
    Cautious = "Cautious",
    Donothing = "Donothing",
    Forceful = "Forceful",
}

export type RegionLicenseMapSettingsInput = {
    closedHandling?: Maybe<RegionLicenseMapSettingsClosedHandling>;
    mode?: Maybe<RegionLicenseMapSettingsMode>;
    updateAttributes?: Maybe<RegionLicenseMapSettingsUpdateAttributes>;
};

export enum RegionLicenseMapSettingsMode {
    Default = "default",
    Perlicensetype = "perlicensetype",
}

export enum RegionLicenseMapSettingsUpdateAttributes {
    Always = "always",
    Never = "never",
}

export type RegionLicenseMapUpdateRequest = {
    autoPropagateEnabled: Scalars["Boolean"];
    existingRegionLicenseMapId?: Maybe<Scalars["RegionLicenseMapId"]>;
    identityFilters: Array<IdentityFilterInput>;
    licenseMappings: Array<LicenseMappingInput>;
    regionId: Scalars["RegionId"];
    settings: RegionLicenseMapSettingsInput;
    tenantId: Scalars["TenantId"];
};

export type RelocateImageInput = {
    id: Scalars["String"];
    src: Scalars["String"];
};

export type RelocateImageResponse = {
    __typename?: "RelocateImageResponse";
    id: Scalars["String"];
    src: Scalars["String"];
};

export type RemoveClientCommunityRequest = {
    communityId: Scalars["CommunityId"];
};

/** An individual slice of time in a client placement report */
export type ReportSegment = {
    __typename?: "ReportSegment";
    /** Total moves for time period */
    moves: Scalars["Int"];
    /** Total revenue for time period */
    revenue: Scalars["Float"];
    /** The beginning of the time window in YYYY-MM-DD format */
    startDate: Scalars["String"];
    /** broken down by user */
    users: Array<UserReportSegment>;
};

export type ResetTenantOptions = {
    /** Delete all communities */
    deleteCommunities: Scalars["Boolean"];
    /** Tenant ID */
    tenantId: Scalars["TenantId"];
};

export type RoleDescription = {
    __typename?: "RoleDescription";
    humanReadableName: Scalars["String"];
    key: Scalars["String"];
};

export type RoleWithActions = {
    __typename?: "RoleWithActions";
    actions: Array<ActionType>;
    humanReadableName: Scalars["String"];
    key: Scalars["String"];
    userAssignable: Scalars["Boolean"];
};

export type SavedQueryInput = {
    condition: AdvancedSearchRequestCondition;
    entityType: Scalars["String"];
    id?: Maybe<Scalars["SavedQueryId"]>;
    isShared: Scalars["Boolean"];
    name: Scalars["String"];
    regionVisibility?: Maybe<EntityRegionVisibilityInput>;
    select: Array<AdvancedSearchSelectInput>;
    sort: AdvancedSearchRequestSort;
    summaries?: Maybe<Array<AdvancedSearchRequestSummaryInput>>;
};

export type SavedQueryResult = {
    __typename?: "SavedQueryResult";
    condition: AdvancedSearchCondition;
    createdAt: Scalars["DateTime"];
    createdBy: Scalars["UserId"];
    editable: Scalars["Boolean"];
    entityType: AdvancedSearchEntityType;
    id: Scalars["SavedQueryId"];
    isFavorite: Scalars["Boolean"];
    isMyQuery: Scalars["Boolean"];
    isShared: Scalars["Boolean"];
    name: Scalars["String"];
    regionVisibility: EntityRegionVisibility;
    schedule?: Maybe<SavedQueryScheduleResult>;
    select: Array<AdvancedSearchSelect>;
    sort: AdvancedSearchSort;
    summaries?: Maybe<Array<AdvancedSearchRequestSummary>>;
    updatedAt: Scalars["DateTime"];
    warnings?: Maybe<Array<SavedQueryWarning>>;
};

export type SavedQueryScheduleInput = {
    audience: UserAudienceInput;
    emailDescription?: Maybe<Scalars["String"]>;
    savedQueryId: Scalars["SavedQueryId"];
    schedule: Scalars["String"];
};

export type SavedQueryScheduleResult = {
    __typename?: "SavedQueryScheduleResult";
    audience: UserAudience;
    emailDescription?: Maybe<Scalars["String"]>;
    schedule: Scalars["String"];
};

export type SavedQuerySendEmailInput = {
    audience: UserAudienceInput;
    emailDescription?: Maybe<Scalars["String"]>;
    savedQueryId: Scalars["SavedQueryId"];
};

export type SavedQueryWarning = {
    __typename?: "SavedQueryWarning";
    location: SavedQueryWarningLocation;
    message: Scalars["String"];
};

export enum SavedQueryWarningLocation {
    Condition = "Condition",
    Select = "Select",
    Sort = "Sort",
}

export type ScheduleTriggerConfigurationEntity = {
    __typename?: "ScheduleTriggerConfigurationEntity";
    cron: Scalars["String"];
    entityCondition: AdvancedSearchCondition;
};

export type ScheduleTriggerConfigurationEntityInput = {
    cron: Scalars["String"];
    entityCondition: AdvancedSearchRequestCondition;
};

export type SearchGeoParams = {
    address?: Maybe<Scalars["String"]>;
    bounds?: Maybe<BoundsInput>;
    city?: Maybe<Scalars["String"]>;
    coordinates?: Maybe<CoordinatesInput>;
    county?: Maybe<Scalars["String"]>;
    distance?: Maybe<Scalars["Float"]>;
    matchType?: Maybe<Scalars["String"]>;
    state?: Maybe<Scalars["String"]>;
    zip?: Maybe<Scalars["String"]>;
};

export enum SectionRenderBehaviorType {
    Custom = "Custom",
    CustomWithLayoutItems = "CustomWithLayoutItems",
    LayoutItems = "LayoutItems",
    None = "None",
    Notes = "Notes",
    SingleProperty = "SingleProperty",
}

export type SendHostedFormWithPermalinkRequest = {
    formLinkQueryParams?: Maybe<Scalars["String"]>;
    hostedFormId: Scalars["HostedFormId"];
    message: OutboundMessage;
};

export type SendHostedFormWithTokenRequest = {
    entityId: Scalars["HostedFormEntityId"];
    hostedFormId: Scalars["HostedFormId"];
    message: OutboundMessage;
};

export type SendHostedFormWithTokenResponse = {
    __typename?: "SendHostedFormWithTokenResponse";
    token: HostedFormToken;
};

/** Result of attempting to send message  */
export type SendMessageResult = {
    __typename?: "SendMessageResult";
    accepted: Array<Scalars["String"]>;
    code?: Maybe<Scalars["String"]>;
    rejected: Array<Scalars["String"]>;
    sentMessage?: Maybe<SentMessage>;
    status: Scalars["String"];
};

export type SentMessage = {
    __typename?: "SentMessage";
    activityEntities?: Maybe<Array<Activity>>;
    attachments?: Maybe<Array<Scalars["String"]>>;
    body?: Maybe<Scalars["String"]>;
    bouncedRecipients: Array<Scalars["String"]>;
    clickedLinkEvents?: Maybe<Array<ClickedLink>>;
    clientIdUuid?: Maybe<Scalars["ClientId"]>;
    communityIdUuid?: Maybe<Scalars["CommunityId"]>;
    createdAt: Scalars["DateTime"];
    events?: Maybe<Array<SentMessageEvent>>;
    id: Scalars["SentMessageId"];
    /** Client sent to iff intake (must be explicitly populated */
    intakeClient?: Maybe<Client>;
    /** Community sent to iff intake (must be explicitly populated */
    intakeCommunity?: Maybe<Community>;
    invoice?: Maybe<Invoice>;
    latestEventStatus?: Maybe<Scalars["String"]>;
    messageType?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
    subject?: Maybe<Scalars["String"]>;
    to?: Maybe<Array<Scalars["String"]>>;
};

export type SentMessageEvent = {
    __typename?: "SentMessageEvent";
    eventTimestamp: Scalars["DateTime"];
    eventType: Scalars["String"];
    recipient?: Maybe<Scalars["String"]>;
    remoteAddress?: Maybe<Scalars["String"]>;
};

export enum SignatureCreatePermission {
    Allowed = "Allowed",
    NotAllowed = "NotAllowed",
    TooMany = "TooMany",
}

export enum SignatureFieldType {
    Checkbox = "Checkbox",
    Date = "Date",
    Initial = "Initial",
    Signature = "Signature",
    Text = "Text",
}

export type SignaturePotentialRecipient = {
    __typename?: "SignaturePotentialRecipient";
    email: Scalars["String"];
    name: Scalars["String"];
    source: Scalars["String"];
};

export type SignatureRequest = {
    __typename?: "SignatureRequest";
    ccRecipients: Array<SignatureRequestCcRecipient>;
    completedAt?: Maybe<Scalars["DateTime"]>;
    completedFileId?: Maybe<Scalars["FileId"]>;
    createdAt: Scalars["DateTime"];
    createdBy: Scalars["UserId"];
    emailTemplateId?: Maybe<Scalars["EmailTemplateId"]>;
    entityId: Scalars["FileEntityId"];
    filename: Scalars["String"];
    id: Scalars["SignatureRequestId"];
    signatureRequestFields: Array<SignatureRequestField>;
    signatureRequestRecipients: Array<SignatureRequestRecipient>;
    signatureTemplateFileId?: Maybe<Scalars["FileId"]>;
    sourceFileId: Scalars["FileId"];
    state: SignatureRequestState;
    updatedAt: Scalars["DateTime"];
    updatedBy: Scalars["UserId"];
};

export type SignatureRequestCcRecipient = {
    __typename?: "SignatureRequestCcRecipient";
    email: Scalars["String"];
    name?: Maybe<Scalars["String"]>;
};

export type SignatureRequestCcRecipientInput = {
    email: Scalars["String"];
    name?: Maybe<Scalars["String"]>;
};

export type SignatureRequestField = {
    __typename?: "SignatureRequestField";
    booleanValue?: Maybe<Scalars["Boolean"]>;
    fieldType: SignatureFieldType;
    filledBy?: Maybe<Scalars["SignatureRequestRecipientId"]>;
    font: Scalars["String"];
    fontSize: Scalars["Int"];
    height: Scalars["Int"];
    id: Scalars["SignatureRequestFieldId"];
    isRequired?: Maybe<Scalars["Boolean"]>;
    order: Scalars["Int"];
    page: Scalars["Int"];
    placeholder?: Maybe<Scalars["String"]>;
    recipientIndex: Scalars["Int"];
    signatureRequest: SignatureRequest;
    textValue?: Maybe<Scalars["String"]>;
    width: Scalars["Int"];
    x: Scalars["Int"];
    y: Scalars["Int"];
};

export type SignatureRequestFieldPayload = {
    booleanValue?: Maybe<Scalars["Boolean"]>;
    isRequired?: Maybe<Scalars["Boolean"]>;
    placeholder?: Maybe<Scalars["String"]>;
    signatureTemplateFieldId: Scalars["SignatureTemplateFieldId"];
    textValue?: Maybe<Scalars["String"]>;
};

export type SignatureRequestPayload = {
    ccRecipients?: Maybe<Array<SignatureRequestCcRecipientInput>>;
    emailTemplate?: Maybe<EmailContentInput>;
    fields: Array<SignatureRequestFieldPayload>;
    fileEntityId: Scalars["FileEntityId"];
    fileId: Scalars["FileId"];
    recipients: Array<SignatureRequestRecipientPayload>;
};

export type SignatureRequestPerspective = {
    __typename?: "SignatureRequestPerspective";
    allSignatureRequestFields: Array<SignatureRequestField>;
    filename: Scalars["String"];
    logo: Scalars["String"];
    perspectiveSignatureRequestFields: Array<SignatureRequestField>;
    recipientCompletedAt: Scalars["DateTime"];
    recipientIndex: Scalars["Int"];
    recipientName: Scalars["String"];
    senderName: Scalars["String"];
    signable: Scalars["Boolean"];
};

export type SignatureRequestRecipient = {
    __typename?: "SignatureRequestRecipient";
    completedAt?: Maybe<Scalars["DateTime"]>;
    createdAt: Scalars["DateTime"];
    email: Scalars["String"];
    id: Scalars["SignatureRequestRecipientId"];
    isComplete: Scalars["Boolean"];
    name: Scalars["String"];
    order: Scalars["Int"];
    remindAfter?: Maybe<Scalars["DateTime"]>;
    remindersSent: Scalars["Float"];
    updatedAt: Scalars["DateTime"];
};

export type SignatureRequestRecipientPayload = {
    email: Scalars["String"];
    name: Scalars["String"];
};

export type SignatureRequestRecipientPayloadResponse = {
    __typename?: "SignatureRequestRecipientPayloadResponse";
    email: Scalars["String"];
    name: Scalars["String"];
};

export enum SignatureRequestState {
    Abandoned = "Abandoned",
    Complete = "Complete",
    InProgress = "InProgress",
}

export type SignatureTemplate = {
    __typename?: "SignatureTemplate";
    createdAt: Scalars["DateTime"];
    createdBy: Scalars["UserId"];
    emailTemplate?: Maybe<EmailTemplate>;
    emailTemplateId?: Maybe<Scalars["EmailTemplateId"]>;
    fileId: Scalars["FileId"];
    id: Scalars["SignatureTemplateId"];
    recipientCount: Scalars["Int"];
    signatureTemplateFields: Array<SignatureTemplateField>;
    updatedAt: Scalars["DateTime"];
    updatedBy: Scalars["UserId"];
};

export type SignatureTemplateDeleteResponse = {
    __typename?: "SignatureTemplateDeleteResponse";
    message?: Maybe<Scalars["String"]>;
    success: Scalars["Boolean"];
};

export type SignatureTemplateField = {
    __typename?: "SignatureTemplateField";
    defaultBooleanValue?: Maybe<Scalars["Boolean"]>;
    defaultTextValue?: Maybe<Scalars["String"]>;
    fieldType: SignatureFieldType;
    font: Scalars["String"];
    fontSize: Scalars["Int"];
    height: Scalars["Int"];
    id: Scalars["SignatureTemplateFieldId"];
    isRequired?: Maybe<Scalars["Boolean"]>;
    order: Scalars["Int"];
    page: Scalars["Int"];
    placeholder?: Maybe<Scalars["String"]>;
    recipientIndex: Scalars["Int"];
    signatureTemplate: SignatureTemplate;
    width: Scalars["Int"];
    x: Scalars["Int"];
    y: Scalars["Int"];
};

export type SignatureTemplateFieldInput = {
    defaultBooleanValue?: Maybe<Scalars["Boolean"]>;
    defaultTextValue?: Maybe<Scalars["String"]>;
    fieldType: SignatureFieldType;
    font: Scalars["String"];
    fontSize: Scalars["Int"];
    height: Scalars["Int"];
    id: Scalars["SignatureTemplateFieldId"];
    isRequired?: Maybe<Scalars["Boolean"]>;
    order: Scalars["Int"];
    page: Scalars["Int"];
    placeholder?: Maybe<Scalars["String"]>;
    recipientIndex: Scalars["Int"];
    signatureTemplateRecipientId?: Maybe<Scalars["SignatureTemplateRecipientId"]>;
    width: Scalars["Int"];
    x: Scalars["Int"];
    y: Scalars["Int"];
};

export type SignatureTemplateInput = {
    emailTemplate?: Maybe<EmailContentInput>;
    recipientCount: Scalars["Int"];
    signatureTemplateFields: Array<SignatureTemplateFieldInput>;
};

export type SmtpCredential = {
    __typename?: "SmtpCredential";
    host: Scalars["String"];
    id: Scalars["SmtpCredentialId"];
    port: Scalars["Int"];
    status: SmtpCredentialStatus;
    username: Scalars["String"];
};

export enum SmtpCredentialStatus {
    Active = "Active",
    Disconnected = "Disconnected",
}

export type SmtpSaveResult = {
    __typename?: "SmtpSaveResult";
    message?: Maybe<Scalars["String"]>;
    success: Scalars["Boolean"];
};

/** Special: CommunityComparison defaults (enabled if object defined) */
export type SpecialCommunityComparisonDefaults = {
    __typename?: "SpecialCommunityComparisonDefaults";
    attachCommunityPDFByDefault: Scalars["Boolean"];
    coverPageTemplateId?: Maybe<Scalars["EmailTemplateId"]>;
    liveLinkText: Scalars["String"];
    showCoverPage: Scalars["Boolean"];
    showRecords: Scalars["Boolean"];
    showSummary: Scalars["Boolean"];
    useLiveLinkByDefault: Scalars["Boolean"];
};

export type SpecificUserAudience = {
    __typename?: "SpecificUserAudience";
    groupIds: Array<Scalars["UserGroupId"]>;
    userIds: Array<Scalars["UserId"]>;
};

export type SpecificUserAudienceInput = {
    groupIds: Array<Scalars["UserGroupId"]>;
    userIds: Array<Scalars["UserId"]>;
};

export type StateReportQueryRow = {
    __typename?: "StateReportQueryRow";
    regionid: Scalars["RegionId"];
    regionkey: Scalars["String"];
    regionname: Scalars["String"];
    state: Scalars["String"];
    tenantid: Scalars["TenantId"];
    tenantname: Scalars["String"];
    thecount: Scalars["Int"];
    totalrlmcount: Scalars["Int"];
};

/** Update a field to equal a string value */
export type StringColumnUpdate = {
    key: Scalars["String"];
    value: Scalars["String"];
};

export type SystemConfiguration = {
    __typename?: "SystemConfiguration";
    fileStorage: Scalars["String"];
};

export type Task = {
    __typename?: "Task";
    activityEntities?: Maybe<Array<Activity>>;
    appLink: Scalars["String"];
    completedAt?: Maybe<Scalars["DateTime"]>;
    compositeDueDateTime?: Maybe<Scalars["DateTime"]>;
    createdAt?: Maybe<Scalars["DateTime"]>;
    done: Scalars["Boolean"];
    dueDate?: Maybe<Scalars["String"]>;
    dueDateTime?: Maybe<Scalars["DateTime"]>;
    id: Scalars["TaskId"];
    notes?: Maybe<Scalars["String"]>;
    ownerId?: Maybe<Scalars["UserId"]>;
    parentClient?: Maybe<Client>;
    parentCommunity?: Maybe<Community>;
    parentId: Scalars["TaskParentId"];
    parentReferenceBusiness?: Maybe<ReferenceBusiness>;
    parentReferenceContact?: Maybe<ReferenceContact>;
    status: Scalars["String"];
    taskType?: Maybe<TaskType>;
    taskTypeId?: Maybe<Scalars["TaskTypeId"]>;
    text?: Maybe<Scalars["String"]>;
    type?: Maybe<Scalars["String"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TaskType = {
    __typename?: "TaskType";
    id: Scalars["TaskTypeId"];
    isArchived: Scalars["Boolean"];
    name: Scalars["String"];
};

export type TemplateList = {
    __typename?: "TemplateList";
    templates: Array<TemplateResponse>;
};

export type TemplateResponse = {
    __typename?: "TemplateResponse";
    author: User;
    isDefaultSignature: Scalars["Boolean"];
    isEditable: Scalars["Boolean"];
    template: EmailTemplate;
};

export type TemplateUpdate = {
    attachments?: Maybe<Array<Scalars["FileId"]>>;
    body: Scalars["String"];
    description: Scalars["String"];
    entityRegionVisibility?: Maybe<EntityRegionVisibilityInput>;
    id?: Maybe<Scalars["EmailTemplateId"]>;
    isDefaultSignature: Scalars["Boolean"];
    isShared: Scalars["Boolean"];
    isSignature?: Maybe<Scalars["Boolean"]>;
    migrationVersion: Scalars["Int"];
    subject?: Maybe<Scalars["String"]>;
    type?: Maybe<Scalars["String"]>;
};

export type TemplateValidationResponse = {
    __typename?: "TemplateValidationResponse";
    isValid: Scalars["Boolean"];
    message?: Maybe<Scalars["String"]>;
    missingTokens: Array<TemplateValidationTokenWarning>;
};

export type TemplateValidationTokenWarning = {
    __typename?: "TemplateValidationTokenWarning";
    description: Scalars["String"];
    humanFriendlyName: Scalars["String"];
    tokenKey: Scalars["String"];
};

/** Tenant */
export type Tenant = {
    __typename?: "Tenant";
    activatedAt?: Maybe<Scalars["DateTime"]>;
    /** Active users who are visible and not "system" users */
    activeHumanUsers: Scalars["Int"];
    activeUsers: Scalars["Int"];
    billing: TenantBillingPayload;
    credentials: Array<TenantCredential>;
    customAppDomain?: Maybe<Scalars["String"]>;
    customDomains: Array<Scalars["String"]>;
    customProductName?: Maybe<Scalars["String"]>;
    dateAdded: Scalars["String"];
    deactivatedAt?: Maybe<Scalars["DateTime"]>;
    demoEndedAt?: Maybe<Scalars["DateTime"]>;
    demoStartedAt?: Maybe<Scalars["DateTime"]>;
    emailProvider: ExternalEmailProvider;
    features: Array<TenantFeature>;
    id: Scalars["TenantId"];
    internalAttributes?: Maybe<Dictionary>;
    internalEmailSender?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    onboardingStartedAt?: Maybe<Scalars["DateTime"]>;
    premiumActivatedAt?: Maybe<Scalars["DateTime"]>;
    premiumDeactivatedAt?: Maybe<Scalars["DateTime"]>;
    productName: Scalars["String"];
    /** List of regions */
    regions: Array<Region>;
    sku: Scalars["String"];
    supportEmailAddress?: Maybe<Scalars["String"]>;
    users: Array<User>;
    whiteLabelContentMode: WhiteLabelContentMode;
};

export type TenantBillingPayload = {
    __typename?: "TenantBillingPayload";
    billingContacts?: Maybe<Scalars["String"]>;
    effectiveMonthlyRateCents?: Maybe<Scalars["Int"]>;
    monthlyRateCents?: Maybe<Scalars["Int"]>;
    perLocationRateCents?: Maybe<Scalars["Int"]>;
    perUserRateCents?: Maybe<Scalars["Int"]>;
    ratePlan?: Maybe<Scalars["String"]>;
    ratePlanEstablished?: Maybe<Scalars["DateTime"]>;
};

export type TenantBillingPayloadInput = {
    billingContacts?: Maybe<Scalars["String"]>;
    effectiveMonthlyRateCents?: Maybe<Scalars["Int"]>;
    monthlyRateCents?: Maybe<Scalars["Int"]>;
    perLocationRateCents?: Maybe<Scalars["Int"]>;
    perUserRateCents?: Maybe<Scalars["Int"]>;
    ratePlan?: Maybe<Scalars["String"]>;
    ratePlanEstablished?: Maybe<Scalars["DateTime"]>;
};

/** Tenant Credential */
export type TenantCredential = {
    __typename?: "TenantCredential";
    dateAdded: Scalars["DateTime"];
    id: Scalars["TenantCredentialId"];
    name: Scalars["String"];
    quickBooksDetails?: Maybe<QuickBooksDetails>;
    status: TenantCredentialStatus;
    tenant: Tenant;
    type: TenantCredentialType;
    updatedAt: Scalars["DateTime"];
};

export enum TenantCredentialStatus {
    Active = "Active",
    Disconnected = "Disconnected",
}

export enum TenantCredentialType {
    QuickBooks = "QuickBooks",
}

/** Feature value for tenant */
export type TenantFeature = {
    __typename?: "TenantFeature";
    enabled: Scalars["Boolean"];
    enabledUpstream: Scalars["Boolean"];
    name: Scalars["ID"];
};

/** Tenant history entry */
export type TenantHistory = {
    __typename?: "TenantHistory";
    author: User;
    dateAdded: Scalars["DateTime"];
    id: Scalars["UUID"];
    message: Scalars["String"];
    tenant: Tenant;
};

export type TenantMessagingConfiguration = {
    __typename?: "TenantMessagingConfiguration";
    customDomains: Array<Scalars["String"]>;
    trackingLevel: MessageTrackingLevel;
};

export type TestResponse = {
    __typename?: "TestResponse";
    val: Scalars["Int"];
};

/** Live community comparison token */
export type Token = {
    __typename?: "Token";
    clientId: Scalars["ClientId"];
    id: Scalars["TokenId"];
    includeContacts: Scalars["Boolean"];
    includeFinancial: Scalars["Boolean"];
    includeLicensing: Scalars["Boolean"];
    sectionKeys?: Maybe<Array<LayoutSectionKey>>;
    tenantId: Scalars["TenantId"];
    token: Scalars["String"];
    url: Scalars["String"];
    userId: Scalars["UserId"];
};

export type TransferClientRequest = {
    clientId: Scalars["ClientId"];
    companyName: Scalars["String"];
    contactEmail: Scalars["String"];
    contactName: Scalars["String"];
};

export type TriggerFieldMatcherEntity = {
    __typename?: "TriggerFieldMatcherEntity";
    current?: Maybe<WorkflowTriggerFieldValueEntityValueType>;
    fieldName: Scalars["String"];
};

export type TriggerFieldMatcherEntityInput = {
    current?: Maybe<WorkflowTriggerFieldValueEntityValueTypeInput>;
    fieldName: Scalars["String"];
};

export type UpdateNoteInput = {
    dateAdded?: Maybe<Scalars["DateTime"]>;
    dateAddedOverride?: Maybe<Scalars["DateTime"]>;
    isPublished?: Maybe<Scalars["Boolean"]>;
    text: Scalars["String"];
    updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type UpdateTaskPayload = {
    completedAt?: Maybe<Scalars["DateTime"]>;
    dueDate?: Maybe<Scalars["String"]>;
    dueDateTime?: Maybe<Scalars["DateTime"]>;
    notes?: Maybe<Scalars["String"]>;
    ownerId?: Maybe<Scalars["UserId"]>;
    status?: Maybe<Scalars["String"]>;
    taskTypeId?: Maybe<Scalars["TaskTypeId"]>;
    text?: Maybe<Scalars["String"]>;
};

export type UpdateTaskTypePayload = {
    isArchived: Scalars["Boolean"];
    name: Scalars["String"];
    taskTypeId: Scalars["TaskTypeId"];
};

export type UpdateTokenRequest = {
    clientId: Scalars["ClientId"];
    includeContacts: Scalars["Boolean"];
    includeFinancial: Scalars["Boolean"];
    includeLicensing: Scalars["Boolean"];
    sectionKeys?: Maybe<Array<LayoutSectionKey>>;
};

/** User */
export type User = {
    __typename?: "User";
    apiToken: Scalars["String"];
    calendarToken: Scalars["String"];
    disabled: Scalars["Boolean"];
    displayEmail?: Maybe<Scalars["String"]>;
    email: Scalars["String"];
    /** [internal] User's first name for bulk emails */
    firstName?: Maybe<Scalars["String"]>;
    hidden: Scalars["Boolean"];
    id: Scalars["UserId"];
    /** User's preferred name */
    name?: Maybe<Scalars["String"]>;
    permissions: Array<Permission>;
    preferredName: Scalars["String"];
    tenant: Tenant;
    tenantId: Scalars["TenantId"];
    token?: Maybe<Scalars["String"]>;
    type: UserType;
};

export type UserAudience = {
    __typename?: "UserAudience";
    specific?: Maybe<SpecificUserAudience>;
    type: UserAudienceType;
};

export type UserAudienceInput = {
    specific?: Maybe<SpecificUserAudienceInput>;
    type: UserAudienceType;
};

export enum UserAudienceType {
    Everyone = "Everyone",
    Specific = "Specific",
}

/** Lightweight placeholder for user, safe for sending to any logged in user. */
export type UserDescriptor = {
    __typename?: "UserDescriptor";
    disabled: Scalars["Boolean"];
    hidden: Scalars["Boolean"];
    id: Scalars["UserId"];
    preferredName: Scalars["String"];
};

export type UserGroupResult = {
    __typename?: "UserGroupResult";
    id: Scalars["UserGroupId"];
    name: Scalars["String"];
};

export type UserMessagingConfiguration = {
    __typename?: "UserMessagingConfiguration";
    directSmtp: Scalars["Boolean"];
};

/** A single user's result in a single time segment in a client placement report */
export type UserReportSegment = {
    __typename?: "UserReportSegment";
    /** Moves for user */
    moves: Scalars["Int"];
    /** Revenue for user */
    revenue: Scalars["Float"];
    /** Assigned user */
    user?: Maybe<ConcreteUser>;
};

export type UserTenantRegionOption = {
    __typename?: "UserTenantRegionOption";
    regionId: Scalars["RegionId"];
    regionKey: Scalars["String"];
    regionName: Scalars["String"];
    tenantId: Scalars["TenantId"];
    tenantName: Scalars["String"];
    userId: Scalars["UserId"];
};

export type UserTenantRegionOptions = {
    __typename?: "UserTenantRegionOptions";
    options: Array<UserTenantRegionOption>;
};

export enum UserType {
    System = "System",
    User = "User",
}

export enum WhiteLabelContentMode {
    Full = "Full",
    Restricted = "Restricted",
}

export type Workflow = {
    __typename?: "Workflow";
    actions: Array<WorkflowActionEntityType>;
    actorMode: WorkflowActorMode;
    attachTo: WorkflowEntityAttachment;
    createdBy: Scalars["UserId"];
    dateAdded: Scalars["DateTime"];
    defaultActorId: Scalars["UserId"];
    delay: Scalars["Int"];
    delayRecipe?: Maybe<Scalars["String"]>;
    description: Scalars["String"];
    id: Scalars["WorkflowId"];
    name: Scalars["String"];
    settings: Array<WorkflowPayloadSettings>;
    status: WorkflowStatus;
    tenantId: Scalars["TenantId"];
    trigger: WorkflowTriggerEntity;
    updatedAt: Scalars["DateTime"];
    updatedBy: Scalars["UserId"];
};

export type WorkflowActionEmailEntity = {
    __typename?: "WorkflowActionEmailEntity";
    attachments?: Maybe<Scalars["String"]>;
    bccMyself?: Maybe<Scalars["String"]>;
    body: Scalars["String"];
    subject: Scalars["String"];
    to: Scalars["String"];
};

export type WorkflowActionEmailEntityInput = {
    attachments?: Maybe<Scalars["String"]>;
    bccMyself?: Maybe<Scalars["String"]>;
    body: Scalars["String"];
    subject: Scalars["String"];
    to: Scalars["String"];
};

export type WorkflowActionEntityInput = {
    email?: Maybe<WorkflowActionEmailEntityInput>;
    task?: Maybe<WorkflowActionTaskEntityInput>;
};

export type WorkflowActionEntityType =
    | WorkflowActionEmailEntity
    | WorkflowActionTaskEntity;

export type WorkflowActionTaskEntity = {
    __typename?: "WorkflowActionTaskEntity";
    assignedTo?: Maybe<Scalars["String"]>;
    dueDate: Scalars["String"];
    notification?: Maybe<WorkflowActionTaskNotification>;
    notifyOwner?: Maybe<Scalars["Boolean"]>;
    taskTypeId?: Maybe<Scalars["String"]>;
    text: Scalars["String"];
};

export type WorkflowActionTaskEntityInput = {
    assignedTo?: Maybe<Scalars["String"]>;
    dueDate: Scalars["String"];
    notification?: Maybe<WorkflowActionTaskNotificationInput>;
    notifyOwner?: Maybe<Scalars["Boolean"]>;
    taskTypeId?: Maybe<Scalars["String"]>;
    text: Scalars["String"];
};

export type WorkflowActionTaskNotification = {
    __typename?: "WorkflowActionTaskNotification";
    subject?: Maybe<Scalars["String"]>;
};

export type WorkflowActionTaskNotificationInput = {
    subject?: Maybe<Scalars["String"]>;
};

export enum WorkflowActorMode {
    AssignedUser = "AssignedUser",
    DefaultActor = "DefaultActor",
}

export enum WorkflowEntityAttachment {
    Client = "client",
}

export enum WorkflowEventType {
    EmailOpened = "EmailOpened",
    EntityUpdated = "EntityUpdated",
    NoteAdded = "NoteAdded",
    NoteDeleted = "NoteDeleted",
    NoteUpdated = "NoteUpdated",
    Schedule = "Schedule",
}

export type WorkflowExecution = {
    __typename?: "WorkflowExecution";
    appLink: Scalars["String"];
    cancelledAt?: Maybe<Scalars["DateTime"]>;
    client?: Maybe<Client>;
    dateAdded: Scalars["DateTime"];
    entityId: Scalars["WorkflowTargetId"];
    finishedAt?: Maybe<Scalars["DateTime"]>;
    id: Scalars["WorkflowExecutionId"];
    isCancelRequested: Scalars["Boolean"];
    result?: Maybe<WorkflowExecutionResult>;
    scheduledAt?: Maybe<Scalars["DateTime"]>;
    updatedAt: Scalars["DateTime"];
    workflow: Workflow;
};

/** The result of a workflow execution */
export enum WorkflowExecutionResult {
    Cancelled = "Cancelled",
    Error = "Error",
    Pending = "Pending",
    Success = "Success",
    Warning = "Warning",
}

export type WorkflowPayload = {
    actionEntities: Array<WorkflowActionEntityInput>;
    actorMode: WorkflowActorMode;
    attachTo: WorkflowEntityAttachment;
    defaultActorId: Scalars["UserId"];
    delay: Scalars["Int"];
    delayRecipe?: Maybe<Scalars["String"]>;
    description: Scalars["String"];
    name: Scalars["String"];
    settings: Array<WorkflowPayloadSettingsInput>;
    status: WorkflowStatus;
    triggerInput: WorkflowTriggerInput;
};

export type WorkflowPayloadSettings = {
    __typename?: "WorkflowPayloadSettings";
    defaultValue: Scalars["String"];
    description: Scalars["String"];
    key: Scalars["String"];
    label: Scalars["String"];
    scope: Scalars["String"];
    type: WorkflowSettingType;
};

export type WorkflowPayloadSettingsInput = {
    defaultValue: Scalars["String"];
    description: Scalars["String"];
    key: Scalars["String"];
    label: Scalars["String"];
    scope: Scalars["String"];
    type: WorkflowSettingType;
};

export enum WorkflowSettingType {
    ClientEmails = "ClientEmails",
    EmailTemplateId = "EmailTemplateId",
    Number = "Number",
    Boolean = "boolean",
    Html = "html",
    String = "string",
    User = "user",
}

export enum WorkflowStatus {
    Draft = "Draft",
    Published = "Published",
}

export type WorkflowTriggerEntity = {
    __typename?: "WorkflowTriggerEntity";
    emailOpened?: Maybe<EmailOpenedTriggerConfigurationEntity>;
    entityUpdated?: Maybe<EntityUpdatedTriggerConfigurationEntity>;
    eventType: WorkflowEventType;
    noteAdded?: Maybe<NoteTriggerConfigurationEntity>;
    schedule?: Maybe<ScheduleTriggerConfigurationEntity>;
};

export type WorkflowTriggerFieldValueEntityValueType = {
    __typename?: "WorkflowTriggerFieldValueEntityValueType";
    specialValue?: Maybe<Scalars["String"]>;
    values?: Maybe<Array<Scalars["String"]>>;
};

export type WorkflowTriggerFieldValueEntityValueTypeInput = {
    specialValue?: Maybe<Scalars["String"]>;
    values?: Maybe<Array<Scalars["String"]>>;
};

export type WorkflowTriggerInput = {
    emailOpened?: Maybe<EmailOpenedTriggerConfigurationInput>;
    entityUpdated?: Maybe<EntityUpdatedTriggerConfigurationInput>;
    eventType: WorkflowEventType;
    noteAdded?: Maybe<NoteTriggerConfigurationInput>;
    noteDeleted?: Maybe<NoteTriggerConfigurationInput>;
    noteUpdated?: Maybe<NoteTriggerConfigurationInput>;
    schedule?: Maybe<ScheduleTriggerConfigurationEntityInput>;
};

export type GetActivityFeedQueryVariables = Exact<{
    entityId: Scalars["ActivityParentEntityId"];
}>;

export type GetActivityFeedQuery = { __typename?: "Query" } & {
    getActivityFeed: { __typename?: "ActivityFeed" } & {
        activities: Array<
            { __typename?: "ActivityFeedEvent" } & Pick<
                ActivityFeedEvent,
                "sourceType"
            > & {
                    activity: { __typename?: "Activity" } & Pick<
                        Activity,
                        "id" | "activityType" | "createdAt"
                    > & {
                            createdByEntity: { __typename?: "User" } & Pick<
                                User,
                                "preferredName"
                            >;
                            parentReferenceContact?: Maybe<
                                { __typename?: "ReferenceContact" } & Pick<
                                    ReferenceContact,
                                    "id" | "appLink"
                                > & {
                                        contactEntity?: Maybe<
                                            { __typename?: "ContactEntity" } & Pick<
                                                ContactEntity,
                                                "name"
                                            >
                                        >;
                                    }
                            >;
                            parentReferenceBusiness?: Maybe<
                                { __typename?: "ReferenceBusiness" } & Pick<
                                    ReferenceBusiness,
                                    "id" | "appLink"
                                > & {
                                        businessContactEntity?: Maybe<
                                            { __typename?: "ContactEntity" } & Pick<
                                                ContactEntity,
                                                "name"
                                            >
                                        >;
                                    }
                            >;
                            parentCommunity?: Maybe<
                                { __typename?: "Community" } & Pick<
                                    Community,
                                    "id" | "name" | "appLink"
                                >
                            >;
                            metadata?: Maybe<
                                { __typename?: "ActivityMetadata" } & {
                                    events: Array<
                                        { __typename?: "ActivityMetadataEvent" } & Pick<
                                            ActivityMetadataEvent,
                                            "type"
                                        > & {
                                                entityFieldChange?: Maybe<
                                                    {
                                                        __typename?: "EntityFieldChange";
                                                    } & Pick<
                                                        EntityFieldChange,
                                                        | "field"
                                                        | "oldStringValue"
                                                        | "newStringValue"
                                                    >
                                                >;
                                                entityReassign?: Maybe<
                                                    {
                                                        __typename?: "EntityReassign";
                                                    } & Pick<
                                                        EntityReassign,
                                                        "from" | "to"
                                                    >
                                                >;
                                            }
                                    >;
                                }
                            >;
                        };
                    note?: Maybe<
                        { __typename?: "Note" } & Pick<
                            Note,
                            "text" | "effectiveCreatedAt" | "publishedAt"
                        >
                    >;
                    task?: Maybe<
                        { __typename?: "Task" } & Pick<
                            Task,
                            | "status"
                            | "dueDate"
                            | "dueDateTime"
                            | "completedAt"
                            | "type"
                            | "text"
                        >
                    >;
                    file?: Maybe<
                        { __typename?: "File" } & Pick<File, "originalFilename">
                    >;
                    message?: Maybe<
                        { __typename?: "SentMessage" } & Pick<SentMessage, "subject"> & {
                                intakeCommunity?: Maybe<
                                    { __typename?: "Community" } & Pick<Community, "name">
                                >;
                            }
                    >;
                }
        >;
    };
};

export type CreateSavedQueryMutationVariables = Exact<{
    savedQuery: SavedQueryInput;
}>;

export type CreateSavedQueryMutation = { __typename?: "Mutation" } & {
    createSavedQuery: { __typename?: "SavedQueryResult" } & Pick<SavedQueryResult, "id">;
};

export type UpdateSavedQueryMutationVariables = Exact<{
    savedQuery: SavedQueryInput;
}>;

export type UpdateSavedQueryMutation = { __typename?: "Mutation" } & {
    updateSavedQuery: { __typename?: "SavedQueryResult" } & Pick<SavedQueryResult, "id">;
};

export type DeleteSavedQueryMutationVariables = Exact<{
    savedQueryId: Scalars["SavedQueryId"];
}>;

export type DeleteSavedQueryMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteSavedQuery"
>;

export type UpdateQueryFavoriteStatusMutationVariables = Exact<{
    savedQueryId: Scalars["SavedQueryId"];
    isFavorite: Scalars["Boolean"];
}>;

export type UpdateQueryFavoriteStatusMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "updateQueryFavoriteStatus"
>;

export type UpsertSavedQueryScheduleMutationVariables = Exact<{
    schedule: SavedQueryScheduleInput;
}>;

export type UpsertSavedQueryScheduleMutation = { __typename?: "Mutation" } & {
    upsertSavedQuerySchedule: { __typename?: "SavedQueryScheduleResult" } & Pick<
        SavedQueryScheduleResult,
        "schedule"
    > & { audience: { __typename?: "UserAudience" } & AudienceFragmentFragment };
};

export type DeleteSavedQueryScheduleMutationVariables = Exact<{
    savedQueryId: Scalars["SavedQueryId"];
}>;

export type DeleteSavedQueryScheduleMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteSavedQuerySchedule"
>;

export type SendSavedQueryEmailMutationVariables = Exact<{
    payload: SavedQuerySendEmailInput;
}>;

export type SendSavedQueryEmailMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "sendSavedQueryEmail"
>;

export type GetSavedQueriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetSavedQueriesQuery = { __typename?: "Query" } & {
    getSavedQueries: { __typename?: "LoadSavedQueryResult" } & Pick<
        LoadSavedQueryResult,
        "canSaveAdditional"
    > & {
            savedQueries: Array<
                { __typename?: "SavedQueryResult" } & Pick<
                    SavedQueryResult,
                    | "id"
                    | "name"
                    | "isMyQuery"
                    | "isShared"
                    | "isFavorite"
                    | "editable"
                    | "entityType"
                    | "createdBy"
                > & {
                        regionVisibility: {
                            __typename?: "EntityRegionVisibility";
                        } & Pick<EntityRegionVisibility, "editable" | "regions">;
                        warnings?: Maybe<
                            Array<
                                { __typename?: "SavedQueryWarning" } & Pick<
                                    SavedQueryWarning,
                                    "location" | "message"
                                >
                            >
                        >;
                        schedule?: Maybe<
                            { __typename?: "SavedQueryScheduleResult" } & Pick<
                                SavedQueryScheduleResult,
                                "schedule" | "emailDescription"
                            > & {
                                    audience: {
                                        __typename?: "UserAudience";
                                    } & AudienceFragmentFragment;
                                }
                        >;
                        sort: { __typename?: "AdvancedSearchSort" } & Pick<
                            AdvancedSearchSort,
                            "ascending" | "column" | "questionId"
                        >;
                        select: Array<
                            {
                                __typename?: "AdvancedSearchSelect";
                            } & SelectFragmentFragment
                        >;
                        summaries?: Maybe<
                            Array<
                                {
                                    __typename?: "AdvancedSearchRequestSummary";
                                } & SummaryFragmentFragment
                            >
                        >;
                        condition: { __typename?: "AdvancedSearchCondition" } & {
                            children?: Maybe<
                                Array<
                                    { __typename?: "AdvancedSearchCondition" } & {
                                        children?: Maybe<
                                            Array<
                                                {
                                                    __typename?: "AdvancedSearchCondition";
                                                } & {
                                                    children?: Maybe<
                                                        Array<
                                                            {
                                                                __typename?: "AdvancedSearchCondition";
                                                            } & {
                                                                children?: Maybe<
                                                                    Array<
                                                                        {
                                                                            __typename?: "AdvancedSearchCondition";
                                                                        } & {
                                                                            children?: Maybe<
                                                                                Array<
                                                                                    {
                                                                                        __typename?: "AdvancedSearchCondition";
                                                                                    } & {
                                                                                        children?: Maybe<
                                                                                            Array<
                                                                                                {
                                                                                                    __typename?: "AdvancedSearchCondition";
                                                                                                } & {
                                                                                                    children?: Maybe<
                                                                                                        Array<
                                                                                                            {
                                                                                                                __typename?: "AdvancedSearchCondition";
                                                                                                            } & {
                                                                                                                children?: Maybe<
                                                                                                                    Array<
                                                                                                                        {
                                                                                                                            __typename?: "AdvancedSearchCondition";
                                                                                                                        } & {
                                                                                                                            children?: Maybe<
                                                                                                                                Array<
                                                                                                                                    {
                                                                                                                                        __typename?: "AdvancedSearchCondition";
                                                                                                                                    } & {
                                                                                                                                        children?: Maybe<
                                                                                                                                            Array<
                                                                                                                                                {
                                                                                                                                                    __typename?: "AdvancedSearchCondition";
                                                                                                                                                } & ConditionFragmentFragment
                                                                                                                                            >
                                                                                                                                        >;
                                                                                                                                    } & ConditionFragmentFragment
                                                                                                                                >
                                                                                                                            >;
                                                                                                                        } & ConditionFragmentFragment
                                                                                                                    >
                                                                                                                >;
                                                                                                            } & ConditionFragmentFragment
                                                                                                        >
                                                                                                    >;
                                                                                                } & ConditionFragmentFragment
                                                                                            >
                                                                                        >;
                                                                                    } & ConditionFragmentFragment
                                                                                >
                                                                            >;
                                                                        } & ConditionFragmentFragment
                                                                    >
                                                                >;
                                                            } & ConditionFragmentFragment
                                                        >
                                                    >;
                                                } & ConditionFragmentFragment
                                            >
                                        >;
                                    } & ConditionFragmentFragment
                                >
                            >;
                        } & ConditionFragmentFragment;
                    }
            >;
        };
};

export type ConditionFragmentFragment = { __typename?: "AdvancedSearchCondition" } & Pick<
    AdvancedSearchCondition,
    | "nodeType"
    | "fieldName"
    | "operator"
    | "textValue"
    | "dateValue"
    | "calendarDateValue"
    | "numberValue"
    | "questionId"
    | "promptId"
> & {
        relativeDate?: Maybe<
            { __typename?: "AdvancedSearchRelativeDate" } & Pick<
                AdvancedSearchRelativeDate,
                "value" | "interval"
            >
        >;
    };

export type SelectFragmentFragment = { __typename?: "AdvancedSearchSelect" } & Pick<
    AdvancedSearchSelect,
    "fieldName" | "questionId"
>;

export type AudienceFragmentFragment = { __typename?: "UserAudience" } & Pick<
    UserAudience,
    "type"
> & {
        specific?: Maybe<
            { __typename?: "SpecificUserAudience" } & Pick<
                SpecificUserAudience,
                "userIds" | "groupIds"
            >
        >;
    };

export type SummaryFragmentFragment = {
    __typename?: "AdvancedSearchRequestSummary";
} & Pick<AdvancedSearchRequestSummary, "groupBy"> & {
        aggregates: Array<
            { __typename?: "AdvancedSearchAggregate" } & Pick<
                AdvancedSearchAggregate,
                "operator" | "column"
            >
        >;
        sort?: Maybe<
            { __typename?: "AdvancedSearchSummarySort" } & Pick<
                AdvancedSearchSummarySort,
                "aggregateIndex" | "ascending"
            >
        >;
    };

export type GetEntitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetEntitiesQuery = { __typename?: "Query" } & {
    getEntities: Array<
        { __typename?: "BridgeEntityResult" } & BridgeEntityFragmentFragment
    >;
};

export type GetEntitiesForTenantQueryVariables = Exact<{
    tenantId: Scalars["TenantId"];
}>;

export type GetEntitiesForTenantQuery = { __typename?: "Query" } & {
    getEntitiesForTenant: Array<
        { __typename?: "BridgeEntityResult" } & BridgeEntityFragmentFragment
    >;
};

export type UpsertEntityQuestionMutationVariables = Exact<{
    entityType: Scalars["String"];
    question: BridgeQuestionInput;
}>;

export type UpsertEntityQuestionMutation = { __typename?: "Mutation" } & {
    upsertEntityQuestion: {
        __typename?: "BridgeQuestion";
    } & BridgeQuestionFragmentFragment;
};

export type SetEntityAnswerMutationVariables = Exact<{
    entityId: Scalars["AnswerEntityId"];
    answer: AnswerInput;
}>;

export type SetEntityAnswerMutation = { __typename?: "Mutation" } & {
    setEntityAnswer: { __typename?: "Answer" } & Pick<
        Answer,
        | "questionId"
        | "booleanAnswer"
        | "numberAnswer"
        | "numberAnswerHigh"
        | "textAnswer"
        | "dateAnswer"
        | "calendarDateAnswer"
        | "selections"
    > & {
            selectionAnnotations?: Maybe<
                Array<
                    { __typename?: "AnswerSelectionAnnotation" } & Pick<
                        AnswerSelectionAnnotation,
                        "selectionId" | "text"
                    >
                >
            >;
        };
};

export type DeleteQuestionMutationVariables = Exact<{
    questionId: Scalars["QuestionId"];
}>;

export type DeleteQuestionMutation = { __typename?: "Mutation" } & {
    deleteQuestion: { __typename?: "DeleteQuestionResult" } & Pick<
        DeleteQuestionResult,
        "questionId"
    >;
};

export type GetQuestionPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetQuestionPermissionsQuery = { __typename?: "Query" } & {
    getQuestionPermissions: { __typename?: "BridgeQuestionPermissions" } & Pick<
        BridgeQuestionPermissions,
        "canEditAllRegions"
    > & {
            editableRegions: Array<
                { __typename?: "BridgeQuestionRegion" } & Pick<
                    BridgeQuestionRegion,
                    "id" | "key" | "name"
                >
            >;
        };
};

export type BridgeEntityFragmentFragment = { __typename?: "BridgeEntityResult" } & Pick<
    BridgeEntityResult,
    | "name"
    | "title"
    | "preferredFilterField"
    | "allowAnswers"
    | "layoutParents"
    | "identifierFields"
    | "preferredGroupBy"
> & {
        priorityFields: Array<
            { __typename?: "AdvancedSearchSelect" } & Pick<
                AdvancedSearchSelect,
                "fieldName" | "title"
            >
        >;
        fields: Array<
            { __typename?: "BridgeFieldResult" } & Pick<
                BridgeFieldResult,
                | "name"
                | "title"
                | "questionType"
                | "formatter"
                | "category"
                | "dependsOn"
                | "customListKey"
                | "aliases"
            > & {
                    naryValues?: Maybe<
                        Array<
                            { __typename?: "BridgeNaryValueResult" } & Pick<
                                BridgeNaryValueResult,
                                "value" | "label"
                            >
                        >
                    >;
                    reportBehavior: { __typename?: "BridgeReportBehaviorResult" } & Pick<
                        BridgeReportBehaviorResult,
                        | "sortable"
                        | "filterable"
                        | "selectable"
                        | "newReportSelectOrder"
                        | "summaryGroupable"
                        | "canAggregate"
                    >;
                }
        >;
        relations: Array<
            { __typename?: "BridgeRelationResult" } & Pick<
                BridgeRelationResult,
                "name" | "title" | "otherEntityName" | "kind" | "selectable"
            >
        >;
        questions: Array<
            { __typename?: "BridgeQuestion" } & BridgeQuestionFragmentFragment
        >;
        defaultSelect?: Maybe<
            Array<{ __typename?: "AdvancedSearchSelect" } & SelectFragmentFragment>
        >;
        preferredDisplay?: Maybe<
            { __typename?: "BridgePreferredDisplay" } & Pick<
                BridgePreferredDisplay,
                "fieldName"
            > & {
                    relation?: Maybe<
                        { __typename?: "BridgePreferredRelationDisplay" } & Pick<
                            BridgePreferredRelationDisplay,
                            "name"
                        >
                    >;
                }
        >;
    };

export type BridgeQuestionFragmentFragment = { __typename?: "BridgeQuestion" } & Pick<
    BridgeQuestion,
    | "id"
    | "title"
    | "interaction"
    | "annotation"
    | "questionType"
    | "category"
    | "key"
    | "order"
    | "regionMask"
    | "rulesString"
    | "searchBehavior"
    | "source"
    | "regions"
    | "editable"
> & {
        prompts?: Maybe<
            Array<
                { __typename?: "BridgeQuestionPrompt" } & Pick<
                    BridgeQuestionPrompt,
                    "id" | "title" | "key" | "order" | "annotation"
                >
            >
        >;
    };

export type GetClientQueryVariables = Exact<{
    id: Scalars["ClientId"];
}>;

export type GetClientQuery = { __typename?: "Query" } & {
    getClient: { __typename?: "Client" } & Pick<
        Client,
        | "id"
        | "totalInvoiceAmountCents"
        | "bestContactName"
        | "bestContactPhone"
        | "bestContactEmail"
    > & {
            invoices?: Maybe<
                Array<
                    { __typename?: "Invoice" } & Pick<
                        Invoice,
                        | "id"
                        | "amountCents"
                        | "paidDate"
                        | "sentDate"
                        | "dueDate"
                        | "serial"
                        | "status"
                        | "clientId"
                        | "clientName"
                        | "communityId"
                        | "fileId"
                        | "notes"
                        | "externalReference"
                        | "remainingBalanceCents"
                        | "totalPaidCents"
                        | "hasOutstandingBalance"
                        | "isExternallyManaged"
                    > & {
                            lineItems?: Maybe<
                                Array<
                                    { __typename?: "InvoiceLineItem" } & Pick<
                                        InvoiceLineItem,
                                        | "id"
                                        | "description"
                                        | "quantity"
                                        | "unitPriceInCents"
                                    >
                                >
                            >;
                            paymentSchedule?: Maybe<
                                { __typename?: "InvoicePaymentSchedule" } & {
                                    items: Array<
                                        {
                                            __typename?: "InvoicePaymentScheduleItem";
                                        } & Pick<
                                            InvoicePaymentScheduleItem,
                                            | "id"
                                            | "amountInCents"
                                            | "dueDate"
                                            | "paidDate"
                                            | "status"
                                        >
                                    >;
                                }
                            >;
                            community?: Maybe<
                                { __typename?: "Community" } & Pick<
                                    Community,
                                    "name" | "id"
                                >
                            >;
                        }
                >
            >;
            disclosures?: Maybe<
                Array<{ __typename?: "Disclosure" } & DisclosureFragmentFragment>
            >;
        };
};

export type ClientCommunitiesFragment = { __typename?: "Client" } & Pick<Client, "id"> & {
        baseLocation?: Maybe<
            { __typename?: "Coordinates" } & Pick<Coordinates, "lat" | "lng">
        >;
        clientCommunities: Array<
            { __typename?: "ClientCommunity" } & Pick<
                ClientCommunity,
                "order" | "relationship" | "publicNotes" | "privateNotes" | "referred"
            > & { community: { __typename?: "Community" } & CommunityCardBasicsFragment }
        >;
    };

export type GetClientCommunitiesQueryVariables = Exact<{
    id: Scalars["ClientId"];
    questionIds: Array<Scalars["QuestionId"]> | Scalars["QuestionId"];
}>;

export type GetClientCommunitiesQuery = { __typename?: "Query" } & {
    getClient: { __typename?: "Client" } & ClientCommunitiesFragment;
};

export type GetClientMatchesQueryVariables = Exact<{
    id: Scalars["ClientId"];
}>;

export type GetClientMatchesQuery = { __typename?: "Query" } & {
    getClient: { __typename?: "Client" } & Pick<Client, "id"> & {
            potentialDuplicates: Array<
                { __typename?: "ClientMatchResponse" } & Pick<
                    ClientMatchResponse,
                    "id" | "name" | "bestContactName" | "dateAdded" | "viewable"
                > & {
                        assignedUser?: Maybe<
                            { __typename?: "User" } & Pick<User, "id" | "preferredName">
                        >;
                    }
            >;
        };
};

export type CreateClientMutationVariables = Exact<{
    regionId: Scalars["RegionId"];
}>;

export type CreateClientMutation = { __typename?: "Mutation" } & {
    createClient: { __typename?: "Client" } & Pick<Client, "id">;
};

export type DeleteClientMatchesMutationVariables = Exact<{
    id: Scalars["ClientId"];
}>;

export type DeleteClientMatchesMutation = { __typename?: "Mutation" } & {
    deleteClientMatches: { __typename?: "Client" } & Pick<Client, "id">;
};

export type SetCommunityOrderMutationVariables = Exact<{
    clientId: Scalars["ClientId"];
    questionIds: Array<Scalars["QuestionId"]> | Scalars["QuestionId"];
    ordering: Array<CommunityOrdering> | CommunityOrdering;
}>;

export type SetCommunityOrderMutation = { __typename?: "Mutation" } & {
    setCommunityOrder: { __typename?: "Client" } & ClientCommunitiesFragment;
};

export type AddClientCommunitiesMutationVariables = Exact<{
    clientId: Scalars["ClientId"];
    questionIds: Array<Scalars["QuestionId"]> | Scalars["QuestionId"];
    params: Array<AddClientCommunityRequest> | AddClientCommunityRequest;
}>;

export type AddClientCommunitiesMutation = { __typename?: "Mutation" } & {
    addClientCommunities: { __typename?: "Client" } & ClientCommunitiesFragment;
};

export type RemoveClientCommunitiesMutationVariables = Exact<{
    clientId: Scalars["ClientId"];
    params: Array<RemoveClientCommunityRequest> | RemoveClientCommunityRequest;
}>;

export type RemoveClientCommunitiesMutation = { __typename?: "Mutation" } & {
    removeClientCommunities: { __typename?: "Client" } & Pick<Client, "id">;
};

export type DashboardDefaultsFragment = { __typename?: "ClientsSearchResult" } & Pick<
    ClientsSearchResult,
    "total"
> & {
        clients: Array<
            { __typename?: "Client" } & Pick<
                Client,
                | "id"
                | "serial"
                | "name"
                | "statusListItemId"
                | "bestContactName"
                | "bestContactPhone"
                | "dateAdded"
                | "invoiceSentDate"
                | "moveTimeline"
                | "moveTimelineActual"
                | "paymentReceivedDate"
                | "statusCheckLaterDate"
                | "statusClosedDate"
                | "updatedAt"
                | "assignedUserId"
                | "phone"
                | "cellPhone"
                | "email"
                | "additionalClientName"
            > & {
                    chosenCommunity?: Maybe<
                        { __typename?: "Community" } & Pick<Community, "id" | "name">
                    >;
                    nextTask?: Maybe<
                        { __typename?: "Task" } & Pick<
                            Task,
                            "id" | "text" | "status" | "dueDate" | "dueDateTime"
                        >
                    >;
                    allContacts: Array<
                        { __typename?: "Contact" } & Pick<
                            Contact,
                            "name" | "email1" | "phone1" | "cellPhone" | "relationship"
                        >
                    >;
                }
        >;
    };

export type GetClientsForTimelineQueryVariables = Exact<{
    regionId: Scalars["RegionId"];
    searchString?: Maybe<Scalars["String"]>;
    assignedUserId?: Maybe<Scalars["UserId"]>;
    assignedReferralUserId?: Maybe<Scalars["UserId"]>;
    searchType?: Maybe<Scalars["String"]>;
    openClosed?: Maybe<Scalars["String"]>;
    page: Scalars["Int"];
    perPage: Scalars["Int"];
    includeStatuses?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type GetClientsForTimelineQuery = { __typename?: "Query" } & {
    pastDue: { __typename?: "ClientsSearchResult" } & DashboardDefaultsFragment;
    oneWeek: { __typename?: "ClientsSearchResult" } & DashboardDefaultsFragment;
    oneToTwoWeeks: { __typename?: "ClientsSearchResult" } & DashboardDefaultsFragment;
    twoToFourWeeks: { __typename?: "ClientsSearchResult" } & DashboardDefaultsFragment;
    fourOrMoreWeeks: { __typename?: "ClientsSearchResult" } & DashboardDefaultsFragment;
    unscheduled: { __typename?: "ClientsSearchResult" } & DashboardDefaultsFragment;
};

export type GetClientsForDetailsQueryVariables = Exact<{
    regionId: Scalars["RegionId"];
    searchString?: Maybe<Scalars["String"]>;
    assignedUserId?: Maybe<Scalars["UserId"]>;
    assignedReferralUserId?: Maybe<Scalars["UserId"]>;
    searchType?: Maybe<Scalars["String"]>;
    openClosed?: Maybe<Scalars["String"]>;
    page: Scalars["Int"];
    perPage: Scalars["Int"];
    includeStatuses?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
    excludeStatuses?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
    statusAtOrLater?: Maybe<Scalars["String"]>;
    status?: Maybe<Scalars["String"]>;
    sort: ClientSearchSort;
}>;

export type GetClientsForDetailsQuery = { __typename?: "Query" } & {
    getClients: { __typename?: "ClientsSearchResult" } & DashboardDefaultsFragment;
};

export type SearchClientsQueryVariables = Exact<{
    regionId: Scalars["RegionId"];
    searchString?: Maybe<Scalars["String"]>;
    assignedUserId?: Maybe<Scalars["UserId"]>;
    assignedReferralUserId?: Maybe<Scalars["UserId"]>;
    searchType?: Maybe<Scalars["String"]>;
    openClosed?: Maybe<Scalars["String"]>;
    referralCommunityId?: Maybe<Scalars["CommunityId"]>;
    referralContactId?: Maybe<Scalars["ReferenceContactId"]>;
    referralBusinessId?: Maybe<Scalars["ReferenceBusinessId"]>;
    relatedTo?: Maybe<Array<Scalars["ClientId"]> | Scalars["ClientId"]>;
    page: Scalars["Int"];
    perPage: Scalars["Int"];
}>;

export type SearchClientsQuery = { __typename?: "Query" } & {
    getClients: { __typename?: "ClientsSearchResult" } & DashboardDefaultsFragment;
};

export type GetClientReferralDetailsQueryVariables = Exact<{
    id: Scalars["ClientId"];
}>;

export type GetClientReferralDetailsQuery = { __typename?: "Query" } & {
    getClient: { __typename?: "Client" } & Pick<
        Client,
        "bestContactName" | "bestContactPhone" | "address" | "city" | "state" | "zip"
    >;
};

export type GetOutboundClientReferralsQueryVariables = Exact<{
    id: Scalars["ClientId"];
}>;

export type GetOutboundClientReferralsQuery = { __typename?: "Query" } & {
    getClientReferrals: Array<
        { __typename?: "Referral" } & {
            referenceBusiness?: Maybe<
                { __typename?: "ReferenceBusiness" } & Pick<ReferenceBusiness, "id"> & {
                        businessContactEntity?: Maybe<
                            { __typename?: "ContactEntity" } & Pick<ContactEntity, "name">
                        >;
                    }
            >;
            referenceContact?: Maybe<
                { __typename?: "ReferenceContact" } & Pick<ReferenceContact, "id"> & {
                        contactEntity?: Maybe<
                            { __typename?: "ContactEntity" } & Pick<ContactEntity, "name">
                        >;
                    }
            >;
            community?: Maybe<
                { __typename?: "Community" } & Pick<Community, "id" | "name">
            >;
        } & ReferralResponseFragment
    >;
};

export type ReferralResponseFragment = { __typename?: "Referral" } & Pick<
    Referral,
    | "id"
    | "clientId"
    | "referenceBusinessId"
    | "referenceContactId"
    | "communityId"
    | "referralType"
    | "notes"
    | "when"
    | "dateAdded"
    | "createdBy"
    | "updatedAt"
    | "updatedBy"
>;

export type CreateClientReferralMutationVariables = Exact<{
    clientId: Scalars["ClientId"];
    referralType: Scalars["String"];
}>;

export type CreateClientReferralMutation = { __typename?: "Mutation" } & {
    createClientReferral: { __typename?: "Referral" } & ReferralResponseFragment;
};

export type PatchClientReferralMutationVariables = Exact<{
    id: Scalars["ReferralId"];
    clientId: Scalars["ClientId"];
    notes?: Maybe<Scalars["String"]>;
    when?: Maybe<Scalars["DateTime"]>;
    deleted?: Maybe<Scalars["Boolean"]>;
    referenceBusinessId?: Maybe<Scalars["ReferenceBusinessId"]>;
    referenceContactId?: Maybe<Scalars["ReferenceContactId"]>;
    communityId?: Maybe<Scalars["CommunityId"]>;
}>;

export type PatchClientReferralMutation = { __typename?: "Mutation" } & {
    patchClientReferral: { __typename?: "Referral" } & ReferralResponseFragment;
};

export type GetClientForIntakeEmailQueryVariables = Exact<{
    clientId: Scalars["ClientId"];
}>;

export type GetClientForIntakeEmailQuery = { __typename?: "Query" } & {
    getClient: { __typename?: "Client" } & Pick<Client, "name"> & {
            clientCommunities: Array<
                { __typename?: "ClientCommunity" } & Pick<
                    ClientCommunity,
                    "relationship" | "referred"
                > & {
                        community: { __typename?: "Community" } & Pick<
                            Community,
                            "id" | "name" | "email" | "emailOptOut"
                        > & {
                                communityContacts: Array<
                                    { __typename?: "CommunityContact" } & Pick<
                                        CommunityContact,
                                        "primary"
                                    > & {
                                            contact: {
                                                __typename?: "ContactEntity";
                                            } & Pick<
                                                ContactEntity,
                                                "name" | "email1" | "email1OptOut"
                                            >;
                                        }
                                >;
                            };
                    }
            >;
        };
};

export type RequestClientTransferMutationVariables = Exact<{
    params: TransferClientRequest;
}>;

export type RequestClientTransferMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "requestClientTransfer"
>;

export type GetCommunityQueryVariables = Exact<{
    id: Scalars["CommunityId"];
}>;

export type GetCommunityQuery = { __typename?: "Query" } & {
    getCommunity: { __typename?: "Community" } & {
        invoices?: Maybe<
            Array<
                { __typename?: "Invoice" } & Pick<
                    Invoice,
                    | "id"
                    | "amountCents"
                    | "paidDate"
                    | "sentDate"
                    | "dueDate"
                    | "serial"
                    | "status"
                    | "clientId"
                    | "communityId"
                    | "fileId"
                    | "clientName"
                    | "notes"
                    | "externalReference"
                    | "remainingBalanceCents"
                    | "totalPaidCents"
                    | "hasOutstandingBalance"
                    | "isExternallyManaged"
                > & {
                        lineItems?: Maybe<
                            Array<
                                { __typename?: "InvoiceLineItem" } & Pick<
                                    InvoiceLineItem,
                                    "id" | "description" | "quantity" | "unitPriceInCents"
                                >
                            >
                        >;
                        paymentSchedule?: Maybe<
                            { __typename?: "InvoicePaymentSchedule" } & {
                                items: Array<
                                    { __typename?: "InvoicePaymentScheduleItem" } & Pick<
                                        InvoicePaymentScheduleItem,
                                        | "id"
                                        | "amountInCents"
                                        | "dueDate"
                                        | "paidDate"
                                        | "status"
                                    >
                                >;
                            }
                        >;
                    }
            >
        >;
        licenses: Array<
            { __typename?: "CommunityLicense" } & Pick<
                CommunityLicense,
                | "href"
                | "id"
                | "licenseEndDate"
                | "licenseNumber"
                | "licenseStartDate"
                | "licensee"
                | "status"
                | "communityId"
                | "tenantId"
            >
        >;
    } & CommunityBasicsFragment;
};

export type GetCommunityAdvancedQueryVariables = Exact<{
    id: Scalars["CommunityId"];
}>;

export type GetCommunityAdvancedQuery = { __typename?: "Query" } & {
    getCommunity: { __typename?: "Community" } & Pick<
        Community,
        "id" | "communityMasterId" | "communityMasterVersion"
    > & {
            matchInfo: { __typename?: "CommunityMatchInfo" } & Pick<
                CommunityMatchInfo,
                "matchRule" | "matchScore" | "originalMatchDate"
            >;
        };
};

export type DeleteCommunityLicenseMutationVariables = Exact<{
    communityId: Scalars["CommunityId"];
    licenseId: Scalars["CommunityLicenseId"];
}>;

export type DeleteCommunityLicenseMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteCommunityLicense"
>;

export type UpdateCommunityLicenseMutationVariables = Exact<{
    licenseId: Scalars["CommunityLicenseId"];
    license: CommunityLicenseInput;
}>;

export type UpdateCommunityLicenseMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "updateCommunityLicense"
>;

export type CreateCommunityLicenseMutationVariables = Exact<{
    communityId: Scalars["CommunityId"];
}>;

export type CreateCommunityLicenseMutation = { __typename?: "Mutation" } & {
    createCommunityLicense: { __typename?: "CommunityLicense" } & Pick<
        CommunityLicense,
        "id"
    >;
};

export type GetReferralsToCommunityQueryVariables = Exact<{
    communityId: Scalars["CommunityId"];
}>;

export type GetReferralsToCommunityQuery = { __typename?: "Query" } & {
    getReferralsToCommunity: Array<{ __typename?: "Referral" } & ReferralBasicsFragment>;
};

export type CommunityBasicsFragment = { __typename?: "Community" } & Pick<
    Community,
    | "address"
    | "caregiverRatio"
    | "city"
    | "contractStatus"
    | "contractExpiresDate"
    | "contractIssuedDate"
    | "contractNotes"
    | "dateAdded"
    | "gender"
    | "feeType"
    | "paymentFee"
    | "paymentPercentage"
    | "id"
    | "latitude"
    | "licenseStatus"
    | "longitude"
    | "mainPhone"
    | "monthlyPrice"
    | "moveInDeposit"
    | "name"
    | "petDeposit"
    | "priceDetails"
    | "priceHighEnd"
    | "rating"
    | "secondPersonFee"
    | "state"
    | "summary"
    | "thumbnailUrl"
    | "unitsOrCapacity"
    | "updatedAt"
    | "virtualTourURL"
    | "website"
    | "zip"
    | "regionId"
    | "email"
    | "county"
> & {
        contacts: Array<
            { __typename?: "ContactEntity" } & Pick<
                ContactEntity,
                "cellPhone" | "name" | "phone1" | "role" | "email1"
            >
        >;
        primaryContacts: Array<
            { __typename?: "ContactEntity" } & Pick<
                ContactEntity,
                "cellPhone" | "name" | "phone1" | "role" | "email1"
            >
        >;
    };

export type CommunityCardBasicsFragment = { __typename?: "Community" } & {
    answers: Array<
        { __typename?: "Answer" } & Pick<
            Answer,
            | "questionId"
            | "booleanAnswer"
            | "numberAnswer"
            | "numberAnswerHigh"
            | "textAnswer"
            | "dateAnswer"
            | "calendarDateAnswer"
            | "selections"
            | "idReferenceAnswer"
        > & {
                selectionAnnotations?: Maybe<
                    Array<
                        { __typename?: "AnswerSelectionAnnotation" } & Pick<
                            AnswerSelectionAnnotation,
                            "text" | "selectionId"
                        >
                    >
                >;
            }
    >;
    photoEntities: Array<{ __typename?: "Photo" } & PhotoBasicsFragment>;
    licenses: Array<
        { __typename?: "CommunityLicense" } & Pick<
            CommunityLicense,
            | "id"
            | "licenseNumber"
            | "href"
            | "licensee"
            | "status"
            | "licenseStartDate"
            | "licenseEndDate"
        >
    >;
} & CommunityBasicsFragment;

export type IdentityDefaultsFragment = { __typename?: "Identity" } & Pick<
    Identity,
    "id" | "name" | "successorId" | "propagateableLicenseTypes" | "thumbnailUrl"
>;

export type LicenseDefaultsFragment = { __typename?: "License" } & Pick<
    License,
    "status" | "jurisdiction" | "licenseNumber" | "id" | "licenseType"
>;

export type PublicRecordDefaultsFragment = { __typename?: "PublicRecord" } & Pick<
    PublicRecord,
    | "id"
    | "name"
    | "address"
    | "city"
    | "state"
    | "zip"
    | "capacity"
    | "licenseType"
    | "licenseNumber"
    | "licensee"
    | "closedDate"
    | "licenseDate"
    | "licenseStatus"
    | "county"
    | "country"
    | "phone"
    | "administrator"
    | "email"
    | "lat"
    | "lng"
>;

export type GetLicenseQueryVariables = Exact<{
    id: Scalars["LicenseId"];
}>;

export type GetLicenseQuery = { __typename?: "Query" } & {
    getLicense?: Maybe<
        { __typename?: "License" } & {
            publicRecords?: Maybe<
                Array<{ __typename?: "PublicRecord" } & PublicRecordDefaultsFragment>
            >;
            identity?: Maybe<{ __typename?: "Identity" } & IdentityDefaultsFragment>;
        } & LicenseDefaultsFragment
    >;
};

export type GetIdentityQueryVariables = Exact<{
    id: Scalars["IdentityId"];
}>;

export type GetIdentityQuery = { __typename?: "Query" } & {
    getIdentity?: Maybe<
        { __typename?: "Identity" } & {
            licenses?: Maybe<
                Array<
                    { __typename?: "License" } & {
                        publicRecords?: Maybe<
                            Array<
                                { __typename?: "PublicRecord" } & {
                                    publicRecordContacts?: Maybe<
                                        Array<
                                            { __typename?: "PublicRecordContact" } & Pick<
                                                PublicRecordContact,
                                                | "address1"
                                                | "address2"
                                                | "cellPhone"
                                                | "city"
                                                | "contactNotes"
                                                | "email1"
                                                | "fax"
                                                | "id"
                                                | "name"
                                                | "phone1"
                                                | "relationship"
                                                | "role"
                                                | "state"
                                                | "website"
                                                | "zip"
                                            >
                                        >
                                    >;
                                } & PublicRecordDefaultsFragment
                            >
                        >;
                    } & LicenseDefaultsFragment
                >
            >;
        } & IdentityDefaultsFragment
    >;
};

export type PublicRecordSearchQueryVariables = Exact<{
    params: PublicRecordSearchRequest;
}>;

export type PublicRecordSearchQuery = { __typename?: "Query" } & {
    publicRecordSearch: { __typename?: "PublicRecordSearchResult" } & Pick<
        PublicRecordSearchResult,
        "count"
    > & {
            records: Array<
                { __typename?: "PublicRecord" } & {
                    license?: Maybe<
                        { __typename?: "License" } & {
                            identity?: Maybe<
                                { __typename?: "Identity" } & IdentityDefaultsFragment
                            >;
                        } & LicenseDefaultsFragment
                    >;
                } & PublicRecordDefaultsFragment
            >;
        };
};

export type GetEvergreenLicenseTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetEvergreenLicenseTypesQuery = { __typename?: "Query" } & {
    getEvergreenLicenseTypes: Array<
        { __typename?: "EvergreenLicenseType" } & Pick<
            EvergreenLicenseType,
            "id" | "state" | "licenseType"
        >
    >;
};

export type UpdateEvergreenLicenseTypeMutationVariables = Exact<{
    input: EvergreenLicenseTypeInput;
}>;

export type UpdateEvergreenLicenseTypeMutation = { __typename?: "Mutation" } & {
    updateEvergreenLicenseType: { __typename?: "EvergreenLicenseType" } & Pick<
        EvergreenLicenseType,
        "id"
    >;
};

export type DeleteEvergreenLicenseTypeMutationVariables = Exact<{
    id: Scalars["EvergreenLicenseTypeId"];
}>;

export type DeleteEvergreenLicenseTypeMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteEvergreenLicenseType"
>;

export type CommunityNamesQueryVariables = Exact<{
    regionKey: Scalars["String"];
    ids?: Maybe<Array<Scalars["CommunityId"]> | Scalars["CommunityId"]>;
}>;

export type CommunityNamesQuery = { __typename?: "Query" } & {
    communityNames: { __typename?: "CommunityNamesResult" } & {
        communities: Array<
            { __typename?: "CommunityName" } & Pick<CommunityName, "id" | "name">
        >;
    };
};

export type CommunitySearchQueryVariables = Exact<{
    params: CommunitySearchRequest;
}>;

export type CommunitySearchQuery = { __typename?: "Query" } & {
    communitySearch: { __typename?: "CommunitySearchResult" } & Pick<
        CommunitySearchResult,
        "total"
    > & {
            coordinates?: Maybe<
                { __typename?: "Coordinates" } & Pick<Coordinates, "lat" | "lng">
            >;
            hits: Array<
                { __typename?: "CommunitySearchHit" } & Pick<
                    CommunitySearchHit,
                    | "address"
                    | "contractStatus"
                    | "contractExpiresDate"
                    | "contractIssuedDate"
                    | "contractNotes"
                    | "paymentFee"
                    | "paymentPercentage"
                    | "gender"
                    | "id"
                    | "lat"
                    | "licenseStatus"
                    | "lng"
                    | "mostRecentPlacement"
                    | "name"
                    | "phone"
                    | "placements"
                    | "rating"
                    | "summary"
                    | "thumbnail"
                    | "url"
                    | "unitsOrCapacity"
                    | "showInSearchResults"
                    | "monthlyPrice"
                    | "priceHighEnd"
                    | "zip"
                    | "state"
                    | "city"
                    | "mainPhone"
                    | "feeType"
                    | "secondPersonFee"
                    | "petDeposit"
                    | "moveInDeposit"
                    | "priceDetails"
                    | "caregiverRatio"
                    | "updatedAt"
                    | "dateAdded"
                    | "regionId"
                    | "referralCount"
                    | "email"
                    | "county"
                > & {
                        contacts: Array<
                            { __typename?: "ContactObject" } & Pick<
                                ContactObject,
                                "cellPhone" | "name" | "phone1" | "email1" | "role"
                            >
                        >;
                        primaryContacts: Array<
                            { __typename?: "ContactObject" } & Pick<
                                ContactObject,
                                "cellPhone" | "name" | "phone1" | "email1" | "role"
                            >
                        >;
                        answers: Array<
                            { __typename?: "Answer" } & Pick<
                                Answer,
                                | "questionId"
                                | "booleanAnswer"
                                | "numberAnswer"
                                | "numberAnswerHigh"
                                | "textAnswer"
                                | "dateAnswer"
                                | "calendarDateAnswer"
                                | "selections"
                                | "idReferenceAnswer"
                            > & {
                                    selectionAnnotations?: Maybe<
                                        Array<
                                            {
                                                __typename?: "AnswerSelectionAnnotation";
                                            } & Pick<
                                                AnswerSelectionAnnotation,
                                                "text" | "selectionId"
                                            >
                                        >
                                    >;
                                }
                        >;
                        licenses?: Maybe<
                            Array<
                                { __typename?: "CommunityLicense" } & Pick<
                                    CommunityLicense,
                                    | "id"
                                    | "licenseNumber"
                                    | "href"
                                    | "licensee"
                                    | "status"
                                    | "licenseStartDate"
                                    | "licenseEndDate"
                                >
                            >
                        >;
                    }
            >;
        };
};

export type CommunitySearchMapViewQueryVariables = Exact<{
    searchParams: CommunitySearchRequest;
    mapView: CommunitySearchRequestMapComponent;
}>;

export type CommunitySearchMapViewQuery = { __typename?: "Query" } & {
    communitySearchMapView: { __typename?: "CommunityMarkerCollection" } & Pick<
        CommunityMarkerCollection,
        "recommendedZoom"
    > & {
            coordinates?: Maybe<
                { __typename?: "Coordinates" } & Pick<Coordinates, "lat" | "lng">
            >;
            communityCoordinates: Array<
                { __typename?: "CommunityCoordinates" } & Pick<
                    CommunityCoordinates,
                    "lat" | "lng" | "name" | "contractStatus" | "id" | "appLink"
                >
            >;
            communityClusters: Array<
                { __typename?: "MapCluster" } & Pick<MapCluster, "lat" | "lng" | "count">
            >;
        };
};

export type GetCommunityContactsQueryVariables = Exact<{
    communityId: Scalars["CommunityId"];
    regionId: Scalars["RegionId"];
}>;

export type GetCommunityContactsQuery = { __typename?: "Query" } & {
    getCommunityContacts: Array<
        { __typename?: "CommunityContactDto" } & Pick<
            CommunityContactDto,
            "contactId" | "communityId" | "primary"
        > & {
                contact: { __typename?: "ContactEntity" } & ContactFragmentFragment;
                referenceContact?: Maybe<
                    { __typename?: "ReferenceContact" } & Pick<
                        ReferenceContact,
                        "id" | "summary" | "regionId"
                    >
                >;
            }
    >;
};

export type PromoteCommunityContactMutationVariables = Exact<{
    communityId: Scalars["CommunityId"];
    contactId: Scalars["ContactId"];
    regionId: Scalars["RegionId"];
}>;

export type PromoteCommunityContactMutation = { __typename?: "Mutation" } & {
    promoteCommunityContact: { __typename?: "CommunityContactDto" } & Pick<
        CommunityContactDto,
        "contactId" | "communityId" | "primary"
    > & {
            contact: { __typename?: "ContactEntity" } & ContactFragmentFragment;
            referenceContact?: Maybe<
                { __typename?: "ReferenceContact" } & Pick<
                    ReferenceContact,
                    | "id"
                    | "summary"
                    | "regions"
                    | "regionId"
                    | "ownerId"
                    | "dateAdded"
                    | "updatedAt"
                    | "updatedBy"
                    | "createdBy"
                >
            >;
        };
};

export type DeleteCommunityContactMutationVariables = Exact<{
    communityId: Scalars["CommunityId"];
    contactId: Scalars["ContactId"];
}>;

export type DeleteCommunityContactMutation = { __typename?: "Mutation" } & {
    deleteCommunityContact: { __typename?: "DeleteCommunityContactResult" } & Pick<
        DeleteCommunityContactResult,
        "contactId" | "communityId"
    >;
};

export type DeleteClientContactMutationVariables = Exact<{
    clientId: Scalars["ClientId"];
    contactId: Scalars["ContactId"];
    relationship: ClientContactRelationship;
}>;

export type DeleteClientContactMutation = { __typename?: "Mutation" } & {
    deleteClientContact: { __typename?: "DeleteClientContactResult" } & Pick<
        DeleteClientContactResult,
        "contactId" | "clientId"
    >;
};

export type GetClientContactsQueryVariables = Exact<{
    params: GetClientContactsRequest;
}>;

export type GetClientContactsQuery = { __typename?: "Query" } & {
    getClientContacts: Array<
        { __typename?: "ClientContact" } & Pick<
            ClientContact,
            "contactId" | "clientId" | "relationship" | "powerOfAttorney"
        > & {
                contact: { __typename?: "ContactEntity" } & {
                    referenceContacts: Array<
                        { __typename?: "ReferenceContact" } & Pick<
                            ReferenceContact,
                            "id" | "appLink"
                        >
                    >;
                } & ContactFragmentFragment;
            }
    >;
};

export type ContactFragmentFragment = { __typename?: "ContactEntity" } & Pick<
    ContactEntity,
    | "id"
    | "name"
    | "preferredName"
    | "address1"
    | "address2"
    | "city"
    | "state"
    | "zip"
    | "relationship"
    | "role"
    | "cellPhone"
    | "phone1"
    | "email1"
    | "email1OptOut"
    | "website"
    | "contactType"
    | "type"
    | "fax"
    | "contactNotes"
>;

export type ContactWithLinksFragment = { __typename?: "ContactEntity" } & {
    communityContacts: Array<
        { __typename?: "CommunityContact" } & Pick<
            CommunityContact,
            "communityId" | "contactId" | "primary"
        > & {
                community: { __typename?: "Community" } & Pick<
                    Community,
                    "id" | "name" | "appLink"
                >;
            }
    >;
    clientContacts: Array<
        { __typename?: "ClientContact" } & Pick<
            ClientContact,
            "clientId" | "contactId" | "relationship" | "powerOfAttorney"
        > & {
                client: { __typename?: "Client" } & Pick<
                    Client,
                    "id" | "name" | "appLink"
                >;
            }
    >;
    referenceContacts: Array<
        { __typename?: "ReferenceContact" } & Pick<
            ReferenceContact,
            | "id"
            | "summary"
            | "regionId"
            | "ownerId"
            | "dateAdded"
            | "updatedAt"
            | "updatedBy"
            | "createdBy"
            | "appLink"
        >
    >;
} & ContactFragmentFragment;

export type GetContactQueryVariables = Exact<{
    contactId: Scalars["ContactId"];
}>;

export type GetContactQuery = { __typename?: "Query" } & {
    getContact: { __typename?: "ContactEntity" } & ContactWithLinksFragment;
};

export type CreateContactMutationVariables = Exact<{
    params: CreateContactInput;
}>;

export type CreateContactMutation = { __typename?: "Mutation" } & {
    createContact: { __typename?: "ContactEntity" } & ContactWithLinksFragment;
};

export type PatchContactMutationVariables = Exact<{
    params: PatchContactInput;
}>;

export type PatchContactMutation = { __typename?: "Mutation" } & {
    patchContact: { __typename?: "ContactEntity" } & ContactWithLinksFragment;
};

export type GetCustomListQueryVariables = Exact<{
    key: CustomListKey;
}>;

export type GetCustomListQuery = { __typename?: "Query" } & {
    getCustomList: { __typename?: "CustomList" } & CustomListResponseFragment;
};

export type GetCustomListsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomListsQuery = { __typename?: "Query" } & {
    getCustomLists: Array<{ __typename?: "CustomList" } & CustomListResponseFragment>;
};

export type CreateCustomListItemMutationVariables = Exact<{
    params: CreateCustomListItemRequest;
}>;

export type CreateCustomListItemMutation = { __typename?: "Mutation" } & {
    createCustomListItem: {
        __typename?: "CustomListItem";
    } & CustomListItemResponseFragment;
};

export type PatchCustomListItemMutationVariables = Exact<{
    params: PatchCustomListItemRequest;
}>;

export type PatchCustomListItemMutation = { __typename?: "Mutation" } & {
    patchCustomListItem: {
        __typename?: "CustomListItem";
    } & CustomListItemResponseFragment;
};

export type PatchCustomListItemsMutationVariables = Exact<{
    params: Array<PatchCustomListItemRequest> | PatchCustomListItemRequest;
}>;

export type PatchCustomListItemsMutation = { __typename?: "Mutation" } & {
    patchCustomListItems: Array<
        { __typename?: "CustomListItem" } & CustomListItemResponseFragment
    >;
};

export type CustomListResponseFragment = { __typename?: "CustomList" } & Pick<
    CustomList,
    "id" | "key"
> & {
        items: Array<{ __typename?: "CustomListItem" } & CustomListItemResponseFragment>;
        categories: Array<
            { __typename?: "CustomListCategory" } & Pick<
                CustomListCategory,
                "id" | "key" | "name" | "order"
            >
        >;
    };

export type CustomListItemResponseFragment = { __typename?: "CustomListItem" } & Pick<
    CustomListItem,
    | "id"
    | "key"
    | "name"
    | "order"
    | "customListCategoryId"
    | "icon"
    | "pipelineVisibility"
    | "pipelineDateLabel"
    | "pipelineDateField"
    | "isArchived"
    | "updateBehavior"
>;

export type CreateDisclosureMutationVariables = Exact<{
    clientId: Scalars["ClientId"];
}>;

export type CreateDisclosureMutation = { __typename?: "Mutation" } & {
    createDisclosure: { __typename?: "Disclosure" } & DisclosureFragmentFragment;
};

export type CreateDisclosureAcknowledgementMutationVariables = Exact<{
    disclosureId: Scalars["DisclosureId"];
    acknowledgement: DisclosureAcknowledgementInput;
}>;

export type CreateDisclosureAcknowledgementMutation = { __typename?: "Mutation" } & {
    createDisclosureAcknowledgement: {
        __typename?: "DisclosureAcknowledgement";
    } & DisclosureAcknowledgementFragmentFragment;
};

export type DisclosureFragmentFragment = { __typename?: "Disclosure" } & Pick<
    Disclosure,
    "id" | "dateAdded" | "publicUrl"
> & {
        acknowledgements: Array<
            {
                __typename?: "DisclosureAcknowledgement";
            } & DisclosureAcknowledgementFragmentFragment
        >;
    };

export type DisclosureAcknowledgementFragmentFragment = {
    __typename?: "DisclosureAcknowledgement";
} & Pick<
    DisclosureAcknowledgement,
    | "id"
    | "name"
    | "email"
    | "phone"
    | "agreed"
    | "response"
    | "dateAdded"
    | "createdBy"
    | "ipAddress"
>;

export type UpdateEvergreenDataSourceMutationVariables = Exact<{
    input: EvergreenDataSourceInput;
}>;

export type UpdateEvergreenDataSourceMutation = { __typename?: "Mutation" } & {
    updateEvergreenDataSource: { __typename?: "EvergreenDataSource" } & Pick<
        EvergreenDataSource,
        "id"
    >;
};

export type UpdateEvergreenImportMutationVariables = Exact<{
    input: EvergreenImportInput;
}>;

export type UpdateEvergreenImportMutation = { __typename?: "Mutation" } & {
    updateEvergreenImport: { __typename?: "EvergreenImport" } & Pick<
        EvergreenImport,
        "id"
    >;
};

export type GetEvergreenDataSourcesQueryVariables = Exact<{ [key: string]: never }>;

export type GetEvergreenDataSourcesQuery = { __typename?: "Query" } & {
    getEvergreenDataSources: Array<
        { __typename?: "EvergreenDataSource" } & Pick<
            EvergreenDataSource,
            | "id"
            | "name"
            | "lastSuccessfulRun"
            | "updateFrequencyInDays"
            | "updatedAt"
            | "dateAdded"
            | "updateRequested"
            | "warningsSilenced"
            | "duplicatesDetectionWindowInDays"
        >
    >;
};

export type ImportFragmentFragment = { __typename?: "EvergreenImport" } & Pick<
    EvergreenImport,
    | "created"
    | "dateAdded"
    | "duplicates"
    | "endedAt"
    | "errors"
    | "id"
    | "message"
    | "skips"
    | "delisted"
    | "sourceName"
    | "startedAt"
    | "updatedAt"
>;

export type GetEvergreenDataSourceQueryVariables = Exact<{
    name: Scalars["String"];
}>;

export type GetEvergreenDataSourceQuery = { __typename?: "Query" } & {
    getEvergreenDataSource: { __typename?: "EvergreenDataSource" } & Pick<
        EvergreenDataSource,
        | "id"
        | "name"
        | "lastSuccessfulRun"
        | "updateFrequencyInDays"
        | "warningsSilenced"
        | "duplicatesDetectionWindowInDays"
        | "updatedAt"
        | "dateAdded"
        | "updateRequested"
    > & {
            imports?: Maybe<
                Array<{ __typename?: "EvergreenImport" } & ImportFragmentFragment>
            >;
        };
};

export type GetEvergreenImportsQueryVariables = Exact<{
    skip: Scalars["Int"];
    take: Scalars["Int"];
}>;

export type GetEvergreenImportsQuery = { __typename?: "Query" } & {
    getEvergreenImports: Array<
        { __typename?: "EvergreenImport" } & ImportFragmentFragment
    >;
};

export type UpdateFeatureFlagMutationVariables = Exact<{
    name: Scalars["ID"];
    enabled: Scalars["Boolean"];
}>;

export type UpdateFeatureFlagMutation = { __typename?: "Mutation" } & {
    updateFeatureFlag: { __typename?: "FeatureFlag" } & Pick<
        FeatureFlag,
        "name" | "enabled"
    >;
};

export type GetGlobalFeaturesQueryVariables = Exact<{ [key: string]: never }>;

export type GetGlobalFeaturesQuery = { __typename?: "Query" } & {
    getGlobalFeatures: { __typename?: "GlobalFeaturesResponse" } & Pick<
        GlobalFeaturesResponse,
        "skus"
    > & {
            features: Array<
                { __typename?: "FeatureDescription" } & Pick<
                    FeatureDescription,
                    | "skus"
                    | "key"
                    | "friendlyTitle"
                    | "description"
                    | "createdOn"
                    | "intent"
                    | "packageEnabled"
                > & {
                        actual: { __typename?: "FeatureFlag" } & Pick<
                            FeatureFlag,
                            "name" | "enabled"
                        >;
                    }
            >;
        };
};

export type FileFragmentFragment = { __typename?: "File" } & Pick<
    File,
    | "id"
    | "entityId"
    | "originalFilename"
    | "mimeType"
    | "description"
    | "byteLength"
    | "shareWithClient"
    | "shareWithCommunity"
    | "shareWithReferral"
    | "createdAt"
    | "createdBy"
    | "updatedAt"
    | "updatedBy"
    | "hasSignatureTemplate"
    | "isGlobal"
> & {
        regionVisibility?: Maybe<
            { __typename?: "EntityRegionVisibility" } & Pick<
                EntityRegionVisibility,
                "editable" | "regions"
            >
        >;
    };

export type GetFilesQueryVariables = Exact<{
    entityId: Scalars["FileEntityId"];
}>;

export type GetFilesQuery = { __typename?: "Query" } & {
    getFiles: { __typename?: "FileEntityList" } & {
        files: Array<{ __typename?: "File" } & FileFragmentFragment>;
    };
};

export type GetFileQueryVariables = Exact<{
    fileId: Scalars["FileId"];
}>;

export type GetFileQuery = { __typename?: "Query" } & {
    getFile: { __typename?: "File" } & Pick<File, "outstandingSignatureRequestsCount"> & {
            accessControls: Array<
                { __typename?: "AccessControlResult" } & Pick<
                    AccessControlResult,
                    "id" | "groupId"
                >
            >;
        } & FileFragmentFragment;
};

export type UpdateFileMutationVariables = Exact<{
    fileId: Scalars["FileId"];
    details: FileUpdateInput;
    accessControls?: Maybe<Array<AccessControlInput> | AccessControlInput>;
    regionVisibility?: Maybe<EntityRegionVisibilityInput>;
}>;

export type UpdateFileMutation = { __typename?: "Mutation" } & {
    updateFile: { __typename?: "File" } & Pick<
        File,
        "outstandingSignatureRequestsCount"
    > & {
            accessControls: Array<
                { __typename?: "AccessControlResult" } & Pick<
                    AccessControlResult,
                    "id" | "groupId"
                >
            >;
        } & FileFragmentFragment;
};

export type DeleteFileMutationVariables = Exact<{
    fileId: Scalars["FileId"];
}>;

export type DeleteFileMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteFile"
>;

export type DeleteHostedFormMutationVariables = Exact<{
    hostedFormId: Scalars["HostedFormId"];
}>;

export type DeleteHostedFormMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteHostedForm"
>;

export type CreateHostedFormMutationVariables = Exact<{ [key: string]: never }>;

export type CreateHostedFormMutation = { __typename?: "Mutation" } & {
    createHostedForm: { __typename?: "HostedForm" } & Pick<HostedForm, "id">;
};

export type PatchHostedFormMutationVariables = Exact<{
    params: PatchHostedFormRequest;
}>;

export type PatchHostedFormMutation = { __typename?: "Mutation" } & {
    patchHostedForm: { __typename?: "HostedForm" } & Pick<HostedForm, "id">;
};

export type GetHostedFormsQueryVariables = Exact<{ [key: string]: never }>;

export type GetHostedFormsQuery = { __typename?: "Query" } & {
    getHostedForms: Array<
        { __typename?: "HostedForm" } & Pick<
            HostedForm,
            "id" | "name" | "displayName" | "dateAdded" | "customizable" | "url"
        > & {
                configuration: { __typename?: "HostedFormConfiguration" } & Pick<
                    HostedFormConfiguration,
                    "style"
                > & {
                        regions?: Maybe<
                            Array<
                                { __typename?: "HostedFormRegionConfiguration" } & Pick<
                                    HostedFormRegionConfiguration,
                                    "regionId" | "userId" | "recipientUserIds"
                                >
                            >
                        >;
                    };
                layoutSections: Array<
                    { __typename?: "LayoutSection" } & Pick<
                        LayoutSection,
                        "id" | "order" | "sectionKey" | "visible"
                    > & {
                            layoutItems: Array<
                                { __typename?: "LayoutItem" } & Pick<
                                    LayoutItem,
                                    | "id"
                                    | "order"
                                    | "questionId"
                                    | "entityFieldName"
                                    | "showIfBlank"
                                >
                            >;
                        }
                >;
            }
    >;
};

export type SendHostedFormWithTokenMutationVariables = Exact<{
    params: SendHostedFormWithTokenRequest;
}>;

export type SendHostedFormWithTokenMutation = { __typename?: "Mutation" } & {
    sendHostedFormWithToken: { __typename?: "SendHostedFormWithTokenResponse" } & {
        token: { __typename?: "HostedFormToken" } & Pick<
            HostedFormToken,
            "id" | "expiresAt" | "updatedAt" | "dateAdded" | "createdBy" | "updatedBy"
        >;
    };
};

export type SendHostedFormWithPermalinkMutationVariables = Exact<{
    params: SendHostedFormWithPermalinkRequest;
}>;

export type SendHostedFormWithPermalinkMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "sendHostedFormWithPermalink"
>;

export type GetHostedFormTokensForEntityQueryVariables = Exact<{
    entityId: Scalars["HostedFormEntityId"];
}>;

export type GetHostedFormTokensForEntityQuery = { __typename?: "Query" } & {
    getHostedFormTokensForEntity: Array<
        { __typename?: "HostedFormToken" } & Pick<
            HostedFormToken,
            | "id"
            | "expiresAt"
            | "completedAt"
            | "hostedFormId"
            | "updatedAt"
            | "dateAdded"
            | "createdBy"
            | "updatedBy"
            | "status"
        > & {
                sentMessages?: Maybe<
                    Array<{ __typename?: "SentMessage" } & Pick<SentMessage, "to">>
                >;
            }
    >;
};

export type DeleteHostedFormTokenMutationVariables = Exact<{
    tokenId: Scalars["HostedFormTokenId"];
}>;

export type DeleteHostedFormTokenMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteHostedFormToken"
>;

export type RelocateImageMutationVariables = Exact<{
    payload: RelocateImageInput;
}>;

export type RelocateImageMutation = { __typename?: "Mutation" } & {
    relocateImage: { __typename?: "RelocateImageResponse" } & Pick<
        RelocateImageResponse,
        "id" | "src"
    >;
};

export type CreateInvoiceMutationVariables = Exact<{
    clientId: Scalars["ClientId"];
    serial?: Maybe<Scalars["String"]>;
    amountCents: Scalars["CurrencyInCents"];
    status: Scalars["String"];
    sentDate?: Maybe<Scalars["String"]>;
    dueDate?: Maybe<Scalars["String"]>;
    generatePdf: Scalars["Boolean"];
    notes?: Maybe<Scalars["String"]>;
    message?: Maybe<OutboundMessage>;
    lineItems?: Maybe<Array<InvoiceLineItemInput> | InvoiceLineItemInput>;
    paymentSchedule?: Maybe<InvoicePaymentScheduleInput>;
    externalReference?: Maybe<Scalars["String"]>;
}>;

export type CreateInvoiceMutation = { __typename?: "Mutation" } & {
    createInvoice: { __typename?: "InvoiceResult" } & {
        invoices?: Maybe<
            Array<{ __typename?: "Invoice" } & Pick<Invoice, "id" | "serial">>
        >;
        errors?: Maybe<
            { __typename?: "ErrorResult" } & Pick<ErrorResult, "message"> & {
                    fields: Array<
                        { __typename?: "ErrorField" } & Pick<
                            ErrorField,
                            "name" | "message"
                        >
                    >;
                }
        >;
    };
};

export type ResendInvoiceMutationVariables = Exact<{
    invoiceId: Scalars["InvoiceId"];
    message: OutboundMessage;
}>;

export type ResendInvoiceMutation = { __typename?: "Mutation" } & {
    resendInvoice: { __typename?: "InvoiceResult" } & {
        invoices?: Maybe<
            Array<{ __typename?: "Invoice" } & Pick<Invoice, "id" | "serial">>
        >;
        errors?: Maybe<
            { __typename?: "ErrorResult" } & Pick<ErrorResult, "message"> & {
                    fields: Array<
                        { __typename?: "ErrorField" } & Pick<
                            ErrorField,
                            "name" | "message"
                        >
                    >;
                }
        >;
    };
};

export type GenerateInvoiceMutationVariables = Exact<{
    id: Scalars["InvoiceId"];
}>;

export type GenerateInvoiceMutation = { __typename?: "Mutation" } & {
    generateInvoice: { __typename?: "InvoiceResult" } & {
        invoices?: Maybe<
            Array<{ __typename?: "Invoice" } & Pick<Invoice, "id" | "serial">>
        >;
        errors?: Maybe<
            { __typename?: "ErrorResult" } & Pick<ErrorResult, "message"> & {
                    fields: Array<
                        { __typename?: "ErrorField" } & Pick<
                            ErrorField,
                            "name" | "message"
                        >
                    >;
                }
        >;
    };
};

export type UpdateInvoiceMutationVariables = Exact<{
    invoiceId: Scalars["InvoiceId"];
    status: Scalars["String"];
    sentDate?: Maybe<Scalars["String"]>;
    paidDate?: Maybe<Scalars["String"]>;
    serial: Scalars["String"];
    amountCents: Scalars["CurrencyInCents"];
    notes?: Maybe<Scalars["String"]>;
    dueDate?: Maybe<Scalars["String"]>;
    lineItems?: Maybe<Array<InvoiceLineItemInput> | InvoiceLineItemInput>;
    paymentSchedule?: Maybe<InvoicePaymentScheduleInput>;
    externalReference?: Maybe<Scalars["String"]>;
}>;

export type UpdateInvoiceMutation = { __typename?: "Mutation" } & {
    updateInvoice: { __typename?: "InvoiceResult" } & {
        invoices?: Maybe<
            Array<
                { __typename?: "Invoice" } & Pick<
                    Invoice,
                    "id" | "clientId" | "communityId"
                >
            >
        >;
        errors?: Maybe<
            { __typename?: "ErrorResult" } & Pick<ErrorResult, "message"> & {
                    fields: Array<
                        { __typename?: "ErrorField" } & Pick<
                            ErrorField,
                            "name" | "message"
                        >
                    >;
                }
        >;
    };
};

export type CreateExternalInvoicesMutationVariables = Exact<{
    params: ExternalInvoicesCreateRequest;
}>;

export type CreateExternalInvoicesMutation = { __typename?: "Mutation" } & {
    createExternalInvoices: { __typename?: "InvoiceResult" } & {
        invoices?: Maybe<
            Array<{ __typename?: "Invoice" } & Pick<Invoice, "id" | "serial">>
        >;
        errors?: Maybe<
            { __typename?: "ErrorResult" } & Pick<ErrorResult, "message"> & {
                    fields: Array<
                        { __typename?: "ErrorField" } & Pick<
                            ErrorField,
                            "name" | "message"
                        >
                    >;
                }
        >;
    };
};

export type GetInvoiceCreateExperienceQueryVariables = Exact<{ [key: string]: never }>;

export type GetInvoiceCreateExperienceQuery = { __typename?: "Query" } & {
    getInvoiceCreateExperience: { __typename?: "InvoiceCreateExperienceResponse" } & Pick<
        InvoiceCreateExperienceResponse,
        "experience"
    >;
};

export type GetExternalInvoiceAccountSettingsQueryVariables = Exact<{
    [key: string]: never;
}>;

export type GetExternalInvoiceAccountSettingsQuery = { __typename?: "Query" } & {
    getExternalInvoiceAccountSettings: {
        __typename?: "ExternalInvoiceAccountSettings";
    } & {
        paymentTerms: Array<
            { __typename?: "ExternalInvoiceAccountPaymentTerm" } & Pick<
                ExternalInvoiceAccountPaymentTerm,
                "id" | "name" | "dueDays" | "default"
            >
        >;
    };
};

export type SearchExternalInvoicesQueryVariables = Exact<{
    params: ExternalInvoiceSearchRequest;
}>;

export type SearchExternalInvoicesQuery = { __typename?: "Query" } & {
    searchExternalInvoices: { __typename?: "ExternalInvoiceSearchResponse" } & Pick<
        ExternalInvoiceSearchResponse,
        "count"
    > & {
            hits: Array<
                { __typename?: "ExternalInvoiceSearchHit" } & Pick<
                    ExternalInvoiceSearchHit,
                    | "externalId"
                    | "serial"
                    | "customer"
                    | "invoiceDate"
                    | "dueDate"
                    | "amountInCents"
                >
            >;
        };
};

export type AddExistingExternalInvoiceMutationVariables = Exact<{
    params: AddExternalInvoiceRequest;
}>;

export type AddExistingExternalInvoiceMutation = { __typename?: "Mutation" } & {
    addExistingExternalInvoice: { __typename?: "Invoice" } & Pick<Invoice, "id">;
};

export type SearchExternalInvoiceCustomersQueryVariables = Exact<{
    params: ExternalInvoiceCustomerSearchRequest;
}>;

export type SearchExternalInvoiceCustomersQuery = { __typename?: "Query" } & {
    searchExternalInvoiceCustomers: {
        __typename?: "ExternalInvoiceCustomerSearchResponse";
    } & Pick<ExternalInvoiceCustomerSearchResponse, "count"> & {
            hits: Array<
                { __typename?: "ExternalInvoiceCustomer" } & Pick<
                    ExternalInvoiceCustomer,
                    "id" | "name"
                >
            >;
        };
};

export type GetExternalInvoiceInitialParametersQueryVariables = Exact<{
    params: GetExternalInvoiceInitialParametersRequest;
}>;

export type GetExternalInvoiceInitialParametersQuery = { __typename?: "Query" } & {
    getExternalInvoiceInitialParameters: {
        __typename?: "ExternalInvoiceInitialParameters";
    } & Pick<ExternalInvoiceInitialParameters, "createCustomerName"> & {
            customer?: Maybe<
                { __typename?: "ExternalInvoiceCustomer" } & Pick<
                    ExternalInvoiceCustomer,
                    "id" | "name"
                >
            >;
        };
};

export type CreateExternalInvoiceCustomerMutationVariables = Exact<{
    params: CreateExternalInvoiceCustomerRequest;
}>;

export type CreateExternalInvoiceCustomerMutation = { __typename?: "Mutation" } & {
    createExternalInvoiceCustomer: { __typename?: "ExternalInvoiceCustomer" } & Pick<
        ExternalInvoiceCustomer,
        "id" | "name"
    >;
};

export type DeleteInvoiceMutationVariables = Exact<{
    invoiceId: Scalars["InvoiceId"];
}>;

export type DeleteInvoiceMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteInvoice"
>;

export type GetLayoutSectionQueryVariables = Exact<{
    id: Scalars["LayoutSectionId"];
}>;

export type GetLayoutSectionQuery = { __typename?: "Query" } & {
    getLayoutSection: { __typename?: "LayoutSection" } & LayoutSectionBasicsFragment;
};

export type CreateLayoutSectionMutationVariables = Exact<{
    params: CreateLayoutSectionInput;
}>;

export type CreateLayoutSectionMutation = { __typename?: "Mutation" } & {
    createLayoutSection: { __typename?: "LayoutSection" } & LayoutSectionBasicsFragment;
};

export type PatchLayoutSectionMutationVariables = Exact<{
    params: PatchLayoutSectionInput;
}>;

export type PatchLayoutSectionMutation = { __typename?: "Mutation" } & {
    patchLayoutSection: { __typename?: "LayoutSection" } & LayoutSectionBasicsFragment;
};

export type PatchLayoutSectionsMutationVariables = Exact<{
    params: PatchLayoutSectionsInput;
}>;

export type PatchLayoutSectionsMutation = { __typename?: "Mutation" } & {
    patchLayoutSections: Array<
        { __typename?: "LayoutSection" } & LayoutSectionBasicsFragment
    >;
};

export type PatchLayoutItemMutationVariables = Exact<{
    params: PatchLayoutItemInput;
}>;

export type PatchLayoutItemMutation = { __typename?: "Mutation" } & {
    patchLayoutItem: { __typename?: "LayoutItem" } & LayoutItemBasicsFragment;
};

export type DeleteLayoutSectionMutationVariables = Exact<{
    id: Scalars["LayoutSectionId"];
}>;

export type DeleteLayoutSectionMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteLayoutSection"
>;

export type GetLayoutSectionsQueryVariables = Exact<{
    parentKey: LayoutSectionParentKey;
}>;

export type GetLayoutSectionsQuery = { __typename?: "Query" } & {
    getLayoutSections: { __typename?: "LayoutSectionsOutput" } & {
        layoutSections: Array<
            { __typename?: "LayoutSection" } & LayoutSectionBasicsFragment
        >;
    };
};

export type GetLayoutContainerByKeyQueryVariables = Exact<{
    key: Scalars["String"];
}>;

export type GetLayoutContainerByKeyQuery = { __typename?: "Query" } & {
    getLayoutContainerByKey: { __typename?: "LayoutContainer" } & Pick<
        LayoutContainer,
        "id" | "title" | "key"
    > & {
            layoutSections: Array<
                { __typename?: "LayoutSection" } & LayoutSectionBasicsFragment
            >;
        };
};

export type LayoutSectionBasicsFragment = { __typename?: "LayoutSection" } & Pick<
    LayoutSection,
    | "id"
    | "order"
    | "sectionKey"
    | "visible"
    | "entityType"
    | "title"
    | "icon"
    | "renderBehavior"
    | "questionCategory"
    | "legacyPerspective"
    | "includeRelations"
    | "singlePropertyName"
    | "defaultStandardFields"
> & {
        settings: Array<
            { __typename?: "LayoutSectionSetting" } & Pick<
                LayoutSectionSetting,
                "key" | "label" | "type" | "order"
            >
        >;
        layoutItems: Array<{ __typename?: "LayoutItem" } & LayoutItemBasicsFragment>;
    };

export type LayoutItemBasicsFragment = { __typename?: "LayoutItem" } & Pick<
    LayoutItem,
    | "id"
    | "order"
    | "questionId"
    | "entityFieldName"
    | "showIfBlank"
    | "naryFormat"
    | "customTitle"
>;

export type GetClientIntakeFaxRecipientsQueryVariables = Exact<{
    clientId: Scalars["ClientId"];
}>;

export type GetClientIntakeFaxRecipientsQuery = { __typename?: "Query" } & {
    getClientIntakeFaxRecipients: Array<
        { __typename?: "ClientIntakeFaxRecipient" } & Pick<
            ClientIntakeFaxRecipient,
            "faxNumber" | "communityName" | "communityId"
        >
    >;
};

export type SendClientIntakeFaxMutationVariables = Exact<{
    message: OutboundClientIntakeFax;
}>;

export type SendClientIntakeFaxMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "sendClientIntakeFax"
>;

export type ComposeMessageQueryVariables = Exact<{
    entityId: Scalars["EmailEntityId"];
    specialMessageExperience?: Maybe<Scalars["String"]>;
}>;

export type ComposeMessageQuery = { __typename?: "Query" } & {
    getTemplates: { __typename?: "TemplateList" } & {
        templates: Array<
            { __typename?: "TemplateResponse" } & Pick<
                TemplateResponse,
                "isDefaultSignature"
            > & {
                    template: { __typename?: "EmailTemplate" } & Pick<
                        EmailTemplate,
                        | "id"
                        | "body"
                        | "subject"
                        | "description"
                        | "type"
                        | "migrationVersion"
                        | "attachments"
                    >;
                }
        >;
    };
    composeMessage: { __typename?: "MessageCompose" } & Pick<
        MessageCompose,
        "recipients"
    > & {
            specialCommunityComparisonDefaults?: Maybe<
                { __typename?: "SpecialCommunityComparisonDefaults" } & Pick<
                    SpecialCommunityComparisonDefaults,
                    | "showSummary"
                    | "showRecords"
                    | "showCoverPage"
                    | "coverPageTemplateId"
                    | "liveLinkText"
                    | "attachCommunityPDFByDefault"
                    | "useLiveLinkByDefault"
                >
            >;
            files: Array<
                { __typename?: "AttachmentScope" } & Pick<
                    AttachmentScope,
                    "entityName"
                > & {
                        files: Array<
                            { __typename?: "File" } & Pick<
                                File,
                                "id" | "originalFilename" | "mimeType" | "byteLength"
                            >
                        >;
                    }
            >;
        };
};

export type ComposeMessageBasicQueryVariables = Exact<{ [key: string]: never }>;

export type ComposeMessageBasicQuery = { __typename?: "Query" } & {
    getTemplates: { __typename?: "TemplateList" } & {
        templates: Array<
            { __typename?: "TemplateResponse" } & Pick<
                TemplateResponse,
                "isDefaultSignature"
            > & {
                    template: { __typename?: "EmailTemplate" } & Pick<
                        EmailTemplate,
                        | "id"
                        | "body"
                        | "subject"
                        | "description"
                        | "type"
                        | "migrationVersion"
                        | "attachments"
                    >;
                }
        >;
    };
};

export type GetMessagesForEntityQueryVariables = Exact<{
    id: Scalars["EmailEntityId"];
}>;

export type GetMessagesForEntityQuery = { __typename?: "Query" } & {
    getMessagesForEntity: Array<
        { __typename?: "SentMessage" } & Pick<
            SentMessage,
            | "id"
            | "subject"
            | "status"
            | "latestEventStatus"
            | "createdAt"
            | "to"
            | "messageType"
            | "attachments"
            | "clientIdUuid"
            | "communityIdUuid"
        > & {
                clickedLinkEvents?: Maybe<
                    Array<
                        { __typename?: "ClickedLink" } & Pick<
                            ClickedLink,
                            "link" | "events" | "redacted"
                        > & {
                                eventsV2?: Maybe<
                                    Array<
                                        { __typename?: "ClickedLinkEvent" } & Pick<
                                            ClickedLinkEvent,
                                            "timestamp" | "recipient"
                                        >
                                    >
                                >;
                            }
                    >
                >;
                intakeCommunity?: Maybe<
                    { __typename?: "Community" } & Pick<
                        Community,
                        "id" | "name" | "appLink"
                    >
                >;
                intakeClient?: Maybe<
                    { __typename?: "Client" } & Pick<
                        Client,
                        "id" | "name" | "bestContactName" | "appLink"
                    >
                >;
                events?: Maybe<
                    Array<
                        { __typename?: "SentMessageEvent" } & Pick<
                            SentMessageEvent,
                            "eventType" | "eventTimestamp" | "recipient"
                        >
                    >
                >;
            }
    >;
};

export type GetMessageQueryVariables = Exact<{
    id: Scalars["SentMessageId"];
}>;

export type GetMessageQuery = { __typename?: "Query" } & {
    getMessage: { __typename?: "SentMessage" } & Pick<
        SentMessage,
        | "id"
        | "subject"
        | "bouncedRecipients"
        | "status"
        | "createdAt"
        | "body"
        | "to"
        | "messageType"
        | "attachments"
    > & {
            intakeCommunity?: Maybe<
                { __typename?: "Community" } & Pick<Community, "name">
            >;
            invoice?: Maybe<{ __typename?: "Invoice" } & Pick<Invoice, "id" | "serial">>;
            clickedLinkEvents?: Maybe<
                Array<
                    { __typename?: "ClickedLink" } & Pick<
                        ClickedLink,
                        "link" | "events" | "redacted"
                    > & {
                            eventsV2?: Maybe<
                                Array<
                                    { __typename?: "ClickedLinkEvent" } & Pick<
                                        ClickedLinkEvent,
                                        "timestamp" | "recipient"
                                    >
                                >
                            >;
                        }
                >
            >;
            events?: Maybe<
                Array<
                    { __typename?: "SentMessageEvent" } & Pick<
                        SentMessageEvent,
                        "eventType" | "eventTimestamp" | "recipient"
                    >
                >
            >;
        };
};

export type SendMessageMutationVariables = Exact<{
    message: OutboundMessage;
}>;

export type SendMessageMutation = { __typename?: "Mutation" } & {
    sendMessage: { __typename?: "SendMessageResult" } & Pick<
        SendMessageResult,
        "status" | "accepted" | "rejected" | "code"
    > & { sentMessage?: Maybe<{ __typename?: "SentMessage" } & Pick<SentMessage, "id">> };
};

export type ValidateTemplateQueryVariables = Exact<{
    emailTemplateId: Scalars["EmailTemplateId"];
    messageType: Scalars["String"];
}>;

export type ValidateTemplateQuery = { __typename?: "Query" } & {
    validateTemplate: { __typename?: "TemplateValidationResponse" } & Pick<
        TemplateValidationResponse,
        "isValid" | "message"
    > & {
            missingTokens: Array<
                { __typename?: "TemplateValidationTokenWarning" } & Pick<
                    TemplateValidationTokenWarning,
                    "tokenKey" | "description" | "humanFriendlyName"
                >
            >;
        };
};

export type GetMessagingConfigurationQueryVariables = Exact<{ [key: string]: never }>;

export type GetMessagingConfigurationQuery = { __typename?: "Query" } & {
    getMessagingConfiguration: { __typename?: "MessagingConfiguration" } & {
        user: { __typename?: "UserMessagingConfiguration" } & Pick<
            UserMessagingConfiguration,
            "directSmtp"
        >;
        tenant: { __typename?: "TenantMessagingConfiguration" } & Pick<
            TenantMessagingConfiguration,
            "customDomains" | "trackingLevel"
        >;
    };
};

export type CreateNoteMutationVariables = Exact<{
    entityType: Scalars["String"];
    entityId: Scalars["NoteEntityId"];
    params: CreateNoteInput;
}>;

export type CreateNoteMutation = { __typename?: "Mutation" } & {
    createNote: { __typename?: "NoteDto" } & NoteResponseFragment;
};

export type UpdateNoteMutationVariables = Exact<{
    entityId: Scalars["NoteEntityId"];
    noteId: Scalars["NoteId"];
    params: UpdateNoteInput;
}>;

export type UpdateNoteMutation = { __typename?: "Mutation" } & {
    updateNote: { __typename?: "NoteDto" } & NoteResponseFragment;
};

export type DeleteNoteMutationVariables = Exact<{
    entityType: Scalars["String"];
    entityId: Scalars["NoteEntityId"];
    noteId: Scalars["NoteId"];
}>;

export type DeleteNoteMutation = { __typename?: "Mutation" } & {
    deleteNote: { __typename?: "DeleteNoteResult" } & Pick<DeleteNoteResult, "_id">;
};

export type GetNotesForEntityQueryVariables = Exact<{
    entityId: Scalars["NoteEntityId"];
    filter?: Maybe<GetNotesForEntityFilter>;
}>;

export type GetNotesForEntityQuery = { __typename?: "Query" } & {
    getNotesForEntity: Array<{ __typename?: "NoteDto" } & NoteResponseFragment>;
};

export type NoteResponseFragment = { __typename?: "NoteDto" } & Pick<
    NoteDto,
    "_id" | "text" | "dateAdded" | "wasEdited" | "authorId" | "isDraft"
>;

export type GetPhotosForEntityQueryVariables = Exact<{
    entityId: Scalars["FileEntityId"];
}>;

export type GetPhotosForEntityQuery = { __typename?: "Query" } & {
    getPhotosForEntity: Array<{ __typename?: "Photo" } & PhotoBasicsFragment>;
};

export type PatchPhotoMutationVariables = Exact<{
    params: PatchPhotoInput;
}>;

export type PatchPhotoMutation = { __typename?: "Mutation" } & {
    patchPhoto: { __typename?: "Photo" } & PhotoBasicsFragment;
};

export type DeletePhotoMutationVariables = Exact<{
    params: DeletePhotoInput;
}>;

export type DeletePhotoMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deletePhoto"
>;

export type PatchPhotosMutationVariables = Exact<{
    params: Array<PatchPhotoInput> | PatchPhotoInput;
}>;

export type PatchPhotosMutation = { __typename?: "Mutation" } & {
    patchPhotos: Array<{ __typename?: "Photo" } & PhotoBasicsFragment>;
};

export type PhotoBasicsFragment = { __typename?: "Photo" } & Pick<
    Photo,
    "id" | "entityId" | "order" | "dateAdded" | "createdBy"
> & { originalFile: { __typename?: "File" } & FileFragmentFragment };

export type BulkCreateLegacyQuestionsMutationVariables = Exact<{
    payload: QuestionBulkCreatePayload;
}>;

export type BulkCreateLegacyQuestionsMutation = { __typename?: "Mutation" } & {
    bulkCreateLegacyQuestions: Array<{ __typename?: "Question" } & Pick<Question, "id">>;
};

export type GetLegacyQuestionsForTenantQueryVariables = Exact<{
    tenantId: Scalars["TenantId"];
}>;

export type GetLegacyQuestionsForTenantQuery = { __typename?: "Query" } & {
    getLegacyQuestionsForTenant: Array<
        { __typename?: "Question" } & Pick<
            Question,
            | "id"
            | "key"
            | "order"
            | "category"
            | "clientTitle"
            | "communityTitle"
            | "questionType"
            | "clientInteraction"
            | "clientPdfBehavior"
            | "clientSearchBehavior"
            | "clientAnnotation"
            | "communityInteraction"
            | "communityPdfBehavior"
            | "communitySearchBehavior"
            | "communityAnnotation"
            | "clientRulesString"
            | "communityRulesString"
        > & {
                prompts?: Maybe<
                    Array<
                        { __typename?: "Prompt" } & Pick<
                            Prompt,
                            "id" | "client" | "community" | "key" | "order"
                        >
                    >
                >;
            }
    >;
};

export type ReorderLegacyQuestionsMutationVariables = Exact<{
    questionReorderPayload: QuestionReorderPayload;
}>;

export type ReorderLegacyQuestionsMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "reorderLegacyQuestions"
>;

export type GetReferenceContactQueryVariables = Exact<{
    id: Scalars["ReferenceContactId"];
}>;

export type GetReferenceContactQuery = { __typename?: "Query" } & {
    getReferenceContact: {
        __typename?: "ReferenceContact";
    } & ReferenceContactBasicsFragment;
};

export type GetReferenceBusinessQueryVariables = Exact<{
    id: Scalars["ReferenceBusinessId"];
}>;

export type GetReferenceBusinessQuery = { __typename?: "Query" } & {
    getReferenceBusiness: {
        __typename?: "ReferenceBusiness";
    } & ReferenceBusinessBasicsFragment;
};

export type ReferenceContactSearchQueryVariables = Exact<{
    search: ReferenceContactSearchRequest;
}>;

export type ReferenceContactSearchQuery = { __typename?: "Query" } & {
    referenceContactSearch: { __typename?: "ReferenceContactSearchResult" } & Pick<
        ReferenceContactSearchResult,
        "total"
    > & {
            coordinates?: Maybe<
                { __typename?: "Coordinates" } & Pick<Coordinates, "lat" | "lng">
            >;
            hits: Array<
                { __typename?: "ReferenceContactSearchHit" } & Pick<
                    ReferenceContactSearchHit,
                    | "id"
                    | "name"
                    | "role"
                    | "updatedAt"
                    | "organizationName"
                    | "organizationType"
                    | "organizationPhone"
                    | "organizationFax"
                    | "address"
                    | "city"
                    | "state"
                    | "zip"
                    | "email"
                    | "cellPhone"
                    | "phone"
                    | "numberOfReferrals"
                    | "mostRecentReferral"
                    | "assignedTo"
                    | "businessId"
                    | "communityId"
                    | "summary"
                    | "latitude"
                    | "longitude"
                >
            >;
        };
};

export type ReferenceContactMapSearchQueryVariables = Exact<{
    searchParams: ReferenceContactSearchRequest;
    mapView: CommunitySearchRequestMapComponent;
}>;

export type ReferenceContactMapSearchQuery = { __typename?: "Query" } & {
    referenceContactMapSearch: { __typename?: "ReferenceContactMarkerCollection" } & Pick<
        ReferenceContactMarkerCollection,
        "recommendedZoom"
    > & {
            referenceContactCoordinates: Array<
                { __typename?: "ReferenceContactCoordinates" } & Pick<
                    ReferenceContactCoordinates,
                    "businessId" | "communityId" | "name" | "lat" | "lng" | "appLink"
                > & {
                        contacts: Array<
                            { __typename?: "ReferenceContactCoordinatesContact" } & Pick<
                                ReferenceContactCoordinatesContact,
                                "id" | "name" | "appLink"
                            >
                        >;
                    }
            >;
            referenceContactClusters: Array<
                { __typename?: "MapCluster" } & Pick<MapCluster, "lat" | "lng" | "count">
            >;
            coordinates?: Maybe<
                { __typename?: "Coordinates" } & Pick<Coordinates, "lat" | "lng">
            >;
        };
};

export type ReferenceBusinessSearchQueryVariables = Exact<{
    search: ReferenceBusinessSearchRequest;
}>;

export type ReferenceBusinessSearchQuery = { __typename?: "Query" } & {
    referenceBusinessSearch: { __typename?: "ReferenceBusinessSearchResult" } & Pick<
        ReferenceBusinessSearchResult,
        "total"
    > & {
            coordinates?: Maybe<
                { __typename?: "Coordinates" } & Pick<Coordinates, "lat" | "lng">
            >;
            hits: Array<
                { __typename?: "ReferenceBusinessSearchHit" } & Pick<
                    ReferenceBusinessSearchHit,
                    | "id"
                    | "name"
                    | "updatedAt"
                    | "summary"
                    | "organizationType"
                    | "city"
                    | "state"
                    | "zip"
                    | "address"
                    | "phone"
                    | "fax"
                    | "numberOfReferrals"
                    | "mostRecentReferral"
                    | "assignedTo"
                    | "latitude"
                    | "longitude"
                >
            >;
        };
};

export type ReferenceBusinessMapSearchQueryVariables = Exact<{
    searchParams: ReferenceBusinessSearchRequest;
    mapView: CommunitySearchRequestMapComponent;
}>;

export type ReferenceBusinessMapSearchQuery = { __typename?: "Query" } & {
    referenceBusinessMapSearch: {
        __typename?: "ReferenceBusinessMarkerCollection";
    } & Pick<ReferenceBusinessMarkerCollection, "recommendedZoom"> & {
            referenceBusinessCoordinates: Array<
                { __typename?: "ReferenceBusinessCoordinates" } & Pick<
                    ReferenceBusinessCoordinates,
                    "id" | "name" | "lat" | "lng" | "appLink"
                >
            >;
            referenceBusinessClusters: Array<
                { __typename?: "MapCluster" } & Pick<MapCluster, "lat" | "lng" | "count">
            >;
            coordinates?: Maybe<
                { __typename?: "Coordinates" } & Pick<Coordinates, "lat" | "lng">
            >;
        };
};

export type ReferenceCommunitySearchQueryVariables = Exact<{
    search: ReferenceCommunitySearchRequest;
}>;

export type ReferenceCommunitySearchQuery = { __typename?: "Query" } & {
    referenceCommunitySearch: { __typename?: "ReferenceCommunitySearchResult" } & Pick<
        ReferenceCommunitySearchResult,
        "total"
    > & {
            hits: Array<
                { __typename?: "ReferenceCommunitySearchHit" } & Pick<
                    ReferenceCommunitySearchHit,
                    | "id"
                    | "name"
                    | "updatedAt"
                    | "city"
                    | "state"
                    | "zip"
                    | "address"
                    | "phone"
                    | "fax"
                    | "summary"
                    | "numberOfReferrals"
                    | "mostRecentReferral"
                    | "latitude"
                    | "longitude"
                >
            >;
        };
};

export type ReferenceCommunityMapSearchQueryVariables = Exact<{
    searchParams: ReferenceCommunitySearchRequest;
    mapView: CommunitySearchRequestMapComponent;
}>;

export type ReferenceCommunityMapSearchQuery = { __typename?: "Query" } & {
    referenceCommunityMapSearch: {
        __typename?: "ReferenceCommunityMarkerCollection";
    } & Pick<ReferenceCommunityMarkerCollection, "recommendedZoom"> & {
            communityCoordinates: Array<
                { __typename?: "CommunityCoordinates" } & Pick<
                    CommunityCoordinates,
                    "id" | "name" | "lat" | "lng" | "appLink"
                >
            >;
            communityClusters: Array<
                { __typename?: "MapCluster" } & Pick<MapCluster, "lat" | "lng" | "count">
            >;
            coordinates?: Maybe<
                { __typename?: "Coordinates" } & Pick<Coordinates, "lat" | "lng">
            >;
        };
};

export type GetReferralsToContactQueryVariables = Exact<{
    referenceContactId: Scalars["ReferenceContactId"];
}>;

export type GetReferralsToContactQuery = { __typename?: "Query" } & {
    getReferralsToContact: Array<{ __typename?: "Referral" } & ReferralBasicsFragment>;
};

export type ReferralBasicsFragment = { __typename?: "Referral" } & Pick<
    Referral,
    "id" | "clientId"
> & {
        client?: Maybe<
            { __typename?: "Client" } & Pick<
                Client,
                | "id"
                | "name"
                | "bestContactName"
                | "statusListItemId"
                | "dateAdded"
                | "serial"
                | "assignedUserId"
                | "updatedAt"
                | "moveTimeline"
                | "moveTimelineActual"
            > & {
                    nextTask?: Maybe<
                        { __typename?: "Task" } & Pick<
                            Task,
                            | "id"
                            | "text"
                            | "dueDate"
                            | "dueDateTime"
                            | "compositeDueDateTime"
                            | "status"
                        >
                    >;
                }
        >;
    };

export type ReferenceContactBasicsFragment = { __typename?: "ReferenceContact" } & Pick<
    ReferenceContact,
    | "id"
    | "ownerId"
    | "deleted"
    | "summary"
    | "businessId"
    | "communityId"
    | "regions"
    | "regionId"
    | "dateAdded"
    | "updatedAt"
    | "createdBy"
    | "updatedBy"
    | "draftNote"
> & {
        contactEntity?: Maybe<{ __typename?: "ContactEntity" } & ContactFragmentFragment>;
        business?: Maybe<
            { __typename?: "ReferenceBusiness" } & Pick<
                ReferenceBusiness,
                "id" | "ownerId" | "deleted" | "regionId" | "latitude" | "longitude"
            > & {
                    businessContactEntity?: Maybe<
                        { __typename?: "ContactEntity" } & ContactFragmentFragment
                    >;
                }
        >;
        community?: Maybe<
            { __typename?: "Community" } & Pick<
                Community,
                | "id"
                | "name"
                | "address"
                | "city"
                | "state"
                | "zip"
                | "mainPhone"
                | "website"
                | "email"
                | "latitude"
                | "longitude"
                | "contractStatus"
            >
        >;
        answers: Array<
            { __typename?: "Answer" } & Pick<
                Answer,
                | "questionId"
                | "booleanAnswer"
                | "numberAnswer"
                | "numberAnswerHigh"
                | "textAnswer"
                | "dateAnswer"
                | "calendarDateAnswer"
                | "selections"
                | "idReferenceAnswer"
            > & {
                    selectionAnnotations?: Maybe<
                        Array<
                            { __typename?: "AnswerSelectionAnnotation" } & Pick<
                                AnswerSelectionAnnotation,
                                "text" | "selectionId"
                            >
                        >
                    >;
                }
        >;
        notes: Array<{ __typename?: "NoteDto" } & NoteResponseFragment>;
    };

export type CreateReferenceContactMutationVariables = Exact<{
    regionId: Scalars["RegionId"];
}>;

export type CreateReferenceContactMutation = { __typename?: "Mutation" } & {
    createReferenceContact: { __typename?: "ReferenceContact" } & Pick<
        ReferenceContact,
        "id"
    >;
};

export type PatchReferenceContactMutationVariables = Exact<{
    id: Scalars["ReferenceContactId"];
    summary?: Maybe<Scalars["String"]>;
    ownerId?: Maybe<Scalars["UserId"]>;
    businessId?: Maybe<Scalars["ReferenceBusinessId"]>;
    communityId?: Maybe<Scalars["CommunityId"]>;
    contactEntity?: Maybe<ContactInput>;
    deleted?: Maybe<Scalars["Boolean"]>;
    draftNote?: Maybe<Scalars["String"]>;
}>;

export type PatchReferenceContactMutation = { __typename?: "Mutation" } & {
    patchReferenceContact: {
        __typename?: "ReferenceContact";
    } & ReferenceContactBasicsFragment;
};

export type CreateReferenceBusinessMutationVariables = Exact<{
    regionId: Scalars["RegionId"];
}>;

export type CreateReferenceBusinessMutation = { __typename?: "Mutation" } & {
    createReferenceBusiness: { __typename?: "ReferenceBusiness" } & Pick<
        ReferenceBusiness,
        "id"
    >;
};

export type PatchReferenceBusinessMutationVariables = Exact<{
    id: Scalars["ReferenceBusinessId"];
    summary?: Maybe<Scalars["String"]>;
    ownerId?: Maybe<Scalars["UserId"]>;
    businessContactEntity?: Maybe<ContactInput>;
    deleted?: Maybe<Scalars["Boolean"]>;
    draftNote?: Maybe<Scalars["String"]>;
}>;

export type PatchReferenceBusinessMutation = { __typename?: "Mutation" } & {
    patchReferenceBusiness: {
        __typename?: "ReferenceBusiness";
    } & ReferenceBusinessBasicsFragment;
};

export type GetReferralsToBusinessQueryVariables = Exact<{
    referenceBusinessId: Scalars["ReferenceBusinessId"];
}>;

export type GetReferralsToBusinessQuery = { __typename?: "Query" } & {
    getReferralsToBusiness: Array<{ __typename?: "Referral" } & ReferralBasicsFragment>;
};

export type ReferenceBusinessBasicsFragment = { __typename?: "ReferenceBusiness" } & Pick<
    ReferenceBusiness,
    | "id"
    | "ownerId"
    | "deleted"
    | "summary"
    | "dateAdded"
    | "updatedAt"
    | "createdBy"
    | "updatedBy"
    | "draftNote"
    | "regionId"
    | "latitude"
    | "longitude"
> & {
        businessContactEntity?: Maybe<
            { __typename?: "ContactEntity" } & ContactFragmentFragment
        >;
        answers: Array<
            { __typename?: "Answer" } & Pick<
                Answer,
                | "questionId"
                | "booleanAnswer"
                | "numberAnswer"
                | "numberAnswerHigh"
                | "textAnswer"
                | "dateAnswer"
                | "calendarDateAnswer"
                | "selections"
                | "idReferenceAnswer"
            > & {
                    selectionAnnotations?: Maybe<
                        Array<
                            { __typename?: "AnswerSelectionAnnotation" } & Pick<
                                AnswerSelectionAnnotation,
                                "text" | "selectionId"
                            >
                        >
                    >;
                }
        >;
        notes: Array<{ __typename?: "NoteDto" } & NoteResponseFragment>;
    };

export type GeocodeReferenceBusinessesMutationVariables = Exact<{ [key: string]: never }>;

export type GeocodeReferenceBusinessesMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "geocodeReferenceBusinesses"
>;

export type GetRegionQueryVariables = Exact<{
    id: Scalars["RegionId"];
    tenantId: Scalars["TenantId"];
}>;

export type GetRegionQuery = { __typename?: "Query" } & {
    getRegion: { __typename?: "Region" } & Pick<Region, "id" | "key" | "name"> & {
            regionLicenseMaps: Array<
                { __typename?: "RegionLicenseMap" } & Pick<
                    RegionLicenseMap,
                    "id" | "autoPropagateEnabled"
                > & {
                        identityFilterObjects: Array<
                            { __typename?: "IdentityFilterObject" } & Pick<
                                IdentityFilterObject,
                                "state" | "zip" | "county"
                            >
                        >;
                        licenseMappingObjects: Array<
                            { __typename?: "LicenseMappingObject" } & Pick<
                                LicenseMappingObject,
                                | "licenseIdentifier"
                                | "promptId"
                                | "questionId"
                                | "annotationOnly"
                            >
                        >;
                        settings?: Maybe<
                            { __typename?: "RegionLicenseMapSettings" } & Pick<
                                RegionLicenseMapSettings,
                                "mode" | "updateAttributes" | "closedHandling"
                            >
                        >;
                    }
            >;
        };
};

export type GetStateReportQueryVariables = Exact<{ [key: string]: never }>;

export type GetStateReportQuery = { __typename?: "Query" } & {
    getStateReport: Array<
        { __typename?: "StateReportQueryRow" } & Pick<
            StateReportQueryRow,
            | "thecount"
            | "totalrlmcount"
            | "state"
            | "regionid"
            | "regionkey"
            | "regionname"
            | "tenantname"
            | "tenantid"
        >
    >;
};

export type PropagateChangesMutationVariables = Exact<{
    tenantId: Scalars["TenantId"];
    regionId: Scalars["RegionId"];
}>;

export type PropagateChangesMutation = { __typename?: "Mutation" } & {
    createCommunitiesInRegion: { __typename?: "CommunityUpdateResult" } & {
        propagations: Array<
            { __typename?: "PropagationResult" } & Pick<
                PropagationResult,
                | "regionLicenseMapId"
                | "communitiesCreated"
                | "communitiesMatched"
                | "communitiesWithExistingLinks"
            >
        >;
    };
};

export type CreateOrUpdateRegionLicenseMapMutationVariables = Exact<{
    regionLicenseMap: RegionLicenseMapUpdateRequest;
}>;

export type CreateOrUpdateRegionLicenseMapMutation = { __typename?: "Mutation" } & {
    createOrUpdateRegionLicenseMap: { __typename?: "RegionLicenseMap" } & Pick<
        RegionLicenseMap,
        "id"
    >;
};

export type DeleteRegionLicenseMapMutationVariables = Exact<{
    id: Scalars["RegionLicenseMapId"];
}>;

export type DeleteRegionLicenseMapMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteRegionLicenseMap"
>;

export type GetEntityRegionPermissionsQueryVariables = Exact<{
    params: GetEntityRegionPermissionsParams;
}>;

export type GetEntityRegionPermissionsQuery = { __typename?: "Query" } & {
    getEntityRegionPermissions: { __typename?: "EntityRegionPermissions" } & {
        editableRegions: Array<
            { __typename?: "EntityRegionPermission" } & Pick<
                EntityRegionPermission,
                "id" | "key" | "name"
            >
        >;
    };
};

export type ClientPlacementRevenueQueryVariables = Exact<{
    startDate: Scalars["String"];
    endDate: Scalars["String"];
    region: Scalars["String"];
}>;

export type ClientPlacementRevenueQuery = { __typename?: "Query" } & {
    reportClientPlacementRevenue: { __typename?: "ClientPlacementRevenueResult" } & {
        segments: Array<
            { __typename?: "ReportSegment" } & Pick<
                ReportSegment,
                "startDate" | "revenue" | "moves"
            > & {
                    users: Array<
                        { __typename?: "UserReportSegment" } & Pick<
                            UserReportSegment,
                            "revenue" | "moves"
                        > & {
                                user?: Maybe<
                                    { __typename?: "ConcreteUser" } & Pick<
                                        ConcreteUser,
                                        "id" | "displayName"
                                    >
                                >;
                            }
                    >;
                }
        >;
    };
};

export type InboundReferralsQueryVariables = Exact<{
    startDate: Scalars["String"];
    endDate: Scalars["String"];
    region: Scalars["String"];
}>;

export type InboundReferralsQuery = { __typename?: "Query" } & {
    reportInboundReferrals: { __typename?: "InboundReferralsResult" } & {
        generic: Array<
            { __typename?: "InboundReferralsGenericReferral" } & Pick<
                InboundReferralsGenericReferral,
                "referralToken" | "count"
            >
        >;
        businesses: Array<
            { __typename?: "InboundReferralsBusinessReferral" } & Pick<
                InboundReferralsBusinessReferral,
                "count"
            > & {
                    business: { __typename?: "ReferenceBusiness" } & Pick<
                        ReferenceBusiness,
                        "id"
                    > & {
                            businessContactEntity?: Maybe<
                                { __typename?: "ContactEntity" } & Pick<
                                    ContactEntity,
                                    "name" | "contactType"
                                >
                            >;
                        };
                }
        >;
        contacts: Array<
            { __typename?: "InboundReferralsContactReferral" } & Pick<
                InboundReferralsContactReferral,
                "count"
            > & {
                    contact: { __typename?: "ReferenceContact" } & Pick<
                        ReferenceContact,
                        "id"
                    > & {
                            contactEntity?: Maybe<
                                { __typename?: "ContactEntity" } & Pick<
                                    ContactEntity,
                                    "name"
                                >
                            >;
                        };
                }
        >;
    };
};

export type ReferralsQueryVariables = Exact<{
    startDate: Scalars["String"];
    endDate: Scalars["String"];
    region: Scalars["String"];
}>;

export type ReferralsQuery = { __typename?: "Query" } & {
    reportReferrals: { __typename?: "ReferralsResult" } & {
        generic: Array<
            { __typename?: "InboundReferralsGenericReferral" } & Pick<
                InboundReferralsGenericReferral,
                "referralToken" | "count"
            >
        >;
        businesses: Array<
            { __typename?: "ReferralsBusinessReferral" } & Pick<
                ReferralsBusinessReferral,
                "inboundCount" | "outboundCount"
            > & {
                    business: { __typename?: "ReferenceBusiness" } & Pick<
                        ReferenceBusiness,
                        "id"
                    > & {
                            businessContactEntity?: Maybe<
                                { __typename?: "ContactEntity" } & Pick<
                                    ContactEntity,
                                    "name" | "contactType"
                                >
                            >;
                        };
                }
        >;
        contacts: Array<
            { __typename?: "ReferralsContactReferral" } & Pick<
                ReferralsContactReferral,
                "inboundCount" | "outboundCount"
            > & {
                    contact: { __typename?: "ReferenceContact" } & Pick<
                        ReferenceContact,
                        "id"
                    > & {
                            contactEntity?: Maybe<
                                { __typename?: "ContactEntity" } & Pick<
                                    ContactEntity,
                                    "name"
                                >
                            >;
                        };
                }
        >;
    };
};

export type GetSignatureTemplateQueryVariables = Exact<{
    fileId: Scalars["FileId"];
}>;

export type GetSignatureTemplateQuery = { __typename?: "Query" } & {
    getFile: { __typename?: "File" } & Pick<File, "originalFilename">;
    getSignatureTemplate?: Maybe<
        { __typename?: "SignatureTemplate" } & Pick<
            SignatureTemplate,
            "id" | "recipientCount"
        > & {
                emailTemplate?: Maybe<
                    { __typename?: "EmailTemplate" } & Pick<
                        EmailTemplate,
                        "subject" | "body" | "attachments"
                    >
                >;
                signatureTemplateFields: Array<
                    { __typename?: "SignatureTemplateField" } & Pick<
                        SignatureTemplateField,
                        | "defaultTextValue"
                        | "defaultBooleanValue"
                        | "isRequired"
                        | "placeholder"
                        | "fieldType"
                        | "font"
                        | "fontSize"
                        | "height"
                        | "id"
                        | "order"
                        | "page"
                        | "recipientIndex"
                        | "width"
                        | "x"
                        | "y"
                    >
                >;
            }
    >;
};

export type SetSignatureTemplateMutationVariables = Exact<{
    fileId: Scalars["FileId"];
    signatureTemplate: SignatureTemplateInput;
}>;

export type SetSignatureTemplateMutation = { __typename?: "Mutation" } & {
    setSignatureTemplate: { __typename?: "SignatureTemplate" } & Pick<
        SignatureTemplate,
        "id"
    >;
};

export type GetSignableTemplatesQueryVariables = Exact<{
    entityType: FileEntityType;
}>;

export type GetSignableTemplatesQuery = { __typename?: "Query" } & {
    getSignableTemplates: Array<
        { __typename?: "File" } & Pick<File, "originalFilename" | "id">
    >;
};

export type GetSignatureRequestsForEntityQueryVariables = Exact<{
    entityId: Scalars["FileEntityId"];
}>;

export type GetSignatureRequestsForEntityQuery = { __typename?: "Query" } & {
    getSignatureRequestsForEntity: Array<
        { __typename?: "SignatureRequest" } & Pick<
            SignatureRequest,
            | "id"
            | "createdAt"
            | "updatedAt"
            | "filename"
            | "sourceFileId"
            | "signatureTemplateFileId"
            | "state"
        > & {
                signatureRequestRecipients: Array<
                    { __typename?: "SignatureRequestRecipient" } & Pick<
                        SignatureRequestRecipient,
                        "email" | "isComplete" | "id" | "order" | "remindAfter"
                    >
                >;
            }
    >;
};

export type GetSignatureRequestQueryVariables = Exact<{
    signatureRequestId: Scalars["SignatureRequestId"];
}>;

export type GetSignatureRequestQuery = { __typename?: "Query" } & {
    getSignatureRequest: { __typename?: "SignatureRequest" } & Pick<
        SignatureRequest,
        | "id"
        | "createdAt"
        | "updatedAt"
        | "filename"
        | "sourceFileId"
        | "signatureTemplateFileId"
        | "state"
    > & {
            signatureRequestFields: Array<
                { __typename?: "SignatureRequestField" } & Pick<
                    SignatureRequestField,
                    | "id"
                    | "page"
                    | "recipientIndex"
                    | "order"
                    | "x"
                    | "y"
                    | "width"
                    | "height"
                    | "font"
                    | "fontSize"
                    | "fieldType"
                    | "textValue"
                    | "booleanValue"
                    | "isRequired"
                    | "placeholder"
                    | "filledBy"
                >
            >;
            signatureRequestRecipients: Array<
                { __typename?: "SignatureRequestRecipient" } & Pick<
                    SignatureRequestRecipient,
                    | "id"
                    | "order"
                    | "remindAfter"
                    | "remindersSent"
                    | "createdAt"
                    | "updatedAt"
                    | "completedAt"
                    | "email"
                    | "name"
                    | "isComplete"
                >
            >;
            ccRecipients: Array<
                { __typename?: "SignatureRequestCcRecipient" } & Pick<
                    SignatureRequestCcRecipient,
                    "email" | "name"
                >
            >;
        };
};

export type PlaceholderPerspectiveQueryVariables = Exact<{ [key: string]: never }>;

export type PlaceholderPerspectiveQuery = { __typename?: "Query" } & {
    placeholderPerspective: { __typename?: "SignatureRequestPerspective" } & Pick<
        SignatureRequestPerspective,
        "recipientIndex"
    >;
};

export type CreateSignatureRequestMutationVariables = Exact<{
    signatureRequestPayload: SignatureRequestPayload;
}>;

export type CreateSignatureRequestMutation = { __typename?: "Mutation" } & {
    createSignatureRequest: { __typename?: "SignatureRequest" } & Pick<
        SignatureRequest,
        "id"
    > & {
            signatureRequestRecipients: Array<
                { __typename?: "SignatureRequestRecipient" } & Pick<
                    SignatureRequestRecipient,
                    "id"
                >
            >;
        };
};

export type GetDraftSignatureRequestQueryVariables = Exact<{
    entityId: Scalars["FileEntityId"];
    fileId: Scalars["FileId"];
    signatureRequestId?: Maybe<Scalars["SignatureRequestId"]>;
}>;

export type GetDraftSignatureRequestQuery = { __typename?: "Query" } & {
    getFile: { __typename?: "File" } & Pick<File, "originalFilename">;
    getDraftSignatureRequest: { __typename?: "DraftSignatureRequest" } & Pick<
        DraftSignatureRequest,
        "entityType"
    > & {
            potentialRecipients: Array<
                { __typename?: "SignaturePotentialRecipient" } & Pick<
                    SignaturePotentialRecipient,
                    "name" | "email" | "source"
                >
            >;
            signatureTemplate: { __typename?: "SignatureTemplate" } & Pick<
                SignatureTemplate,
                "id" | "recipientCount"
            > & {
                    signatureTemplateFields: Array<
                        { __typename?: "SignatureTemplateField" } & Pick<
                            SignatureTemplateField,
                            | "isRequired"
                            | "defaultTextValue"
                            | "defaultBooleanValue"
                            | "placeholder"
                            | "fieldType"
                            | "font"
                            | "fontSize"
                            | "height"
                            | "id"
                            | "order"
                            | "page"
                            | "recipientIndex"
                            | "width"
                            | "x"
                            | "y"
                        >
                    >;
                };
            actualRecipients: Array<
                { __typename?: "SignatureRequestRecipientPayloadResponse" } & Pick<
                    SignatureRequestRecipientPayloadResponse,
                    "name" | "email"
                >
            >;
            draftFields?: Maybe<
                Array<
                    { __typename?: "PrefilledSignatureRequestFieldPayload" } & Pick<
                        PrefilledSignatureRequestFieldPayload,
                        | "signatureTemplateFieldId"
                        | "textValue"
                        | "booleanValue"
                        | "placeholder"
                        | "isRequired"
                    >
                >
            >;
            emailTemplate: { __typename?: "DraftSignatureRequestEmailContent" } & Pick<
                DraftSignatureRequestEmailContent,
                "subject" | "body" | "attachments"
            >;
            ccRecipients: Array<
                { __typename?: "SignatureRequestCcRecipient" } & Pick<
                    SignatureRequestCcRecipient,
                    "name" | "email"
                >
            >;
        };
};

export type ResendSignatureRequestMutationVariables = Exact<{
    signatureRequestId: Scalars["SignatureRequestId"];
}>;

export type ResendSignatureRequestMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "resendSignatureRequest"
>;

export type AbandonSignatureRequestMutationVariables = Exact<{
    signatureRequestId: Scalars["SignatureRequestId"];
}>;

export type AbandonSignatureRequestMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "abandonSignatureRequest"
>;

export type CreateSmtpCredentialMutationVariables = Exact<{
    params: CreateSmtpCredentialRequest;
}>;

export type CreateSmtpCredentialMutation = { __typename?: "Mutation" } & {
    createSmtpCredential: { __typename?: "SmtpSaveResult" } & Pick<
        SmtpSaveResult,
        "success" | "message"
    >;
};

export type GetSmtpCredentialForUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetSmtpCredentialForUserQuery = { __typename?: "Query" } & {
    getSmtpCredentialForUser?: Maybe<
        { __typename?: "SmtpCredential" } & SmtpCredentialBasicsFragment
    >;
};

export type DeleteSmtpCredentialForUserMutationVariables = Exact<{
    [key: string]: never;
}>;

export type DeleteSmtpCredentialForUserMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteSmtpCredentialForUser"
>;

export type SmtpCredentialBasicsFragment = { __typename?: "SmtpCredential" } & Pick<
    SmtpCredential,
    "id" | "host" | "port" | "username" | "status"
>;

export type SystemQueryVariables = Exact<{ [key: string]: never }>;

export type SystemQuery = { __typename?: "Query" } & {
    jobStats: { __typename?: "JobStats" } & Pick<
        JobStats,
        "complete" | "pending" | "available" | "started" | "failed"
    >;
    systemConfiguration: { __typename?: "SystemConfiguration" } & Pick<
        SystemConfiguration,
        "fileStorage"
    >;
};

export type GetTaskQueryVariables = Exact<{
    regionId: Scalars["RegionId"];
    taskId: Scalars["TaskId"];
}>;

export type GetTaskQuery = { __typename?: "Query" } & {
    getTask: { __typename?: "PopulatedTask" } & {
        task: { __typename?: "Task" } & TaskBasicsFragment;
        entity:
            | ({ __typename?: "ClientEntity" } & Pick<
                  ClientEntity,
                  "displayName" | "path" | "kind"
              >)
            | ({ __typename?: "CommunityEntity" } & Pick<
                  CommunityEntity,
                  "displayName" | "path" | "kind"
              >)
            | ({ __typename?: "ReferenceBusinessEntity" } & Pick<
                  ReferenceBusinessEntity,
                  "displayName" | "path" | "kind"
              >)
            | ({ __typename?: "ReferenceContactEntity" } & Pick<
                  ReferenceContactEntity,
                  "displayName" | "path" | "kind"
              >);
    };
};

export type GetTasksQueryVariables = Exact<{
    activeOnly: Scalars["Boolean"];
    regionId: Scalars["ID"];
    page: Scalars["Int"];
}>;

export type GetTasksQuery = { __typename?: "Query" } & {
    getTasks: { __typename?: "PopulatedTaskSet" } & Pick<
        PopulatedTaskSet,
        "page" | "totalPages"
    > & {
            tasks: Array<
                { __typename?: "PopulatedTask" } & {
                    task: { __typename?: "Task" } & TaskBasicsFragment;
                    entity:
                        | ({ __typename?: "ClientEntity" } & Pick<
                              ClientEntity,
                              "displayName" | "path" | "kind"
                          >)
                        | ({ __typename?: "CommunityEntity" } & Pick<
                              CommunityEntity,
                              "displayName" | "path" | "kind"
                          >)
                        | ({ __typename?: "ReferenceBusinessEntity" } & Pick<
                              ReferenceBusinessEntity,
                              "displayName" | "path" | "kind"
                          >)
                        | ({ __typename?: "ReferenceContactEntity" } & Pick<
                              ReferenceContactEntity,
                              "displayName" | "path" | "kind"
                          >);
                }
            >;
        };
};

export type GetTasksv2QueryVariables = Exact<{
    params: GetTasksRequest;
}>;

export type GetTasksv2Query = { __typename?: "Query" } & {
    getTasksv2: Array<
        { __typename?: "PopulatedTask" } & {
            task: { __typename?: "Task" } & TaskBasicsFragment;
            entity:
                | ({ __typename?: "ClientEntity" } & Pick<
                      ClientEntity,
                      "displayName" | "path" | "kind"
                  > & {
                          contact?: Maybe<
                              { __typename?: "CrmEntityContact" } & Pick<
                                  CrmEntityContact,
                                  "name" | "phoneNumber" | "contactType"
                              >
                          >;
                      })
                | ({ __typename?: "CommunityEntity" } & Pick<
                      CommunityEntity,
                      "displayName" | "path" | "kind"
                  > & {
                          contact?: Maybe<
                              { __typename?: "CrmEntityContact" } & Pick<
                                  CrmEntityContact,
                                  "name" | "phoneNumber" | "contactType"
                              >
                          >;
                      })
                | ({ __typename?: "ReferenceBusinessEntity" } & Pick<
                      ReferenceBusinessEntity,
                      "displayName" | "path" | "kind"
                  > & {
                          contact?: Maybe<
                              { __typename?: "CrmEntityContact" } & Pick<
                                  CrmEntityContact,
                                  "name" | "phoneNumber" | "contactType"
                              >
                          >;
                      })
                | ({ __typename?: "ReferenceContactEntity" } & Pick<
                      ReferenceContactEntity,
                      "displayName" | "path" | "kind"
                  > & {
                          contact?: Maybe<
                              { __typename?: "CrmEntityContact" } & Pick<
                                  CrmEntityContact,
                                  "name" | "phoneNumber" | "contactType"
                              >
                          >;
                      });
        }
    >;
};

export type GetNextTasksForParentIdsQueryVariables = Exact<{
    parentIds: Array<Scalars["TaskParentId"]> | Scalars["TaskParentId"];
}>;

export type GetNextTasksForParentIdsQuery = { __typename?: "Query" } & {
    getNextTasksForParentIds: Array<{ __typename?: "Task" } & TaskBasicsFragment>;
};

export type GetTasksForEntityQueryVariables = Exact<{
    parentId: Scalars["TaskParentId"];
}>;

export type GetTasksForEntityQuery = { __typename?: "Query" } & {
    getTasksForEntity: Array<{ __typename?: "Task" } & TaskDetailsFragment>;
};

export type CreateTaskMutationVariables = Exact<{
    parentId: Scalars["TaskParentId"];
    params: CreateTaskPayload;
}>;

export type CreateTaskMutation = { __typename?: "Mutation" } & {
    createTask: { __typename?: "Task" } & TaskDetailsFragment;
};

export type UpdateTaskMutationVariables = Exact<{
    taskId: Scalars["TaskId"];
    params: UpdateTaskPayload;
}>;

export type UpdateTaskMutation = { __typename?: "Mutation" } & {
    updateTask: { __typename?: "Task" } & TaskDetailsFragment;
};

export type DeleteTaskMutationVariables = Exact<{
    taskId: Scalars["TaskId"];
}>;

export type DeleteTaskMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteTask"
>;

export type TaskBasicsFragment = { __typename?: "Task" } & Pick<
    Task,
    | "id"
    | "completedAt"
    | "dueDate"
    | "dueDateTime"
    | "ownerId"
    | "parentId"
    | "status"
    | "text"
    | "done"
    | "taskTypeId"
> & { taskType?: Maybe<{ __typename?: "TaskType" } & Pick<TaskType, "id" | "name">> };

export type TaskDetailsFragment = { __typename?: "Task" } & Pick<Task, "notes"> &
    TaskBasicsFragment;

export type GetTaskTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTaskTypesQuery = { __typename?: "Query" } & {
    getTaskTypes: Array<
        { __typename?: "TaskType" } & Pick<TaskType, "id" | "name" | "isArchived">
    >;
};

export type CreateTaskTypeMutationVariables = Exact<{
    payload: CreateTaskTypePayload;
}>;

export type CreateTaskTypeMutation = { __typename?: "Mutation" } & {
    createTaskType: { __typename?: "TaskType" } & Pick<TaskType, "id">;
};

export type UpdateTaskTypeMutationVariables = Exact<{
    payload: UpdateTaskTypePayload;
}>;

export type UpdateTaskTypeMutation = { __typename?: "Mutation" } & {
    updateTaskType: { __typename?: "TaskType" } & Pick<TaskType, "id">;
};

export type GetTemplateQueryVariables = Exact<{
    id: Scalars["EmailTemplateId"];
}>;

export type GetTemplateQuery = { __typename?: "Query" } & {
    getTemplate: { __typename?: "TemplateResponse" } & Pick<
        TemplateResponse,
        "isEditable" | "isDefaultSignature"
    > & {
            template: { __typename?: "EmailTemplate" } & Pick<
                EmailTemplate,
                | "id"
                | "body"
                | "description"
                | "isShared"
                | "type"
                | "subject"
                | "migrationVersion"
                | "attachments"
            > & {
                    regionVisibility?: Maybe<
                        { __typename?: "EntityRegionVisibility" } & Pick<
                            EntityRegionVisibility,
                            "editable" | "regions"
                        >
                    >;
                };
            author: { __typename?: "User" } & Pick<User, "name" | "email">;
        };
};

export type GetTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTemplatesQuery = { __typename?: "Query" } & {
    getTemplates: { __typename?: "TemplateList" } & {
        templates: Array<
            { __typename?: "TemplateResponse" } & Pick<
                TemplateResponse,
                "isEditable" | "isDefaultSignature"
            > & {
                    template: { __typename?: "EmailTemplate" } & Pick<
                        EmailTemplate,
                        | "id"
                        | "body"
                        | "description"
                        | "isShared"
                        | "type"
                        | "subject"
                        | "migrationVersion"
                        | "attachments"
                    > & {
                            regionVisibility?: Maybe<
                                { __typename?: "EntityRegionVisibility" } & Pick<
                                    EntityRegionVisibility,
                                    "editable" | "regions"
                                >
                            >;
                        };
                    author: { __typename?: "User" } & Pick<User, "name" | "email">;
                }
        >;
    };
};

export type CanCreateSignatureTemplateQueryVariables = Exact<{
    fileId: Scalars["FileId"];
}>;

export type CanCreateSignatureTemplateQuery = { __typename?: "Query" } & Pick<
    Query,
    "canCreateSignatureTemplate"
>;

export type DeleteSignatureTemplateMutationVariables = Exact<{
    signatureTemplateId: Scalars["SignatureTemplateId"];
}>;

export type DeleteSignatureTemplateMutation = { __typename?: "Mutation" } & {
    deleteSignatureTemplate: { __typename?: "SignatureTemplateDeleteResponse" } & Pick<
        SignatureTemplateDeleteResponse,
        "success" | "message"
    >;
};

export type CreateOrUpdateTemplateMutationVariables = Exact<{
    template: TemplateUpdate;
}>;

export type CreateOrUpdateTemplateMutation = { __typename?: "Mutation" } & {
    createOrUpdateTemplate: { __typename?: "TemplateResponse" } & {
        template: { __typename?: "EmailTemplate" } & Pick<EmailTemplate, "id">;
    };
};

export type DeleteTemplateMutationVariables = Exact<{
    id: Scalars["EmailTemplateId"];
}>;

export type DeleteTemplateMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteTemplate"
>;

export type GetTenantCredentialsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTenantCredentialsQuery = { __typename?: "Query" } & {
    getTenantCredentials: Array<
        { __typename?: "TenantCredential" } & Pick<
            TenantCredential,
            "id" | "type" | "status"
        > & {
                quickBooksDetails?: Maybe<
                    { __typename?: "QuickBooksDetails" } & Pick<
                        QuickBooksDetails,
                        "realmId"
                    >
                >;
            }
    >;
};

export type DeleteTenantCredentialMutationVariables = Exact<{
    id: Scalars["TenantCredentialId"];
}>;

export type DeleteTenantCredentialMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteTenantCredential"
>;

export type CreateTenantRegionMutationVariables = Exact<{
    tenantId: Scalars["TenantId"];
    name: Scalars["String"];
    key: Scalars["String"];
}>;

export type CreateTenantRegionMutation = { __typename?: "Mutation" } & {
    createTenantRegion: { __typename?: "Tenant" } & Pick<Tenant, "id">;
};

export type CreateTenantMutationVariables = Exact<{ [key: string]: never }>;

export type CreateTenantMutation = { __typename?: "Mutation" } & {
    createTenant: { __typename?: "Tenant" } & Pick<Tenant, "id">;
};

export type UpdateTenantMutationVariables = Exact<{
    id: Scalars["TenantId"];
    name?: Maybe<Scalars["String"]>;
    dateAdded?: Maybe<Scalars["String"]>;
    features?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
    sku?: Maybe<Scalars["String"]>;
    billing?: Maybe<TenantBillingPayloadInput>;
    customDomains?: Maybe<Array<Scalars["String"]> | Scalars["String"]>;
    activatedAt?: Maybe<Scalars["String"]>;
    deactivatedAt?: Maybe<Scalars["String"]>;
    onboardingStartedAt?: Maybe<Scalars["String"]>;
    demoStartedAt?: Maybe<Scalars["String"]>;
    demoEndedAt?: Maybe<Scalars["String"]>;
    premiumActivatedAt?: Maybe<Scalars["String"]>;
    premiumDeactivatedAt?: Maybe<Scalars["String"]>;
    emailProvider?: Maybe<ExternalEmailProvider>;
    internalEmailSender?: Maybe<Scalars["String"]>;
    supportEmailAddress?: Maybe<Scalars["String"]>;
    customProductName?: Maybe<Scalars["String"]>;
    customAppDomain?: Maybe<Scalars["String"]>;
    whiteLabelContentMode?: Maybe<WhiteLabelContentMode>;
}>;

export type UpdateTenantMutation = { __typename?: "Mutation" } & {
    updateTenant: { __typename?: "Tenant" } & Pick<Tenant, "id">;
};

export type AddTenantLogEntryMutationVariables = Exact<{
    tenantId: Scalars["TenantId"];
    message: Scalars["String"];
}>;

export type AddTenantLogEntryMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "tenantInternalUpdate"
>;

export type UpdateTenantInternalAttributesMutationVariables = Exact<{
    tenantId: Scalars["TenantId"];
    internalAttributes: DictionaryInput;
}>;

export type UpdateTenantInternalAttributesMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "tenantInternalUpdate"
>;

export type UpdateRegionNameMutationVariables = Exact<{
    tenantId: Scalars["TenantId"];
    regionId: Scalars["RegionId"];
    name: Scalars["String"];
}>;

export type UpdateRegionNameMutation = { __typename?: "Mutation" } & {
    updateRegion: { __typename?: "Region" } & Pick<Region, "id">;
};

export type ModifyUserRoleMutationVariables = Exact<{
    tenantId: Scalars["TenantId"];
    userId: Scalars["UserId"];
    regionKey: Scalars["ID"];
    role: Scalars["String"];
}>;

export type ModifyUserRoleMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "modifyUserRole"
>;

export type GetTenantsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTenantsQuery = { __typename?: "Query" } & {
    getTenants: Array<
        { __typename?: "Tenant" } & Pick<
            Tenant,
            "id" | "name" | "activeUsers" | "activeHumanUsers" | "sku"
        >
    >;
};

export type GetTenantQueryVariables = Exact<{
    id: Scalars["TenantId"];
}>;

export type GetTenantQuery = { __typename?: "Query" } & {
    getTenant: { __typename?: "Tenant" } & Pick<
        Tenant,
        | "id"
        | "name"
        | "dateAdded"
        | "sku"
        | "activatedAt"
        | "deactivatedAt"
        | "onboardingStartedAt"
        | "demoStartedAt"
        | "demoEndedAt"
        | "premiumActivatedAt"
        | "premiumDeactivatedAt"
        | "customDomains"
        | "emailProvider"
        | "internalEmailSender"
        | "supportEmailAddress"
        | "customProductName"
        | "customAppDomain"
        | "whiteLabelContentMode"
    > & {
            billing: { __typename?: "TenantBillingPayload" } & Pick<
                TenantBillingPayload,
                | "billingContacts"
                | "effectiveMonthlyRateCents"
                | "monthlyRateCents"
                | "perLocationRateCents"
                | "perUserRateCents"
                | "ratePlan"
                | "ratePlanEstablished"
            >;
            users: Array<
                { __typename?: "User" } & Pick<
                    User,
                    "id" | "name" | "email" | "disabled" | "hidden"
                >
            >;
            features: Array<
                { __typename?: "TenantFeature" } & Pick<
                    TenantFeature,
                    "name" | "enabled" | "enabledUpstream"
                >
            >;
            regions: Array<
                { __typename?: "Region" } & Pick<Region, "id" | "name" | "key"> & {
                        permissions: Array<
                            { __typename?: "PermissionRecord" } & Pick<
                                PermissionRecord,
                                "role"
                            > & {
                                    user: { __typename?: "User" } & Pick<
                                        User,
                                        "id" | "name" | "email" | "disabled" | "hidden"
                                    >;
                                }
                        >;
                    }
            >;
            internalAttributes?: Maybe<
                { __typename?: "Dictionary" } & {
                    entries: Array<
                        { __typename?: "DictionaryEntry" } & Pick<
                            DictionaryEntry,
                            "key" | "value"
                        >
                    >;
                }
            >;
        };
    getGlobalFeatures: { __typename?: "GlobalFeaturesResponse" } & Pick<
        GlobalFeaturesResponse,
        "skus"
    > & {
            features: Array<
                { __typename?: "FeatureDescription" } & Pick<
                    FeatureDescription,
                    | "skus"
                    | "key"
                    | "friendlyTitle"
                    | "description"
                    | "createdOn"
                    | "intent"
                > & {
                        actual: { __typename?: "FeatureFlag" } & Pick<
                            FeatureFlag,
                            "name" | "enabled"
                        >;
                    }
            >;
        };
};

export type GetTenantHistoryQueryVariables = Exact<{
    tenantId: Scalars["TenantId"];
}>;

export type GetTenantHistoryQuery = { __typename?: "Query" } & {
    getTenantHistory: Array<
        { __typename?: "TenantHistory" } & Pick<
            TenantHistory,
            "id" | "message" | "dateAdded"
        > & { author: { __typename?: "User" } & Pick<User, "preferredName"> }
    >;
};

export type GetImportsQueryVariables = Exact<{
    tenantId: Scalars["TenantId"];
}>;

export type GetImportsQuery = { __typename?: "Query" } & {
    getImports: Array<
        { __typename?: "Import" } & Pick<
            Import,
            "id" | "name" | "status" | "dateAdded" | "runAs" | "regionId"
        >
    >;
};

export type GetImportQueryVariables = Exact<{
    tenantId: Scalars["TenantId"];
    importId: Scalars["ImportId"];
}>;

export type GetImportQuery = { __typename?: "Query" } & {
    getImport: { __typename?: "Import" } & Pick<
        Import,
        "id" | "name" | "status" | "dateAdded" | "runAs" | "regionId"
    > & {
            plan?: Maybe<
                { __typename?: "ImportPlan" } & {
                    inserts: Array<
                        { __typename?: "ImportPlanInsert" } & Pick<
                            ImportPlanInsert,
                            "entityType" | "records"
                        >
                    >;
                    errors?: Maybe<
                        Array<
                            { __typename?: "ImportPlanError" } & Pick<
                                ImportPlanError,
                                "rowNumber" | "message" | "sheetName"
                            >
                        >
                    >;
                }
            >;
            result?: Maybe<
                { __typename?: "ImportResult" } & Pick<ImportResult, "error"> & {
                        results: Array<
                            { __typename?: "ImportEntityResult" } & Pick<
                                ImportEntityResult,
                                "entityType"
                            > & {
                                    inserts: Array<
                                        { __typename?: "ImportResultInsert" } & Pick<
                                            ImportResultInsert,
                                            "rowNumber" | "insertedId" | "warnings"
                                        >
                                    >;
                                    errors: Array<
                                        { __typename?: "ImportResultError" } & Pick<
                                            ImportResultError,
                                            "rowNumber" | "message" | "sheetName"
                                        >
                                    >;
                                }
                        >;
                    }
            >;
        };
};

export type ScheduleImportMutationVariables = Exact<{
    tenantId: Scalars["TenantId"];
    importId: Scalars["ImportId"];
}>;

export type ScheduleImportMutation = { __typename?: "Mutation" } & {
    scheduleImport: { __typename?: "Import" } & Pick<Import, "id" | "status">;
};

export type ResetTenantMutationVariables = Exact<{
    options: ResetTenantOptions;
}>;

export type ResetTenantMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "resetTenant"
>;

export type GetTokenForClientQueryVariables = Exact<{
    id: Scalars["ClientId"];
}>;

export type GetTokenForClientQuery = { __typename?: "Query" } & {
    getTokenForClient: { __typename?: "Token" } & Pick<
        Token,
        | "id"
        | "clientId"
        | "url"
        | "includeContacts"
        | "includeFinancial"
        | "includeLicensing"
        | "sectionKeys"
    >;
};

export type UpdateTokenMutationVariables = Exact<{
    updateRequest: UpdateTokenRequest;
}>;

export type UpdateTokenMutation = { __typename?: "Mutation" } & {
    updateToken: { __typename?: "Token" } & Pick<
        Token,
        | "id"
        | "clientId"
        | "url"
        | "includeContacts"
        | "includeFinancial"
        | "includeLicensing"
        | "sectionKeys"
    >;
};

export type GetUserGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserGroupsQuery = { __typename?: "Query" } & {
    getUserGroups: Array<
        { __typename?: "UserGroupResult" } & Pick<UserGroupResult, "id" | "name">
    >;
};

export type UserManagementGetRolesQueryVariables = Exact<{ [key: string]: never }>;

export type UserManagementGetRolesQuery = { __typename?: "Query" } & {
    userManagementGetRoles: Array<
        { __typename?: "RoleDescription" } & Pick<
            RoleDescription,
            "key" | "humanReadableName"
        >
    >;
};

export type UserManagementGetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type UserManagementGetUsersQuery = { __typename?: "Query" } & {
    userManagementGetUsers: Array<
        { __typename?: "ManageableUser" } & {
            user: { __typename?: "User" } & Pick<
                User,
                "id" | "type" | "email" | "name" | "hidden" | "disabled"
            > & {
                    permissions: Array<
                        { __typename?: "Permission" } & Pick<Permission, "role"> & {
                                region: { __typename?: "Region" } & Pick<
                                    Region,
                                    "id" | "name" | "key"
                                >;
                            }
                    >;
                };
        }
    >;
};

export type UserManagementGetIntegrationUsersQueryVariables = Exact<{
    [key: string]: never;
}>;

export type UserManagementGetIntegrationUsersQuery = { __typename?: "Query" } & {
    userManagementGetUsers: Array<
        { __typename?: "ManageableUser" } & {
            user: { __typename?: "User" } & Pick<
                User,
                "id" | "type" | "name" | "hidden" | "disabled"
            > & {
                    permissions: Array<
                        { __typename?: "Permission" } & Pick<Permission, "role"> & {
                                region: { __typename?: "Region" } & Pick<
                                    Region,
                                    "id" | "name" | "key"
                                >;
                            }
                    >;
                };
        }
    >;
};

export type UserManagementGetUserQueryVariables = Exact<{
    userId: Scalars["UserId"];
}>;

export type UserManagementGetUserQuery = { __typename?: "Query" } & {
    userManagementGetUser: { __typename?: "ManageableUser" } & Pick<
        ManageableUser,
        "canDisable"
    > & {
            user: { __typename?: "User" } & Pick<
                User,
                "id" | "email" | "name" | "type" | "hidden" | "disabled"
            > & {
                    permissions: Array<
                        { __typename?: "Permission" } & Pick<Permission, "role"> & {
                                region: { __typename?: "Region" } & Pick<
                                    Region,
                                    "id" | "name" | "key"
                                >;
                            }
                    >;
                };
        };
};

export type UserManagementGetApiKeyQueryVariables = Exact<{
    userId: Scalars["UserId"];
}>;

export type UserManagementGetApiKeyQuery = { __typename?: "Query" } & {
    userManagementGetApiKey: { __typename?: "GetApiKeyResponse" } & Pick<
        GetApiKeyResponse,
        "apiKey"
    >;
};

export type UserManagementCreateUserMutationVariables = Exact<{
    userEmail: Scalars["String"];
    roleKey: Scalars["String"];
    regionId: Scalars["RegionId"];
}>;

export type UserManagementCreateUserMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "userManagementCreateUser"
>;

export type UserManagementCreateSystemUserMutationVariables = Exact<{
    name: Scalars["String"];
    roleKey: Scalars["String"];
    regionId: Scalars["RegionId"];
}>;

export type UserManagementCreateSystemUserMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "userManagementCreateSystemUser"
>;

export type UserManagementUpdatePermissionMutationVariables = Exact<{
    userId: Scalars["UserId"];
    regionId: Scalars["RegionId"];
    roleKey?: Maybe<Scalars["String"]>;
}>;

export type UserManagementUpdatePermissionMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "userManagementUpdatePermission"
>;

export type UserManagementDisableUserMutationVariables = Exact<{
    userId: Scalars["UserId"];
}>;

export type UserManagementDisableUserMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "userManagementDisableUser"
>;

export type UserManagementEnableUserMutationVariables = Exact<{
    userId: Scalars["UserId"];
}>;

export type UserManagementEnableUserMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "userManagementEnableUser"
>;

export type UserManagementHideUserMutationVariables = Exact<{
    userId: Scalars["UserId"];
}>;

export type UserManagementHideUserMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "userManagementHideUser"
>;

export type UserManagementShowUserMutationVariables = Exact<{
    userId: Scalars["UserId"];
}>;

export type UserManagementShowUserMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "userManagementShowUser"
>;

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = { __typename?: "Query" } & {
    getUsers: Array<
        { __typename?: "User" } & Pick<
            User,
            "id" | "email" | "type" | "name" | "firstName" | "disabled" | "hidden"
        >
    >;
};

export type CreateUserMutationVariables = Exact<{
    tenantId: Scalars["TenantId"];
    email: Scalars["String"];
    password: Scalars["String"];
}>;

export type CreateUserMutation = { __typename?: "Mutation" } & {
    createUser: { __typename?: "User" } & Pick<User, "id" | "email">;
};

export type CloneUserMutationVariables = Exact<{
    tenantId: Scalars["TenantId"];
    userId: Scalars["UserId"];
}>;

export type CloneUserMutation = { __typename?: "Mutation" } & Pick<Mutation, "cloneUser">;

export type SuperuserUpdateUserMutationVariables = Exact<{
    userId: Scalars["UserId"];
    disabled: Scalars["Boolean"];
    hidden: Scalars["Boolean"];
    email: Scalars["String"];
    firstName?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
}>;

export type SuperuserUpdateUserMutation = { __typename?: "Mutation" } & {
    superuserUpdateUser: { __typename?: "User" } & Pick<User, "id">;
};

export type AdminUpdateUserMutationVariables = Exact<{
    userId: Scalars["UserId"];
    name: Scalars["String"];
}>;

export type AdminUpdateUserMutation = { __typename?: "Mutation" } & {
    adminUpdateUser: { __typename?: "User" } & Pick<User, "id">;
};

export type GetUserQueryVariables = Exact<{
    id: Scalars["UserId"];
}>;

export type GetUserQuery = { __typename?: "Query" } & {
    getUser: { __typename?: "User" } & Pick<
        User,
        "id" | "name" | "firstName" | "type" | "email" | "disabled" | "hidden" | "token"
    > & {
            tenant: { __typename?: "Tenant" } & Pick<Tenant, "id" | "name"> & {
                    regions: Array<
                        { __typename?: "Region" } & Pick<Region, "key" | "name"> & {
                                permissions: Array<
                                    { __typename?: "PermissionRecord" } & Pick<
                                        PermissionRecord,
                                        "role" | "userId"
                                    >
                                >;
                            }
                    >;
                    features: Array<
                        { __typename?: "TenantFeature" } & Pick<
                            TenantFeature,
                            "name" | "enabled" | "enabledUpstream"
                        >
                    >;
                };
        };
};

export type GetUserApiTokenQueryVariables = Exact<{
    id: Scalars["UserId"];
}>;

export type GetUserApiTokenQuery = { __typename?: "Query" } & {
    getUser: { __typename?: "User" } & Pick<User, "id" | "apiToken">;
};

export type GetRolesWithActionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRolesWithActionsQuery = { __typename?: "Query" } & {
    getRolesWithActions: Array<
        { __typename?: "RoleWithActions" } & Pick<
            RoleWithActions,
            "key" | "humanReadableName" | "userAssignable" | "actions"
        >
    >;
};

export type GetCalendarSubscriptionQueryVariables = Exact<{ [key: string]: never }>;

export type GetCalendarSubscriptionQuery = { __typename?: "Query" } & {
    getCalendarSubscription: { __typename?: "CalendarSubscription" } & Pick<
        CalendarSubscription,
        "calendarUrl"
    >;
};

export type GetUserTenantRegionOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserTenantRegionOptionsQuery = { __typename?: "Query" } & {
    getUserTenantRegionOptions: { __typename?: "UserTenantRegionOptions" } & {
        options: Array<
            { __typename?: "UserTenantRegionOption" } & Pick<
                UserTenantRegionOption,
                | "regionId"
                | "regionName"
                | "regionKey"
                | "tenantId"
                | "tenantName"
                | "userId"
            >
        >;
    };
};

export type GetUsersForRegionQueryVariables = Exact<{
    regionId: Scalars["RegionId"];
}>;

export type GetUsersForRegionQuery = { __typename?: "Query" } & {
    getUsersForRegion: Array<
        { __typename?: "UserDescriptor" } & Pick<
            UserDescriptor,
            "id" | "disabled" | "hidden" | "preferredName"
        >
    >;
};

export type WorkflowDefaultsFragment = { __typename?: "Workflow" } & Pick<
    Workflow,
    | "attachTo"
    | "createdBy"
    | "dateAdded"
    | "delay"
    | "delayRecipe"
    | "description"
    | "id"
    | "name"
    | "status"
    | "defaultActorId"
    | "actorMode"
    | "updatedAt"
    | "updatedBy"
> & {
        actions: Array<
            | ({ __typename: "WorkflowActionEmailEntity" } & Pick<
                  WorkflowActionEmailEntity,
                  "body" | "subject" | "to" | "attachments" | "bccMyself"
              >)
            | ({ __typename: "WorkflowActionTaskEntity" } & Pick<
                  WorkflowActionTaskEntity,
                  "dueDate" | "text" | "assignedTo" | "taskTypeId" | "notifyOwner"
              > & {
                      notification?: Maybe<
                          { __typename?: "WorkflowActionTaskNotification" } & Pick<
                              WorkflowActionTaskNotification,
                              "subject"
                          >
                      >;
                  })
        >;
        settings: Array<
            { __typename?: "WorkflowPayloadSettings" } & Pick<
                WorkflowPayloadSettings,
                "defaultValue" | "description" | "key" | "label" | "type" | "scope"
            >
        >;
        trigger: { __typename?: "WorkflowTriggerEntity" } & Pick<
            WorkflowTriggerEntity,
            "eventType"
        > & {
                entityUpdated?: Maybe<
                    { __typename?: "EntityUpdatedTriggerConfigurationEntity" } & {
                        fieldMatchers: Array<
                            { __typename?: "EntityUpdatedFieldMatcherEntity" } & Pick<
                                EntityUpdatedFieldMatcherEntity,
                                "fieldName"
                            > & {
                                    previous?: Maybe<
                                        {
                                            __typename?: "WorkflowTriggerFieldValueEntityValueType";
                                        } & Pick<
                                            WorkflowTriggerFieldValueEntityValueType,
                                            "values" | "specialValue"
                                        >
                                    >;
                                    current?: Maybe<
                                        {
                                            __typename?: "WorkflowTriggerFieldValueEntityValueType";
                                        } & Pick<
                                            WorkflowTriggerFieldValueEntityValueType,
                                            "values" | "specialValue"
                                        >
                                    >;
                                }
                        >;
                    }
                >;
                noteAdded?: Maybe<
                    { __typename?: "NoteTriggerConfigurationEntity" } & {
                        entityFieldMatchers: Array<
                            { __typename?: "TriggerFieldMatcherEntity" } & Pick<
                                TriggerFieldMatcherEntity,
                                "fieldName"
                            > & {
                                    current?: Maybe<
                                        {
                                            __typename?: "WorkflowTriggerFieldValueEntityValueType";
                                        } & Pick<
                                            WorkflowTriggerFieldValueEntityValueType,
                                            "values" | "specialValue"
                                        >
                                    >;
                                }
                        >;
                    }
                >;
                emailOpened?: Maybe<
                    { __typename?: "EmailOpenedTriggerConfigurationEntity" } & Pick<
                        EmailOpenedTriggerConfigurationEntity,
                        "activityThresholdDays"
                    > & {
                            entityFieldMatchers: Array<
                                { __typename?: "TriggerFieldMatcherEntity" } & Pick<
                                    TriggerFieldMatcherEntity,
                                    "fieldName"
                                > & {
                                        current?: Maybe<
                                            {
                                                __typename?: "WorkflowTriggerFieldValueEntityValueType";
                                            } & Pick<
                                                WorkflowTriggerFieldValueEntityValueType,
                                                "values" | "specialValue"
                                            >
                                        >;
                                    }
                            >;
                        }
                >;
                schedule?: Maybe<
                    { __typename?: "ScheduleTriggerConfigurationEntity" } & Pick<
                        ScheduleTriggerConfigurationEntity,
                        "cron"
                    > & {
                            entityCondition: {
                                __typename?: "AdvancedSearchCondition";
                            } & {
                                children?: Maybe<
                                    Array<
                                        { __typename?: "AdvancedSearchCondition" } & {
                                            children?: Maybe<
                                                Array<
                                                    {
                                                        __typename?: "AdvancedSearchCondition";
                                                    } & {
                                                        children?: Maybe<
                                                            Array<
                                                                {
                                                                    __typename?: "AdvancedSearchCondition";
                                                                } & {
                                                                    children?: Maybe<
                                                                        Array<
                                                                            {
                                                                                __typename?: "AdvancedSearchCondition";
                                                                            } & {
                                                                                children?: Maybe<
                                                                                    Array<
                                                                                        {
                                                                                            __typename?: "AdvancedSearchCondition";
                                                                                        } & {
                                                                                            children?: Maybe<
                                                                                                Array<
                                                                                                    {
                                                                                                        __typename?: "AdvancedSearchCondition";
                                                                                                    } & {
                                                                                                        children?: Maybe<
                                                                                                            Array<
                                                                                                                {
                                                                                                                    __typename?: "AdvancedSearchCondition";
                                                                                                                } & {
                                                                                                                    children?: Maybe<
                                                                                                                        Array<
                                                                                                                            {
                                                                                                                                __typename?: "AdvancedSearchCondition";
                                                                                                                            } & {
                                                                                                                                children?: Maybe<
                                                                                                                                    Array<
                                                                                                                                        {
                                                                                                                                            __typename?: "AdvancedSearchCondition";
                                                                                                                                        } & {
                                                                                                                                            children?: Maybe<
                                                                                                                                                Array<
                                                                                                                                                    {
                                                                                                                                                        __typename?: "AdvancedSearchCondition";
                                                                                                                                                    } & ConditionFragmentFragment
                                                                                                                                                >
                                                                                                                                            >;
                                                                                                                                        } & ConditionFragmentFragment
                                                                                                                                    >
                                                                                                                                >;
                                                                                                                            } & ConditionFragmentFragment
                                                                                                                        >
                                                                                                                    >;
                                                                                                                } & ConditionFragmentFragment
                                                                                                            >
                                                                                                        >;
                                                                                                    } & ConditionFragmentFragment
                                                                                                >
                                                                                            >;
                                                                                        } & ConditionFragmentFragment
                                                                                    >
                                                                                >;
                                                                            } & ConditionFragmentFragment
                                                                        >
                                                                    >;
                                                                } & ConditionFragmentFragment
                                                            >
                                                        >;
                                                    } & ConditionFragmentFragment
                                                >
                                            >;
                                        } & ConditionFragmentFragment
                                    >
                                >;
                            } & ConditionFragmentFragment;
                        }
                >;
            };
    };

export type GetWorkflowsForTenantQueryVariables = Exact<{
    tenantId: Scalars["TenantId"];
}>;

export type GetWorkflowsForTenantQuery = { __typename?: "Query" } & {
    getWorkflowsForTenant: Array<{ __typename?: "Workflow" } & WorkflowDefaultsFragment>;
};

export type GetWorkflowsQueryVariables = Exact<{ [key: string]: never }>;

export type GetWorkflowsQuery = { __typename?: "Query" } & {
    getWorkflows: Array<{ __typename?: "Workflow" } & WorkflowDefaultsFragment>;
};

export type GetWorkflowQueryVariables = Exact<{
    tenantId: Scalars["TenantId"];
    workflowId: Scalars["WorkflowId"];
}>;

export type GetWorkflowQuery = { __typename?: "Query" } & {
    getWorkflow: { __typename?: "Workflow" } & WorkflowDefaultsFragment;
};

export type CreateWorkflowForTenantMutationVariables = Exact<{
    tenantId: Scalars["TenantId"];
    workflow: WorkflowPayload;
}>;

export type CreateWorkflowForTenantMutation = { __typename?: "Mutation" } & {
    createWorkflowForTenant: { __typename?: "Workflow" } & WorkflowDefaultsFragment;
};

export type CreateWorkflowMutationVariables = Exact<{
    workflow: WorkflowPayload;
}>;

export type CreateWorkflowMutation = { __typename?: "Mutation" } & {
    createWorkflow: { __typename?: "Workflow" } & WorkflowDefaultsFragment;
};

export type UpdateWorkflowForTenantMutationVariables = Exact<{
    tenantId: Scalars["TenantId"];
    workflowId: Scalars["WorkflowId"];
    workflow: WorkflowPayload;
}>;

export type UpdateWorkflowForTenantMutation = { __typename?: "Mutation" } & {
    updateWorkflowForTenant: { __typename?: "Workflow" } & WorkflowDefaultsFragment;
};

export type UpdateWorkflowMutationVariables = Exact<{
    workflowId: Scalars["WorkflowId"];
    workflow: WorkflowPayload;
}>;

export type UpdateWorkflowMutation = { __typename?: "Mutation" } & {
    updateWorkflow: { __typename?: "Workflow" } & WorkflowDefaultsFragment;
};

export type DeleteWorkflowForTenantMutationVariables = Exact<{
    tenantId: Scalars["TenantId"];
    workflowId: Scalars["WorkflowId"];
}>;

export type DeleteWorkflowForTenantMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteWorkflowForTenant"
>;

export type DeleteWorkflowMutationVariables = Exact<{
    workflowId: Scalars["WorkflowId"];
}>;

export type DeleteWorkflowMutation = { __typename?: "Mutation" } & Pick<
    Mutation,
    "deleteWorkflow"
>;

export type GetWorkflowExecutionsForEntityQueryVariables = Exact<{
    workflowTargetId: Scalars["WorkflowTargetId"];
}>;

export type GetWorkflowExecutionsForEntityQuery = { __typename?: "Query" } & {
    getWorkflowExecutionsForEntity: Array<
        { __typename?: "WorkflowExecution" } & Pick<
            WorkflowExecution,
            | "id"
            | "scheduledAt"
            | "cancelledAt"
            | "isCancelRequested"
            | "finishedAt"
            | "result"
        > & { workflow: { __typename?: "Workflow" } & Pick<Workflow, "name" | "id"> }
    >;
};

export type CancelWorkflowExecutionMutationVariables = Exact<{
    workflowExecutionId: Scalars["WorkflowExecutionId"];
}>;

export type CancelWorkflowExecutionMutation = { __typename?: "Mutation" } & {
    cancelWorkflowExecution: { __typename?: "WorkflowExecution" } & Pick<
        WorkflowExecution,
        "id"
    >;
};

export const AudienceFragment = gql`
    fragment AudienceFragment on UserAudience {
        type
        specific {
            userIds
            groupIds
        }
    }
`;
export const SummaryFragment = gql`
    fragment SummaryFragment on AdvancedSearchRequestSummary {
        aggregates {
            operator
            column
        }
        groupBy
        sort {
            aggregateIndex
            ascending
        }
    }
`;
export const BridgeQuestionFragment = gql`
    fragment BridgeQuestionFragment on BridgeQuestion {
        id
        title
        interaction
        annotation
        questionType
        category
        key
        order
        regionMask
        rulesString
        searchBehavior
        source
        regions
        editable
        prompts {
            id
            title
            key
            order
            annotation
        }
    }
`;
export const SelectFragment = gql`
    fragment SelectFragment on AdvancedSearchSelect {
        fieldName
        questionId
    }
`;
export const BridgeEntityFragment = gql`
    fragment BridgeEntityFragment on BridgeEntityResult {
        name
        title
        preferredFilterField
        allowAnswers
        layoutParents
        identifierFields
        priorityFields {
            fieldName
            title
        }
        fields {
            name
            title
            questionType
            formatter
            category
            dependsOn
            customListKey
            aliases
            naryValues {
                value
                label
            }
            reportBehavior {
                sortable
                filterable
                selectable
                newReportSelectOrder
                summaryGroupable
                canAggregate
            }
        }
        relations {
            name
            title
            otherEntityName
            kind
            selectable
        }
        questions {
            ...BridgeQuestionFragment
        }
        defaultSelect {
            ...SelectFragment
        }
        preferredDisplay {
            fieldName
            relation {
                name
            }
        }
        preferredGroupBy
    }
    ${BridgeQuestionFragment}
    ${SelectFragment}
`;
export const CommunityBasics = gql`
    fragment CommunityBasics on Community {
        address
        caregiverRatio
        city
        contractStatus
        contractExpiresDate
        contractIssuedDate
        contractNotes
        dateAdded
        gender
        feeType
        paymentFee
        paymentPercentage
        id
        latitude
        licenseStatus
        longitude
        mainPhone
        monthlyPrice
        moveInDeposit
        name
        petDeposit
        priceDetails
        priceHighEnd
        rating
        secondPersonFee
        state
        summary
        thumbnailUrl
        unitsOrCapacity
        updatedAt
        virtualTourURL
        website
        zip
        regionId
        email
        county
        contacts {
            cellPhone
            name
            phone1
            role
            email1
        }
        primaryContacts {
            cellPhone
            name
            phone1
            role
            email1
        }
    }
`;
export const FileFragment = gql`
    fragment FileFragment on File {
        id
        entityId
        originalFilename
        mimeType
        description
        byteLength
        shareWithClient
        shareWithCommunity
        shareWithReferral
        createdAt
        createdBy
        updatedAt
        updatedBy
        hasSignatureTemplate
        isGlobal
        regionVisibility {
            editable
            regions
        }
    }
`;
export const PhotoBasics = gql`
    fragment PhotoBasics on Photo {
        id
        entityId
        order
        dateAdded
        createdBy
        originalFile {
            ...FileFragment
        }
    }
    ${FileFragment}
`;
export const CommunityCardBasics = gql`
    fragment CommunityCardBasics on Community {
        ...CommunityBasics
        answers {
            questionId
            booleanAnswer
            numberAnswer
            numberAnswerHigh
            textAnswer
            dateAnswer
            calendarDateAnswer
            selections
            idReferenceAnswer
            selectionAnnotations {
                text
                selectionId
            }
        }
        photoEntities {
            ...PhotoBasics
        }
        licenses {
            id
            licenseNumber
            href
            licensee
            status
            licenseStartDate
            licenseEndDate
        }
    }
    ${CommunityBasics}
    ${PhotoBasics}
`;
export const ClientCommunities = gql`
    fragment ClientCommunities on Client {
        id
        baseLocation {
            lat
            lng
        }
        clientCommunities {
            order
            relationship
            publicNotes
            privateNotes
            community {
                ...CommunityCardBasics
            }
            referred
        }
    }
    ${CommunityCardBasics}
`;
export const DashboardDefaults = gql`
    fragment DashboardDefaults on ClientsSearchResult {
        total
        clients {
            id
            serial
            name
            statusListItemId
            bestContactName
            bestContactPhone
            dateAdded
            invoiceSentDate
            moveTimeline
            moveTimelineActual
            paymentReceivedDate
            statusCheckLaterDate
            statusClosedDate
            updatedAt
            assignedUserId
            phone
            cellPhone
            email
            additionalClientName
            chosenCommunity {
                id
                name
            }
            nextTask {
                id
                text
                status
                dueDate
                dueDateTime
            }
            allContacts {
                name
                email1
                phone1
                cellPhone
                relationship
            }
        }
    }
`;
export const ReferralResponse = gql`
    fragment ReferralResponse on Referral {
        id
        clientId
        referenceBusinessId
        referenceContactId
        communityId
        referralType
        notes
        when
        dateAdded
        createdBy
        updatedAt
        updatedBy
    }
`;
export const IdentityDefaults = gql`
    fragment IdentityDefaults on Identity {
        id
        name
        successorId
        propagateableLicenseTypes
        thumbnailUrl
    }
`;
export const LicenseDefaults = gql`
    fragment LicenseDefaults on License {
        status
        jurisdiction
        licenseNumber
        id
        licenseType
    }
`;
export const PublicRecordDefaults = gql`
    fragment PublicRecordDefaults on PublicRecord {
        id
        name
        address
        city
        state
        zip
        capacity
        licenseType
        licenseNumber
        licensee
        closedDate
        licenseDate
        licenseStatus
        county
        country
        phone
        administrator
        email
        lat
        lng
    }
`;
export const ContactFragment = gql`
    fragment ContactFragment on ContactEntity {
        id
        name
        preferredName
        address1
        address2
        city
        state
        zip
        relationship
        role
        cellPhone
        phone1
        email1
        email1OptOut
        website
        contactType
        type
        fax
        contactNotes
    }
`;
export const ContactWithLinks = gql`
    fragment ContactWithLinks on ContactEntity {
        ...ContactFragment
        communityContacts {
            communityId
            contactId
            primary
            community {
                id
                name
                appLink
            }
        }
        clientContacts {
            clientId
            contactId
            relationship
            powerOfAttorney
            client {
                id
                name
                appLink
            }
        }
        referenceContacts {
            id
            summary
            regionId
            ownerId
            dateAdded
            updatedAt
            updatedBy
            createdBy
            appLink
        }
    }
    ${ContactFragment}
`;
export const CustomListItemResponse = gql`
    fragment CustomListItemResponse on CustomListItem {
        id
        key
        name
        order
        customListCategoryId
        icon
        pipelineVisibility
        pipelineDateLabel
        pipelineDateField
        isArchived
        updateBehavior
    }
`;
export const CustomListResponse = gql`
    fragment CustomListResponse on CustomList {
        id
        key
        items {
            ...CustomListItemResponse
        }
        categories {
            id
            key
            name
            order
        }
    }
    ${CustomListItemResponse}
`;
export const DisclosureAcknowledgementFragment = gql`
    fragment DisclosureAcknowledgementFragment on DisclosureAcknowledgement {
        id
        name
        email
        phone
        agreed
        response
        dateAdded
        createdBy
        ipAddress
    }
`;
export const DisclosureFragment = gql`
    fragment DisclosureFragment on Disclosure {
        id
        dateAdded
        publicUrl
        acknowledgements {
            ...DisclosureAcknowledgementFragment
        }
    }
    ${DisclosureAcknowledgementFragment}
`;
export const ImportFragment = gql`
    fragment ImportFragment on EvergreenImport {
        created
        dateAdded
        duplicates
        endedAt
        errors
        id
        message
        skips
        delisted
        sourceName
        startedAt
        updatedAt
    }
`;
export const LayoutItemBasics = gql`
    fragment LayoutItemBasics on LayoutItem {
        id
        order
        questionId
        entityFieldName
        showIfBlank
        naryFormat
        customTitle
    }
`;
export const LayoutSectionBasics = gql`
    fragment LayoutSectionBasics on LayoutSection {
        id
        order
        sectionKey
        visible
        entityType
        title
        icon
        renderBehavior
        questionCategory
        legacyPerspective
        includeRelations
        singlePropertyName
        defaultStandardFields
        settings {
            key
            label
            type
            order
        }
        layoutItems {
            ...LayoutItemBasics
        }
    }
    ${LayoutItemBasics}
`;
export const ReferralBasics = gql`
    fragment ReferralBasics on Referral {
        id
        clientId
        client {
            id
            name
            bestContactName
            statusListItemId
            dateAdded
            nextTask {
                id
                text
                dueDate
                dueDateTime
                compositeDueDateTime
                status
            }
            serial
            assignedUserId
            updatedAt
            moveTimeline
            moveTimelineActual
        }
    }
`;
export const NoteResponse = gql`
    fragment NoteResponse on NoteDto {
        _id
        text
        dateAdded
        wasEdited
        authorId
        isDraft
    }
`;
export const ReferenceContactBasics = gql`
    fragment ReferenceContactBasics on ReferenceContact {
        id
        ownerId
        deleted
        summary
        businessId
        communityId
        regions
        regionId
        dateAdded
        updatedAt
        createdBy
        updatedBy
        draftNote
        contactEntity {
            ...ContactFragment
        }
        business {
            id
            ownerId
            deleted
            regionId
            latitude
            longitude
            businessContactEntity {
                ...ContactFragment
            }
        }
        community {
            id
            name
            address
            city
            state
            zip
            mainPhone
            website
            email
            latitude
            longitude
            contractStatus
        }
        answers {
            questionId
            booleanAnswer
            numberAnswer
            numberAnswerHigh
            textAnswer
            dateAnswer
            calendarDateAnswer
            selections
            idReferenceAnswer
            selectionAnnotations {
                text
                selectionId
            }
        }
        notes {
            ...NoteResponse
        }
    }
    ${ContactFragment}
    ${NoteResponse}
`;
export const ReferenceBusinessBasics = gql`
    fragment ReferenceBusinessBasics on ReferenceBusiness {
        id
        ownerId
        deleted
        summary
        dateAdded
        updatedAt
        createdBy
        updatedBy
        draftNote
        regionId
        latitude
        longitude
        businessContactEntity {
            ...ContactFragment
        }
        answers {
            questionId
            booleanAnswer
            numberAnswer
            numberAnswerHigh
            textAnswer
            dateAnswer
            calendarDateAnswer
            selections
            idReferenceAnswer
            selectionAnnotations {
                text
                selectionId
            }
        }
        notes {
            ...NoteResponse
        }
    }
    ${ContactFragment}
    ${NoteResponse}
`;
export const SmtpCredentialBasics = gql`
    fragment SmtpCredentialBasics on SmtpCredential {
        id
        host
        port
        username
        status
    }
`;
export const TaskBasics = gql`
    fragment TaskBasics on Task {
        id
        completedAt
        dueDate
        dueDateTime
        ownerId
        parentId
        status
        text
        done
        taskTypeId
        taskType {
            id
            name
        }
    }
`;
export const TaskDetails = gql`
    fragment TaskDetails on Task {
        ...TaskBasics
        notes
    }
    ${TaskBasics}
`;
export const ConditionFragment = gql`
    fragment ConditionFragment on AdvancedSearchCondition {
        nodeType
        fieldName
        operator
        textValue
        dateValue
        calendarDateValue
        relativeDate {
            value
            interval
        }
        numberValue
        questionId
        promptId
    }
`;
export const WorkflowDefaults = gql`
    fragment WorkflowDefaults on Workflow {
        actions {
            ... on WorkflowActionEmailEntity {
                __typename
                body
                subject
                to
                attachments
                bccMyself
            }
            ... on WorkflowActionTaskEntity {
                __typename
                dueDate
                text
                assignedTo
                taskTypeId
                notifyOwner
                notification {
                    subject
                }
            }
        }
        attachTo
        createdBy
        dateAdded
        delay
        delayRecipe
        description
        id
        name
        status
        defaultActorId
        actorMode
        settings {
            defaultValue
            description
            key
            label
            type
            scope
        }
        trigger {
            eventType
            entityUpdated {
                fieldMatchers {
                    fieldName
                    previous {
                        values
                        specialValue
                    }
                    current {
                        values
                        specialValue
                    }
                }
            }
            noteAdded {
                entityFieldMatchers {
                    fieldName
                    current {
                        values
                        specialValue
                    }
                }
            }
            emailOpened {
                entityFieldMatchers {
                    fieldName
                    current {
                        values
                        specialValue
                    }
                }
                activityThresholdDays
            }
            schedule {
                cron
                entityCondition {
                    ...ConditionFragment
                    children {
                        ...ConditionFragment
                        children {
                            ...ConditionFragment
                            children {
                                ...ConditionFragment
                                children {
                                    ...ConditionFragment
                                    children {
                                        ...ConditionFragment
                                        children {
                                            ...ConditionFragment
                                            children {
                                                ...ConditionFragment
                                                children {
                                                    ...ConditionFragment
                                                    children {
                                                        ...ConditionFragment
                                                        children {
                                                            ...ConditionFragment
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        updatedAt
        updatedBy
    }
    ${ConditionFragment}
`;
export const GetActivityFeed = gql`
    query getActivityFeed($entityId: ActivityParentEntityId!) {
        getActivityFeed(entityId: $entityId) {
            activities {
                activity {
                    id
                    activityType
                    createdAt
                    createdByEntity {
                        preferredName
                    }
                    parentReferenceContact {
                        id
                        contactEntity {
                            name
                        }
                        appLink
                    }
                    parentReferenceBusiness {
                        id
                        businessContactEntity {
                            name
                        }
                        appLink
                    }
                    parentCommunity {
                        id
                        name
                        appLink
                    }
                    metadata {
                        events {
                            type
                            entityFieldChange {
                                field
                                oldStringValue
                                newStringValue
                            }
                            entityReassign {
                                from
                                to
                            }
                        }
                    }
                }
                note {
                    text
                    effectiveCreatedAt
                    publishedAt
                }
                task {
                    status
                    dueDate
                    dueDateTime
                    completedAt
                    type
                    text
                }
                file {
                    originalFilename
                }
                message {
                    subject
                    intakeCommunity {
                        name
                    }
                }
                sourceType
            }
        }
    }
`;
export const CreateSavedQuery = gql`
    mutation createSavedQuery($savedQuery: SavedQueryInput!) {
        createSavedQuery(savedQuery: $savedQuery) {
            id
        }
    }
`;
export const UpdateSavedQuery = gql`
    mutation updateSavedQuery($savedQuery: SavedQueryInput!) {
        updateSavedQuery(savedQuery: $savedQuery) {
            id
        }
    }
`;
export const DeleteSavedQuery = gql`
    mutation deleteSavedQuery($savedQueryId: SavedQueryId!) {
        deleteSavedQuery(savedQueryId: $savedQueryId)
    }
`;
export const UpdateQueryFavoriteStatus = gql`
    mutation updateQueryFavoriteStatus(
        $savedQueryId: SavedQueryId!
        $isFavorite: Boolean!
    ) {
        updateQueryFavoriteStatus(savedQueryId: $savedQueryId, isFavorite: $isFavorite)
    }
`;
export const UpsertSavedQuerySchedule = gql`
    mutation upsertSavedQuerySchedule($schedule: SavedQueryScheduleInput!) {
        upsertSavedQuerySchedule(schedule: $schedule) {
            schedule
            audience {
                ...AudienceFragment
            }
        }
    }
    ${AudienceFragment}
`;
export const DeleteSavedQuerySchedule = gql`
    mutation deleteSavedQuerySchedule($savedQueryId: SavedQueryId!) {
        deleteSavedQuerySchedule(savedQueryId: $savedQueryId)
    }
`;
export const SendSavedQueryEmail = gql`
    mutation sendSavedQueryEmail($payload: SavedQuerySendEmailInput!) {
        sendSavedQueryEmail(payload: $payload)
    }
`;
export const GetSavedQueries = gql`
    query getSavedQueries {
        getSavedQueries {
            canSaveAdditional
            savedQueries {
                id
                name
                isMyQuery
                isShared
                regionVisibility {
                    editable
                    regions
                }
                isFavorite
                editable
                entityType
                createdBy
                warnings {
                    location
                    message
                }
                schedule {
                    schedule
                    audience {
                        ...AudienceFragment
                    }
                    emailDescription
                }
                sort {
                    ascending
                    column
                    questionId
                }
                select {
                    ...SelectFragment
                }
                summaries {
                    ...SummaryFragment
                }
                condition {
                    ...ConditionFragment
                    children {
                        ...ConditionFragment
                        children {
                            ...ConditionFragment
                            children {
                                ...ConditionFragment
                                children {
                                    ...ConditionFragment
                                    children {
                                        ...ConditionFragment
                                        children {
                                            ...ConditionFragment
                                            children {
                                                ...ConditionFragment
                                                children {
                                                    ...ConditionFragment
                                                    children {
                                                        ...ConditionFragment
                                                        children {
                                                            ...ConditionFragment
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${AudienceFragment}
    ${SelectFragment}
    ${SummaryFragment}
    ${ConditionFragment}
`;
export const GetEntities = gql`
    query getEntities {
        getEntities {
            ...BridgeEntityFragment
        }
    }
    ${BridgeEntityFragment}
`;
export const GetEntitiesForTenant = gql`
    query getEntitiesForTenant($tenantId: TenantId!) {
        getEntitiesForTenant(tenantId: $tenantId) {
            ...BridgeEntityFragment
        }
    }
    ${BridgeEntityFragment}
`;
export const UpsertEntityQuestion = gql`
    mutation upsertEntityQuestion($entityType: String!, $question: BridgeQuestionInput!) {
        upsertEntityQuestion(entityType: $entityType, question: $question) {
            ...BridgeQuestionFragment
        }
    }
    ${BridgeQuestionFragment}
`;
export const SetEntityAnswer = gql`
    mutation setEntityAnswer($entityId: AnswerEntityId!, $answer: AnswerInput!) {
        setEntityAnswer(entityId: $entityId, answer: $answer) {
            questionId
            booleanAnswer
            numberAnswer
            numberAnswerHigh
            textAnswer
            dateAnswer
            calendarDateAnswer
            selections
            selectionAnnotations {
                selectionId
                text
            }
        }
    }
`;
export const DeleteQuestion = gql`
    mutation deleteQuestion($questionId: QuestionId!) {
        deleteQuestion(questionId: $questionId) {
            questionId
        }
    }
`;
export const GetQuestionPermissions = gql`
    query getQuestionPermissions {
        getQuestionPermissions {
            editableRegions {
                id
                key
                name
            }
            canEditAllRegions
        }
    }
`;
export const GetClient = gql`
    query getClient($id: ClientId!) {
        getClient(clientId: $id) {
            id
            totalInvoiceAmountCents
            invoices {
                id
                amountCents
                paidDate
                sentDate
                dueDate
                serial
                status
                clientId
                clientName
                communityId
                fileId
                notes
                externalReference
                remainingBalanceCents
                totalPaidCents
                hasOutstandingBalance
                lineItems {
                    id
                    description
                    quantity
                    unitPriceInCents
                }
                paymentSchedule {
                    items {
                        id
                        amountInCents
                        dueDate
                        paidDate
                        status
                    }
                }
                isExternallyManaged
                community {
                    name
                    id
                }
            }
            bestContactName
            bestContactPhone
            bestContactEmail
            disclosures {
                ...DisclosureFragment
            }
        }
    }
    ${DisclosureFragment}
`;
export const GetClientCommunities = gql`
    query getClientCommunities($id: ClientId!, $questionIds: [QuestionId!]!) {
        getClient(clientId: $id, options: { includeCommunityQuestionIds: $questionIds }) {
            ...ClientCommunities
        }
    }
    ${ClientCommunities}
`;
export const GetClientMatches = gql`
    query getClientMatches($id: ClientId!) {
        getClient(clientId: $id) {
            id
            potentialDuplicates {
                id
                name
                bestContactName
                dateAdded
                viewable
                assignedUser {
                    id
                    preferredName
                }
            }
        }
    }
`;
export const CreateClient = gql`
    mutation createClient($regionId: RegionId!) {
        createClient(regionId: $regionId) {
            id
        }
    }
`;
export const DeleteClientMatches = gql`
    mutation deleteClientMatches($id: ClientId!) {
        deleteClientMatches(clientId: $id) {
            id
        }
    }
`;
export const SetCommunityOrder = gql`
    mutation setCommunityOrder(
        $clientId: ClientId!
        $questionIds: [QuestionId!]!
        $ordering: [CommunityOrdering!]!
    ) {
        setCommunityOrder(
            clientId: $clientId
            ordering: $ordering
            includeCommunityQuestionIds: $questionIds
        ) {
            ...ClientCommunities
        }
    }
    ${ClientCommunities}
`;
export const AddClientCommunities = gql`
    mutation addClientCommunities(
        $clientId: ClientId!
        $questionIds: [QuestionId!]!
        $params: [AddClientCommunityRequest!]!
    ) {
        addClientCommunities(
            clientId: $clientId
            params: $params
            includeCommunityQuestionIds: $questionIds
        ) {
            ...ClientCommunities
        }
    }
    ${ClientCommunities}
`;
export const RemoveClientCommunities = gql`
    mutation removeClientCommunities(
        $clientId: ClientId!
        $params: [RemoveClientCommunityRequest!]!
    ) {
        removeClientCommunities(clientId: $clientId, params: $params) {
            id
        }
    }
`;
export const GetClientsForTimeline = gql`
    query getClientsForTimeline(
        $regionId: RegionId!
        $searchString: String
        $assignedUserId: UserId
        $assignedReferralUserId: UserId
        $searchType: String
        $openClosed: String
        $page: Int!
        $perPage: Int!
        $includeStatuses: [String!]
    ) {
        pastDue: getClients(
            params: {
                regionId: $regionId
                searchString: $searchString
                assignedUserId: $assignedUserId
                assignedReferralUserId: $assignedReferralUserId
                searchType: $searchType
                moveTimelineWindow: "pastDue"
                archived: false
                openClosed: $openClosed
                includeStatuses: $includeStatuses
                page: $page
                perPage: $perPage
                sort: UpdatedAtDesc
            }
        ) {
            ...DashboardDefaults
        }
        oneWeek: getClients(
            params: {
                regionId: $regionId
                searchString: $searchString
                assignedUserId: $assignedUserId
                assignedReferralUserId: $assignedReferralUserId
                searchType: $searchType
                moveTimelineWindow: "oneWeek"
                archived: false
                openClosed: $openClosed
                includeStatuses: $includeStatuses
                page: $page
                perPage: $perPage
                sort: UpdatedAtDesc
            }
        ) {
            ...DashboardDefaults
        }
        oneToTwoWeeks: getClients(
            params: {
                regionId: $regionId
                searchString: $searchString
                assignedUserId: $assignedUserId
                assignedReferralUserId: $assignedReferralUserId
                searchType: $searchType
                moveTimelineWindow: "oneToTwoWeeks"
                archived: false
                openClosed: $openClosed
                includeStatuses: $includeStatuses
                page: $page
                perPage: $perPage
                sort: UpdatedAtDesc
            }
        ) {
            ...DashboardDefaults
        }
        twoToFourWeeks: getClients(
            params: {
                regionId: $regionId
                searchString: $searchString
                assignedUserId: $assignedUserId
                assignedReferralUserId: $assignedReferralUserId
                searchType: $searchType
                moveTimelineWindow: "twoToFourWeeks"
                archived: false
                openClosed: $openClosed
                includeStatuses: $includeStatuses
                page: $page
                perPage: $perPage
                sort: UpdatedAtDesc
            }
        ) {
            ...DashboardDefaults
        }
        fourOrMoreWeeks: getClients(
            params: {
                regionId: $regionId
                searchString: $searchString
                assignedUserId: $assignedUserId
                assignedReferralUserId: $assignedReferralUserId
                searchType: $searchType
                moveTimelineWindow: "fourOrMoreWeeks"
                archived: false
                openClosed: $openClosed
                includeStatuses: $includeStatuses
                page: $page
                perPage: $perPage
                sort: UpdatedAtDesc
            }
        ) {
            ...DashboardDefaults
        }
        unscheduled: getClients(
            params: {
                regionId: $regionId
                searchString: $searchString
                assignedUserId: $assignedUserId
                assignedReferralUserId: $assignedReferralUserId
                searchType: $searchType
                moveTimelineWindow: "unscheduled"
                archived: false
                openClosed: $openClosed
                includeStatuses: $includeStatuses
                page: $page
                perPage: $perPage
                sort: UpdatedAtDesc
            }
        ) {
            ...DashboardDefaults
        }
    }
    ${DashboardDefaults}
`;
export const GetClientsForDetails = gql`
    query getClientsForDetails(
        $regionId: RegionId!
        $searchString: String
        $assignedUserId: UserId
        $assignedReferralUserId: UserId
        $searchType: String
        $openClosed: String
        $page: Int!
        $perPage: Int!
        $includeStatuses: [String!]
        $excludeStatuses: [String!]
        $statusAtOrLater: String
        $status: String
        $sort: ClientSearchSort!
    ) {
        getClients(
            params: {
                regionId: $regionId
                searchString: $searchString
                assignedUserId: $assignedUserId
                assignedReferralUserId: $assignedReferralUserId
                sort: $sort
                searchType: $searchType
                includeStatuses: $includeStatuses
                excludeStatuses: $excludeStatuses
                statusAtOrLater: $statusAtOrLater
                status: $status
                openClosed: $openClosed
                page: $page
                perPage: $perPage
            }
        ) {
            ...DashboardDefaults
        }
    }
    ${DashboardDefaults}
`;
export const SearchClients = gql`
    query searchClients(
        $regionId: RegionId!
        $searchString: String
        $assignedUserId: UserId
        $assignedReferralUserId: UserId
        $searchType: String
        $openClosed: String
        $referralCommunityId: CommunityId
        $referralContactId: ReferenceContactId
        $referralBusinessId: ReferenceBusinessId
        $relatedTo: [ClientId!]
        $page: Int!
        $perPage: Int!
    ) {
        getClients(
            params: {
                regionId: $regionId
                searchString: $searchString
                assignedUserId: $assignedUserId
                assignedReferralUserId: $assignedReferralUserId
                searchType: $searchType
                openClosed: $openClosed
                referralCommunityId: $referralCommunityId
                referralContactId: $referralContactId
                referralBusinessId: $referralBusinessId
                relatedTo: $relatedTo
                page: $page
                perPage: $perPage
                sort: NameAsc
            }
        ) {
            ...DashboardDefaults
        }
    }
    ${DashboardDefaults}
`;
export const GetClientReferralDetails = gql`
    query getClientReferralDetails($id: ClientId!) {
        getClient(clientId: $id) {
            bestContactName
            bestContactPhone
            address
            city
            state
            zip
        }
    }
`;
export const GetOutboundClientReferrals = gql`
    query getOutboundClientReferrals($id: ClientId!) {
        getClientReferrals(clientId: $id) {
            ...ReferralResponse
            referenceBusiness {
                id
                businessContactEntity {
                    name
                }
            }
            referenceContact {
                id
                contactEntity {
                    name
                }
            }
            community {
                id
                name
            }
        }
    }
    ${ReferralResponse}
`;
export const CreateClientReferral = gql`
    mutation createClientReferral($clientId: ClientId!, $referralType: String!) {
        createClientReferral(clientId: $clientId, referralType: $referralType) {
            ...ReferralResponse
        }
    }
    ${ReferralResponse}
`;
export const PatchClientReferral = gql`
    mutation patchClientReferral(
        $id: ReferralId!
        $clientId: ClientId!
        $notes: String
        $when: DateTime
        $deleted: Boolean
        $referenceBusinessId: ReferenceBusinessId
        $referenceContactId: ReferenceContactId
        $communityId: CommunityId
    ) {
        patchClientReferral(
            id: $id
            clientId: $clientId
            notes: $notes
            when: $when
            deleted: $deleted
            referenceBusinessId: $referenceBusinessId
            referenceContactId: $referenceContactId
            communityId: $communityId
        ) {
            ...ReferralResponse
        }
    }
    ${ReferralResponse}
`;
export const GetClientForIntakeEmail = gql`
    query getClientForIntakeEmail($clientId: ClientId!) {
        getClient(clientId: $clientId) {
            name
            clientCommunities {
                relationship
                referred
                community {
                    id
                    name
                    email
                    emailOptOut
                    communityContacts {
                        primary
                        contact {
                            name
                            email1
                            email1OptOut
                        }
                    }
                }
            }
        }
    }
`;
export const RequestClientTransfer = gql`
    mutation requestClientTransfer($params: TransferClientRequest!) {
        requestClientTransfer(params: $params)
    }
`;
export const GetCommunity = gql`
    query getCommunity($id: CommunityId!) {
        getCommunity(communityId: $id) {
            ...CommunityBasics
            invoices {
                id
                amountCents
                paidDate
                sentDate
                dueDate
                serial
                status
                clientId
                communityId
                fileId
                clientName
                notes
                externalReference
                remainingBalanceCents
                totalPaidCents
                hasOutstandingBalance
                lineItems {
                    id
                    description
                    quantity
                    unitPriceInCents
                }
                paymentSchedule {
                    items {
                        id
                        amountInCents
                        dueDate
                        paidDate
                        status
                    }
                }
                isExternallyManaged
            }
            licenses {
                href
                id
                licenseEndDate
                licenseNumber
                licenseStartDate
                licensee
                status
                communityId
                tenantId
            }
        }
    }
    ${CommunityBasics}
`;
export const GetCommunityAdvanced = gql`
    query getCommunityAdvanced($id: CommunityId!) {
        getCommunity(communityId: $id) {
            id
            communityMasterId
            communityMasterVersion
            matchInfo {
                matchRule
                matchScore
                originalMatchDate
            }
        }
    }
`;
export const DeleteCommunityLicense = gql`
    mutation deleteCommunityLicense(
        $communityId: CommunityId!
        $licenseId: CommunityLicenseId!
    ) {
        deleteCommunityLicense(communityId: $communityId, licenseId: $licenseId)
    }
`;
export const UpdateCommunityLicense = gql`
    mutation updateCommunityLicense(
        $licenseId: CommunityLicenseId!
        $license: CommunityLicenseInput!
    ) {
        updateCommunityLicense(licenseId: $licenseId, license: $license)
    }
`;
export const CreateCommunityLicense = gql`
    mutation createCommunityLicense($communityId: CommunityId!) {
        createCommunityLicense(communityId: $communityId) {
            id
        }
    }
`;
export const GetReferralsToCommunity = gql`
    query getReferralsToCommunity($communityId: CommunityId!) {
        getReferralsToCommunity(communityId: $communityId) {
            ...ReferralBasics
        }
    }
    ${ReferralBasics}
`;
export const GetLicense = gql`
    query getLicense($id: LicenseId!) {
        getLicense(licenseId: $id) {
            ...LicenseDefaults
            publicRecords {
                ...PublicRecordDefaults
            }
            identity {
                ...IdentityDefaults
            }
        }
    }
    ${LicenseDefaults}
    ${PublicRecordDefaults}
    ${IdentityDefaults}
`;
export const GetIdentity = gql`
    query getIdentity($id: IdentityId!) {
        getIdentity(identityId: $id) {
            ...IdentityDefaults
            licenses {
                ...LicenseDefaults
                publicRecords {
                    ...PublicRecordDefaults
                    publicRecordContacts {
                        address1
                        address2
                        cellPhone
                        city
                        contactNotes
                        email1
                        fax
                        id
                        name
                        phone1
                        relationship
                        role
                        state
                        website
                        zip
                    }
                }
            }
        }
    }
    ${IdentityDefaults}
    ${LicenseDefaults}
    ${PublicRecordDefaults}
`;
export const PublicRecordSearch = gql`
    query publicRecordSearch($params: PublicRecordSearchRequest!) {
        publicRecordSearch(params: $params) {
            count
            records {
                ...PublicRecordDefaults
                license {
                    ...LicenseDefaults
                    identity {
                        ...IdentityDefaults
                    }
                }
            }
        }
    }
    ${PublicRecordDefaults}
    ${LicenseDefaults}
    ${IdentityDefaults}
`;
export const GetEvergreenLicenseTypes = gql`
    query getEvergreenLicenseTypes {
        getEvergreenLicenseTypes {
            id
            state
            licenseType
        }
    }
`;
export const UpdateEvergreenLicenseType = gql`
    mutation updateEvergreenLicenseType($input: EvergreenLicenseTypeInput!) {
        updateEvergreenLicenseType(input: $input) {
            id
        }
    }
`;
export const DeleteEvergreenLicenseType = gql`
    mutation deleteEvergreenLicenseType($id: EvergreenLicenseTypeId!) {
        deleteEvergreenLicenseType(licenseTypeId: $id)
    }
`;
export const CommunityNames = gql`
    query CommunityNames($regionKey: String!, $ids: [CommunityId!]) {
        communityNames(regionKey: $regionKey, ids: $ids) {
            communities {
                id
                name
            }
        }
    }
`;
export const CommunitySearch = gql`
    query CommunitySearch($params: CommunitySearchRequest!) {
        communitySearch(params: $params) {
            total
            coordinates {
                lat
                lng
            }
            hits {
                address
                contractStatus
                contractExpiresDate
                contractIssuedDate
                contractNotes
                paymentFee
                paymentPercentage
                gender
                id
                lat
                licenseStatus
                lng
                mostRecentPlacement
                name
                phone
                placements
                rating
                summary
                thumbnail
                url
                unitsOrCapacity
                showInSearchResults
                monthlyPrice
                priceHighEnd
                zip
                state
                city
                mainPhone
                feeType
                secondPersonFee
                petDeposit
                moveInDeposit
                priceDetails
                caregiverRatio
                updatedAt
                dateAdded
                regionId
                referralCount
                email
                county
                contacts {
                    cellPhone
                    name
                    phone1
                    email1
                    role
                }
                primaryContacts {
                    cellPhone
                    name
                    phone1
                    email1
                    role
                }
                answers {
                    questionId
                    booleanAnswer
                    numberAnswer
                    numberAnswerHigh
                    textAnswer
                    dateAnswer
                    calendarDateAnswer
                    selections
                    idReferenceAnswer
                    selectionAnnotations {
                        text
                        selectionId
                    }
                }
                licenses {
                    id
                    licenseNumber
                    href
                    licensee
                    status
                    licenseStartDate
                    licenseEndDate
                }
            }
        }
    }
`;
export const CommunitySearchMapView = gql`
    query CommunitySearchMapView(
        $searchParams: CommunitySearchRequest!
        $mapView: CommunitySearchRequestMapComponent!
    ) {
        communitySearchMapView(searchParams: $searchParams, mapView: $mapView) {
            recommendedZoom
            coordinates {
                lat
                lng
            }
            communityCoordinates {
                lat
                lng
                name
                contractStatus
                id
                appLink
            }
            communityClusters {
                lat
                lng
                count
            }
        }
    }
`;
export const GetCommunityContacts = gql`
    query getCommunityContacts($communityId: CommunityId!, $regionId: RegionId!) {
        getCommunityContacts(communityId: $communityId, regionId: $regionId) {
            contactId
            communityId
            primary
            contact {
                ...ContactFragment
            }
            referenceContact {
                id
                summary
                regionId
            }
        }
    }
    ${ContactFragment}
`;
export const PromoteCommunityContact = gql`
    mutation promoteCommunityContact(
        $communityId: CommunityId!
        $contactId: ContactId!
        $regionId: RegionId!
    ) {
        promoteCommunityContact(
            communityId: $communityId
            contactId: $contactId
            regionId: $regionId
        ) {
            contactId
            communityId
            primary
            contact {
                ...ContactFragment
            }
            referenceContact {
                id
                summary
                regions
                regionId
                ownerId
                dateAdded
                updatedAt
                updatedBy
                createdBy
            }
        }
    }
    ${ContactFragment}
`;
export const DeleteCommunityContact = gql`
    mutation deleteCommunityContact($communityId: CommunityId!, $contactId: ContactId!) {
        deleteCommunityContact(communityId: $communityId, contactId: $contactId) {
            contactId
            communityId
        }
    }
`;
export const DeleteClientContact = gql`
    mutation deleteClientContact(
        $clientId: ClientId!
        $contactId: ContactId!
        $relationship: ClientContactRelationship!
    ) {
        deleteClientContact(
            clientId: $clientId
            contactId: $contactId
            relationship: $relationship
        ) {
            contactId
            clientId
        }
    }
`;
export const GetClientContacts = gql`
    query getClientContacts($params: GetClientContactsRequest!) {
        getClientContacts(params: $params) {
            contactId
            clientId
            relationship
            powerOfAttorney
            contact {
                ...ContactFragment
                referenceContacts {
                    id
                    appLink
                }
            }
        }
    }
    ${ContactFragment}
`;
export const GetContact = gql`
    query getContact($contactId: ContactId!) {
        getContact(contactId: $contactId) {
            ...ContactWithLinks
        }
    }
    ${ContactWithLinks}
`;
export const CreateContact = gql`
    mutation createContact($params: CreateContactInput!) {
        createContact(params: $params) {
            ...ContactWithLinks
        }
    }
    ${ContactWithLinks}
`;
export const PatchContact = gql`
    mutation patchContact($params: PatchContactInput!) {
        patchContact(params: $params) {
            ...ContactWithLinks
        }
    }
    ${ContactWithLinks}
`;
export const GetCustomList = gql`
    query getCustomList($key: CustomListKey!) {
        getCustomList(key: $key) {
            ...CustomListResponse
        }
    }
    ${CustomListResponse}
`;
export const GetCustomLists = gql`
    query getCustomLists {
        getCustomLists {
            ...CustomListResponse
        }
    }
    ${CustomListResponse}
`;
export const CreateCustomListItem = gql`
    mutation createCustomListItem($params: CreateCustomListItemRequest!) {
        createCustomListItem(params: $params) {
            ...CustomListItemResponse
        }
    }
    ${CustomListItemResponse}
`;
export const PatchCustomListItem = gql`
    mutation patchCustomListItem($params: PatchCustomListItemRequest!) {
        patchCustomListItem(params: $params) {
            ...CustomListItemResponse
        }
    }
    ${CustomListItemResponse}
`;
export const PatchCustomListItems = gql`
    mutation patchCustomListItems($params: [PatchCustomListItemRequest!]!) {
        patchCustomListItems(params: $params) {
            ...CustomListItemResponse
        }
    }
    ${CustomListItemResponse}
`;
export const CreateDisclosure = gql`
    mutation createDisclosure($clientId: ClientId!) {
        createDisclosure(clientId: $clientId) {
            ...DisclosureFragment
        }
    }
    ${DisclosureFragment}
`;
export const CreateDisclosureAcknowledgement = gql`
    mutation createDisclosureAcknowledgement(
        $disclosureId: DisclosureId!
        $acknowledgement: DisclosureAcknowledgementInput!
    ) {
        createDisclosureAcknowledgement(
            disclosureId: $disclosureId
            acknowledgement: $acknowledgement
        ) {
            ...DisclosureAcknowledgementFragment
        }
    }
    ${DisclosureAcknowledgementFragment}
`;
export const UpdateEvergreenDataSource = gql`
    mutation updateEvergreenDataSource($input: EvergreenDataSourceInput!) {
        updateEvergreenDataSource(input: $input) {
            id
        }
    }
`;
export const UpdateEvergreenImport = gql`
    mutation updateEvergreenImport($input: EvergreenImportInput!) {
        updateEvergreenImport(input: $input) {
            id
        }
    }
`;
export const GetEvergreenDataSources = gql`
    query getEvergreenDataSources {
        getEvergreenDataSources {
            id
            name
            lastSuccessfulRun
            updateFrequencyInDays
            updatedAt
            dateAdded
            updateRequested
            warningsSilenced
            duplicatesDetectionWindowInDays
        }
    }
`;
export const GetEvergreenDataSource = gql`
    query getEvergreenDataSource($name: String!) {
        getEvergreenDataSource(sourceName: $name) {
            id
            name
            lastSuccessfulRun
            updateFrequencyInDays
            warningsSilenced
            duplicatesDetectionWindowInDays
            updatedAt
            dateAdded
            updateRequested
            imports {
                ...ImportFragment
            }
        }
    }
    ${ImportFragment}
`;
export const GetEvergreenImports = gql`
    query getEvergreenImports($skip: Int!, $take: Int!) {
        getEvergreenImports(skip: $skip, take: $take) {
            ...ImportFragment
        }
    }
    ${ImportFragment}
`;
export const UpdateFeatureFlag = gql`
    mutation updateFeatureFlag($name: ID!, $enabled: Boolean!) {
        updateFeatureFlag(name: $name, enabled: $enabled) {
            name
            enabled
        }
    }
`;
export const GetGlobalFeatures = gql`
    query getGlobalFeatures {
        getGlobalFeatures {
            skus
            features {
                actual {
                    name
                    enabled
                }
                skus
                key
                friendlyTitle
                description
                createdOn
                intent
                packageEnabled
            }
        }
    }
`;
export const GetFiles = gql`
    query getFiles($entityId: FileEntityId!) {
        getFiles(entityId: $entityId) {
            files {
                ...FileFragment
            }
        }
    }
    ${FileFragment}
`;
export const GetFile = gql`
    query getFile($fileId: FileId!) {
        getFile(fileId: $fileId) {
            ...FileFragment
            outstandingSignatureRequestsCount
            accessControls {
                id
                groupId
            }
        }
    }
    ${FileFragment}
`;
export const UpdateFile = gql`
    mutation updateFile(
        $fileId: FileId!
        $details: FileUpdateInput!
        $accessControls: [AccessControlInput!]
        $regionVisibility: EntityRegionVisibilityInput
    ) {
        updateFile(
            fileId: $fileId
            details: $details
            accessControls: $accessControls
            regionVisibility: $regionVisibility
        ) {
            ...FileFragment
            accessControls {
                id
                groupId
            }
            outstandingSignatureRequestsCount
        }
    }
    ${FileFragment}
`;
export const DeleteFile = gql`
    mutation deleteFile($fileId: FileId!) {
        deleteFile(fileId: $fileId)
    }
`;
export const DeleteHostedForm = gql`
    mutation deleteHostedForm($hostedFormId: HostedFormId!) {
        deleteHostedForm(hostedFormId: $hostedFormId)
    }
`;
export const CreateHostedForm = gql`
    mutation createHostedForm {
        createHostedForm {
            id
        }
    }
`;
export const PatchHostedForm = gql`
    mutation patchHostedForm($params: PatchHostedFormRequest!) {
        patchHostedForm(params: $params) {
            id
        }
    }
`;
export const GetHostedForms = gql`
    query getHostedForms {
        getHostedForms {
            id
            name
            displayName
            dateAdded
            configuration {
                style
                regions {
                    regionId
                    userId
                    recipientUserIds
                }
            }
            layoutSections {
                id
                order
                sectionKey
                visible
                layoutItems {
                    id
                    order
                    questionId
                    entityFieldName
                    showIfBlank
                }
            }
            customizable
            url
        }
    }
`;
export const SendHostedFormWithToken = gql`
    mutation sendHostedFormWithToken($params: SendHostedFormWithTokenRequest!) {
        sendHostedFormWithToken(params: $params) {
            token {
                id
                expiresAt
                updatedAt
                dateAdded
                createdBy
                updatedBy
            }
        }
    }
`;
export const SendHostedFormWithPermalink = gql`
    mutation sendHostedFormWithPermalink($params: SendHostedFormWithPermalinkRequest!) {
        sendHostedFormWithPermalink(params: $params)
    }
`;
export const GetHostedFormTokensForEntity = gql`
    query getHostedFormTokensForEntity($entityId: HostedFormEntityId!) {
        getHostedFormTokensForEntity(entityId: $entityId) {
            id
            expiresAt
            completedAt
            hostedFormId
            updatedAt
            dateAdded
            createdBy
            updatedBy
            status
            sentMessages {
                to
            }
        }
    }
`;
export const DeleteHostedFormToken = gql`
    mutation deleteHostedFormToken($tokenId: HostedFormTokenId!) {
        deleteHostedFormToken(tokenId: $tokenId)
    }
`;
export const RelocateImage = gql`
    mutation relocateImage($payload: RelocateImageInput!) {
        relocateImage(payload: $payload) {
            id
            src
        }
    }
`;
export const CreateInvoice = gql`
    mutation createInvoice(
        $clientId: ClientId!
        $serial: String
        $amountCents: CurrencyInCents!
        $status: String!
        $sentDate: String
        $dueDate: String
        $generatePdf: Boolean!
        $notes: String
        $message: OutboundMessage
        $lineItems: [InvoiceLineItemInput!]
        $paymentSchedule: InvoicePaymentScheduleInput
        $externalReference: String
    ) {
        createInvoice(
            clientId: $clientId
            serial: $serial
            amountCents: $amountCents
            status: $status
            sentDate: $sentDate
            dueDate: $dueDate
            generatePdf: $generatePdf
            notes: $notes
            message: $message
            lineItems: $lineItems
            paymentSchedule: $paymentSchedule
            externalReference: $externalReference
        ) {
            invoices {
                id
                serial
            }
            errors {
                message
                fields {
                    name
                    message
                }
            }
        }
    }
`;
export const ResendInvoice = gql`
    mutation resendInvoice($invoiceId: InvoiceId!, $message: OutboundMessage!) {
        resendInvoice(invoiceId: $invoiceId, message: $message) {
            invoices {
                id
                serial
            }
            errors {
                message
                fields {
                    name
                    message
                }
            }
        }
    }
`;
export const GenerateInvoice = gql`
    mutation generateInvoice($id: InvoiceId!) {
        generateInvoice(invoiceId: $id) {
            invoices {
                id
                serial
            }
            errors {
                message
                fields {
                    name
                    message
                }
            }
        }
    }
`;
export const UpdateInvoice = gql`
    mutation updateInvoice(
        $invoiceId: InvoiceId!
        $status: String!
        $sentDate: String
        $paidDate: String
        $serial: String!
        $amountCents: CurrencyInCents!
        $notes: String
        $dueDate: String
        $lineItems: [InvoiceLineItemInput!]
        $paymentSchedule: InvoicePaymentScheduleInput
        $externalReference: String
    ) {
        updateInvoice(
            invoiceId: $invoiceId
            status: $status
            sentDate: $sentDate
            paidDate: $paidDate
            serial: $serial
            amountCents: $amountCents
            notes: $notes
            dueDate: $dueDate
            lineItems: $lineItems
            paymentSchedule: $paymentSchedule
            externalReference: $externalReference
        ) {
            invoices {
                id
                clientId
                communityId
            }
            errors {
                message
                fields {
                    name
                    message
                }
            }
        }
    }
`;
export const CreateExternalInvoices = gql`
    mutation createExternalInvoices($params: ExternalInvoicesCreateRequest!) {
        createExternalInvoices(params: $params) {
            invoices {
                id
                serial
            }
            errors {
                message
                fields {
                    name
                    message
                }
            }
        }
    }
`;
export const GetInvoiceCreateExperience = gql`
    query getInvoiceCreateExperience {
        getInvoiceCreateExperience {
            experience
        }
    }
`;
export const GetExternalInvoiceAccountSettings = gql`
    query getExternalInvoiceAccountSettings {
        getExternalInvoiceAccountSettings {
            paymentTerms {
                id
                name
                dueDays
                default
            }
        }
    }
`;
export const SearchExternalInvoices = gql`
    query searchExternalInvoices($params: ExternalInvoiceSearchRequest!) {
        searchExternalInvoices(params: $params) {
            count
            hits {
                externalId
                serial
                customer
                invoiceDate
                dueDate
                amountInCents
            }
        }
    }
`;
export const AddExistingExternalInvoice = gql`
    mutation addExistingExternalInvoice($params: AddExternalInvoiceRequest!) {
        addExistingExternalInvoice(params: $params) {
            id
        }
    }
`;
export const SearchExternalInvoiceCustomers = gql`
    query searchExternalInvoiceCustomers($params: ExternalInvoiceCustomerSearchRequest!) {
        searchExternalInvoiceCustomers(params: $params) {
            count
            hits {
                id
                name
            }
        }
    }
`;
export const GetExternalInvoiceInitialParameters = gql`
    query getExternalInvoiceInitialParameters(
        $params: GetExternalInvoiceInitialParametersRequest!
    ) {
        getExternalInvoiceInitialParameters(params: $params) {
            customer {
                id
                name
            }
            createCustomerName
        }
    }
`;
export const CreateExternalInvoiceCustomer = gql`
    mutation createExternalInvoiceCustomer(
        $params: CreateExternalInvoiceCustomerRequest!
    ) {
        createExternalInvoiceCustomer(params: $params) {
            id
            name
        }
    }
`;
export const DeleteInvoice = gql`
    mutation deleteInvoice($invoiceId: InvoiceId!) {
        deleteInvoice(invoiceId: $invoiceId)
    }
`;
export const GetLayoutSection = gql`
    query getLayoutSection($id: LayoutSectionId!) {
        getLayoutSection(id: $id) {
            ...LayoutSectionBasics
        }
    }
    ${LayoutSectionBasics}
`;
export const CreateLayoutSection = gql`
    mutation createLayoutSection($params: CreateLayoutSectionInput!) {
        createLayoutSection(params: $params) {
            ...LayoutSectionBasics
        }
    }
    ${LayoutSectionBasics}
`;
export const PatchLayoutSection = gql`
    mutation patchLayoutSection($params: PatchLayoutSectionInput!) {
        patchLayoutSection(params: $params) {
            ...LayoutSectionBasics
        }
    }
    ${LayoutSectionBasics}
`;
export const PatchLayoutSections = gql`
    mutation patchLayoutSections($params: PatchLayoutSectionsInput!) {
        patchLayoutSections(params: $params) {
            ...LayoutSectionBasics
        }
    }
    ${LayoutSectionBasics}
`;
export const PatchLayoutItem = gql`
    mutation patchLayoutItem($params: PatchLayoutItemInput!) {
        patchLayoutItem(params: $params) {
            ...LayoutItemBasics
        }
    }
    ${LayoutItemBasics}
`;
export const DeleteLayoutSection = gql`
    mutation deleteLayoutSection($id: LayoutSectionId!) {
        deleteLayoutSection(id: $id)
    }
`;
export const GetLayoutSections = gql`
    query getLayoutSections($parentKey: LayoutSectionParentKey!) {
        getLayoutSections(parentKey: $parentKey) {
            layoutSections {
                ...LayoutSectionBasics
            }
        }
    }
    ${LayoutSectionBasics}
`;
export const GetLayoutContainerByKey = gql`
    query getLayoutContainerByKey($key: String!) {
        getLayoutContainerByKey(key: $key) {
            id
            title
            key
            layoutSections {
                ...LayoutSectionBasics
            }
        }
    }
    ${LayoutSectionBasics}
`;
export const GetClientIntakeFaxRecipients = gql`
    query getClientIntakeFaxRecipients($clientId: ClientId!) {
        getClientIntakeFaxRecipients(clientId: $clientId) {
            faxNumber
            communityName
            communityId
        }
    }
`;
export const SendClientIntakeFax = gql`
    mutation sendClientIntakeFax($message: OutboundClientIntakeFax!) {
        sendClientIntakeFax(message: $message)
    }
`;
export const ComposeMessage = gql`
    query composeMessage($entityId: EmailEntityId!, $specialMessageExperience: String) {
        getTemplates {
            templates {
                isDefaultSignature
                template {
                    id
                    body
                    subject
                    description
                    type
                    migrationVersion
                    attachments
                }
            }
        }
        composeMessage(
            id: $entityId
            specialMessageExperience: $specialMessageExperience
        ) {
            recipients
            specialCommunityComparisonDefaults {
                showSummary
                showRecords
                showCoverPage
                coverPageTemplateId
                liveLinkText
                attachCommunityPDFByDefault
                useLiveLinkByDefault
            }
            files {
                entityName
                files {
                    id
                    originalFilename
                    mimeType
                    byteLength
                }
            }
        }
    }
`;
export const ComposeMessageBasic = gql`
    query composeMessageBasic {
        getTemplates {
            templates {
                isDefaultSignature
                template {
                    id
                    body
                    subject
                    description
                    type
                    migrationVersion
                    attachments
                }
            }
        }
    }
`;
export const GetMessagesForEntity = gql`
    query getMessagesForEntity($id: EmailEntityId!) {
        getMessagesForEntity(entityId: $id) {
            id
            subject
            status
            latestEventStatus
            createdAt
            to
            messageType
            attachments
            clientIdUuid
            communityIdUuid
            clickedLinkEvents {
                link
                events
                redacted
                eventsV2 {
                    timestamp
                    recipient
                }
            }
            intakeCommunity {
                id
                name
                appLink
            }
            intakeClient {
                id
                name
                bestContactName
                appLink
            }
            events {
                eventType
                eventTimestamp
                recipient
            }
        }
    }
`;
export const GetMessage = gql`
    query getMessage($id: SentMessageId!) {
        getMessage(sentMessageId: $id) {
            id
            subject
            bouncedRecipients
            status
            createdAt
            body
            to
            messageType
            attachments
            intakeCommunity {
                name
            }
            invoice {
                id
                serial
            }
            clickedLinkEvents {
                link
                events
                redacted
                eventsV2 {
                    timestamp
                    recipient
                }
            }
            events {
                eventType
                eventTimestamp
                recipient
            }
        }
    }
`;
export const SendMessage = gql`
    mutation sendMessage($message: OutboundMessage!) {
        sendMessage(message: $message) {
            status
            sentMessage {
                id
            }
            accepted
            rejected
            code
        }
    }
`;
export const ValidateTemplate = gql`
    query validateTemplate($emailTemplateId: EmailTemplateId!, $messageType: String!) {
        validateTemplate(emailTemplateId: $emailTemplateId, messageType: $messageType) {
            isValid
            message
            missingTokens {
                tokenKey
                description
                humanFriendlyName
            }
        }
    }
`;
export const GetMessagingConfiguration = gql`
    query getMessagingConfiguration {
        getMessagingConfiguration {
            user {
                directSmtp
            }
            tenant {
                customDomains
                trackingLevel
            }
        }
    }
`;
export const CreateNote = gql`
    mutation createNote(
        $entityType: String!
        $entityId: NoteEntityId!
        $params: CreateNoteInput!
    ) {
        createNote(entityType: $entityType, entityId: $entityId, params: $params) {
            ...NoteResponse
        }
    }
    ${NoteResponse}
`;
export const UpdateNote = gql`
    mutation updateNote(
        $entityId: NoteEntityId!
        $noteId: NoteId!
        $params: UpdateNoteInput!
    ) {
        updateNote(entityId: $entityId, noteId: $noteId, params: $params) {
            ...NoteResponse
        }
    }
    ${NoteResponse}
`;
export const DeleteNote = gql`
    mutation deleteNote(
        $entityType: String!
        $entityId: NoteEntityId!
        $noteId: NoteId!
    ) {
        deleteNote(entityType: $entityType, entityId: $entityId, noteId: $noteId) {
            _id
        }
    }
`;
export const GetNotesForEntity = gql`
    query getNotesForEntity($entityId: NoteEntityId!, $filter: GetNotesForEntityFilter) {
        getNotesForEntity(entityId: $entityId, filter: $filter) {
            ...NoteResponse
        }
    }
    ${NoteResponse}
`;
export const GetPhotosForEntity = gql`
    query getPhotosForEntity($entityId: FileEntityId!) {
        getPhotosForEntity(entityId: $entityId) {
            ...PhotoBasics
        }
    }
    ${PhotoBasics}
`;
export const PatchPhoto = gql`
    mutation patchPhoto($params: PatchPhotoInput!) {
        patchPhoto(params: $params) {
            ...PhotoBasics
        }
    }
    ${PhotoBasics}
`;
export const DeletePhoto = gql`
    mutation deletePhoto($params: DeletePhotoInput!) {
        deletePhoto(params: $params)
    }
`;
export const PatchPhotos = gql`
    mutation patchPhotos($params: [PatchPhotoInput!]!) {
        patchPhotos(params: $params) {
            ...PhotoBasics
        }
    }
    ${PhotoBasics}
`;
export const BulkCreateLegacyQuestions = gql`
    mutation bulkCreateLegacyQuestions($payload: QuestionBulkCreatePayload!) {
        bulkCreateLegacyQuestions(bulkPayload: $payload) {
            id
        }
    }
`;
export const GetLegacyQuestionsForTenant = gql`
    query getLegacyQuestionsForTenant($tenantId: TenantId!) {
        getLegacyQuestionsForTenant(tenantId: $tenantId) {
            id
            key
            order
            category
            clientTitle
            communityTitle
            questionType
            clientInteraction
            clientPdfBehavior
            clientSearchBehavior
            clientAnnotation
            communityInteraction
            communityPdfBehavior
            communitySearchBehavior
            communityAnnotation
            clientRulesString
            communityRulesString
            prompts {
                id
                client
                community
                key
                order
            }
        }
    }
`;
export const ReorderLegacyQuestions = gql`
    mutation reorderLegacyQuestions($questionReorderPayload: QuestionReorderPayload!) {
        reorderLegacyQuestions(questionReorderPayload: $questionReorderPayload)
    }
`;
export const GetReferenceContact = gql`
    query getReferenceContact($id: ReferenceContactId!) {
        getReferenceContact(referenceContactId: $id) {
            ...ReferenceContactBasics
        }
    }
    ${ReferenceContactBasics}
`;
export const GetReferenceBusiness = gql`
    query getReferenceBusiness($id: ReferenceBusinessId!) {
        getReferenceBusiness(referenceBusinessId: $id) {
            ...ReferenceBusinessBasics
        }
    }
    ${ReferenceBusinessBasics}
`;
export const ReferenceContactSearch = gql`
    query referenceContactSearch($search: ReferenceContactSearchRequest!) {
        referenceContactSearch(search: $search) {
            total
            coordinates {
                lat
                lng
            }
            hits {
                id
                name
                role
                updatedAt
                organizationName
                organizationType
                organizationPhone
                organizationFax
                address
                city
                state
                zip
                email
                cellPhone
                phone
                numberOfReferrals
                mostRecentReferral
                assignedTo
                businessId
                communityId
                summary
                latitude
                longitude
            }
        }
    }
`;
export const ReferenceContactMapSearch = gql`
    query referenceContactMapSearch(
        $searchParams: ReferenceContactSearchRequest!
        $mapView: CommunitySearchRequestMapComponent!
    ) {
        referenceContactMapSearch(searchParams: $searchParams, mapView: $mapView) {
            referenceContactCoordinates {
                businessId
                communityId
                name
                lat
                lng
                appLink
                contacts {
                    id
                    name
                    appLink
                }
            }
            referenceContactClusters {
                lat
                lng
                count
            }
            recommendedZoom
            coordinates {
                lat
                lng
            }
        }
    }
`;
export const ReferenceBusinessSearch = gql`
    query referenceBusinessSearch($search: ReferenceBusinessSearchRequest!) {
        referenceBusinessSearch(search: $search) {
            total
            coordinates {
                lat
                lng
            }
            hits {
                id
                name
                updatedAt
                summary
                organizationType
                city
                state
                zip
                address
                phone
                fax
                numberOfReferrals
                mostRecentReferral
                assignedTo
                latitude
                longitude
            }
        }
    }
`;
export const ReferenceBusinessMapSearch = gql`
    query referenceBusinessMapSearch(
        $searchParams: ReferenceBusinessSearchRequest!
        $mapView: CommunitySearchRequestMapComponent!
    ) {
        referenceBusinessMapSearch(searchParams: $searchParams, mapView: $mapView) {
            referenceBusinessCoordinates {
                id
                name
                lat
                lng
                appLink
            }
            referenceBusinessClusters {
                lat
                lng
                count
            }
            recommendedZoom
            coordinates {
                lat
                lng
            }
        }
    }
`;
export const ReferenceCommunitySearch = gql`
    query referenceCommunitySearch($search: ReferenceCommunitySearchRequest!) {
        referenceCommunitySearch(search: $search) {
            total
            hits {
                id
                name
                updatedAt
                city
                state
                zip
                address
                phone
                fax
                summary
                numberOfReferrals
                mostRecentReferral
                latitude
                longitude
            }
        }
    }
`;
export const ReferenceCommunityMapSearch = gql`
    query referenceCommunityMapSearch(
        $searchParams: ReferenceCommunitySearchRequest!
        $mapView: CommunitySearchRequestMapComponent!
    ) {
        referenceCommunityMapSearch(searchParams: $searchParams, mapView: $mapView) {
            communityCoordinates {
                id
                name
                lat
                lng
                appLink
            }
            communityClusters {
                lat
                lng
                count
            }
            recommendedZoom
            coordinates {
                lat
                lng
            }
        }
    }
`;
export const GetReferralsToContact = gql`
    query getReferralsToContact($referenceContactId: ReferenceContactId!) {
        getReferralsToContact(referenceContactId: $referenceContactId) {
            ...ReferralBasics
        }
    }
    ${ReferralBasics}
`;
export const CreateReferenceContact = gql`
    mutation createReferenceContact($regionId: RegionId!) {
        createReferenceContact(regionId: $regionId) {
            id
        }
    }
`;
export const PatchReferenceContact = gql`
    mutation patchReferenceContact(
        $id: ReferenceContactId!
        $summary: String
        $ownerId: UserId
        $businessId: ReferenceBusinessId
        $communityId: CommunityId
        $contactEntity: ContactInput
        $deleted: Boolean
        $draftNote: String
    ) {
        patchReferenceContact(
            id: $id
            summary: $summary
            ownerId: $ownerId
            businessId: $businessId
            communityId: $communityId
            contactEntity: $contactEntity
            deleted: $deleted
            draftNote: $draftNote
        ) {
            ...ReferenceContactBasics
        }
    }
    ${ReferenceContactBasics}
`;
export const CreateReferenceBusiness = gql`
    mutation createReferenceBusiness($regionId: RegionId!) {
        createReferenceBusiness(regionId: $regionId) {
            id
        }
    }
`;
export const PatchReferenceBusiness = gql`
    mutation patchReferenceBusiness(
        $id: ReferenceBusinessId!
        $summary: String
        $ownerId: UserId
        $businessContactEntity: ContactInput
        $deleted: Boolean
        $draftNote: String
    ) {
        patchReferenceBusiness(
            id: $id
            summary: $summary
            ownerId: $ownerId
            businessContactEntity: $businessContactEntity
            deleted: $deleted
            draftNote: $draftNote
        ) {
            ...ReferenceBusinessBasics
        }
    }
    ${ReferenceBusinessBasics}
`;
export const GetReferralsToBusiness = gql`
    query getReferralsToBusiness($referenceBusinessId: ReferenceBusinessId!) {
        getReferralsToBusiness(referenceBusinessId: $referenceBusinessId) {
            ...ReferralBasics
        }
    }
    ${ReferralBasics}
`;
export const GeocodeReferenceBusinesses = gql`
    mutation geocodeReferenceBusinesses {
        geocodeReferenceBusinesses
    }
`;
export const GetRegion = gql`
    query getRegion($id: RegionId!, $tenantId: TenantId!) {
        getRegion(regionId: $id, tenantId: $tenantId) {
            id
            key
            name
            regionLicenseMaps {
                id
                autoPropagateEnabled
                identityFilterObjects {
                    state
                    zip
                    county
                }
                licenseMappingObjects {
                    licenseIdentifier
                    promptId
                    questionId
                    annotationOnly
                }
                settings {
                    mode
                    updateAttributes
                    closedHandling
                }
            }
        }
    }
`;
export const GetStateReport = gql`
    query getStateReport {
        getStateReport {
            thecount
            totalrlmcount
            state
            regionid
            regionkey
            regionname
            tenantname
            tenantid
        }
    }
`;
export const PropagateChanges = gql`
    mutation propagateChanges($tenantId: TenantId!, $regionId: RegionId!) {
        createCommunitiesInRegion(tenantId: $tenantId, regionId: $regionId) {
            propagations {
                regionLicenseMapId
                communitiesCreated
                communitiesMatched
                communitiesWithExistingLinks
            }
        }
    }
`;
export const CreateOrUpdateRegionLicenseMap = gql`
    mutation createOrUpdateRegionLicenseMap(
        $regionLicenseMap: RegionLicenseMapUpdateRequest!
    ) {
        createOrUpdateRegionLicenseMap(params: $regionLicenseMap) {
            id
        }
    }
`;
export const DeleteRegionLicenseMap = gql`
    mutation deleteRegionLicenseMap($id: RegionLicenseMapId!) {
        deleteRegionLicenseMap(regionLicenseMapId: $id)
    }
`;
export const GetEntityRegionPermissions = gql`
    query getEntityRegionPermissions($params: GetEntityRegionPermissionsParams!) {
        getEntityRegionPermissions(params: $params) {
            editableRegions {
                id
                key
                name
            }
        }
    }
`;
export const ClientPlacementRevenue = gql`
    query ClientPlacementRevenue(
        $startDate: String!
        $endDate: String!
        $region: String!
    ) {
        reportClientPlacementRevenue(
            startDate: $startDate
            endDate: $endDate
            region: $region
        ) {
            segments {
                startDate
                revenue
                moves
                users {
                    user {
                        id
                        displayName
                    }
                    revenue
                    moves
                }
            }
        }
    }
`;
export const InboundReferrals = gql`
    query InboundReferrals($startDate: String!, $endDate: String!, $region: String!) {
        reportInboundReferrals(
            region: $region
            startDate: $startDate
            endDate: $endDate
        ) {
            generic {
                referralToken
                count
            }
            businesses {
                count
                business {
                    id
                    businessContactEntity {
                        name
                        contactType
                    }
                }
            }
            contacts {
                count
                contact {
                    id
                    contactEntity {
                        name
                    }
                }
            }
        }
    }
`;
export const Referrals = gql`
    query Referrals($startDate: String!, $endDate: String!, $region: String!) {
        reportReferrals(region: $region, startDate: $startDate, endDate: $endDate) {
            generic {
                referralToken
                count
            }
            businesses {
                inboundCount
                outboundCount
                business {
                    id
                    businessContactEntity {
                        name
                        contactType
                    }
                }
            }
            contacts {
                inboundCount
                outboundCount
                contact {
                    id
                    contactEntity {
                        name
                    }
                }
            }
        }
    }
`;
export const GetSignatureTemplate = gql`
    query getSignatureTemplate($fileId: FileId!) {
        getFile(fileId: $fileId) {
            originalFilename
        }
        getSignatureTemplate(fileId: $fileId) {
            id
            recipientCount
            emailTemplate {
                subject
                body
                attachments
            }
            signatureTemplateFields {
                defaultTextValue
                defaultBooleanValue
                isRequired
                placeholder
                fieldType
                font
                fontSize
                height
                id
                order
                page
                recipientIndex
                width
                x
                y
            }
        }
    }
`;
export const SetSignatureTemplate = gql`
    mutation setSignatureTemplate(
        $fileId: FileId!
        $signatureTemplate: SignatureTemplateInput!
    ) {
        setSignatureTemplate(fileId: $fileId, signatureTemplate: $signatureTemplate) {
            id
        }
    }
`;
export const GetSignableTemplates = gql`
    query getSignableTemplates($entityType: FileEntityType!) {
        getSignableTemplates(entityType: $entityType) {
            originalFilename
            id
        }
    }
`;
export const GetSignatureRequestsForEntity = gql`
    query getSignatureRequestsForEntity($entityId: FileEntityId!) {
        getSignatureRequestsForEntity(entityId: $entityId) {
            id
            createdAt
            updatedAt
            filename
            sourceFileId
            signatureTemplateFileId
            state
            signatureRequestRecipients {
                email
                isComplete
                id
                order
                remindAfter
            }
        }
    }
`;
export const GetSignatureRequest = gql`
    query getSignatureRequest($signatureRequestId: SignatureRequestId!) {
        getSignatureRequest(signatureRequestId: $signatureRequestId) {
            id
            createdAt
            updatedAt
            filename
            sourceFileId
            signatureTemplateFileId
            state
            signatureRequestFields {
                id
                page
                recipientIndex
                order
                x
                y
                width
                height
                font
                fontSize
                fieldType
                textValue
                booleanValue
                isRequired
                placeholder
                filledBy
            }
            signatureRequestRecipients {
                id
                order
                remindAfter
                remindersSent
                createdAt
                updatedAt
                completedAt
                email
                name
                isComplete
            }
            ccRecipients {
                email
                name
            }
        }
    }
`;
export const PlaceholderPerspective = gql`
    query placeholderPerspective {
        placeholderPerspective {
            recipientIndex
        }
    }
`;
export const CreateSignatureRequest = gql`
    mutation createSignatureRequest($signatureRequestPayload: SignatureRequestPayload!) {
        createSignatureRequest(signatureRequestPayload: $signatureRequestPayload) {
            id
            signatureRequestRecipients {
                id
            }
        }
    }
`;
export const GetDraftSignatureRequest = gql`
    query getDraftSignatureRequest(
        $entityId: FileEntityId!
        $fileId: FileId!
        $signatureRequestId: SignatureRequestId
    ) {
        getFile(fileId: $fileId) {
            originalFilename
        }
        getDraftSignatureRequest(
            entityId: $entityId
            fileId: $fileId
            signatureRequestId: $signatureRequestId
        ) {
            potentialRecipients {
                name
                email
                source
            }
            signatureTemplate {
                id
                recipientCount
                signatureTemplateFields {
                    isRequired
                    defaultTextValue
                    defaultBooleanValue
                    placeholder
                    fieldType
                    font
                    fontSize
                    height
                    id
                    order
                    page
                    recipientIndex
                    width
                    x
                    y
                }
            }
            actualRecipients {
                name
                email
            }
            draftFields {
                signatureTemplateFieldId
                textValue
                booleanValue
                placeholder
                isRequired
            }
            emailTemplate {
                subject
                body
                attachments
            }
            entityType
            ccRecipients {
                name
                email
            }
        }
    }
`;
export const ResendSignatureRequest = gql`
    mutation resendSignatureRequest($signatureRequestId: SignatureRequestId!) {
        resendSignatureRequest(signatureRequestId: $signatureRequestId)
    }
`;
export const AbandonSignatureRequest = gql`
    mutation abandonSignatureRequest($signatureRequestId: SignatureRequestId!) {
        abandonSignatureRequest(signatureRequestId: $signatureRequestId)
    }
`;
export const CreateSmtpCredential = gql`
    mutation createSmtpCredential($params: CreateSmtpCredentialRequest!) {
        createSmtpCredential(params: $params) {
            success
            message
        }
    }
`;
export const GetSmtpCredentialForUser = gql`
    query getSmtpCredentialForUser {
        getSmtpCredentialForUser {
            ...SmtpCredentialBasics
        }
    }
    ${SmtpCredentialBasics}
`;
export const DeleteSmtpCredentialForUser = gql`
    mutation deleteSmtpCredentialForUser {
        deleteSmtpCredentialForUser
    }
`;
export const System = gql`
    query system {
        jobStats {
            complete
            pending
            available
            started
            failed
        }
        systemConfiguration {
            fileStorage
        }
    }
`;
export const GetTask = gql`
    query getTask($regionId: RegionId!, $taskId: TaskId!) {
        getTask(regionId: $regionId, taskId: $taskId) {
            task {
                ...TaskBasics
            }
            entity {
                displayName
                path
                kind
            }
        }
    }
    ${TaskBasics}
`;
export const GetTasks = gql`
    query getTasks($activeOnly: Boolean!, $regionId: ID!, $page: Int!) {
        getTasks(activeOnly: $activeOnly, regionId: $regionId, page: $page) {
            page
            totalPages
            tasks {
                task {
                    ...TaskBasics
                }
                entity {
                    displayName
                    path
                    kind
                }
            }
        }
    }
    ${TaskBasics}
`;
export const GetTasksv2 = gql`
    query getTasksv2($params: GetTasksRequest!) {
        getTasksv2(params: $params) {
            task {
                ...TaskBasics
            }
            entity {
                displayName
                path
                kind
                contact {
                    name
                    phoneNumber
                    contactType
                }
            }
        }
    }
    ${TaskBasics}
`;
export const GetNextTasksForParentIds = gql`
    query getNextTasksForParentIds($parentIds: [TaskParentId!]!) {
        getNextTasksForParentIds(parentIds: $parentIds) {
            ...TaskBasics
        }
    }
    ${TaskBasics}
`;
export const GetTasksForEntity = gql`
    query getTasksForEntity($parentId: TaskParentId!) {
        getTasksForEntity(parentId: $parentId) {
            ...TaskDetails
        }
    }
    ${TaskDetails}
`;
export const CreateTask = gql`
    mutation createTask($parentId: TaskParentId!, $params: CreateTaskPayload!) {
        createTask(parentId: $parentId, params: $params) {
            ...TaskDetails
        }
    }
    ${TaskDetails}
`;
export const UpdateTask = gql`
    mutation updateTask($taskId: TaskId!, $params: UpdateTaskPayload!) {
        updateTask(taskId: $taskId, params: $params) {
            ...TaskDetails
        }
    }
    ${TaskDetails}
`;
export const DeleteTask = gql`
    mutation deleteTask($taskId: TaskId!) {
        deleteTask(taskId: $taskId)
    }
`;
export const GetTaskTypes = gql`
    query getTaskTypes {
        getTaskTypes {
            id
            name
            isArchived
        }
    }
`;
export const CreateTaskType = gql`
    mutation createTaskType($payload: CreateTaskTypePayload!) {
        createTaskType(payload: $payload) {
            id
        }
    }
`;
export const UpdateTaskType = gql`
    mutation updateTaskType($payload: UpdateTaskTypePayload!) {
        updateTaskType(payload: $payload) {
            id
        }
    }
`;
export const GetTemplate = gql`
    query getTemplate($id: EmailTemplateId!) {
        getTemplate(emailTemplateId: $id) {
            isEditable
            isDefaultSignature
            template {
                id
                body
                description
                isShared
                type
                subject
                migrationVersion
                attachments
                regionVisibility {
                    editable
                    regions
                }
            }
            author {
                name
                email
            }
        }
    }
`;
export const GetTemplates = gql`
    query getTemplates {
        getTemplates {
            templates {
                isEditable
                isDefaultSignature
                template {
                    id
                    body
                    description
                    isShared
                    type
                    subject
                    migrationVersion
                    attachments
                    regionVisibility {
                        editable
                        regions
                    }
                }
                author {
                    name
                    email
                }
            }
        }
    }
`;
export const CanCreateSignatureTemplate = gql`
    query canCreateSignatureTemplate($fileId: FileId!) {
        canCreateSignatureTemplate(fileId: $fileId)
    }
`;
export const DeleteSignatureTemplate = gql`
    mutation deleteSignatureTemplate($signatureTemplateId: SignatureTemplateId!) {
        deleteSignatureTemplate(signatureTemplateId: $signatureTemplateId) {
            success
            message
        }
    }
`;
export const CreateOrUpdateTemplate = gql`
    mutation createOrUpdateTemplate($template: TemplateUpdate!) {
        createOrUpdateTemplate(template: $template) {
            template {
                id
            }
        }
    }
`;
export const DeleteTemplate = gql`
    mutation deleteTemplate($id: EmailTemplateId!) {
        deleteTemplate(emailTemplateId: $id)
    }
`;
export const GetTenantCredentials = gql`
    query getTenantCredentials {
        getTenantCredentials {
            id
            type
            status
            quickBooksDetails {
                realmId
            }
        }
    }
`;
export const DeleteTenantCredential = gql`
    mutation deleteTenantCredential($id: TenantCredentialId!) {
        deleteTenantCredential(id: $id)
    }
`;
export const CreateTenantRegion = gql`
    mutation createTenantRegion($tenantId: TenantId!, $name: String!, $key: String!) {
        createTenantRegion(tenantId: $tenantId, name: $name, key: $key) {
            id
        }
    }
`;
export const CreateTenant = gql`
    mutation createTenant {
        createTenant {
            id
        }
    }
`;
export const UpdateTenant = gql`
    mutation updateTenant(
        $id: TenantId!
        $name: String
        $dateAdded: String
        $features: [String!]
        $sku: String
        $billing: TenantBillingPayloadInput
        $customDomains: [String!]
        $activatedAt: String
        $deactivatedAt: String
        $onboardingStartedAt: String
        $demoStartedAt: String
        $demoEndedAt: String
        $premiumActivatedAt: String
        $premiumDeactivatedAt: String
        $emailProvider: ExternalEmailProvider
        $internalEmailSender: String
        $supportEmailAddress: String
        $customProductName: String
        $customAppDomain: String
        $whiteLabelContentMode: WhiteLabelContentMode
    ) {
        updateTenant(
            id: $id
            name: $name
            dateAdded: $dateAdded
            features: $features
            sku: $sku
            billing: $billing
            customDomains: $customDomains
            activatedAt: $activatedAt
            deactivatedAt: $deactivatedAt
            onboardingStartedAt: $onboardingStartedAt
            demoStartedAt: $demoStartedAt
            demoEndedAt: $demoEndedAt
            premiumActivatedAt: $premiumActivatedAt
            premiumDeactivatedAt: $premiumDeactivatedAt
            emailProvider: $emailProvider
            internalEmailSender: $internalEmailSender
            supportEmailAddress: $supportEmailAddress
            customProductName: $customProductName
            customAppDomain: $customAppDomain
            whiteLabelContentMode: $whiteLabelContentMode
        ) {
            id
        }
    }
`;
export const AddTenantLogEntry = gql`
    mutation addTenantLogEntry($tenantId: TenantId!, $message: String!) {
        tenantInternalUpdate(tenantId: $tenantId, messages: [$message])
    }
`;
export const UpdateTenantInternalAttributes = gql`
    mutation updateTenantInternalAttributes(
        $tenantId: TenantId!
        $internalAttributes: DictionaryInput!
    ) {
        tenantInternalUpdate(tenantId: $tenantId, internalAttributes: $internalAttributes)
    }
`;
export const UpdateRegionName = gql`
    mutation updateRegionName(
        $tenantId: TenantId!
        $regionId: RegionId!
        $name: String!
    ) {
        updateRegion(tenantId: $tenantId, regionId: $regionId, name: $name) {
            id
        }
    }
`;
export const ModifyUserRole = gql`
    mutation modifyUserRole(
        $tenantId: TenantId!
        $userId: UserId!
        $regionKey: ID!
        $role: String!
    ) {
        modifyUserRole(
            tenantId: $tenantId
            userId: $userId
            regionKey: $regionKey
            role: $role
        )
    }
`;
export const GetTenants = gql`
    query getTenants {
        getTenants {
            id
            name
            activeUsers
            activeHumanUsers
            sku
        }
    }
`;
export const GetTenant = gql`
    query getTenant($id: TenantId!) {
        getTenant(tenantId: $id) {
            id
            name
            dateAdded
            sku
            activatedAt
            deactivatedAt
            onboardingStartedAt
            demoStartedAt
            demoEndedAt
            premiumActivatedAt
            premiumDeactivatedAt
            billing {
                billingContacts
                effectiveMonthlyRateCents
                monthlyRateCents
                perLocationRateCents
                perUserRateCents
                ratePlan
                ratePlanEstablished
            }
            customDomains
            emailProvider
            internalEmailSender
            supportEmailAddress
            customProductName
            customAppDomain
            whiteLabelContentMode
            users {
                id
                name
                email
                disabled
                hidden
            }
            features {
                name
                enabled
                enabledUpstream
            }
            regions {
                id
                name
                key
                permissions {
                    role
                    user {
                        id
                        name
                        email
                        disabled
                        hidden
                    }
                }
            }
            internalAttributes {
                entries {
                    key
                    value
                }
            }
        }
        getGlobalFeatures {
            skus
            features {
                actual {
                    name
                    enabled
                }
                skus
                key
                friendlyTitle
                description
                createdOn
                intent
            }
        }
    }
`;
export const GetTenantHistory = gql`
    query getTenantHistory($tenantId: TenantId!) {
        getTenantHistory(tenantId: $tenantId) {
            id
            message
            dateAdded
            author {
                preferredName
            }
        }
    }
`;
export const GetImports = gql`
    query getImports($tenantId: TenantId!) {
        getImports(tenantId: $tenantId) {
            id
            name
            status
            dateAdded
            runAs
            regionId
        }
    }
`;
export const GetImport = gql`
    query getImport($tenantId: TenantId!, $importId: ImportId!) {
        getImport(tenantId: $tenantId, importId: $importId) {
            id
            name
            status
            dateAdded
            runAs
            regionId
            plan {
                inserts {
                    entityType
                    records
                }
                errors {
                    rowNumber
                    message
                    sheetName
                }
            }
            result {
                results {
                    entityType
                    inserts {
                        rowNumber
                        insertedId
                        warnings
                    }
                    errors {
                        rowNumber
                        message
                        sheetName
                    }
                }
                error
            }
        }
    }
`;
export const ScheduleImport = gql`
    mutation scheduleImport($tenantId: TenantId!, $importId: ImportId!) {
        scheduleImport(tenantId: $tenantId, importId: $importId) {
            id
            status
        }
    }
`;
export const ResetTenant = gql`
    mutation resetTenant($options: ResetTenantOptions!) {
        resetTenant(options: $options)
    }
`;
export const GetTokenForClient = gql`
    query getTokenForClient($id: ClientId!) {
        getTokenForClient(clientId: $id) {
            id
            clientId
            url
            includeContacts
            includeFinancial
            includeLicensing
            sectionKeys
        }
    }
`;
export const UpdateToken = gql`
    mutation updateToken($updateRequest: UpdateTokenRequest!) {
        updateToken(updateRequest: $updateRequest) {
            id
            clientId
            url
            includeContacts
            includeFinancial
            includeLicensing
            sectionKeys
        }
    }
`;
export const GetUserGroups = gql`
    query getUserGroups {
        getUserGroups {
            id
            name
        }
    }
`;
export const UserManagementGetRoles = gql`
    query userManagementGetRoles {
        userManagementGetRoles {
            key
            humanReadableName
        }
    }
`;
export const UserManagementGetUsers = gql`
    query userManagementGetUsers {
        userManagementGetUsers(params: { userTypes: [User] }) {
            user {
                id
                type
                email
                name
                hidden
                disabled
                permissions {
                    role
                    region {
                        id
                        name
                        key
                    }
                }
            }
        }
    }
`;
export const UserManagementGetIntegrationUsers = gql`
    query userManagementGetIntegrationUsers {
        userManagementGetUsers(params: { userTypes: [System] }) {
            user {
                id
                type
                name
                hidden
                disabled
                permissions {
                    role
                    region {
                        id
                        name
                        key
                    }
                }
            }
        }
    }
`;
export const UserManagementGetUser = gql`
    query userManagementGetUser($userId: UserId!) {
        userManagementGetUser(userId: $userId) {
            canDisable
            user {
                id
                email
                name
                type
                hidden
                disabled
                permissions {
                    role
                    region {
                        id
                        name
                        key
                    }
                }
            }
        }
    }
`;
export const UserManagementGetApiKey = gql`
    query userManagementGetApiKey($userId: UserId!) {
        userManagementGetApiKey(userId: $userId) {
            apiKey
        }
    }
`;
export const UserManagementCreateUser = gql`
    mutation userManagementCreateUser(
        $userEmail: String!
        $roleKey: String!
        $regionId: RegionId!
    ) {
        userManagementCreateUser(
            userEmail: $userEmail
            roleKey: $roleKey
            regionId: $regionId
        )
    }
`;
export const UserManagementCreateSystemUser = gql`
    mutation userManagementCreateSystemUser(
        $name: String!
        $roleKey: String!
        $regionId: RegionId!
    ) {
        userManagementCreateSystemUser(
            name: $name
            roleKey: $roleKey
            regionId: $regionId
        )
    }
`;
export const UserManagementUpdatePermission = gql`
    mutation userManagementUpdatePermission(
        $userId: UserId!
        $regionId: RegionId!
        $roleKey: String
    ) {
        userManagementUpdatePermission(
            userId: $userId
            regionId: $regionId
            roleKey: $roleKey
        )
    }
`;
export const UserManagementDisableUser = gql`
    mutation userManagementDisableUser($userId: UserId!) {
        userManagementDisableUser(userId: $userId)
    }
`;
export const UserManagementEnableUser = gql`
    mutation userManagementEnableUser($userId: UserId!) {
        userManagementEnableUser(userId: $userId)
    }
`;
export const UserManagementHideUser = gql`
    mutation userManagementHideUser($userId: UserId!) {
        userManagementHideUser(userId: $userId)
    }
`;
export const UserManagementShowUser = gql`
    mutation userManagementShowUser($userId: UserId!) {
        userManagementShowUser(userId: $userId)
    }
`;
export const GetUsers = gql`
    query getUsers {
        getUsers {
            id
            email
            type
            name
            firstName
            disabled
            hidden
        }
    }
`;
export const CreateUser = gql`
    mutation createUser($tenantId: TenantId!, $email: String!, $password: String!) {
        createUser(tenantId: $tenantId, email: $email, password: $password) {
            id
            email
        }
    }
`;
export const CloneUser = gql`
    mutation cloneUser($tenantId: TenantId!, $userId: UserId!) {
        cloneUser(tenantId: $tenantId, userId: $userId)
    }
`;
export const SuperuserUpdateUser = gql`
    mutation superuserUpdateUser(
        $userId: UserId!
        $disabled: Boolean!
        $hidden: Boolean!
        $email: String!
        $firstName: String
        $name: String
    ) {
        superuserUpdateUser(
            userId: $userId
            disabled: $disabled
            hidden: $hidden
            email: $email
            firstName: $firstName
            name: $name
        ) {
            id
        }
    }
`;
export const AdminUpdateUser = gql`
    mutation adminUpdateUser($userId: UserId!, $name: String!) {
        adminUpdateUser(userId: $userId, name: $name) {
            id
        }
    }
`;
export const GetUser = gql`
    query getUser($id: UserId!) {
        getUser(userId: $id) {
            id
            name
            firstName
            type
            email
            disabled
            hidden
            token
            tenant {
                id
                name
                regions {
                    key
                    name
                    permissions {
                        role
                        userId
                    }
                }
                features {
                    name
                    enabled
                    enabledUpstream
                }
            }
        }
    }
`;
export const GetUserApiToken = gql`
    query getUserApiToken($id: UserId!) {
        getUser(userId: $id) {
            id
            apiToken
        }
    }
`;
export const GetRolesWithActions = gql`
    query getRolesWithActions {
        getRolesWithActions {
            key
            humanReadableName
            userAssignable
            actions
        }
    }
`;
export const GetCalendarSubscription = gql`
    query getCalendarSubscription {
        getCalendarSubscription {
            calendarUrl
        }
    }
`;
export const GetUserTenantRegionOptions = gql`
    query getUserTenantRegionOptions {
        getUserTenantRegionOptions {
            options {
                regionId
                regionName
                regionKey
                tenantId
                tenantName
                userId
            }
        }
    }
`;
export const GetUsersForRegion = gql`
    query getUsersForRegion($regionId: RegionId!) {
        getUsersForRegion(regionId: $regionId) {
            id
            disabled
            hidden
            preferredName
        }
    }
`;
export const GetWorkflowsForTenant = gql`
    query getWorkflowsForTenant($tenantId: TenantId!) {
        getWorkflowsForTenant(tenantId: $tenantId) {
            ...WorkflowDefaults
        }
    }
    ${WorkflowDefaults}
`;
export const GetWorkflows = gql`
    query getWorkflows {
        getWorkflows {
            ...WorkflowDefaults
        }
    }
    ${WorkflowDefaults}
`;
export const GetWorkflow = gql`
    query getWorkflow($tenantId: TenantId!, $workflowId: WorkflowId!) {
        getWorkflow(tenantId: $tenantId, workflowId: $workflowId) {
            ...WorkflowDefaults
        }
    }
    ${WorkflowDefaults}
`;
export const CreateWorkflowForTenant = gql`
    mutation createWorkflowForTenant($tenantId: TenantId!, $workflow: WorkflowPayload!) {
        createWorkflowForTenant(tenantId: $tenantId, workflow: $workflow) {
            ...WorkflowDefaults
        }
    }
    ${WorkflowDefaults}
`;
export const CreateWorkflow = gql`
    mutation createWorkflow($workflow: WorkflowPayload!) {
        createWorkflow(workflow: $workflow) {
            ...WorkflowDefaults
        }
    }
    ${WorkflowDefaults}
`;
export const UpdateWorkflowForTenant = gql`
    mutation updateWorkflowForTenant(
        $tenantId: TenantId!
        $workflowId: WorkflowId!
        $workflow: WorkflowPayload!
    ) {
        updateWorkflowForTenant(
            tenantId: $tenantId
            workflowId: $workflowId
            workflow: $workflow
        ) {
            ...WorkflowDefaults
        }
    }
    ${WorkflowDefaults}
`;
export const UpdateWorkflow = gql`
    mutation updateWorkflow($workflowId: WorkflowId!, $workflow: WorkflowPayload!) {
        updateWorkflow(workflowId: $workflowId, workflow: $workflow) {
            ...WorkflowDefaults
        }
    }
    ${WorkflowDefaults}
`;
export const DeleteWorkflowForTenant = gql`
    mutation deleteWorkflowForTenant($tenantId: TenantId!, $workflowId: WorkflowId!) {
        deleteWorkflowForTenant(tenantId: $tenantId, workflowId: $workflowId)
    }
`;
export const DeleteWorkflow = gql`
    mutation deleteWorkflow($workflowId: WorkflowId!) {
        deleteWorkflow(workflowId: $workflowId)
    }
`;
export const GetWorkflowExecutionsForEntity = gql`
    query getWorkflowExecutionsForEntity($workflowTargetId: WorkflowTargetId!) {
        getWorkflowExecutionsForEntity(workflowTargetId: $workflowTargetId) {
            id
            scheduledAt
            cancelledAt
            isCancelRequested
            finishedAt
            result
            workflow {
                name
                id
            }
        }
    }
`;
export const CancelWorkflowExecution = gql`
    mutation cancelWorkflowExecution($workflowExecutionId: WorkflowExecutionId!) {
        cancelWorkflowExecution(workflowExecutionId: $workflowExecutionId) {
            id
        }
    }
`;
export const AudienceFragmentFragmentDoc = `
    fragment AudienceFragment on UserAudience {
  type
  specific {
    userIds
    groupIds
  }
}
    `;
export const SummaryFragmentFragmentDoc = `
    fragment SummaryFragment on AdvancedSearchRequestSummary {
  aggregates {
    operator
    column
  }
  groupBy
  sort {
    aggregateIndex
    ascending
  }
}
    `;
export const BridgeQuestionFragmentFragmentDoc = `
    fragment BridgeQuestionFragment on BridgeQuestion {
  id
  title
  interaction
  annotation
  questionType
  category
  key
  order
  regionMask
  rulesString
  searchBehavior
  source
  regions
  editable
  prompts {
    id
    title
    key
    order
    annotation
  }
}
    `;
export const SelectFragmentFragmentDoc = `
    fragment SelectFragment on AdvancedSearchSelect {
  fieldName
  questionId
}
    `;
export const BridgeEntityFragmentFragmentDoc = `
    fragment BridgeEntityFragment on BridgeEntityResult {
  name
  title
  preferredFilterField
  allowAnswers
  layoutParents
  identifierFields
  priorityFields {
    fieldName
    title
  }
  fields {
    name
    title
    questionType
    formatter
    category
    dependsOn
    customListKey
    aliases
    naryValues {
      value
      label
    }
    reportBehavior {
      sortable
      filterable
      selectable
      newReportSelectOrder
      summaryGroupable
      canAggregate
    }
  }
  relations {
    name
    title
    otherEntityName
    kind
    selectable
  }
  questions {
    ...BridgeQuestionFragment
  }
  defaultSelect {
    ...SelectFragment
  }
  preferredDisplay {
    fieldName
    relation {
      name
    }
  }
  preferredGroupBy
}
    ${BridgeQuestionFragmentFragmentDoc}
${SelectFragmentFragmentDoc}`;
export const CommunityBasicsFragmentDoc = `
    fragment CommunityBasics on Community {
  address
  caregiverRatio
  city
  contractStatus
  contractExpiresDate
  contractIssuedDate
  contractNotes
  dateAdded
  gender
  feeType
  paymentFee
  paymentPercentage
  id
  latitude
  licenseStatus
  longitude
  mainPhone
  monthlyPrice
  moveInDeposit
  name
  petDeposit
  priceDetails
  priceHighEnd
  rating
  secondPersonFee
  state
  summary
  thumbnailUrl
  unitsOrCapacity
  updatedAt
  virtualTourURL
  website
  zip
  regionId
  email
  county
  contacts {
    cellPhone
    name
    phone1
    role
    email1
  }
  primaryContacts {
    cellPhone
    name
    phone1
    role
    email1
  }
}
    `;
export const FileFragmentFragmentDoc = `
    fragment FileFragment on File {
  id
  entityId
  originalFilename
  mimeType
  description
  byteLength
  shareWithClient
  shareWithCommunity
  shareWithReferral
  createdAt
  createdBy
  updatedAt
  updatedBy
  hasSignatureTemplate
  isGlobal
  regionVisibility {
    editable
    regions
  }
}
    `;
export const PhotoBasicsFragmentDoc = `
    fragment PhotoBasics on Photo {
  id
  entityId
  order
  dateAdded
  createdBy
  originalFile {
    ...FileFragment
  }
}
    ${FileFragmentFragmentDoc}`;
export const CommunityCardBasicsFragmentDoc = `
    fragment CommunityCardBasics on Community {
  ...CommunityBasics
  answers {
    questionId
    booleanAnswer
    numberAnswer
    numberAnswerHigh
    textAnswer
    dateAnswer
    calendarDateAnswer
    selections
    idReferenceAnswer
    selectionAnnotations {
      text
      selectionId
    }
  }
  photoEntities {
    ...PhotoBasics
  }
  licenses {
    id
    licenseNumber
    href
    licensee
    status
    licenseStartDate
    licenseEndDate
  }
}
    ${CommunityBasicsFragmentDoc}
${PhotoBasicsFragmentDoc}`;
export const ClientCommunitiesFragmentDoc = `
    fragment ClientCommunities on Client {
  id
  baseLocation {
    lat
    lng
  }
  clientCommunities {
    order
    relationship
    publicNotes
    privateNotes
    community {
      ...CommunityCardBasics
    }
    referred
  }
}
    ${CommunityCardBasicsFragmentDoc}`;
export const DashboardDefaultsFragmentDoc = `
    fragment DashboardDefaults on ClientsSearchResult {
  total
  clients {
    id
    serial
    name
    statusListItemId
    bestContactName
    bestContactPhone
    dateAdded
    invoiceSentDate
    moveTimeline
    moveTimelineActual
    paymentReceivedDate
    statusCheckLaterDate
    statusClosedDate
    updatedAt
    assignedUserId
    phone
    cellPhone
    email
    additionalClientName
    chosenCommunity {
      id
      name
    }
    nextTask {
      id
      text
      status
      dueDate
      dueDateTime
    }
    allContacts {
      name
      email1
      phone1
      cellPhone
      relationship
    }
  }
}
    `;
export const ReferralResponseFragmentDoc = `
    fragment ReferralResponse on Referral {
  id
  clientId
  referenceBusinessId
  referenceContactId
  communityId
  referralType
  notes
  when
  dateAdded
  createdBy
  updatedAt
  updatedBy
}
    `;
export const IdentityDefaultsFragmentDoc = `
    fragment IdentityDefaults on Identity {
  id
  name
  successorId
  propagateableLicenseTypes
  thumbnailUrl
}
    `;
export const LicenseDefaultsFragmentDoc = `
    fragment LicenseDefaults on License {
  status
  jurisdiction
  licenseNumber
  id
  licenseType
}
    `;
export const PublicRecordDefaultsFragmentDoc = `
    fragment PublicRecordDefaults on PublicRecord {
  id
  name
  address
  city
  state
  zip
  capacity
  licenseType
  licenseNumber
  licensee
  closedDate
  licenseDate
  licenseStatus
  county
  country
  phone
  administrator
  email
  lat
  lng
}
    `;
export const ContactFragmentFragmentDoc = `
    fragment ContactFragment on ContactEntity {
  id
  name
  preferredName
  address1
  address2
  city
  state
  zip
  relationship
  role
  cellPhone
  phone1
  email1
  email1OptOut
  website
  contactType
  type
  fax
  contactNotes
}
    `;
export const ContactWithLinksFragmentDoc = `
    fragment ContactWithLinks on ContactEntity {
  ...ContactFragment
  communityContacts {
    communityId
    contactId
    primary
    community {
      id
      name
      appLink
    }
  }
  clientContacts {
    clientId
    contactId
    relationship
    powerOfAttorney
    client {
      id
      name
      appLink
    }
  }
  referenceContacts {
    id
    summary
    regionId
    ownerId
    dateAdded
    updatedAt
    updatedBy
    createdBy
    appLink
  }
}
    ${ContactFragmentFragmentDoc}`;
export const CustomListItemResponseFragmentDoc = `
    fragment CustomListItemResponse on CustomListItem {
  id
  key
  name
  order
  customListCategoryId
  icon
  pipelineVisibility
  pipelineDateLabel
  pipelineDateField
  isArchived
  updateBehavior
}
    `;
export const CustomListResponseFragmentDoc = `
    fragment CustomListResponse on CustomList {
  id
  key
  items {
    ...CustomListItemResponse
  }
  categories {
    id
    key
    name
    order
  }
}
    ${CustomListItemResponseFragmentDoc}`;
export const DisclosureAcknowledgementFragmentFragmentDoc = `
    fragment DisclosureAcknowledgementFragment on DisclosureAcknowledgement {
  id
  name
  email
  phone
  agreed
  response
  dateAdded
  createdBy
  ipAddress
}
    `;
export const DisclosureFragmentFragmentDoc = `
    fragment DisclosureFragment on Disclosure {
  id
  dateAdded
  publicUrl
  acknowledgements {
    ...DisclosureAcknowledgementFragment
  }
}
    ${DisclosureAcknowledgementFragmentFragmentDoc}`;
export const ImportFragmentFragmentDoc = `
    fragment ImportFragment on EvergreenImport {
  created
  dateAdded
  duplicates
  endedAt
  errors
  id
  message
  skips
  delisted
  sourceName
  startedAt
  updatedAt
}
    `;
export const LayoutItemBasicsFragmentDoc = `
    fragment LayoutItemBasics on LayoutItem {
  id
  order
  questionId
  entityFieldName
  showIfBlank
  naryFormat
  customTitle
}
    `;
export const LayoutSectionBasicsFragmentDoc = `
    fragment LayoutSectionBasics on LayoutSection {
  id
  order
  sectionKey
  visible
  entityType
  title
  icon
  renderBehavior
  questionCategory
  legacyPerspective
  includeRelations
  singlePropertyName
  defaultStandardFields
  settings {
    key
    label
    type
    order
  }
  layoutItems {
    ...LayoutItemBasics
  }
}
    ${LayoutItemBasicsFragmentDoc}`;
export const ReferralBasicsFragmentDoc = `
    fragment ReferralBasics on Referral {
  id
  clientId
  client {
    id
    name
    bestContactName
    statusListItemId
    dateAdded
    nextTask {
      id
      text
      dueDate
      dueDateTime
      compositeDueDateTime
      status
    }
    serial
    assignedUserId
    updatedAt
    moveTimeline
    moveTimelineActual
  }
}
    `;
export const NoteResponseFragmentDoc = `
    fragment NoteResponse on NoteDto {
  _id
  text
  dateAdded
  wasEdited
  authorId
  isDraft
}
    `;
export const ReferenceContactBasicsFragmentDoc = `
    fragment ReferenceContactBasics on ReferenceContact {
  id
  ownerId
  deleted
  summary
  businessId
  communityId
  regions
  regionId
  dateAdded
  updatedAt
  createdBy
  updatedBy
  draftNote
  contactEntity {
    ...ContactFragment
  }
  business {
    id
    ownerId
    deleted
    regionId
    latitude
    longitude
    businessContactEntity {
      ...ContactFragment
    }
  }
  community {
    id
    name
    address
    city
    state
    zip
    mainPhone
    website
    email
    latitude
    longitude
    contractStatus
  }
  answers {
    questionId
    booleanAnswer
    numberAnswer
    numberAnswerHigh
    textAnswer
    dateAnswer
    calendarDateAnswer
    selections
    idReferenceAnswer
    selectionAnnotations {
      text
      selectionId
    }
  }
  notes {
    ...NoteResponse
  }
}
    ${ContactFragmentFragmentDoc}
${NoteResponseFragmentDoc}`;
export const ReferenceBusinessBasicsFragmentDoc = `
    fragment ReferenceBusinessBasics on ReferenceBusiness {
  id
  ownerId
  deleted
  summary
  dateAdded
  updatedAt
  createdBy
  updatedBy
  draftNote
  regionId
  latitude
  longitude
  businessContactEntity {
    ...ContactFragment
  }
  answers {
    questionId
    booleanAnswer
    numberAnswer
    numberAnswerHigh
    textAnswer
    dateAnswer
    calendarDateAnswer
    selections
    idReferenceAnswer
    selectionAnnotations {
      text
      selectionId
    }
  }
  notes {
    ...NoteResponse
  }
}
    ${ContactFragmentFragmentDoc}
${NoteResponseFragmentDoc}`;
export const SmtpCredentialBasicsFragmentDoc = `
    fragment SmtpCredentialBasics on SmtpCredential {
  id
  host
  port
  username
  status
}
    `;
export const TaskBasicsFragmentDoc = `
    fragment TaskBasics on Task {
  id
  completedAt
  dueDate
  dueDateTime
  ownerId
  parentId
  status
  text
  done
  taskTypeId
  taskType {
    id
    name
  }
}
    `;
export const TaskDetailsFragmentDoc = `
    fragment TaskDetails on Task {
  ...TaskBasics
  notes
}
    ${TaskBasicsFragmentDoc}`;
export const ConditionFragmentFragmentDoc = `
    fragment ConditionFragment on AdvancedSearchCondition {
  nodeType
  fieldName
  operator
  textValue
  dateValue
  calendarDateValue
  relativeDate {
    value
    interval
  }
  numberValue
  questionId
  promptId
}
    `;
export const WorkflowDefaultsFragmentDoc = `
    fragment WorkflowDefaults on Workflow {
  actions {
    ... on WorkflowActionEmailEntity {
      __typename
      body
      subject
      to
      attachments
      bccMyself
    }
    ... on WorkflowActionTaskEntity {
      __typename
      dueDate
      text
      assignedTo
      taskTypeId
      notifyOwner
      notification {
        subject
      }
    }
  }
  attachTo
  createdBy
  dateAdded
  delay
  delayRecipe
  description
  id
  name
  status
  defaultActorId
  actorMode
  settings {
    defaultValue
    description
    key
    label
    type
    scope
  }
  trigger {
    eventType
    entityUpdated {
      fieldMatchers {
        fieldName
        previous {
          values
          specialValue
        }
        current {
          values
          specialValue
        }
      }
    }
    noteAdded {
      entityFieldMatchers {
        fieldName
        current {
          values
          specialValue
        }
      }
    }
    emailOpened {
      entityFieldMatchers {
        fieldName
        current {
          values
          specialValue
        }
      }
      activityThresholdDays
    }
    schedule {
      cron
      entityCondition {
        ...ConditionFragment
        children {
          ...ConditionFragment
          children {
            ...ConditionFragment
            children {
              ...ConditionFragment
              children {
                ...ConditionFragment
                children {
                  ...ConditionFragment
                  children {
                    ...ConditionFragment
                    children {
                      ...ConditionFragment
                      children {
                        ...ConditionFragment
                        children {
                          ...ConditionFragment
                          children {
                            ...ConditionFragment
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  updatedAt
  updatedBy
}
    ${ConditionFragmentFragmentDoc}`;
export const GetActivityFeedDocument = `
    query getActivityFeed($entityId: ActivityParentEntityId!) {
  getActivityFeed(entityId: $entityId) {
    activities {
      activity {
        id
        activityType
        createdAt
        createdByEntity {
          preferredName
        }
        parentReferenceContact {
          id
          contactEntity {
            name
          }
          appLink
        }
        parentReferenceBusiness {
          id
          businessContactEntity {
            name
          }
          appLink
        }
        parentCommunity {
          id
          name
          appLink
        }
        metadata {
          events {
            type
            entityFieldChange {
              field
              oldStringValue
              newStringValue
            }
            entityReassign {
              from
              to
            }
          }
        }
      }
      note {
        text
        effectiveCreatedAt
        publishedAt
      }
      task {
        status
        dueDate
        dueDateTime
        completedAt
        type
        text
      }
      file {
        originalFilename
      }
      message {
        subject
        intakeCommunity {
          name
        }
      }
      sourceType
    }
  }
}
    `;
export const useGetActivityFeedQuery = <TData = GetActivityFeedQuery, TError = unknown>(
    variables: GetActivityFeedQueryVariables,
    options?: UseQueryOptions<GetActivityFeedQuery, TError, TData>,
) =>
    useQuery<GetActivityFeedQuery, TError, TData>(
        ["getActivityFeed", variables],
        graphqlFetcher<GetActivityFeedQuery, GetActivityFeedQueryVariables>(
            GetActivityFeedDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetActivityFeedQuery = <
    TData = GetActivityFeedQuery,
    TError = unknown,
>(
    variables: GetActivityFeedQueryVariables,
    options?: UseInfiniteQueryOptions<GetActivityFeedQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetActivityFeedQuery, TError, TData>(
        ["getActivityFeed.infinite", variables],
        metaData =>
            graphqlFetcher<GetActivityFeedQuery, GetActivityFeedQueryVariables>(
                GetActivityFeedDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const CreateSavedQueryDocument = `
    mutation createSavedQuery($savedQuery: SavedQueryInput!) {
  createSavedQuery(savedQuery: $savedQuery) {
    id
  }
}
    `;
export const useCreateSavedQueryMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateSavedQueryMutation,
        TError,
        CreateSavedQueryMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateSavedQueryMutation,
        TError,
        CreateSavedQueryMutationVariables,
        TContext
    >(
        ["createSavedQuery"],
        (variables?: CreateSavedQueryMutationVariables) =>
            graphqlFetcher<CreateSavedQueryMutation, CreateSavedQueryMutationVariables>(
                CreateSavedQueryDocument,
                variables,
            )(),
        options,
    );
export const UpdateSavedQueryDocument = `
    mutation updateSavedQuery($savedQuery: SavedQueryInput!) {
  updateSavedQuery(savedQuery: $savedQuery) {
    id
  }
}
    `;
export const useUpdateSavedQueryMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpdateSavedQueryMutation,
        TError,
        UpdateSavedQueryMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UpdateSavedQueryMutation,
        TError,
        UpdateSavedQueryMutationVariables,
        TContext
    >(
        ["updateSavedQuery"],
        (variables?: UpdateSavedQueryMutationVariables) =>
            graphqlFetcher<UpdateSavedQueryMutation, UpdateSavedQueryMutationVariables>(
                UpdateSavedQueryDocument,
                variables,
            )(),
        options,
    );
export const DeleteSavedQueryDocument = `
    mutation deleteSavedQuery($savedQueryId: SavedQueryId!) {
  deleteSavedQuery(savedQueryId: $savedQueryId)
}
    `;
export const useDeleteSavedQueryMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteSavedQueryMutation,
        TError,
        DeleteSavedQueryMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteSavedQueryMutation,
        TError,
        DeleteSavedQueryMutationVariables,
        TContext
    >(
        ["deleteSavedQuery"],
        (variables?: DeleteSavedQueryMutationVariables) =>
            graphqlFetcher<DeleteSavedQueryMutation, DeleteSavedQueryMutationVariables>(
                DeleteSavedQueryDocument,
                variables,
            )(),
        options,
    );
export const UpdateQueryFavoriteStatusDocument = `
    mutation updateQueryFavoriteStatus($savedQueryId: SavedQueryId!, $isFavorite: Boolean!) {
  updateQueryFavoriteStatus(savedQueryId: $savedQueryId, isFavorite: $isFavorite)
}
    `;
export const useUpdateQueryFavoriteStatusMutation = <
    TError = unknown,
    TContext = unknown,
>(
    options?: UseMutationOptions<
        UpdateQueryFavoriteStatusMutation,
        TError,
        UpdateQueryFavoriteStatusMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UpdateQueryFavoriteStatusMutation,
        TError,
        UpdateQueryFavoriteStatusMutationVariables,
        TContext
    >(
        ["updateQueryFavoriteStatus"],
        (variables?: UpdateQueryFavoriteStatusMutationVariables) =>
            graphqlFetcher<
                UpdateQueryFavoriteStatusMutation,
                UpdateQueryFavoriteStatusMutationVariables
            >(UpdateQueryFavoriteStatusDocument, variables)(),
        options,
    );
export const UpsertSavedQueryScheduleDocument = `
    mutation upsertSavedQuerySchedule($schedule: SavedQueryScheduleInput!) {
  upsertSavedQuerySchedule(schedule: $schedule) {
    schedule
    audience {
      ...AudienceFragment
    }
  }
}
    ${AudienceFragmentFragmentDoc}`;
export const useUpsertSavedQueryScheduleMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpsertSavedQueryScheduleMutation,
        TError,
        UpsertSavedQueryScheduleMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UpsertSavedQueryScheduleMutation,
        TError,
        UpsertSavedQueryScheduleMutationVariables,
        TContext
    >(
        ["upsertSavedQuerySchedule"],
        (variables?: UpsertSavedQueryScheduleMutationVariables) =>
            graphqlFetcher<
                UpsertSavedQueryScheduleMutation,
                UpsertSavedQueryScheduleMutationVariables
            >(UpsertSavedQueryScheduleDocument, variables)(),
        options,
    );
export const DeleteSavedQueryScheduleDocument = `
    mutation deleteSavedQuerySchedule($savedQueryId: SavedQueryId!) {
  deleteSavedQuerySchedule(savedQueryId: $savedQueryId)
}
    `;
export const useDeleteSavedQueryScheduleMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteSavedQueryScheduleMutation,
        TError,
        DeleteSavedQueryScheduleMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteSavedQueryScheduleMutation,
        TError,
        DeleteSavedQueryScheduleMutationVariables,
        TContext
    >(
        ["deleteSavedQuerySchedule"],
        (variables?: DeleteSavedQueryScheduleMutationVariables) =>
            graphqlFetcher<
                DeleteSavedQueryScheduleMutation,
                DeleteSavedQueryScheduleMutationVariables
            >(DeleteSavedQueryScheduleDocument, variables)(),
        options,
    );
export const SendSavedQueryEmailDocument = `
    mutation sendSavedQueryEmail($payload: SavedQuerySendEmailInput!) {
  sendSavedQueryEmail(payload: $payload)
}
    `;
export const useSendSavedQueryEmailMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        SendSavedQueryEmailMutation,
        TError,
        SendSavedQueryEmailMutationVariables,
        TContext
    >,
) =>
    useMutation<
        SendSavedQueryEmailMutation,
        TError,
        SendSavedQueryEmailMutationVariables,
        TContext
    >(
        ["sendSavedQueryEmail"],
        (variables?: SendSavedQueryEmailMutationVariables) =>
            graphqlFetcher<
                SendSavedQueryEmailMutation,
                SendSavedQueryEmailMutationVariables
            >(SendSavedQueryEmailDocument, variables)(),
        options,
    );
export const GetSavedQueriesDocument = `
    query getSavedQueries {
  getSavedQueries {
    canSaveAdditional
    savedQueries {
      id
      name
      isMyQuery
      isShared
      regionVisibility {
        editable
        regions
      }
      isFavorite
      editable
      entityType
      createdBy
      warnings {
        location
        message
      }
      schedule {
        schedule
        audience {
          ...AudienceFragment
        }
        emailDescription
      }
      sort {
        ascending
        column
        questionId
      }
      select {
        ...SelectFragment
      }
      summaries {
        ...SummaryFragment
      }
      condition {
        ...ConditionFragment
        children {
          ...ConditionFragment
          children {
            ...ConditionFragment
            children {
              ...ConditionFragment
              children {
                ...ConditionFragment
                children {
                  ...ConditionFragment
                  children {
                    ...ConditionFragment
                    children {
                      ...ConditionFragment
                      children {
                        ...ConditionFragment
                        children {
                          ...ConditionFragment
                          children {
                            ...ConditionFragment
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${AudienceFragmentFragmentDoc}
${SelectFragmentFragmentDoc}
${SummaryFragmentFragmentDoc}
${ConditionFragmentFragmentDoc}`;
export const useGetSavedQueriesQuery = <TData = GetSavedQueriesQuery, TError = unknown>(
    variables?: GetSavedQueriesQueryVariables,
    options?: UseQueryOptions<GetSavedQueriesQuery, TError, TData>,
) =>
    useQuery<GetSavedQueriesQuery, TError, TData>(
        variables === undefined ? ["getSavedQueries"] : ["getSavedQueries", variables],
        graphqlFetcher<GetSavedQueriesQuery, GetSavedQueriesQueryVariables>(
            GetSavedQueriesDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetSavedQueriesQuery = <
    TData = GetSavedQueriesQuery,
    TError = unknown,
>(
    variables?: GetSavedQueriesQueryVariables,
    options?: UseInfiniteQueryOptions<GetSavedQueriesQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetSavedQueriesQuery, TError, TData>(
        variables === undefined
            ? ["getSavedQueries.infinite"]
            : ["getSavedQueries.infinite", variables],
        metaData =>
            graphqlFetcher<GetSavedQueriesQuery, GetSavedQueriesQueryVariables>(
                GetSavedQueriesDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetEntitiesDocument = `
    query getEntities {
  getEntities {
    ...BridgeEntityFragment
  }
}
    ${BridgeEntityFragmentFragmentDoc}`;
export const useGetEntitiesQuery = <TData = GetEntitiesQuery, TError = unknown>(
    variables?: GetEntitiesQueryVariables,
    options?: UseQueryOptions<GetEntitiesQuery, TError, TData>,
) =>
    useQuery<GetEntitiesQuery, TError, TData>(
        variables === undefined ? ["getEntities"] : ["getEntities", variables],
        graphqlFetcher<GetEntitiesQuery, GetEntitiesQueryVariables>(
            GetEntitiesDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetEntitiesQuery = <TData = GetEntitiesQuery, TError = unknown>(
    variables?: GetEntitiesQueryVariables,
    options?: UseInfiniteQueryOptions<GetEntitiesQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetEntitiesQuery, TError, TData>(
        variables === undefined
            ? ["getEntities.infinite"]
            : ["getEntities.infinite", variables],
        metaData =>
            graphqlFetcher<GetEntitiesQuery, GetEntitiesQueryVariables>(
                GetEntitiesDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetEntitiesForTenantDocument = `
    query getEntitiesForTenant($tenantId: TenantId!) {
  getEntitiesForTenant(tenantId: $tenantId) {
    ...BridgeEntityFragment
  }
}
    ${BridgeEntityFragmentFragmentDoc}`;
export const useGetEntitiesForTenantQuery = <
    TData = GetEntitiesForTenantQuery,
    TError = unknown,
>(
    variables: GetEntitiesForTenantQueryVariables,
    options?: UseQueryOptions<GetEntitiesForTenantQuery, TError, TData>,
) =>
    useQuery<GetEntitiesForTenantQuery, TError, TData>(
        ["getEntitiesForTenant", variables],
        graphqlFetcher<GetEntitiesForTenantQuery, GetEntitiesForTenantQueryVariables>(
            GetEntitiesForTenantDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetEntitiesForTenantQuery = <
    TData = GetEntitiesForTenantQuery,
    TError = unknown,
>(
    variables: GetEntitiesForTenantQueryVariables,
    options?: UseInfiniteQueryOptions<GetEntitiesForTenantQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetEntitiesForTenantQuery, TError, TData>(
        ["getEntitiesForTenant.infinite", variables],
        metaData =>
            graphqlFetcher<GetEntitiesForTenantQuery, GetEntitiesForTenantQueryVariables>(
                GetEntitiesForTenantDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const UpsertEntityQuestionDocument = `
    mutation upsertEntityQuestion($entityType: String!, $question: BridgeQuestionInput!) {
  upsertEntityQuestion(entityType: $entityType, question: $question) {
    ...BridgeQuestionFragment
  }
}
    ${BridgeQuestionFragmentFragmentDoc}`;
export const useUpsertEntityQuestionMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpsertEntityQuestionMutation,
        TError,
        UpsertEntityQuestionMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UpsertEntityQuestionMutation,
        TError,
        UpsertEntityQuestionMutationVariables,
        TContext
    >(
        ["upsertEntityQuestion"],
        (variables?: UpsertEntityQuestionMutationVariables) =>
            graphqlFetcher<
                UpsertEntityQuestionMutation,
                UpsertEntityQuestionMutationVariables
            >(UpsertEntityQuestionDocument, variables)(),
        options,
    );
export const SetEntityAnswerDocument = `
    mutation setEntityAnswer($entityId: AnswerEntityId!, $answer: AnswerInput!) {
  setEntityAnswer(entityId: $entityId, answer: $answer) {
    questionId
    booleanAnswer
    numberAnswer
    numberAnswerHigh
    textAnswer
    dateAnswer
    calendarDateAnswer
    selections
    selectionAnnotations {
      selectionId
      text
    }
  }
}
    `;
export const useSetEntityAnswerMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        SetEntityAnswerMutation,
        TError,
        SetEntityAnswerMutationVariables,
        TContext
    >,
) =>
    useMutation<
        SetEntityAnswerMutation,
        TError,
        SetEntityAnswerMutationVariables,
        TContext
    >(
        ["setEntityAnswer"],
        (variables?: SetEntityAnswerMutationVariables) =>
            graphqlFetcher<SetEntityAnswerMutation, SetEntityAnswerMutationVariables>(
                SetEntityAnswerDocument,
                variables,
            )(),
        options,
    );
export const DeleteQuestionDocument = `
    mutation deleteQuestion($questionId: QuestionId!) {
  deleteQuestion(questionId: $questionId) {
    questionId
  }
}
    `;
export const useDeleteQuestionMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteQuestionMutation,
        TError,
        DeleteQuestionMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteQuestionMutation,
        TError,
        DeleteQuestionMutationVariables,
        TContext
    >(
        ["deleteQuestion"],
        (variables?: DeleteQuestionMutationVariables) =>
            graphqlFetcher<DeleteQuestionMutation, DeleteQuestionMutationVariables>(
                DeleteQuestionDocument,
                variables,
            )(),
        options,
    );
export const GetQuestionPermissionsDocument = `
    query getQuestionPermissions {
  getQuestionPermissions {
    editableRegions {
      id
      key
      name
    }
    canEditAllRegions
  }
}
    `;
export const useGetQuestionPermissionsQuery = <
    TData = GetQuestionPermissionsQuery,
    TError = unknown,
>(
    variables?: GetQuestionPermissionsQueryVariables,
    options?: UseQueryOptions<GetQuestionPermissionsQuery, TError, TData>,
) =>
    useQuery<GetQuestionPermissionsQuery, TError, TData>(
        variables === undefined
            ? ["getQuestionPermissions"]
            : ["getQuestionPermissions", variables],
        graphqlFetcher<GetQuestionPermissionsQuery, GetQuestionPermissionsQueryVariables>(
            GetQuestionPermissionsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetQuestionPermissionsQuery = <
    TData = GetQuestionPermissionsQuery,
    TError = unknown,
>(
    variables?: GetQuestionPermissionsQueryVariables,
    options?: UseInfiniteQueryOptions<GetQuestionPermissionsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetQuestionPermissionsQuery, TError, TData>(
        variables === undefined
            ? ["getQuestionPermissions.infinite"]
            : ["getQuestionPermissions.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetQuestionPermissionsQuery,
                GetQuestionPermissionsQueryVariables
            >(GetQuestionPermissionsDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetClientDocument = `
    query getClient($id: ClientId!) {
  getClient(clientId: $id) {
    id
    totalInvoiceAmountCents
    invoices {
      id
      amountCents
      paidDate
      sentDate
      dueDate
      serial
      status
      clientId
      clientName
      communityId
      fileId
      notes
      externalReference
      remainingBalanceCents
      totalPaidCents
      hasOutstandingBalance
      lineItems {
        id
        description
        quantity
        unitPriceInCents
      }
      paymentSchedule {
        items {
          id
          amountInCents
          dueDate
          paidDate
          status
        }
      }
      isExternallyManaged
      community {
        name
        id
      }
    }
    bestContactName
    bestContactPhone
    bestContactEmail
    disclosures {
      ...DisclosureFragment
    }
  }
}
    ${DisclosureFragmentFragmentDoc}`;
export const useGetClientQuery = <TData = GetClientQuery, TError = unknown>(
    variables: GetClientQueryVariables,
    options?: UseQueryOptions<GetClientQuery, TError, TData>,
) =>
    useQuery<GetClientQuery, TError, TData>(
        ["getClient", variables],
        graphqlFetcher<GetClientQuery, GetClientQueryVariables>(
            GetClientDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetClientQuery = <TData = GetClientQuery, TError = unknown>(
    variables: GetClientQueryVariables,
    options?: UseInfiniteQueryOptions<GetClientQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetClientQuery, TError, TData>(
        ["getClient.infinite", variables],
        metaData =>
            graphqlFetcher<GetClientQuery, GetClientQueryVariables>(GetClientDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetClientCommunitiesDocument = `
    query getClientCommunities($id: ClientId!, $questionIds: [QuestionId!]!) {
  getClient(clientId: $id, options: {includeCommunityQuestionIds: $questionIds}) {
    ...ClientCommunities
  }
}
    ${ClientCommunitiesFragmentDoc}`;
export const useGetClientCommunitiesQuery = <
    TData = GetClientCommunitiesQuery,
    TError = unknown,
>(
    variables: GetClientCommunitiesQueryVariables,
    options?: UseQueryOptions<GetClientCommunitiesQuery, TError, TData>,
) =>
    useQuery<GetClientCommunitiesQuery, TError, TData>(
        ["getClientCommunities", variables],
        graphqlFetcher<GetClientCommunitiesQuery, GetClientCommunitiesQueryVariables>(
            GetClientCommunitiesDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetClientCommunitiesQuery = <
    TData = GetClientCommunitiesQuery,
    TError = unknown,
>(
    variables: GetClientCommunitiesQueryVariables,
    options?: UseInfiniteQueryOptions<GetClientCommunitiesQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetClientCommunitiesQuery, TError, TData>(
        ["getClientCommunities.infinite", variables],
        metaData =>
            graphqlFetcher<GetClientCommunitiesQuery, GetClientCommunitiesQueryVariables>(
                GetClientCommunitiesDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetClientMatchesDocument = `
    query getClientMatches($id: ClientId!) {
  getClient(clientId: $id) {
    id
    potentialDuplicates {
      id
      name
      bestContactName
      dateAdded
      viewable
      assignedUser {
        id
        preferredName
      }
    }
  }
}
    `;
export const useGetClientMatchesQuery = <TData = GetClientMatchesQuery, TError = unknown>(
    variables: GetClientMatchesQueryVariables,
    options?: UseQueryOptions<GetClientMatchesQuery, TError, TData>,
) =>
    useQuery<GetClientMatchesQuery, TError, TData>(
        ["getClientMatches", variables],
        graphqlFetcher<GetClientMatchesQuery, GetClientMatchesQueryVariables>(
            GetClientMatchesDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetClientMatchesQuery = <
    TData = GetClientMatchesQuery,
    TError = unknown,
>(
    variables: GetClientMatchesQueryVariables,
    options?: UseInfiniteQueryOptions<GetClientMatchesQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetClientMatchesQuery, TError, TData>(
        ["getClientMatches.infinite", variables],
        metaData =>
            graphqlFetcher<GetClientMatchesQuery, GetClientMatchesQueryVariables>(
                GetClientMatchesDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const CreateClientDocument = `
    mutation createClient($regionId: RegionId!) {
  createClient(regionId: $regionId) {
    id
  }
}
    `;
export const useCreateClientMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateClientMutation,
        TError,
        CreateClientMutationVariables,
        TContext
    >,
) =>
    useMutation<CreateClientMutation, TError, CreateClientMutationVariables, TContext>(
        ["createClient"],
        (variables?: CreateClientMutationVariables) =>
            graphqlFetcher<CreateClientMutation, CreateClientMutationVariables>(
                CreateClientDocument,
                variables,
            )(),
        options,
    );
export const DeleteClientMatchesDocument = `
    mutation deleteClientMatches($id: ClientId!) {
  deleteClientMatches(clientId: $id) {
    id
  }
}
    `;
export const useDeleteClientMatchesMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteClientMatchesMutation,
        TError,
        DeleteClientMatchesMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteClientMatchesMutation,
        TError,
        DeleteClientMatchesMutationVariables,
        TContext
    >(
        ["deleteClientMatches"],
        (variables?: DeleteClientMatchesMutationVariables) =>
            graphqlFetcher<
                DeleteClientMatchesMutation,
                DeleteClientMatchesMutationVariables
            >(DeleteClientMatchesDocument, variables)(),
        options,
    );
export const SetCommunityOrderDocument = `
    mutation setCommunityOrder($clientId: ClientId!, $questionIds: [QuestionId!]!, $ordering: [CommunityOrdering!]!) {
  setCommunityOrder(
    clientId: $clientId
    ordering: $ordering
    includeCommunityQuestionIds: $questionIds
  ) {
    ...ClientCommunities
  }
}
    ${ClientCommunitiesFragmentDoc}`;
export const useSetCommunityOrderMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        SetCommunityOrderMutation,
        TError,
        SetCommunityOrderMutationVariables,
        TContext
    >,
) =>
    useMutation<
        SetCommunityOrderMutation,
        TError,
        SetCommunityOrderMutationVariables,
        TContext
    >(
        ["setCommunityOrder"],
        (variables?: SetCommunityOrderMutationVariables) =>
            graphqlFetcher<SetCommunityOrderMutation, SetCommunityOrderMutationVariables>(
                SetCommunityOrderDocument,
                variables,
            )(),
        options,
    );
export const AddClientCommunitiesDocument = `
    mutation addClientCommunities($clientId: ClientId!, $questionIds: [QuestionId!]!, $params: [AddClientCommunityRequest!]!) {
  addClientCommunities(
    clientId: $clientId
    params: $params
    includeCommunityQuestionIds: $questionIds
  ) {
    ...ClientCommunities
  }
}
    ${ClientCommunitiesFragmentDoc}`;
export const useAddClientCommunitiesMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        AddClientCommunitiesMutation,
        TError,
        AddClientCommunitiesMutationVariables,
        TContext
    >,
) =>
    useMutation<
        AddClientCommunitiesMutation,
        TError,
        AddClientCommunitiesMutationVariables,
        TContext
    >(
        ["addClientCommunities"],
        (variables?: AddClientCommunitiesMutationVariables) =>
            graphqlFetcher<
                AddClientCommunitiesMutation,
                AddClientCommunitiesMutationVariables
            >(AddClientCommunitiesDocument, variables)(),
        options,
    );
export const RemoveClientCommunitiesDocument = `
    mutation removeClientCommunities($clientId: ClientId!, $params: [RemoveClientCommunityRequest!]!) {
  removeClientCommunities(clientId: $clientId, params: $params) {
    id
  }
}
    `;
export const useRemoveClientCommunitiesMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        RemoveClientCommunitiesMutation,
        TError,
        RemoveClientCommunitiesMutationVariables,
        TContext
    >,
) =>
    useMutation<
        RemoveClientCommunitiesMutation,
        TError,
        RemoveClientCommunitiesMutationVariables,
        TContext
    >(
        ["removeClientCommunities"],
        (variables?: RemoveClientCommunitiesMutationVariables) =>
            graphqlFetcher<
                RemoveClientCommunitiesMutation,
                RemoveClientCommunitiesMutationVariables
            >(RemoveClientCommunitiesDocument, variables)(),
        options,
    );
export const GetClientsForTimelineDocument = `
    query getClientsForTimeline($regionId: RegionId!, $searchString: String, $assignedUserId: UserId, $assignedReferralUserId: UserId, $searchType: String, $openClosed: String, $page: Int!, $perPage: Int!, $includeStatuses: [String!]) {
  pastDue: getClients(
    params: {regionId: $regionId, searchString: $searchString, assignedUserId: $assignedUserId, assignedReferralUserId: $assignedReferralUserId, searchType: $searchType, moveTimelineWindow: "pastDue", archived: false, openClosed: $openClosed, includeStatuses: $includeStatuses, page: $page, perPage: $perPage, sort: UpdatedAtDesc}
  ) {
    ...DashboardDefaults
  }
  oneWeek: getClients(
    params: {regionId: $regionId, searchString: $searchString, assignedUserId: $assignedUserId, assignedReferralUserId: $assignedReferralUserId, searchType: $searchType, moveTimelineWindow: "oneWeek", archived: false, openClosed: $openClosed, includeStatuses: $includeStatuses, page: $page, perPage: $perPage, sort: UpdatedAtDesc}
  ) {
    ...DashboardDefaults
  }
  oneToTwoWeeks: getClients(
    params: {regionId: $regionId, searchString: $searchString, assignedUserId: $assignedUserId, assignedReferralUserId: $assignedReferralUserId, searchType: $searchType, moveTimelineWindow: "oneToTwoWeeks", archived: false, openClosed: $openClosed, includeStatuses: $includeStatuses, page: $page, perPage: $perPage, sort: UpdatedAtDesc}
  ) {
    ...DashboardDefaults
  }
  twoToFourWeeks: getClients(
    params: {regionId: $regionId, searchString: $searchString, assignedUserId: $assignedUserId, assignedReferralUserId: $assignedReferralUserId, searchType: $searchType, moveTimelineWindow: "twoToFourWeeks", archived: false, openClosed: $openClosed, includeStatuses: $includeStatuses, page: $page, perPage: $perPage, sort: UpdatedAtDesc}
  ) {
    ...DashboardDefaults
  }
  fourOrMoreWeeks: getClients(
    params: {regionId: $regionId, searchString: $searchString, assignedUserId: $assignedUserId, assignedReferralUserId: $assignedReferralUserId, searchType: $searchType, moveTimelineWindow: "fourOrMoreWeeks", archived: false, openClosed: $openClosed, includeStatuses: $includeStatuses, page: $page, perPage: $perPage, sort: UpdatedAtDesc}
  ) {
    ...DashboardDefaults
  }
  unscheduled: getClients(
    params: {regionId: $regionId, searchString: $searchString, assignedUserId: $assignedUserId, assignedReferralUserId: $assignedReferralUserId, searchType: $searchType, moveTimelineWindow: "unscheduled", archived: false, openClosed: $openClosed, includeStatuses: $includeStatuses, page: $page, perPage: $perPage, sort: UpdatedAtDesc}
  ) {
    ...DashboardDefaults
  }
}
    ${DashboardDefaultsFragmentDoc}`;
export const useGetClientsForTimelineQuery = <
    TData = GetClientsForTimelineQuery,
    TError = unknown,
>(
    variables: GetClientsForTimelineQueryVariables,
    options?: UseQueryOptions<GetClientsForTimelineQuery, TError, TData>,
) =>
    useQuery<GetClientsForTimelineQuery, TError, TData>(
        ["getClientsForTimeline", variables],
        graphqlFetcher<GetClientsForTimelineQuery, GetClientsForTimelineQueryVariables>(
            GetClientsForTimelineDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetClientsForTimelineQuery = <
    TData = GetClientsForTimelineQuery,
    TError = unknown,
>(
    variables: GetClientsForTimelineQueryVariables,
    options?: UseInfiniteQueryOptions<GetClientsForTimelineQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetClientsForTimelineQuery, TError, TData>(
        ["getClientsForTimeline.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetClientsForTimelineQuery,
                GetClientsForTimelineQueryVariables
            >(GetClientsForTimelineDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetClientsForDetailsDocument = `
    query getClientsForDetails($regionId: RegionId!, $searchString: String, $assignedUserId: UserId, $assignedReferralUserId: UserId, $searchType: String, $openClosed: String, $page: Int!, $perPage: Int!, $includeStatuses: [String!], $excludeStatuses: [String!], $statusAtOrLater: String, $status: String, $sort: ClientSearchSort!) {
  getClients(
    params: {regionId: $regionId, searchString: $searchString, assignedUserId: $assignedUserId, assignedReferralUserId: $assignedReferralUserId, sort: $sort, searchType: $searchType, includeStatuses: $includeStatuses, excludeStatuses: $excludeStatuses, statusAtOrLater: $statusAtOrLater, status: $status, openClosed: $openClosed, page: $page, perPage: $perPage}
  ) {
    ...DashboardDefaults
  }
}
    ${DashboardDefaultsFragmentDoc}`;
export const useGetClientsForDetailsQuery = <
    TData = GetClientsForDetailsQuery,
    TError = unknown,
>(
    variables: GetClientsForDetailsQueryVariables,
    options?: UseQueryOptions<GetClientsForDetailsQuery, TError, TData>,
) =>
    useQuery<GetClientsForDetailsQuery, TError, TData>(
        ["getClientsForDetails", variables],
        graphqlFetcher<GetClientsForDetailsQuery, GetClientsForDetailsQueryVariables>(
            GetClientsForDetailsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetClientsForDetailsQuery = <
    TData = GetClientsForDetailsQuery,
    TError = unknown,
>(
    variables: GetClientsForDetailsQueryVariables,
    options?: UseInfiniteQueryOptions<GetClientsForDetailsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetClientsForDetailsQuery, TError, TData>(
        ["getClientsForDetails.infinite", variables],
        metaData =>
            graphqlFetcher<GetClientsForDetailsQuery, GetClientsForDetailsQueryVariables>(
                GetClientsForDetailsDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const SearchClientsDocument = `
    query searchClients($regionId: RegionId!, $searchString: String, $assignedUserId: UserId, $assignedReferralUserId: UserId, $searchType: String, $openClosed: String, $referralCommunityId: CommunityId, $referralContactId: ReferenceContactId, $referralBusinessId: ReferenceBusinessId, $relatedTo: [ClientId!], $page: Int!, $perPage: Int!) {
  getClients(
    params: {regionId: $regionId, searchString: $searchString, assignedUserId: $assignedUserId, assignedReferralUserId: $assignedReferralUserId, searchType: $searchType, openClosed: $openClosed, referralCommunityId: $referralCommunityId, referralContactId: $referralContactId, referralBusinessId: $referralBusinessId, relatedTo: $relatedTo, page: $page, perPage: $perPage, sort: NameAsc}
  ) {
    ...DashboardDefaults
  }
}
    ${DashboardDefaultsFragmentDoc}`;
export const useSearchClientsQuery = <TData = SearchClientsQuery, TError = unknown>(
    variables: SearchClientsQueryVariables,
    options?: UseQueryOptions<SearchClientsQuery, TError, TData>,
) =>
    useQuery<SearchClientsQuery, TError, TData>(
        ["searchClients", variables],
        graphqlFetcher<SearchClientsQuery, SearchClientsQueryVariables>(
            SearchClientsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteSearchClientsQuery = <
    TData = SearchClientsQuery,
    TError = unknown,
>(
    variables: SearchClientsQueryVariables,
    options?: UseInfiniteQueryOptions<SearchClientsQuery, TError, TData>,
) => {
    return useInfiniteQuery<SearchClientsQuery, TError, TData>(
        ["searchClients.infinite", variables],
        metaData =>
            graphqlFetcher<SearchClientsQuery, SearchClientsQueryVariables>(
                SearchClientsDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetClientReferralDetailsDocument = `
    query getClientReferralDetails($id: ClientId!) {
  getClient(clientId: $id) {
    bestContactName
    bestContactPhone
    address
    city
    state
    zip
  }
}
    `;
export const useGetClientReferralDetailsQuery = <
    TData = GetClientReferralDetailsQuery,
    TError = unknown,
>(
    variables: GetClientReferralDetailsQueryVariables,
    options?: UseQueryOptions<GetClientReferralDetailsQuery, TError, TData>,
) =>
    useQuery<GetClientReferralDetailsQuery, TError, TData>(
        ["getClientReferralDetails", variables],
        graphqlFetcher<
            GetClientReferralDetailsQuery,
            GetClientReferralDetailsQueryVariables
        >(GetClientReferralDetailsDocument, variables),
        options,
    );
export const useInfiniteGetClientReferralDetailsQuery = <
    TData = GetClientReferralDetailsQuery,
    TError = unknown,
>(
    variables: GetClientReferralDetailsQueryVariables,
    options?: UseInfiniteQueryOptions<GetClientReferralDetailsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetClientReferralDetailsQuery, TError, TData>(
        ["getClientReferralDetails.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetClientReferralDetailsQuery,
                GetClientReferralDetailsQueryVariables
            >(GetClientReferralDetailsDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetOutboundClientReferralsDocument = `
    query getOutboundClientReferrals($id: ClientId!) {
  getClientReferrals(clientId: $id) {
    ...ReferralResponse
    referenceBusiness {
      id
      businessContactEntity {
        name
      }
    }
    referenceContact {
      id
      contactEntity {
        name
      }
    }
    community {
      id
      name
    }
  }
}
    ${ReferralResponseFragmentDoc}`;
export const useGetOutboundClientReferralsQuery = <
    TData = GetOutboundClientReferralsQuery,
    TError = unknown,
>(
    variables: GetOutboundClientReferralsQueryVariables,
    options?: UseQueryOptions<GetOutboundClientReferralsQuery, TError, TData>,
) =>
    useQuery<GetOutboundClientReferralsQuery, TError, TData>(
        ["getOutboundClientReferrals", variables],
        graphqlFetcher<
            GetOutboundClientReferralsQuery,
            GetOutboundClientReferralsQueryVariables
        >(GetOutboundClientReferralsDocument, variables),
        options,
    );
export const useInfiniteGetOutboundClientReferralsQuery = <
    TData = GetOutboundClientReferralsQuery,
    TError = unknown,
>(
    variables: GetOutboundClientReferralsQueryVariables,
    options?: UseInfiniteQueryOptions<GetOutboundClientReferralsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetOutboundClientReferralsQuery, TError, TData>(
        ["getOutboundClientReferrals.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetOutboundClientReferralsQuery,
                GetOutboundClientReferralsQueryVariables
            >(GetOutboundClientReferralsDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const CreateClientReferralDocument = `
    mutation createClientReferral($clientId: ClientId!, $referralType: String!) {
  createClientReferral(clientId: $clientId, referralType: $referralType) {
    ...ReferralResponse
  }
}
    ${ReferralResponseFragmentDoc}`;
export const useCreateClientReferralMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateClientReferralMutation,
        TError,
        CreateClientReferralMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateClientReferralMutation,
        TError,
        CreateClientReferralMutationVariables,
        TContext
    >(
        ["createClientReferral"],
        (variables?: CreateClientReferralMutationVariables) =>
            graphqlFetcher<
                CreateClientReferralMutation,
                CreateClientReferralMutationVariables
            >(CreateClientReferralDocument, variables)(),
        options,
    );
export const PatchClientReferralDocument = `
    mutation patchClientReferral($id: ReferralId!, $clientId: ClientId!, $notes: String, $when: DateTime, $deleted: Boolean, $referenceBusinessId: ReferenceBusinessId, $referenceContactId: ReferenceContactId, $communityId: CommunityId) {
  patchClientReferral(
    id: $id
    clientId: $clientId
    notes: $notes
    when: $when
    deleted: $deleted
    referenceBusinessId: $referenceBusinessId
    referenceContactId: $referenceContactId
    communityId: $communityId
  ) {
    ...ReferralResponse
  }
}
    ${ReferralResponseFragmentDoc}`;
export const usePatchClientReferralMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        PatchClientReferralMutation,
        TError,
        PatchClientReferralMutationVariables,
        TContext
    >,
) =>
    useMutation<
        PatchClientReferralMutation,
        TError,
        PatchClientReferralMutationVariables,
        TContext
    >(
        ["patchClientReferral"],
        (variables?: PatchClientReferralMutationVariables) =>
            graphqlFetcher<
                PatchClientReferralMutation,
                PatchClientReferralMutationVariables
            >(PatchClientReferralDocument, variables)(),
        options,
    );
export const GetClientForIntakeEmailDocument = `
    query getClientForIntakeEmail($clientId: ClientId!) {
  getClient(clientId: $clientId) {
    name
    clientCommunities {
      relationship
      referred
      community {
        id
        name
        email
        emailOptOut
        communityContacts {
          primary
          contact {
            name
            email1
            email1OptOut
          }
        }
      }
    }
  }
}
    `;
export const useGetClientForIntakeEmailQuery = <
    TData = GetClientForIntakeEmailQuery,
    TError = unknown,
>(
    variables: GetClientForIntakeEmailQueryVariables,
    options?: UseQueryOptions<GetClientForIntakeEmailQuery, TError, TData>,
) =>
    useQuery<GetClientForIntakeEmailQuery, TError, TData>(
        ["getClientForIntakeEmail", variables],
        graphqlFetcher<
            GetClientForIntakeEmailQuery,
            GetClientForIntakeEmailQueryVariables
        >(GetClientForIntakeEmailDocument, variables),
        options,
    );
export const useInfiniteGetClientForIntakeEmailQuery = <
    TData = GetClientForIntakeEmailQuery,
    TError = unknown,
>(
    variables: GetClientForIntakeEmailQueryVariables,
    options?: UseInfiniteQueryOptions<GetClientForIntakeEmailQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetClientForIntakeEmailQuery, TError, TData>(
        ["getClientForIntakeEmail.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetClientForIntakeEmailQuery,
                GetClientForIntakeEmailQueryVariables
            >(GetClientForIntakeEmailDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const RequestClientTransferDocument = `
    mutation requestClientTransfer($params: TransferClientRequest!) {
  requestClientTransfer(params: $params)
}
    `;
export const useRequestClientTransferMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        RequestClientTransferMutation,
        TError,
        RequestClientTransferMutationVariables,
        TContext
    >,
) =>
    useMutation<
        RequestClientTransferMutation,
        TError,
        RequestClientTransferMutationVariables,
        TContext
    >(
        ["requestClientTransfer"],
        (variables?: RequestClientTransferMutationVariables) =>
            graphqlFetcher<
                RequestClientTransferMutation,
                RequestClientTransferMutationVariables
            >(RequestClientTransferDocument, variables)(),
        options,
    );
export const GetCommunityDocument = `
    query getCommunity($id: CommunityId!) {
  getCommunity(communityId: $id) {
    ...CommunityBasics
    invoices {
      id
      amountCents
      paidDate
      sentDate
      dueDate
      serial
      status
      clientId
      communityId
      fileId
      clientName
      notes
      externalReference
      remainingBalanceCents
      totalPaidCents
      hasOutstandingBalance
      lineItems {
        id
        description
        quantity
        unitPriceInCents
      }
      paymentSchedule {
        items {
          id
          amountInCents
          dueDate
          paidDate
          status
        }
      }
      isExternallyManaged
    }
    licenses {
      href
      id
      licenseEndDate
      licenseNumber
      licenseStartDate
      licensee
      status
      communityId
      tenantId
    }
  }
}
    ${CommunityBasicsFragmentDoc}`;
export const useGetCommunityQuery = <TData = GetCommunityQuery, TError = unknown>(
    variables: GetCommunityQueryVariables,
    options?: UseQueryOptions<GetCommunityQuery, TError, TData>,
) =>
    useQuery<GetCommunityQuery, TError, TData>(
        ["getCommunity", variables],
        graphqlFetcher<GetCommunityQuery, GetCommunityQueryVariables>(
            GetCommunityDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetCommunityQuery = <TData = GetCommunityQuery, TError = unknown>(
    variables: GetCommunityQueryVariables,
    options?: UseInfiniteQueryOptions<GetCommunityQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetCommunityQuery, TError, TData>(
        ["getCommunity.infinite", variables],
        metaData =>
            graphqlFetcher<GetCommunityQuery, GetCommunityQueryVariables>(
                GetCommunityDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetCommunityAdvancedDocument = `
    query getCommunityAdvanced($id: CommunityId!) {
  getCommunity(communityId: $id) {
    id
    communityMasterId
    communityMasterVersion
    matchInfo {
      matchRule
      matchScore
      originalMatchDate
    }
  }
}
    `;
export const useGetCommunityAdvancedQuery = <
    TData = GetCommunityAdvancedQuery,
    TError = unknown,
>(
    variables: GetCommunityAdvancedQueryVariables,
    options?: UseQueryOptions<GetCommunityAdvancedQuery, TError, TData>,
) =>
    useQuery<GetCommunityAdvancedQuery, TError, TData>(
        ["getCommunityAdvanced", variables],
        graphqlFetcher<GetCommunityAdvancedQuery, GetCommunityAdvancedQueryVariables>(
            GetCommunityAdvancedDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetCommunityAdvancedQuery = <
    TData = GetCommunityAdvancedQuery,
    TError = unknown,
>(
    variables: GetCommunityAdvancedQueryVariables,
    options?: UseInfiniteQueryOptions<GetCommunityAdvancedQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetCommunityAdvancedQuery, TError, TData>(
        ["getCommunityAdvanced.infinite", variables],
        metaData =>
            graphqlFetcher<GetCommunityAdvancedQuery, GetCommunityAdvancedQueryVariables>(
                GetCommunityAdvancedDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const DeleteCommunityLicenseDocument = `
    mutation deleteCommunityLicense($communityId: CommunityId!, $licenseId: CommunityLicenseId!) {
  deleteCommunityLicense(communityId: $communityId, licenseId: $licenseId)
}
    `;
export const useDeleteCommunityLicenseMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteCommunityLicenseMutation,
        TError,
        DeleteCommunityLicenseMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteCommunityLicenseMutation,
        TError,
        DeleteCommunityLicenseMutationVariables,
        TContext
    >(
        ["deleteCommunityLicense"],
        (variables?: DeleteCommunityLicenseMutationVariables) =>
            graphqlFetcher<
                DeleteCommunityLicenseMutation,
                DeleteCommunityLicenseMutationVariables
            >(DeleteCommunityLicenseDocument, variables)(),
        options,
    );
export const UpdateCommunityLicenseDocument = `
    mutation updateCommunityLicense($licenseId: CommunityLicenseId!, $license: CommunityLicenseInput!) {
  updateCommunityLicense(licenseId: $licenseId, license: $license)
}
    `;
export const useUpdateCommunityLicenseMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpdateCommunityLicenseMutation,
        TError,
        UpdateCommunityLicenseMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UpdateCommunityLicenseMutation,
        TError,
        UpdateCommunityLicenseMutationVariables,
        TContext
    >(
        ["updateCommunityLicense"],
        (variables?: UpdateCommunityLicenseMutationVariables) =>
            graphqlFetcher<
                UpdateCommunityLicenseMutation,
                UpdateCommunityLicenseMutationVariables
            >(UpdateCommunityLicenseDocument, variables)(),
        options,
    );
export const CreateCommunityLicenseDocument = `
    mutation createCommunityLicense($communityId: CommunityId!) {
  createCommunityLicense(communityId: $communityId) {
    id
  }
}
    `;
export const useCreateCommunityLicenseMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateCommunityLicenseMutation,
        TError,
        CreateCommunityLicenseMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateCommunityLicenseMutation,
        TError,
        CreateCommunityLicenseMutationVariables,
        TContext
    >(
        ["createCommunityLicense"],
        (variables?: CreateCommunityLicenseMutationVariables) =>
            graphqlFetcher<
                CreateCommunityLicenseMutation,
                CreateCommunityLicenseMutationVariables
            >(CreateCommunityLicenseDocument, variables)(),
        options,
    );
export const GetReferralsToCommunityDocument = `
    query getReferralsToCommunity($communityId: CommunityId!) {
  getReferralsToCommunity(communityId: $communityId) {
    ...ReferralBasics
  }
}
    ${ReferralBasicsFragmentDoc}`;
export const useGetReferralsToCommunityQuery = <
    TData = GetReferralsToCommunityQuery,
    TError = unknown,
>(
    variables: GetReferralsToCommunityQueryVariables,
    options?: UseQueryOptions<GetReferralsToCommunityQuery, TError, TData>,
) =>
    useQuery<GetReferralsToCommunityQuery, TError, TData>(
        ["getReferralsToCommunity", variables],
        graphqlFetcher<
            GetReferralsToCommunityQuery,
            GetReferralsToCommunityQueryVariables
        >(GetReferralsToCommunityDocument, variables),
        options,
    );
export const useInfiniteGetReferralsToCommunityQuery = <
    TData = GetReferralsToCommunityQuery,
    TError = unknown,
>(
    variables: GetReferralsToCommunityQueryVariables,
    options?: UseInfiniteQueryOptions<GetReferralsToCommunityQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetReferralsToCommunityQuery, TError, TData>(
        ["getReferralsToCommunity.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetReferralsToCommunityQuery,
                GetReferralsToCommunityQueryVariables
            >(GetReferralsToCommunityDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetLicenseDocument = `
    query getLicense($id: LicenseId!) {
  getLicense(licenseId: $id) {
    ...LicenseDefaults
    publicRecords {
      ...PublicRecordDefaults
    }
    identity {
      ...IdentityDefaults
    }
  }
}
    ${LicenseDefaultsFragmentDoc}
${PublicRecordDefaultsFragmentDoc}
${IdentityDefaultsFragmentDoc}`;
export const useGetLicenseQuery = <TData = GetLicenseQuery, TError = unknown>(
    variables: GetLicenseQueryVariables,
    options?: UseQueryOptions<GetLicenseQuery, TError, TData>,
) =>
    useQuery<GetLicenseQuery, TError, TData>(
        ["getLicense", variables],
        graphqlFetcher<GetLicenseQuery, GetLicenseQueryVariables>(
            GetLicenseDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetLicenseQuery = <TData = GetLicenseQuery, TError = unknown>(
    variables: GetLicenseQueryVariables,
    options?: UseInfiniteQueryOptions<GetLicenseQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetLicenseQuery, TError, TData>(
        ["getLicense.infinite", variables],
        metaData =>
            graphqlFetcher<GetLicenseQuery, GetLicenseQueryVariables>(
                GetLicenseDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetIdentityDocument = `
    query getIdentity($id: IdentityId!) {
  getIdentity(identityId: $id) {
    ...IdentityDefaults
    licenses {
      ...LicenseDefaults
      publicRecords {
        ...PublicRecordDefaults
        publicRecordContacts {
          address1
          address2
          cellPhone
          city
          contactNotes
          email1
          fax
          id
          name
          phone1
          relationship
          role
          state
          website
          zip
        }
      }
    }
  }
}
    ${IdentityDefaultsFragmentDoc}
${LicenseDefaultsFragmentDoc}
${PublicRecordDefaultsFragmentDoc}`;
export const useGetIdentityQuery = <TData = GetIdentityQuery, TError = unknown>(
    variables: GetIdentityQueryVariables,
    options?: UseQueryOptions<GetIdentityQuery, TError, TData>,
) =>
    useQuery<GetIdentityQuery, TError, TData>(
        ["getIdentity", variables],
        graphqlFetcher<GetIdentityQuery, GetIdentityQueryVariables>(
            GetIdentityDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetIdentityQuery = <TData = GetIdentityQuery, TError = unknown>(
    variables: GetIdentityQueryVariables,
    options?: UseInfiniteQueryOptions<GetIdentityQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetIdentityQuery, TError, TData>(
        ["getIdentity.infinite", variables],
        metaData =>
            graphqlFetcher<GetIdentityQuery, GetIdentityQueryVariables>(
                GetIdentityDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const PublicRecordSearchDocument = `
    query publicRecordSearch($params: PublicRecordSearchRequest!) {
  publicRecordSearch(params: $params) {
    count
    records {
      ...PublicRecordDefaults
      license {
        ...LicenseDefaults
        identity {
          ...IdentityDefaults
        }
      }
    }
  }
}
    ${PublicRecordDefaultsFragmentDoc}
${LicenseDefaultsFragmentDoc}
${IdentityDefaultsFragmentDoc}`;
export const usePublicRecordSearchQuery = <
    TData = PublicRecordSearchQuery,
    TError = unknown,
>(
    variables: PublicRecordSearchQueryVariables,
    options?: UseQueryOptions<PublicRecordSearchQuery, TError, TData>,
) =>
    useQuery<PublicRecordSearchQuery, TError, TData>(
        ["publicRecordSearch", variables],
        graphqlFetcher<PublicRecordSearchQuery, PublicRecordSearchQueryVariables>(
            PublicRecordSearchDocument,
            variables,
        ),
        options,
    );
export const useInfinitePublicRecordSearchQuery = <
    TData = PublicRecordSearchQuery,
    TError = unknown,
>(
    variables: PublicRecordSearchQueryVariables,
    options?: UseInfiniteQueryOptions<PublicRecordSearchQuery, TError, TData>,
) => {
    return useInfiniteQuery<PublicRecordSearchQuery, TError, TData>(
        ["publicRecordSearch.infinite", variables],
        metaData =>
            graphqlFetcher<PublicRecordSearchQuery, PublicRecordSearchQueryVariables>(
                PublicRecordSearchDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetEvergreenLicenseTypesDocument = `
    query getEvergreenLicenseTypes {
  getEvergreenLicenseTypes {
    id
    state
    licenseType
  }
}
    `;
export const useGetEvergreenLicenseTypesQuery = <
    TData = GetEvergreenLicenseTypesQuery,
    TError = unknown,
>(
    variables?: GetEvergreenLicenseTypesQueryVariables,
    options?: UseQueryOptions<GetEvergreenLicenseTypesQuery, TError, TData>,
) =>
    useQuery<GetEvergreenLicenseTypesQuery, TError, TData>(
        variables === undefined
            ? ["getEvergreenLicenseTypes"]
            : ["getEvergreenLicenseTypes", variables],
        graphqlFetcher<
            GetEvergreenLicenseTypesQuery,
            GetEvergreenLicenseTypesQueryVariables
        >(GetEvergreenLicenseTypesDocument, variables),
        options,
    );
export const useInfiniteGetEvergreenLicenseTypesQuery = <
    TData = GetEvergreenLicenseTypesQuery,
    TError = unknown,
>(
    variables?: GetEvergreenLicenseTypesQueryVariables,
    options?: UseInfiniteQueryOptions<GetEvergreenLicenseTypesQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetEvergreenLicenseTypesQuery, TError, TData>(
        variables === undefined
            ? ["getEvergreenLicenseTypes.infinite"]
            : ["getEvergreenLicenseTypes.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetEvergreenLicenseTypesQuery,
                GetEvergreenLicenseTypesQueryVariables
            >(GetEvergreenLicenseTypesDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const UpdateEvergreenLicenseTypeDocument = `
    mutation updateEvergreenLicenseType($input: EvergreenLicenseTypeInput!) {
  updateEvergreenLicenseType(input: $input) {
    id
  }
}
    `;
export const useUpdateEvergreenLicenseTypeMutation = <
    TError = unknown,
    TContext = unknown,
>(
    options?: UseMutationOptions<
        UpdateEvergreenLicenseTypeMutation,
        TError,
        UpdateEvergreenLicenseTypeMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UpdateEvergreenLicenseTypeMutation,
        TError,
        UpdateEvergreenLicenseTypeMutationVariables,
        TContext
    >(
        ["updateEvergreenLicenseType"],
        (variables?: UpdateEvergreenLicenseTypeMutationVariables) =>
            graphqlFetcher<
                UpdateEvergreenLicenseTypeMutation,
                UpdateEvergreenLicenseTypeMutationVariables
            >(UpdateEvergreenLicenseTypeDocument, variables)(),
        options,
    );
export const DeleteEvergreenLicenseTypeDocument = `
    mutation deleteEvergreenLicenseType($id: EvergreenLicenseTypeId!) {
  deleteEvergreenLicenseType(licenseTypeId: $id)
}
    `;
export const useDeleteEvergreenLicenseTypeMutation = <
    TError = unknown,
    TContext = unknown,
>(
    options?: UseMutationOptions<
        DeleteEvergreenLicenseTypeMutation,
        TError,
        DeleteEvergreenLicenseTypeMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteEvergreenLicenseTypeMutation,
        TError,
        DeleteEvergreenLicenseTypeMutationVariables,
        TContext
    >(
        ["deleteEvergreenLicenseType"],
        (variables?: DeleteEvergreenLicenseTypeMutationVariables) =>
            graphqlFetcher<
                DeleteEvergreenLicenseTypeMutation,
                DeleteEvergreenLicenseTypeMutationVariables
            >(DeleteEvergreenLicenseTypeDocument, variables)(),
        options,
    );
export const CommunityNamesDocument = `
    query CommunityNames($regionKey: String!, $ids: [CommunityId!]) {
  communityNames(regionKey: $regionKey, ids: $ids) {
    communities {
      id
      name
    }
  }
}
    `;
export const useCommunityNamesQuery = <TData = CommunityNamesQuery, TError = unknown>(
    variables: CommunityNamesQueryVariables,
    options?: UseQueryOptions<CommunityNamesQuery, TError, TData>,
) =>
    useQuery<CommunityNamesQuery, TError, TData>(
        ["CommunityNames", variables],
        graphqlFetcher<CommunityNamesQuery, CommunityNamesQueryVariables>(
            CommunityNamesDocument,
            variables,
        ),
        options,
    );
export const useInfiniteCommunityNamesQuery = <
    TData = CommunityNamesQuery,
    TError = unknown,
>(
    variables: CommunityNamesQueryVariables,
    options?: UseInfiniteQueryOptions<CommunityNamesQuery, TError, TData>,
) => {
    return useInfiniteQuery<CommunityNamesQuery, TError, TData>(
        ["CommunityNames.infinite", variables],
        metaData =>
            graphqlFetcher<CommunityNamesQuery, CommunityNamesQueryVariables>(
                CommunityNamesDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const CommunitySearchDocument = `
    query CommunitySearch($params: CommunitySearchRequest!) {
  communitySearch(params: $params) {
    total
    coordinates {
      lat
      lng
    }
    hits {
      address
      contractStatus
      contractExpiresDate
      contractIssuedDate
      contractNotes
      paymentFee
      paymentPercentage
      gender
      id
      lat
      licenseStatus
      lng
      mostRecentPlacement
      name
      phone
      placements
      rating
      summary
      thumbnail
      url
      unitsOrCapacity
      showInSearchResults
      monthlyPrice
      priceHighEnd
      zip
      state
      city
      mainPhone
      feeType
      secondPersonFee
      petDeposit
      moveInDeposit
      priceDetails
      caregiverRatio
      updatedAt
      dateAdded
      regionId
      referralCount
      email
      county
      contacts {
        cellPhone
        name
        phone1
        email1
        role
      }
      primaryContacts {
        cellPhone
        name
        phone1
        email1
        role
      }
      answers {
        questionId
        booleanAnswer
        numberAnswer
        numberAnswerHigh
        textAnswer
        dateAnswer
        calendarDateAnswer
        selections
        idReferenceAnswer
        selectionAnnotations {
          text
          selectionId
        }
      }
      licenses {
        id
        licenseNumber
        href
        licensee
        status
        licenseStartDate
        licenseEndDate
      }
    }
  }
}
    `;
export const useCommunitySearchQuery = <TData = CommunitySearchQuery, TError = unknown>(
    variables: CommunitySearchQueryVariables,
    options?: UseQueryOptions<CommunitySearchQuery, TError, TData>,
) =>
    useQuery<CommunitySearchQuery, TError, TData>(
        ["CommunitySearch", variables],
        graphqlFetcher<CommunitySearchQuery, CommunitySearchQueryVariables>(
            CommunitySearchDocument,
            variables,
        ),
        options,
    );
export const useInfiniteCommunitySearchQuery = <
    TData = CommunitySearchQuery,
    TError = unknown,
>(
    variables: CommunitySearchQueryVariables,
    options?: UseInfiniteQueryOptions<CommunitySearchQuery, TError, TData>,
) => {
    return useInfiniteQuery<CommunitySearchQuery, TError, TData>(
        ["CommunitySearch.infinite", variables],
        metaData =>
            graphqlFetcher<CommunitySearchQuery, CommunitySearchQueryVariables>(
                CommunitySearchDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const CommunitySearchMapViewDocument = `
    query CommunitySearchMapView($searchParams: CommunitySearchRequest!, $mapView: CommunitySearchRequestMapComponent!) {
  communitySearchMapView(searchParams: $searchParams, mapView: $mapView) {
    recommendedZoom
    coordinates {
      lat
      lng
    }
    communityCoordinates {
      lat
      lng
      name
      contractStatus
      id
      appLink
    }
    communityClusters {
      lat
      lng
      count
    }
  }
}
    `;
export const useCommunitySearchMapViewQuery = <
    TData = CommunitySearchMapViewQuery,
    TError = unknown,
>(
    variables: CommunitySearchMapViewQueryVariables,
    options?: UseQueryOptions<CommunitySearchMapViewQuery, TError, TData>,
) =>
    useQuery<CommunitySearchMapViewQuery, TError, TData>(
        ["CommunitySearchMapView", variables],
        graphqlFetcher<CommunitySearchMapViewQuery, CommunitySearchMapViewQueryVariables>(
            CommunitySearchMapViewDocument,
            variables,
        ),
        options,
    );
export const useInfiniteCommunitySearchMapViewQuery = <
    TData = CommunitySearchMapViewQuery,
    TError = unknown,
>(
    variables: CommunitySearchMapViewQueryVariables,
    options?: UseInfiniteQueryOptions<CommunitySearchMapViewQuery, TError, TData>,
) => {
    return useInfiniteQuery<CommunitySearchMapViewQuery, TError, TData>(
        ["CommunitySearchMapView.infinite", variables],
        metaData =>
            graphqlFetcher<
                CommunitySearchMapViewQuery,
                CommunitySearchMapViewQueryVariables
            >(CommunitySearchMapViewDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetCommunityContactsDocument = `
    query getCommunityContacts($communityId: CommunityId!, $regionId: RegionId!) {
  getCommunityContacts(communityId: $communityId, regionId: $regionId) {
    contactId
    communityId
    primary
    contact {
      ...ContactFragment
    }
    referenceContact {
      id
      summary
      regionId
    }
  }
}
    ${ContactFragmentFragmentDoc}`;
export const useGetCommunityContactsQuery = <
    TData = GetCommunityContactsQuery,
    TError = unknown,
>(
    variables: GetCommunityContactsQueryVariables,
    options?: UseQueryOptions<GetCommunityContactsQuery, TError, TData>,
) =>
    useQuery<GetCommunityContactsQuery, TError, TData>(
        ["getCommunityContacts", variables],
        graphqlFetcher<GetCommunityContactsQuery, GetCommunityContactsQueryVariables>(
            GetCommunityContactsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetCommunityContactsQuery = <
    TData = GetCommunityContactsQuery,
    TError = unknown,
>(
    variables: GetCommunityContactsQueryVariables,
    options?: UseInfiniteQueryOptions<GetCommunityContactsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetCommunityContactsQuery, TError, TData>(
        ["getCommunityContacts.infinite", variables],
        metaData =>
            graphqlFetcher<GetCommunityContactsQuery, GetCommunityContactsQueryVariables>(
                GetCommunityContactsDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const PromoteCommunityContactDocument = `
    mutation promoteCommunityContact($communityId: CommunityId!, $contactId: ContactId!, $regionId: RegionId!) {
  promoteCommunityContact(
    communityId: $communityId
    contactId: $contactId
    regionId: $regionId
  ) {
    contactId
    communityId
    primary
    contact {
      ...ContactFragment
    }
    referenceContact {
      id
      summary
      regions
      regionId
      ownerId
      dateAdded
      updatedAt
      updatedBy
      createdBy
    }
  }
}
    ${ContactFragmentFragmentDoc}`;
export const usePromoteCommunityContactMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        PromoteCommunityContactMutation,
        TError,
        PromoteCommunityContactMutationVariables,
        TContext
    >,
) =>
    useMutation<
        PromoteCommunityContactMutation,
        TError,
        PromoteCommunityContactMutationVariables,
        TContext
    >(
        ["promoteCommunityContact"],
        (variables?: PromoteCommunityContactMutationVariables) =>
            graphqlFetcher<
                PromoteCommunityContactMutation,
                PromoteCommunityContactMutationVariables
            >(PromoteCommunityContactDocument, variables)(),
        options,
    );
export const DeleteCommunityContactDocument = `
    mutation deleteCommunityContact($communityId: CommunityId!, $contactId: ContactId!) {
  deleteCommunityContact(communityId: $communityId, contactId: $contactId) {
    contactId
    communityId
  }
}
    `;
export const useDeleteCommunityContactMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteCommunityContactMutation,
        TError,
        DeleteCommunityContactMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteCommunityContactMutation,
        TError,
        DeleteCommunityContactMutationVariables,
        TContext
    >(
        ["deleteCommunityContact"],
        (variables?: DeleteCommunityContactMutationVariables) =>
            graphqlFetcher<
                DeleteCommunityContactMutation,
                DeleteCommunityContactMutationVariables
            >(DeleteCommunityContactDocument, variables)(),
        options,
    );
export const DeleteClientContactDocument = `
    mutation deleteClientContact($clientId: ClientId!, $contactId: ContactId!, $relationship: ClientContactRelationship!) {
  deleteClientContact(
    clientId: $clientId
    contactId: $contactId
    relationship: $relationship
  ) {
    contactId
    clientId
  }
}
    `;
export const useDeleteClientContactMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteClientContactMutation,
        TError,
        DeleteClientContactMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteClientContactMutation,
        TError,
        DeleteClientContactMutationVariables,
        TContext
    >(
        ["deleteClientContact"],
        (variables?: DeleteClientContactMutationVariables) =>
            graphqlFetcher<
                DeleteClientContactMutation,
                DeleteClientContactMutationVariables
            >(DeleteClientContactDocument, variables)(),
        options,
    );
export const GetClientContactsDocument = `
    query getClientContacts($params: GetClientContactsRequest!) {
  getClientContacts(params: $params) {
    contactId
    clientId
    relationship
    powerOfAttorney
    contact {
      ...ContactFragment
      referenceContacts {
        id
        appLink
      }
    }
  }
}
    ${ContactFragmentFragmentDoc}`;
export const useGetClientContactsQuery = <
    TData = GetClientContactsQuery,
    TError = unknown,
>(
    variables: GetClientContactsQueryVariables,
    options?: UseQueryOptions<GetClientContactsQuery, TError, TData>,
) =>
    useQuery<GetClientContactsQuery, TError, TData>(
        ["getClientContacts", variables],
        graphqlFetcher<GetClientContactsQuery, GetClientContactsQueryVariables>(
            GetClientContactsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetClientContactsQuery = <
    TData = GetClientContactsQuery,
    TError = unknown,
>(
    variables: GetClientContactsQueryVariables,
    options?: UseInfiniteQueryOptions<GetClientContactsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetClientContactsQuery, TError, TData>(
        ["getClientContacts.infinite", variables],
        metaData =>
            graphqlFetcher<GetClientContactsQuery, GetClientContactsQueryVariables>(
                GetClientContactsDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetContactDocument = `
    query getContact($contactId: ContactId!) {
  getContact(contactId: $contactId) {
    ...ContactWithLinks
  }
}
    ${ContactWithLinksFragmentDoc}`;
export const useGetContactQuery = <TData = GetContactQuery, TError = unknown>(
    variables: GetContactQueryVariables,
    options?: UseQueryOptions<GetContactQuery, TError, TData>,
) =>
    useQuery<GetContactQuery, TError, TData>(
        ["getContact", variables],
        graphqlFetcher<GetContactQuery, GetContactQueryVariables>(
            GetContactDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetContactQuery = <TData = GetContactQuery, TError = unknown>(
    variables: GetContactQueryVariables,
    options?: UseInfiniteQueryOptions<GetContactQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetContactQuery, TError, TData>(
        ["getContact.infinite", variables],
        metaData =>
            graphqlFetcher<GetContactQuery, GetContactQueryVariables>(
                GetContactDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const CreateContactDocument = `
    mutation createContact($params: CreateContactInput!) {
  createContact(params: $params) {
    ...ContactWithLinks
  }
}
    ${ContactWithLinksFragmentDoc}`;
export const useCreateContactMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateContactMutation,
        TError,
        CreateContactMutationVariables,
        TContext
    >,
) =>
    useMutation<CreateContactMutation, TError, CreateContactMutationVariables, TContext>(
        ["createContact"],
        (variables?: CreateContactMutationVariables) =>
            graphqlFetcher<CreateContactMutation, CreateContactMutationVariables>(
                CreateContactDocument,
                variables,
            )(),
        options,
    );
export const PatchContactDocument = `
    mutation patchContact($params: PatchContactInput!) {
  patchContact(params: $params) {
    ...ContactWithLinks
  }
}
    ${ContactWithLinksFragmentDoc}`;
export const usePatchContactMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        PatchContactMutation,
        TError,
        PatchContactMutationVariables,
        TContext
    >,
) =>
    useMutation<PatchContactMutation, TError, PatchContactMutationVariables, TContext>(
        ["patchContact"],
        (variables?: PatchContactMutationVariables) =>
            graphqlFetcher<PatchContactMutation, PatchContactMutationVariables>(
                PatchContactDocument,
                variables,
            )(),
        options,
    );
export const GetCustomListDocument = `
    query getCustomList($key: CustomListKey!) {
  getCustomList(key: $key) {
    ...CustomListResponse
  }
}
    ${CustomListResponseFragmentDoc}`;
export const useGetCustomListQuery = <TData = GetCustomListQuery, TError = unknown>(
    variables: GetCustomListQueryVariables,
    options?: UseQueryOptions<GetCustomListQuery, TError, TData>,
) =>
    useQuery<GetCustomListQuery, TError, TData>(
        ["getCustomList", variables],
        graphqlFetcher<GetCustomListQuery, GetCustomListQueryVariables>(
            GetCustomListDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetCustomListQuery = <
    TData = GetCustomListQuery,
    TError = unknown,
>(
    variables: GetCustomListQueryVariables,
    options?: UseInfiniteQueryOptions<GetCustomListQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetCustomListQuery, TError, TData>(
        ["getCustomList.infinite", variables],
        metaData =>
            graphqlFetcher<GetCustomListQuery, GetCustomListQueryVariables>(
                GetCustomListDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetCustomListsDocument = `
    query getCustomLists {
  getCustomLists {
    ...CustomListResponse
  }
}
    ${CustomListResponseFragmentDoc}`;
export const useGetCustomListsQuery = <TData = GetCustomListsQuery, TError = unknown>(
    variables?: GetCustomListsQueryVariables,
    options?: UseQueryOptions<GetCustomListsQuery, TError, TData>,
) =>
    useQuery<GetCustomListsQuery, TError, TData>(
        variables === undefined ? ["getCustomLists"] : ["getCustomLists", variables],
        graphqlFetcher<GetCustomListsQuery, GetCustomListsQueryVariables>(
            GetCustomListsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetCustomListsQuery = <
    TData = GetCustomListsQuery,
    TError = unknown,
>(
    variables?: GetCustomListsQueryVariables,
    options?: UseInfiniteQueryOptions<GetCustomListsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetCustomListsQuery, TError, TData>(
        variables === undefined
            ? ["getCustomLists.infinite"]
            : ["getCustomLists.infinite", variables],
        metaData =>
            graphqlFetcher<GetCustomListsQuery, GetCustomListsQueryVariables>(
                GetCustomListsDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const CreateCustomListItemDocument = `
    mutation createCustomListItem($params: CreateCustomListItemRequest!) {
  createCustomListItem(params: $params) {
    ...CustomListItemResponse
  }
}
    ${CustomListItemResponseFragmentDoc}`;
export const useCreateCustomListItemMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateCustomListItemMutation,
        TError,
        CreateCustomListItemMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateCustomListItemMutation,
        TError,
        CreateCustomListItemMutationVariables,
        TContext
    >(
        ["createCustomListItem"],
        (variables?: CreateCustomListItemMutationVariables) =>
            graphqlFetcher<
                CreateCustomListItemMutation,
                CreateCustomListItemMutationVariables
            >(CreateCustomListItemDocument, variables)(),
        options,
    );
export const PatchCustomListItemDocument = `
    mutation patchCustomListItem($params: PatchCustomListItemRequest!) {
  patchCustomListItem(params: $params) {
    ...CustomListItemResponse
  }
}
    ${CustomListItemResponseFragmentDoc}`;
export const usePatchCustomListItemMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        PatchCustomListItemMutation,
        TError,
        PatchCustomListItemMutationVariables,
        TContext
    >,
) =>
    useMutation<
        PatchCustomListItemMutation,
        TError,
        PatchCustomListItemMutationVariables,
        TContext
    >(
        ["patchCustomListItem"],
        (variables?: PatchCustomListItemMutationVariables) =>
            graphqlFetcher<
                PatchCustomListItemMutation,
                PatchCustomListItemMutationVariables
            >(PatchCustomListItemDocument, variables)(),
        options,
    );
export const PatchCustomListItemsDocument = `
    mutation patchCustomListItems($params: [PatchCustomListItemRequest!]!) {
  patchCustomListItems(params: $params) {
    ...CustomListItemResponse
  }
}
    ${CustomListItemResponseFragmentDoc}`;
export const usePatchCustomListItemsMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        PatchCustomListItemsMutation,
        TError,
        PatchCustomListItemsMutationVariables,
        TContext
    >,
) =>
    useMutation<
        PatchCustomListItemsMutation,
        TError,
        PatchCustomListItemsMutationVariables,
        TContext
    >(
        ["patchCustomListItems"],
        (variables?: PatchCustomListItemsMutationVariables) =>
            graphqlFetcher<
                PatchCustomListItemsMutation,
                PatchCustomListItemsMutationVariables
            >(PatchCustomListItemsDocument, variables)(),
        options,
    );
export const CreateDisclosureDocument = `
    mutation createDisclosure($clientId: ClientId!) {
  createDisclosure(clientId: $clientId) {
    ...DisclosureFragment
  }
}
    ${DisclosureFragmentFragmentDoc}`;
export const useCreateDisclosureMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateDisclosureMutation,
        TError,
        CreateDisclosureMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateDisclosureMutation,
        TError,
        CreateDisclosureMutationVariables,
        TContext
    >(
        ["createDisclosure"],
        (variables?: CreateDisclosureMutationVariables) =>
            graphqlFetcher<CreateDisclosureMutation, CreateDisclosureMutationVariables>(
                CreateDisclosureDocument,
                variables,
            )(),
        options,
    );
export const CreateDisclosureAcknowledgementDocument = `
    mutation createDisclosureAcknowledgement($disclosureId: DisclosureId!, $acknowledgement: DisclosureAcknowledgementInput!) {
  createDisclosureAcknowledgement(
    disclosureId: $disclosureId
    acknowledgement: $acknowledgement
  ) {
    ...DisclosureAcknowledgementFragment
  }
}
    ${DisclosureAcknowledgementFragmentFragmentDoc}`;
export const useCreateDisclosureAcknowledgementMutation = <
    TError = unknown,
    TContext = unknown,
>(
    options?: UseMutationOptions<
        CreateDisclosureAcknowledgementMutation,
        TError,
        CreateDisclosureAcknowledgementMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateDisclosureAcknowledgementMutation,
        TError,
        CreateDisclosureAcknowledgementMutationVariables,
        TContext
    >(
        ["createDisclosureAcknowledgement"],
        (variables?: CreateDisclosureAcknowledgementMutationVariables) =>
            graphqlFetcher<
                CreateDisclosureAcknowledgementMutation,
                CreateDisclosureAcknowledgementMutationVariables
            >(CreateDisclosureAcknowledgementDocument, variables)(),
        options,
    );
export const UpdateEvergreenDataSourceDocument = `
    mutation updateEvergreenDataSource($input: EvergreenDataSourceInput!) {
  updateEvergreenDataSource(input: $input) {
    id
  }
}
    `;
export const useUpdateEvergreenDataSourceMutation = <
    TError = unknown,
    TContext = unknown,
>(
    options?: UseMutationOptions<
        UpdateEvergreenDataSourceMutation,
        TError,
        UpdateEvergreenDataSourceMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UpdateEvergreenDataSourceMutation,
        TError,
        UpdateEvergreenDataSourceMutationVariables,
        TContext
    >(
        ["updateEvergreenDataSource"],
        (variables?: UpdateEvergreenDataSourceMutationVariables) =>
            graphqlFetcher<
                UpdateEvergreenDataSourceMutation,
                UpdateEvergreenDataSourceMutationVariables
            >(UpdateEvergreenDataSourceDocument, variables)(),
        options,
    );
export const UpdateEvergreenImportDocument = `
    mutation updateEvergreenImport($input: EvergreenImportInput!) {
  updateEvergreenImport(input: $input) {
    id
  }
}
    `;
export const useUpdateEvergreenImportMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpdateEvergreenImportMutation,
        TError,
        UpdateEvergreenImportMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UpdateEvergreenImportMutation,
        TError,
        UpdateEvergreenImportMutationVariables,
        TContext
    >(
        ["updateEvergreenImport"],
        (variables?: UpdateEvergreenImportMutationVariables) =>
            graphqlFetcher<
                UpdateEvergreenImportMutation,
                UpdateEvergreenImportMutationVariables
            >(UpdateEvergreenImportDocument, variables)(),
        options,
    );
export const GetEvergreenDataSourcesDocument = `
    query getEvergreenDataSources {
  getEvergreenDataSources {
    id
    name
    lastSuccessfulRun
    updateFrequencyInDays
    updatedAt
    dateAdded
    updateRequested
    warningsSilenced
    duplicatesDetectionWindowInDays
  }
}
    `;
export const useGetEvergreenDataSourcesQuery = <
    TData = GetEvergreenDataSourcesQuery,
    TError = unknown,
>(
    variables?: GetEvergreenDataSourcesQueryVariables,
    options?: UseQueryOptions<GetEvergreenDataSourcesQuery, TError, TData>,
) =>
    useQuery<GetEvergreenDataSourcesQuery, TError, TData>(
        variables === undefined
            ? ["getEvergreenDataSources"]
            : ["getEvergreenDataSources", variables],
        graphqlFetcher<
            GetEvergreenDataSourcesQuery,
            GetEvergreenDataSourcesQueryVariables
        >(GetEvergreenDataSourcesDocument, variables),
        options,
    );
export const useInfiniteGetEvergreenDataSourcesQuery = <
    TData = GetEvergreenDataSourcesQuery,
    TError = unknown,
>(
    variables?: GetEvergreenDataSourcesQueryVariables,
    options?: UseInfiniteQueryOptions<GetEvergreenDataSourcesQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetEvergreenDataSourcesQuery, TError, TData>(
        variables === undefined
            ? ["getEvergreenDataSources.infinite"]
            : ["getEvergreenDataSources.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetEvergreenDataSourcesQuery,
                GetEvergreenDataSourcesQueryVariables
            >(GetEvergreenDataSourcesDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetEvergreenDataSourceDocument = `
    query getEvergreenDataSource($name: String!) {
  getEvergreenDataSource(sourceName: $name) {
    id
    name
    lastSuccessfulRun
    updateFrequencyInDays
    warningsSilenced
    duplicatesDetectionWindowInDays
    updatedAt
    dateAdded
    updateRequested
    imports {
      ...ImportFragment
    }
  }
}
    ${ImportFragmentFragmentDoc}`;
export const useGetEvergreenDataSourceQuery = <
    TData = GetEvergreenDataSourceQuery,
    TError = unknown,
>(
    variables: GetEvergreenDataSourceQueryVariables,
    options?: UseQueryOptions<GetEvergreenDataSourceQuery, TError, TData>,
) =>
    useQuery<GetEvergreenDataSourceQuery, TError, TData>(
        ["getEvergreenDataSource", variables],
        graphqlFetcher<GetEvergreenDataSourceQuery, GetEvergreenDataSourceQueryVariables>(
            GetEvergreenDataSourceDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetEvergreenDataSourceQuery = <
    TData = GetEvergreenDataSourceQuery,
    TError = unknown,
>(
    variables: GetEvergreenDataSourceQueryVariables,
    options?: UseInfiniteQueryOptions<GetEvergreenDataSourceQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetEvergreenDataSourceQuery, TError, TData>(
        ["getEvergreenDataSource.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetEvergreenDataSourceQuery,
                GetEvergreenDataSourceQueryVariables
            >(GetEvergreenDataSourceDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetEvergreenImportsDocument = `
    query getEvergreenImports($skip: Int!, $take: Int!) {
  getEvergreenImports(skip: $skip, take: $take) {
    ...ImportFragment
  }
}
    ${ImportFragmentFragmentDoc}`;
export const useGetEvergreenImportsQuery = <
    TData = GetEvergreenImportsQuery,
    TError = unknown,
>(
    variables: GetEvergreenImportsQueryVariables,
    options?: UseQueryOptions<GetEvergreenImportsQuery, TError, TData>,
) =>
    useQuery<GetEvergreenImportsQuery, TError, TData>(
        ["getEvergreenImports", variables],
        graphqlFetcher<GetEvergreenImportsQuery, GetEvergreenImportsQueryVariables>(
            GetEvergreenImportsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetEvergreenImportsQuery = <
    TData = GetEvergreenImportsQuery,
    TError = unknown,
>(
    variables: GetEvergreenImportsQueryVariables,
    options?: UseInfiniteQueryOptions<GetEvergreenImportsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetEvergreenImportsQuery, TError, TData>(
        ["getEvergreenImports.infinite", variables],
        metaData =>
            graphqlFetcher<GetEvergreenImportsQuery, GetEvergreenImportsQueryVariables>(
                GetEvergreenImportsDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const UpdateFeatureFlagDocument = `
    mutation updateFeatureFlag($name: ID!, $enabled: Boolean!) {
  updateFeatureFlag(name: $name, enabled: $enabled) {
    name
    enabled
  }
}
    `;
export const useUpdateFeatureFlagMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpdateFeatureFlagMutation,
        TError,
        UpdateFeatureFlagMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UpdateFeatureFlagMutation,
        TError,
        UpdateFeatureFlagMutationVariables,
        TContext
    >(
        ["updateFeatureFlag"],
        (variables?: UpdateFeatureFlagMutationVariables) =>
            graphqlFetcher<UpdateFeatureFlagMutation, UpdateFeatureFlagMutationVariables>(
                UpdateFeatureFlagDocument,
                variables,
            )(),
        options,
    );
export const GetGlobalFeaturesDocument = `
    query getGlobalFeatures {
  getGlobalFeatures {
    skus
    features {
      actual {
        name
        enabled
      }
      skus
      key
      friendlyTitle
      description
      createdOn
      intent
      packageEnabled
    }
  }
}
    `;
export const useGetGlobalFeaturesQuery = <
    TData = GetGlobalFeaturesQuery,
    TError = unknown,
>(
    variables?: GetGlobalFeaturesQueryVariables,
    options?: UseQueryOptions<GetGlobalFeaturesQuery, TError, TData>,
) =>
    useQuery<GetGlobalFeaturesQuery, TError, TData>(
        variables === undefined
            ? ["getGlobalFeatures"]
            : ["getGlobalFeatures", variables],
        graphqlFetcher<GetGlobalFeaturesQuery, GetGlobalFeaturesQueryVariables>(
            GetGlobalFeaturesDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetGlobalFeaturesQuery = <
    TData = GetGlobalFeaturesQuery,
    TError = unknown,
>(
    variables?: GetGlobalFeaturesQueryVariables,
    options?: UseInfiniteQueryOptions<GetGlobalFeaturesQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetGlobalFeaturesQuery, TError, TData>(
        variables === undefined
            ? ["getGlobalFeatures.infinite"]
            : ["getGlobalFeatures.infinite", variables],
        metaData =>
            graphqlFetcher<GetGlobalFeaturesQuery, GetGlobalFeaturesQueryVariables>(
                GetGlobalFeaturesDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetFilesDocument = `
    query getFiles($entityId: FileEntityId!) {
  getFiles(entityId: $entityId) {
    files {
      ...FileFragment
    }
  }
}
    ${FileFragmentFragmentDoc}`;
export const useGetFilesQuery = <TData = GetFilesQuery, TError = unknown>(
    variables: GetFilesQueryVariables,
    options?: UseQueryOptions<GetFilesQuery, TError, TData>,
) =>
    useQuery<GetFilesQuery, TError, TData>(
        ["getFiles", variables],
        graphqlFetcher<GetFilesQuery, GetFilesQueryVariables>(
            GetFilesDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetFilesQuery = <TData = GetFilesQuery, TError = unknown>(
    variables: GetFilesQueryVariables,
    options?: UseInfiniteQueryOptions<GetFilesQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetFilesQuery, TError, TData>(
        ["getFiles.infinite", variables],
        metaData =>
            graphqlFetcher<GetFilesQuery, GetFilesQueryVariables>(GetFilesDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetFileDocument = `
    query getFile($fileId: FileId!) {
  getFile(fileId: $fileId) {
    ...FileFragment
    outstandingSignatureRequestsCount
    accessControls {
      id
      groupId
    }
  }
}
    ${FileFragmentFragmentDoc}`;
export const useGetFileQuery = <TData = GetFileQuery, TError = unknown>(
    variables: GetFileQueryVariables,
    options?: UseQueryOptions<GetFileQuery, TError, TData>,
) =>
    useQuery<GetFileQuery, TError, TData>(
        ["getFile", variables],
        graphqlFetcher<GetFileQuery, GetFileQueryVariables>(GetFileDocument, variables),
        options,
    );
export const useInfiniteGetFileQuery = <TData = GetFileQuery, TError = unknown>(
    variables: GetFileQueryVariables,
    options?: UseInfiniteQueryOptions<GetFileQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetFileQuery, TError, TData>(
        ["getFile.infinite", variables],
        metaData =>
            graphqlFetcher<GetFileQuery, GetFileQueryVariables>(GetFileDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const UpdateFileDocument = `
    mutation updateFile($fileId: FileId!, $details: FileUpdateInput!, $accessControls: [AccessControlInput!], $regionVisibility: EntityRegionVisibilityInput) {
  updateFile(
    fileId: $fileId
    details: $details
    accessControls: $accessControls
    regionVisibility: $regionVisibility
  ) {
    ...FileFragment
    accessControls {
      id
      groupId
    }
    outstandingSignatureRequestsCount
  }
}
    ${FileFragmentFragmentDoc}`;
export const useUpdateFileMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpdateFileMutation,
        TError,
        UpdateFileMutationVariables,
        TContext
    >,
) =>
    useMutation<UpdateFileMutation, TError, UpdateFileMutationVariables, TContext>(
        ["updateFile"],
        (variables?: UpdateFileMutationVariables) =>
            graphqlFetcher<UpdateFileMutation, UpdateFileMutationVariables>(
                UpdateFileDocument,
                variables,
            )(),
        options,
    );
export const DeleteFileDocument = `
    mutation deleteFile($fileId: FileId!) {
  deleteFile(fileId: $fileId)
}
    `;
export const useDeleteFileMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteFileMutation,
        TError,
        DeleteFileMutationVariables,
        TContext
    >,
) =>
    useMutation<DeleteFileMutation, TError, DeleteFileMutationVariables, TContext>(
        ["deleteFile"],
        (variables?: DeleteFileMutationVariables) =>
            graphqlFetcher<DeleteFileMutation, DeleteFileMutationVariables>(
                DeleteFileDocument,
                variables,
            )(),
        options,
    );
export const DeleteHostedFormDocument = `
    mutation deleteHostedForm($hostedFormId: HostedFormId!) {
  deleteHostedForm(hostedFormId: $hostedFormId)
}
    `;
export const useDeleteHostedFormMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteHostedFormMutation,
        TError,
        DeleteHostedFormMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteHostedFormMutation,
        TError,
        DeleteHostedFormMutationVariables,
        TContext
    >(
        ["deleteHostedForm"],
        (variables?: DeleteHostedFormMutationVariables) =>
            graphqlFetcher<DeleteHostedFormMutation, DeleteHostedFormMutationVariables>(
                DeleteHostedFormDocument,
                variables,
            )(),
        options,
    );
export const CreateHostedFormDocument = `
    mutation createHostedForm {
  createHostedForm {
    id
  }
}
    `;
export const useCreateHostedFormMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateHostedFormMutation,
        TError,
        CreateHostedFormMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateHostedFormMutation,
        TError,
        CreateHostedFormMutationVariables,
        TContext
    >(
        ["createHostedForm"],
        (variables?: CreateHostedFormMutationVariables) =>
            graphqlFetcher<CreateHostedFormMutation, CreateHostedFormMutationVariables>(
                CreateHostedFormDocument,
                variables,
            )(),
        options,
    );
export const PatchHostedFormDocument = `
    mutation patchHostedForm($params: PatchHostedFormRequest!) {
  patchHostedForm(params: $params) {
    id
  }
}
    `;
export const usePatchHostedFormMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        PatchHostedFormMutation,
        TError,
        PatchHostedFormMutationVariables,
        TContext
    >,
) =>
    useMutation<
        PatchHostedFormMutation,
        TError,
        PatchHostedFormMutationVariables,
        TContext
    >(
        ["patchHostedForm"],
        (variables?: PatchHostedFormMutationVariables) =>
            graphqlFetcher<PatchHostedFormMutation, PatchHostedFormMutationVariables>(
                PatchHostedFormDocument,
                variables,
            )(),
        options,
    );
export const GetHostedFormsDocument = `
    query getHostedForms {
  getHostedForms {
    id
    name
    displayName
    dateAdded
    configuration {
      style
      regions {
        regionId
        userId
        recipientUserIds
      }
    }
    layoutSections {
      id
      order
      sectionKey
      visible
      layoutItems {
        id
        order
        questionId
        entityFieldName
        showIfBlank
      }
    }
    customizable
    url
  }
}
    `;
export const useGetHostedFormsQuery = <TData = GetHostedFormsQuery, TError = unknown>(
    variables?: GetHostedFormsQueryVariables,
    options?: UseQueryOptions<GetHostedFormsQuery, TError, TData>,
) =>
    useQuery<GetHostedFormsQuery, TError, TData>(
        variables === undefined ? ["getHostedForms"] : ["getHostedForms", variables],
        graphqlFetcher<GetHostedFormsQuery, GetHostedFormsQueryVariables>(
            GetHostedFormsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetHostedFormsQuery = <
    TData = GetHostedFormsQuery,
    TError = unknown,
>(
    variables?: GetHostedFormsQueryVariables,
    options?: UseInfiniteQueryOptions<GetHostedFormsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetHostedFormsQuery, TError, TData>(
        variables === undefined
            ? ["getHostedForms.infinite"]
            : ["getHostedForms.infinite", variables],
        metaData =>
            graphqlFetcher<GetHostedFormsQuery, GetHostedFormsQueryVariables>(
                GetHostedFormsDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const SendHostedFormWithTokenDocument = `
    mutation sendHostedFormWithToken($params: SendHostedFormWithTokenRequest!) {
  sendHostedFormWithToken(params: $params) {
    token {
      id
      expiresAt
      updatedAt
      dateAdded
      createdBy
      updatedBy
    }
  }
}
    `;
export const useSendHostedFormWithTokenMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        SendHostedFormWithTokenMutation,
        TError,
        SendHostedFormWithTokenMutationVariables,
        TContext
    >,
) =>
    useMutation<
        SendHostedFormWithTokenMutation,
        TError,
        SendHostedFormWithTokenMutationVariables,
        TContext
    >(
        ["sendHostedFormWithToken"],
        (variables?: SendHostedFormWithTokenMutationVariables) =>
            graphqlFetcher<
                SendHostedFormWithTokenMutation,
                SendHostedFormWithTokenMutationVariables
            >(SendHostedFormWithTokenDocument, variables)(),
        options,
    );
export const SendHostedFormWithPermalinkDocument = `
    mutation sendHostedFormWithPermalink($params: SendHostedFormWithPermalinkRequest!) {
  sendHostedFormWithPermalink(params: $params)
}
    `;
export const useSendHostedFormWithPermalinkMutation = <
    TError = unknown,
    TContext = unknown,
>(
    options?: UseMutationOptions<
        SendHostedFormWithPermalinkMutation,
        TError,
        SendHostedFormWithPermalinkMutationVariables,
        TContext
    >,
) =>
    useMutation<
        SendHostedFormWithPermalinkMutation,
        TError,
        SendHostedFormWithPermalinkMutationVariables,
        TContext
    >(
        ["sendHostedFormWithPermalink"],
        (variables?: SendHostedFormWithPermalinkMutationVariables) =>
            graphqlFetcher<
                SendHostedFormWithPermalinkMutation,
                SendHostedFormWithPermalinkMutationVariables
            >(SendHostedFormWithPermalinkDocument, variables)(),
        options,
    );
export const GetHostedFormTokensForEntityDocument = `
    query getHostedFormTokensForEntity($entityId: HostedFormEntityId!) {
  getHostedFormTokensForEntity(entityId: $entityId) {
    id
    expiresAt
    completedAt
    hostedFormId
    updatedAt
    dateAdded
    createdBy
    updatedBy
    status
    sentMessages {
      to
    }
  }
}
    `;
export const useGetHostedFormTokensForEntityQuery = <
    TData = GetHostedFormTokensForEntityQuery,
    TError = unknown,
>(
    variables: GetHostedFormTokensForEntityQueryVariables,
    options?: UseQueryOptions<GetHostedFormTokensForEntityQuery, TError, TData>,
) =>
    useQuery<GetHostedFormTokensForEntityQuery, TError, TData>(
        ["getHostedFormTokensForEntity", variables],
        graphqlFetcher<
            GetHostedFormTokensForEntityQuery,
            GetHostedFormTokensForEntityQueryVariables
        >(GetHostedFormTokensForEntityDocument, variables),
        options,
    );
export const useInfiniteGetHostedFormTokensForEntityQuery = <
    TData = GetHostedFormTokensForEntityQuery,
    TError = unknown,
>(
    variables: GetHostedFormTokensForEntityQueryVariables,
    options?: UseInfiniteQueryOptions<GetHostedFormTokensForEntityQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetHostedFormTokensForEntityQuery, TError, TData>(
        ["getHostedFormTokensForEntity.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetHostedFormTokensForEntityQuery,
                GetHostedFormTokensForEntityQueryVariables
            >(GetHostedFormTokensForEntityDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const DeleteHostedFormTokenDocument = `
    mutation deleteHostedFormToken($tokenId: HostedFormTokenId!) {
  deleteHostedFormToken(tokenId: $tokenId)
}
    `;
export const useDeleteHostedFormTokenMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteHostedFormTokenMutation,
        TError,
        DeleteHostedFormTokenMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteHostedFormTokenMutation,
        TError,
        DeleteHostedFormTokenMutationVariables,
        TContext
    >(
        ["deleteHostedFormToken"],
        (variables?: DeleteHostedFormTokenMutationVariables) =>
            graphqlFetcher<
                DeleteHostedFormTokenMutation,
                DeleteHostedFormTokenMutationVariables
            >(DeleteHostedFormTokenDocument, variables)(),
        options,
    );
export const RelocateImageDocument = `
    mutation relocateImage($payload: RelocateImageInput!) {
  relocateImage(payload: $payload) {
    id
    src
  }
}
    `;
export const useRelocateImageMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        RelocateImageMutation,
        TError,
        RelocateImageMutationVariables,
        TContext
    >,
) =>
    useMutation<RelocateImageMutation, TError, RelocateImageMutationVariables, TContext>(
        ["relocateImage"],
        (variables?: RelocateImageMutationVariables) =>
            graphqlFetcher<RelocateImageMutation, RelocateImageMutationVariables>(
                RelocateImageDocument,
                variables,
            )(),
        options,
    );
export const CreateInvoiceDocument = `
    mutation createInvoice($clientId: ClientId!, $serial: String, $amountCents: CurrencyInCents!, $status: String!, $sentDate: String, $dueDate: String, $generatePdf: Boolean!, $notes: String, $message: OutboundMessage, $lineItems: [InvoiceLineItemInput!], $paymentSchedule: InvoicePaymentScheduleInput, $externalReference: String) {
  createInvoice(
    clientId: $clientId
    serial: $serial
    amountCents: $amountCents
    status: $status
    sentDate: $sentDate
    dueDate: $dueDate
    generatePdf: $generatePdf
    notes: $notes
    message: $message
    lineItems: $lineItems
    paymentSchedule: $paymentSchedule
    externalReference: $externalReference
  ) {
    invoices {
      id
      serial
    }
    errors {
      message
      fields {
        name
        message
      }
    }
  }
}
    `;
export const useCreateInvoiceMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateInvoiceMutation,
        TError,
        CreateInvoiceMutationVariables,
        TContext
    >,
) =>
    useMutation<CreateInvoiceMutation, TError, CreateInvoiceMutationVariables, TContext>(
        ["createInvoice"],
        (variables?: CreateInvoiceMutationVariables) =>
            graphqlFetcher<CreateInvoiceMutation, CreateInvoiceMutationVariables>(
                CreateInvoiceDocument,
                variables,
            )(),
        options,
    );
export const ResendInvoiceDocument = `
    mutation resendInvoice($invoiceId: InvoiceId!, $message: OutboundMessage!) {
  resendInvoice(invoiceId: $invoiceId, message: $message) {
    invoices {
      id
      serial
    }
    errors {
      message
      fields {
        name
        message
      }
    }
  }
}
    `;
export const useResendInvoiceMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        ResendInvoiceMutation,
        TError,
        ResendInvoiceMutationVariables,
        TContext
    >,
) =>
    useMutation<ResendInvoiceMutation, TError, ResendInvoiceMutationVariables, TContext>(
        ["resendInvoice"],
        (variables?: ResendInvoiceMutationVariables) =>
            graphqlFetcher<ResendInvoiceMutation, ResendInvoiceMutationVariables>(
                ResendInvoiceDocument,
                variables,
            )(),
        options,
    );
export const GenerateInvoiceDocument = `
    mutation generateInvoice($id: InvoiceId!) {
  generateInvoice(invoiceId: $id) {
    invoices {
      id
      serial
    }
    errors {
      message
      fields {
        name
        message
      }
    }
  }
}
    `;
export const useGenerateInvoiceMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        GenerateInvoiceMutation,
        TError,
        GenerateInvoiceMutationVariables,
        TContext
    >,
) =>
    useMutation<
        GenerateInvoiceMutation,
        TError,
        GenerateInvoiceMutationVariables,
        TContext
    >(
        ["generateInvoice"],
        (variables?: GenerateInvoiceMutationVariables) =>
            graphqlFetcher<GenerateInvoiceMutation, GenerateInvoiceMutationVariables>(
                GenerateInvoiceDocument,
                variables,
            )(),
        options,
    );
export const UpdateInvoiceDocument = `
    mutation updateInvoice($invoiceId: InvoiceId!, $status: String!, $sentDate: String, $paidDate: String, $serial: String!, $amountCents: CurrencyInCents!, $notes: String, $dueDate: String, $lineItems: [InvoiceLineItemInput!], $paymentSchedule: InvoicePaymentScheduleInput, $externalReference: String) {
  updateInvoice(
    invoiceId: $invoiceId
    status: $status
    sentDate: $sentDate
    paidDate: $paidDate
    serial: $serial
    amountCents: $amountCents
    notes: $notes
    dueDate: $dueDate
    lineItems: $lineItems
    paymentSchedule: $paymentSchedule
    externalReference: $externalReference
  ) {
    invoices {
      id
      clientId
      communityId
    }
    errors {
      message
      fields {
        name
        message
      }
    }
  }
}
    `;
export const useUpdateInvoiceMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpdateInvoiceMutation,
        TError,
        UpdateInvoiceMutationVariables,
        TContext
    >,
) =>
    useMutation<UpdateInvoiceMutation, TError, UpdateInvoiceMutationVariables, TContext>(
        ["updateInvoice"],
        (variables?: UpdateInvoiceMutationVariables) =>
            graphqlFetcher<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(
                UpdateInvoiceDocument,
                variables,
            )(),
        options,
    );
export const CreateExternalInvoicesDocument = `
    mutation createExternalInvoices($params: ExternalInvoicesCreateRequest!) {
  createExternalInvoices(params: $params) {
    invoices {
      id
      serial
    }
    errors {
      message
      fields {
        name
        message
      }
    }
  }
}
    `;
export const useCreateExternalInvoicesMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateExternalInvoicesMutation,
        TError,
        CreateExternalInvoicesMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateExternalInvoicesMutation,
        TError,
        CreateExternalInvoicesMutationVariables,
        TContext
    >(
        ["createExternalInvoices"],
        (variables?: CreateExternalInvoicesMutationVariables) =>
            graphqlFetcher<
                CreateExternalInvoicesMutation,
                CreateExternalInvoicesMutationVariables
            >(CreateExternalInvoicesDocument, variables)(),
        options,
    );
export const GetInvoiceCreateExperienceDocument = `
    query getInvoiceCreateExperience {
  getInvoiceCreateExperience {
    experience
  }
}
    `;
export const useGetInvoiceCreateExperienceQuery = <
    TData = GetInvoiceCreateExperienceQuery,
    TError = unknown,
>(
    variables?: GetInvoiceCreateExperienceQueryVariables,
    options?: UseQueryOptions<GetInvoiceCreateExperienceQuery, TError, TData>,
) =>
    useQuery<GetInvoiceCreateExperienceQuery, TError, TData>(
        variables === undefined
            ? ["getInvoiceCreateExperience"]
            : ["getInvoiceCreateExperience", variables],
        graphqlFetcher<
            GetInvoiceCreateExperienceQuery,
            GetInvoiceCreateExperienceQueryVariables
        >(GetInvoiceCreateExperienceDocument, variables),
        options,
    );
export const useInfiniteGetInvoiceCreateExperienceQuery = <
    TData = GetInvoiceCreateExperienceQuery,
    TError = unknown,
>(
    variables?: GetInvoiceCreateExperienceQueryVariables,
    options?: UseInfiniteQueryOptions<GetInvoiceCreateExperienceQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetInvoiceCreateExperienceQuery, TError, TData>(
        variables === undefined
            ? ["getInvoiceCreateExperience.infinite"]
            : ["getInvoiceCreateExperience.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetInvoiceCreateExperienceQuery,
                GetInvoiceCreateExperienceQueryVariables
            >(GetInvoiceCreateExperienceDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetExternalInvoiceAccountSettingsDocument = `
    query getExternalInvoiceAccountSettings {
  getExternalInvoiceAccountSettings {
    paymentTerms {
      id
      name
      dueDays
      default
    }
  }
}
    `;
export const useGetExternalInvoiceAccountSettingsQuery = <
    TData = GetExternalInvoiceAccountSettingsQuery,
    TError = unknown,
>(
    variables?: GetExternalInvoiceAccountSettingsQueryVariables,
    options?: UseQueryOptions<GetExternalInvoiceAccountSettingsQuery, TError, TData>,
) =>
    useQuery<GetExternalInvoiceAccountSettingsQuery, TError, TData>(
        variables === undefined
            ? ["getExternalInvoiceAccountSettings"]
            : ["getExternalInvoiceAccountSettings", variables],
        graphqlFetcher<
            GetExternalInvoiceAccountSettingsQuery,
            GetExternalInvoiceAccountSettingsQueryVariables
        >(GetExternalInvoiceAccountSettingsDocument, variables),
        options,
    );
export const useInfiniteGetExternalInvoiceAccountSettingsQuery = <
    TData = GetExternalInvoiceAccountSettingsQuery,
    TError = unknown,
>(
    variables?: GetExternalInvoiceAccountSettingsQueryVariables,
    options?: UseInfiniteQueryOptions<
        GetExternalInvoiceAccountSettingsQuery,
        TError,
        TData
    >,
) => {
    return useInfiniteQuery<GetExternalInvoiceAccountSettingsQuery, TError, TData>(
        variables === undefined
            ? ["getExternalInvoiceAccountSettings.infinite"]
            : ["getExternalInvoiceAccountSettings.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetExternalInvoiceAccountSettingsQuery,
                GetExternalInvoiceAccountSettingsQueryVariables
            >(GetExternalInvoiceAccountSettingsDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const SearchExternalInvoicesDocument = `
    query searchExternalInvoices($params: ExternalInvoiceSearchRequest!) {
  searchExternalInvoices(params: $params) {
    count
    hits {
      externalId
      serial
      customer
      invoiceDate
      dueDate
      amountInCents
    }
  }
}
    `;
export const useSearchExternalInvoicesQuery = <
    TData = SearchExternalInvoicesQuery,
    TError = unknown,
>(
    variables: SearchExternalInvoicesQueryVariables,
    options?: UseQueryOptions<SearchExternalInvoicesQuery, TError, TData>,
) =>
    useQuery<SearchExternalInvoicesQuery, TError, TData>(
        ["searchExternalInvoices", variables],
        graphqlFetcher<SearchExternalInvoicesQuery, SearchExternalInvoicesQueryVariables>(
            SearchExternalInvoicesDocument,
            variables,
        ),
        options,
    );
export const useInfiniteSearchExternalInvoicesQuery = <
    TData = SearchExternalInvoicesQuery,
    TError = unknown,
>(
    variables: SearchExternalInvoicesQueryVariables,
    options?: UseInfiniteQueryOptions<SearchExternalInvoicesQuery, TError, TData>,
) => {
    return useInfiniteQuery<SearchExternalInvoicesQuery, TError, TData>(
        ["searchExternalInvoices.infinite", variables],
        metaData =>
            graphqlFetcher<
                SearchExternalInvoicesQuery,
                SearchExternalInvoicesQueryVariables
            >(SearchExternalInvoicesDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const AddExistingExternalInvoiceDocument = `
    mutation addExistingExternalInvoice($params: AddExternalInvoiceRequest!) {
  addExistingExternalInvoice(params: $params) {
    id
  }
}
    `;
export const useAddExistingExternalInvoiceMutation = <
    TError = unknown,
    TContext = unknown,
>(
    options?: UseMutationOptions<
        AddExistingExternalInvoiceMutation,
        TError,
        AddExistingExternalInvoiceMutationVariables,
        TContext
    >,
) =>
    useMutation<
        AddExistingExternalInvoiceMutation,
        TError,
        AddExistingExternalInvoiceMutationVariables,
        TContext
    >(
        ["addExistingExternalInvoice"],
        (variables?: AddExistingExternalInvoiceMutationVariables) =>
            graphqlFetcher<
                AddExistingExternalInvoiceMutation,
                AddExistingExternalInvoiceMutationVariables
            >(AddExistingExternalInvoiceDocument, variables)(),
        options,
    );
export const SearchExternalInvoiceCustomersDocument = `
    query searchExternalInvoiceCustomers($params: ExternalInvoiceCustomerSearchRequest!) {
  searchExternalInvoiceCustomers(params: $params) {
    count
    hits {
      id
      name
    }
  }
}
    `;
export const useSearchExternalInvoiceCustomersQuery = <
    TData = SearchExternalInvoiceCustomersQuery,
    TError = unknown,
>(
    variables: SearchExternalInvoiceCustomersQueryVariables,
    options?: UseQueryOptions<SearchExternalInvoiceCustomersQuery, TError, TData>,
) =>
    useQuery<SearchExternalInvoiceCustomersQuery, TError, TData>(
        ["searchExternalInvoiceCustomers", variables],
        graphqlFetcher<
            SearchExternalInvoiceCustomersQuery,
            SearchExternalInvoiceCustomersQueryVariables
        >(SearchExternalInvoiceCustomersDocument, variables),
        options,
    );
export const useInfiniteSearchExternalInvoiceCustomersQuery = <
    TData = SearchExternalInvoiceCustomersQuery,
    TError = unknown,
>(
    variables: SearchExternalInvoiceCustomersQueryVariables,
    options?: UseInfiniteQueryOptions<SearchExternalInvoiceCustomersQuery, TError, TData>,
) => {
    return useInfiniteQuery<SearchExternalInvoiceCustomersQuery, TError, TData>(
        ["searchExternalInvoiceCustomers.infinite", variables],
        metaData =>
            graphqlFetcher<
                SearchExternalInvoiceCustomersQuery,
                SearchExternalInvoiceCustomersQueryVariables
            >(SearchExternalInvoiceCustomersDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetExternalInvoiceInitialParametersDocument = `
    query getExternalInvoiceInitialParameters($params: GetExternalInvoiceInitialParametersRequest!) {
  getExternalInvoiceInitialParameters(params: $params) {
    customer {
      id
      name
    }
    createCustomerName
  }
}
    `;
export const useGetExternalInvoiceInitialParametersQuery = <
    TData = GetExternalInvoiceInitialParametersQuery,
    TError = unknown,
>(
    variables: GetExternalInvoiceInitialParametersQueryVariables,
    options?: UseQueryOptions<GetExternalInvoiceInitialParametersQuery, TError, TData>,
) =>
    useQuery<GetExternalInvoiceInitialParametersQuery, TError, TData>(
        ["getExternalInvoiceInitialParameters", variables],
        graphqlFetcher<
            GetExternalInvoiceInitialParametersQuery,
            GetExternalInvoiceInitialParametersQueryVariables
        >(GetExternalInvoiceInitialParametersDocument, variables),
        options,
    );
export const useInfiniteGetExternalInvoiceInitialParametersQuery = <
    TData = GetExternalInvoiceInitialParametersQuery,
    TError = unknown,
>(
    variables: GetExternalInvoiceInitialParametersQueryVariables,
    options?: UseInfiniteQueryOptions<
        GetExternalInvoiceInitialParametersQuery,
        TError,
        TData
    >,
) => {
    return useInfiniteQuery<GetExternalInvoiceInitialParametersQuery, TError, TData>(
        ["getExternalInvoiceInitialParameters.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetExternalInvoiceInitialParametersQuery,
                GetExternalInvoiceInitialParametersQueryVariables
            >(GetExternalInvoiceInitialParametersDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const CreateExternalInvoiceCustomerDocument = `
    mutation createExternalInvoiceCustomer($params: CreateExternalInvoiceCustomerRequest!) {
  createExternalInvoiceCustomer(params: $params) {
    id
    name
  }
}
    `;
export const useCreateExternalInvoiceCustomerMutation = <
    TError = unknown,
    TContext = unknown,
>(
    options?: UseMutationOptions<
        CreateExternalInvoiceCustomerMutation,
        TError,
        CreateExternalInvoiceCustomerMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateExternalInvoiceCustomerMutation,
        TError,
        CreateExternalInvoiceCustomerMutationVariables,
        TContext
    >(
        ["createExternalInvoiceCustomer"],
        (variables?: CreateExternalInvoiceCustomerMutationVariables) =>
            graphqlFetcher<
                CreateExternalInvoiceCustomerMutation,
                CreateExternalInvoiceCustomerMutationVariables
            >(CreateExternalInvoiceCustomerDocument, variables)(),
        options,
    );
export const DeleteInvoiceDocument = `
    mutation deleteInvoice($invoiceId: InvoiceId!) {
  deleteInvoice(invoiceId: $invoiceId)
}
    `;
export const useDeleteInvoiceMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteInvoiceMutation,
        TError,
        DeleteInvoiceMutationVariables,
        TContext
    >,
) =>
    useMutation<DeleteInvoiceMutation, TError, DeleteInvoiceMutationVariables, TContext>(
        ["deleteInvoice"],
        (variables?: DeleteInvoiceMutationVariables) =>
            graphqlFetcher<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>(
                DeleteInvoiceDocument,
                variables,
            )(),
        options,
    );
export const GetLayoutSectionDocument = `
    query getLayoutSection($id: LayoutSectionId!) {
  getLayoutSection(id: $id) {
    ...LayoutSectionBasics
  }
}
    ${LayoutSectionBasicsFragmentDoc}`;
export const useGetLayoutSectionQuery = <TData = GetLayoutSectionQuery, TError = unknown>(
    variables: GetLayoutSectionQueryVariables,
    options?: UseQueryOptions<GetLayoutSectionQuery, TError, TData>,
) =>
    useQuery<GetLayoutSectionQuery, TError, TData>(
        ["getLayoutSection", variables],
        graphqlFetcher<GetLayoutSectionQuery, GetLayoutSectionQueryVariables>(
            GetLayoutSectionDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetLayoutSectionQuery = <
    TData = GetLayoutSectionQuery,
    TError = unknown,
>(
    variables: GetLayoutSectionQueryVariables,
    options?: UseInfiniteQueryOptions<GetLayoutSectionQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetLayoutSectionQuery, TError, TData>(
        ["getLayoutSection.infinite", variables],
        metaData =>
            graphqlFetcher<GetLayoutSectionQuery, GetLayoutSectionQueryVariables>(
                GetLayoutSectionDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const CreateLayoutSectionDocument = `
    mutation createLayoutSection($params: CreateLayoutSectionInput!) {
  createLayoutSection(params: $params) {
    ...LayoutSectionBasics
  }
}
    ${LayoutSectionBasicsFragmentDoc}`;
export const useCreateLayoutSectionMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateLayoutSectionMutation,
        TError,
        CreateLayoutSectionMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateLayoutSectionMutation,
        TError,
        CreateLayoutSectionMutationVariables,
        TContext
    >(
        ["createLayoutSection"],
        (variables?: CreateLayoutSectionMutationVariables) =>
            graphqlFetcher<
                CreateLayoutSectionMutation,
                CreateLayoutSectionMutationVariables
            >(CreateLayoutSectionDocument, variables)(),
        options,
    );
export const PatchLayoutSectionDocument = `
    mutation patchLayoutSection($params: PatchLayoutSectionInput!) {
  patchLayoutSection(params: $params) {
    ...LayoutSectionBasics
  }
}
    ${LayoutSectionBasicsFragmentDoc}`;
export const usePatchLayoutSectionMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        PatchLayoutSectionMutation,
        TError,
        PatchLayoutSectionMutationVariables,
        TContext
    >,
) =>
    useMutation<
        PatchLayoutSectionMutation,
        TError,
        PatchLayoutSectionMutationVariables,
        TContext
    >(
        ["patchLayoutSection"],
        (variables?: PatchLayoutSectionMutationVariables) =>
            graphqlFetcher<
                PatchLayoutSectionMutation,
                PatchLayoutSectionMutationVariables
            >(PatchLayoutSectionDocument, variables)(),
        options,
    );
export const PatchLayoutSectionsDocument = `
    mutation patchLayoutSections($params: PatchLayoutSectionsInput!) {
  patchLayoutSections(params: $params) {
    ...LayoutSectionBasics
  }
}
    ${LayoutSectionBasicsFragmentDoc}`;
export const usePatchLayoutSectionsMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        PatchLayoutSectionsMutation,
        TError,
        PatchLayoutSectionsMutationVariables,
        TContext
    >,
) =>
    useMutation<
        PatchLayoutSectionsMutation,
        TError,
        PatchLayoutSectionsMutationVariables,
        TContext
    >(
        ["patchLayoutSections"],
        (variables?: PatchLayoutSectionsMutationVariables) =>
            graphqlFetcher<
                PatchLayoutSectionsMutation,
                PatchLayoutSectionsMutationVariables
            >(PatchLayoutSectionsDocument, variables)(),
        options,
    );
export const PatchLayoutItemDocument = `
    mutation patchLayoutItem($params: PatchLayoutItemInput!) {
  patchLayoutItem(params: $params) {
    ...LayoutItemBasics
  }
}
    ${LayoutItemBasicsFragmentDoc}`;
export const usePatchLayoutItemMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        PatchLayoutItemMutation,
        TError,
        PatchLayoutItemMutationVariables,
        TContext
    >,
) =>
    useMutation<
        PatchLayoutItemMutation,
        TError,
        PatchLayoutItemMutationVariables,
        TContext
    >(
        ["patchLayoutItem"],
        (variables?: PatchLayoutItemMutationVariables) =>
            graphqlFetcher<PatchLayoutItemMutation, PatchLayoutItemMutationVariables>(
                PatchLayoutItemDocument,
                variables,
            )(),
        options,
    );
export const DeleteLayoutSectionDocument = `
    mutation deleteLayoutSection($id: LayoutSectionId!) {
  deleteLayoutSection(id: $id)
}
    `;
export const useDeleteLayoutSectionMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteLayoutSectionMutation,
        TError,
        DeleteLayoutSectionMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteLayoutSectionMutation,
        TError,
        DeleteLayoutSectionMutationVariables,
        TContext
    >(
        ["deleteLayoutSection"],
        (variables?: DeleteLayoutSectionMutationVariables) =>
            graphqlFetcher<
                DeleteLayoutSectionMutation,
                DeleteLayoutSectionMutationVariables
            >(DeleteLayoutSectionDocument, variables)(),
        options,
    );
export const GetLayoutSectionsDocument = `
    query getLayoutSections($parentKey: LayoutSectionParentKey!) {
  getLayoutSections(parentKey: $parentKey) {
    layoutSections {
      ...LayoutSectionBasics
    }
  }
}
    ${LayoutSectionBasicsFragmentDoc}`;
export const useGetLayoutSectionsQuery = <
    TData = GetLayoutSectionsQuery,
    TError = unknown,
>(
    variables: GetLayoutSectionsQueryVariables,
    options?: UseQueryOptions<GetLayoutSectionsQuery, TError, TData>,
) =>
    useQuery<GetLayoutSectionsQuery, TError, TData>(
        ["getLayoutSections", variables],
        graphqlFetcher<GetLayoutSectionsQuery, GetLayoutSectionsQueryVariables>(
            GetLayoutSectionsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetLayoutSectionsQuery = <
    TData = GetLayoutSectionsQuery,
    TError = unknown,
>(
    variables: GetLayoutSectionsQueryVariables,
    options?: UseInfiniteQueryOptions<GetLayoutSectionsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetLayoutSectionsQuery, TError, TData>(
        ["getLayoutSections.infinite", variables],
        metaData =>
            graphqlFetcher<GetLayoutSectionsQuery, GetLayoutSectionsQueryVariables>(
                GetLayoutSectionsDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetLayoutContainerByKeyDocument = `
    query getLayoutContainerByKey($key: String!) {
  getLayoutContainerByKey(key: $key) {
    id
    title
    key
    layoutSections {
      ...LayoutSectionBasics
    }
  }
}
    ${LayoutSectionBasicsFragmentDoc}`;
export const useGetLayoutContainerByKeyQuery = <
    TData = GetLayoutContainerByKeyQuery,
    TError = unknown,
>(
    variables: GetLayoutContainerByKeyQueryVariables,
    options?: UseQueryOptions<GetLayoutContainerByKeyQuery, TError, TData>,
) =>
    useQuery<GetLayoutContainerByKeyQuery, TError, TData>(
        ["getLayoutContainerByKey", variables],
        graphqlFetcher<
            GetLayoutContainerByKeyQuery,
            GetLayoutContainerByKeyQueryVariables
        >(GetLayoutContainerByKeyDocument, variables),
        options,
    );
export const useInfiniteGetLayoutContainerByKeyQuery = <
    TData = GetLayoutContainerByKeyQuery,
    TError = unknown,
>(
    variables: GetLayoutContainerByKeyQueryVariables,
    options?: UseInfiniteQueryOptions<GetLayoutContainerByKeyQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetLayoutContainerByKeyQuery, TError, TData>(
        ["getLayoutContainerByKey.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetLayoutContainerByKeyQuery,
                GetLayoutContainerByKeyQueryVariables
            >(GetLayoutContainerByKeyDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetClientIntakeFaxRecipientsDocument = `
    query getClientIntakeFaxRecipients($clientId: ClientId!) {
  getClientIntakeFaxRecipients(clientId: $clientId) {
    faxNumber
    communityName
    communityId
  }
}
    `;
export const useGetClientIntakeFaxRecipientsQuery = <
    TData = GetClientIntakeFaxRecipientsQuery,
    TError = unknown,
>(
    variables: GetClientIntakeFaxRecipientsQueryVariables,
    options?: UseQueryOptions<GetClientIntakeFaxRecipientsQuery, TError, TData>,
) =>
    useQuery<GetClientIntakeFaxRecipientsQuery, TError, TData>(
        ["getClientIntakeFaxRecipients", variables],
        graphqlFetcher<
            GetClientIntakeFaxRecipientsQuery,
            GetClientIntakeFaxRecipientsQueryVariables
        >(GetClientIntakeFaxRecipientsDocument, variables),
        options,
    );
export const useInfiniteGetClientIntakeFaxRecipientsQuery = <
    TData = GetClientIntakeFaxRecipientsQuery,
    TError = unknown,
>(
    variables: GetClientIntakeFaxRecipientsQueryVariables,
    options?: UseInfiniteQueryOptions<GetClientIntakeFaxRecipientsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetClientIntakeFaxRecipientsQuery, TError, TData>(
        ["getClientIntakeFaxRecipients.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetClientIntakeFaxRecipientsQuery,
                GetClientIntakeFaxRecipientsQueryVariables
            >(GetClientIntakeFaxRecipientsDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const SendClientIntakeFaxDocument = `
    mutation sendClientIntakeFax($message: OutboundClientIntakeFax!) {
  sendClientIntakeFax(message: $message)
}
    `;
export const useSendClientIntakeFaxMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        SendClientIntakeFaxMutation,
        TError,
        SendClientIntakeFaxMutationVariables,
        TContext
    >,
) =>
    useMutation<
        SendClientIntakeFaxMutation,
        TError,
        SendClientIntakeFaxMutationVariables,
        TContext
    >(
        ["sendClientIntakeFax"],
        (variables?: SendClientIntakeFaxMutationVariables) =>
            graphqlFetcher<
                SendClientIntakeFaxMutation,
                SendClientIntakeFaxMutationVariables
            >(SendClientIntakeFaxDocument, variables)(),
        options,
    );
export const ComposeMessageDocument = `
    query composeMessage($entityId: EmailEntityId!, $specialMessageExperience: String) {
  getTemplates {
    templates {
      isDefaultSignature
      template {
        id
        body
        subject
        description
        type
        migrationVersion
        attachments
      }
    }
  }
  composeMessage(
    id: $entityId
    specialMessageExperience: $specialMessageExperience
  ) {
    recipients
    specialCommunityComparisonDefaults {
      showSummary
      showRecords
      showCoverPage
      coverPageTemplateId
      liveLinkText
      attachCommunityPDFByDefault
      useLiveLinkByDefault
    }
    files {
      entityName
      files {
        id
        originalFilename
        mimeType
        byteLength
      }
    }
  }
}
    `;
export const useComposeMessageQuery = <TData = ComposeMessageQuery, TError = unknown>(
    variables: ComposeMessageQueryVariables,
    options?: UseQueryOptions<ComposeMessageQuery, TError, TData>,
) =>
    useQuery<ComposeMessageQuery, TError, TData>(
        ["composeMessage", variables],
        graphqlFetcher<ComposeMessageQuery, ComposeMessageQueryVariables>(
            ComposeMessageDocument,
            variables,
        ),
        options,
    );
export const useInfiniteComposeMessageQuery = <
    TData = ComposeMessageQuery,
    TError = unknown,
>(
    variables: ComposeMessageQueryVariables,
    options?: UseInfiniteQueryOptions<ComposeMessageQuery, TError, TData>,
) => {
    return useInfiniteQuery<ComposeMessageQuery, TError, TData>(
        ["composeMessage.infinite", variables],
        metaData =>
            graphqlFetcher<ComposeMessageQuery, ComposeMessageQueryVariables>(
                ComposeMessageDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const ComposeMessageBasicDocument = `
    query composeMessageBasic {
  getTemplates {
    templates {
      isDefaultSignature
      template {
        id
        body
        subject
        description
        type
        migrationVersion
        attachments
      }
    }
  }
}
    `;
export const useComposeMessageBasicQuery = <
    TData = ComposeMessageBasicQuery,
    TError = unknown,
>(
    variables?: ComposeMessageBasicQueryVariables,
    options?: UseQueryOptions<ComposeMessageBasicQuery, TError, TData>,
) =>
    useQuery<ComposeMessageBasicQuery, TError, TData>(
        variables === undefined
            ? ["composeMessageBasic"]
            : ["composeMessageBasic", variables],
        graphqlFetcher<ComposeMessageBasicQuery, ComposeMessageBasicQueryVariables>(
            ComposeMessageBasicDocument,
            variables,
        ),
        options,
    );
export const useInfiniteComposeMessageBasicQuery = <
    TData = ComposeMessageBasicQuery,
    TError = unknown,
>(
    variables?: ComposeMessageBasicQueryVariables,
    options?: UseInfiniteQueryOptions<ComposeMessageBasicQuery, TError, TData>,
) => {
    return useInfiniteQuery<ComposeMessageBasicQuery, TError, TData>(
        variables === undefined
            ? ["composeMessageBasic.infinite"]
            : ["composeMessageBasic.infinite", variables],
        metaData =>
            graphqlFetcher<ComposeMessageBasicQuery, ComposeMessageBasicQueryVariables>(
                ComposeMessageBasicDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetMessagesForEntityDocument = `
    query getMessagesForEntity($id: EmailEntityId!) {
  getMessagesForEntity(entityId: $id) {
    id
    subject
    status
    latestEventStatus
    createdAt
    to
    messageType
    attachments
    clientIdUuid
    communityIdUuid
    clickedLinkEvents {
      link
      events
      redacted
      eventsV2 {
        timestamp
        recipient
      }
    }
    intakeCommunity {
      id
      name
      appLink
    }
    intakeClient {
      id
      name
      bestContactName
      appLink
    }
    events {
      eventType
      eventTimestamp
      recipient
    }
  }
}
    `;
export const useGetMessagesForEntityQuery = <
    TData = GetMessagesForEntityQuery,
    TError = unknown,
>(
    variables: GetMessagesForEntityQueryVariables,
    options?: UseQueryOptions<GetMessagesForEntityQuery, TError, TData>,
) =>
    useQuery<GetMessagesForEntityQuery, TError, TData>(
        ["getMessagesForEntity", variables],
        graphqlFetcher<GetMessagesForEntityQuery, GetMessagesForEntityQueryVariables>(
            GetMessagesForEntityDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetMessagesForEntityQuery = <
    TData = GetMessagesForEntityQuery,
    TError = unknown,
>(
    variables: GetMessagesForEntityQueryVariables,
    options?: UseInfiniteQueryOptions<GetMessagesForEntityQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetMessagesForEntityQuery, TError, TData>(
        ["getMessagesForEntity.infinite", variables],
        metaData =>
            graphqlFetcher<GetMessagesForEntityQuery, GetMessagesForEntityQueryVariables>(
                GetMessagesForEntityDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetMessageDocument = `
    query getMessage($id: SentMessageId!) {
  getMessage(sentMessageId: $id) {
    id
    subject
    bouncedRecipients
    status
    createdAt
    body
    to
    messageType
    attachments
    intakeCommunity {
      name
    }
    invoice {
      id
      serial
    }
    clickedLinkEvents {
      link
      events
      redacted
      eventsV2 {
        timestamp
        recipient
      }
    }
    events {
      eventType
      eventTimestamp
      recipient
    }
  }
}
    `;
export const useGetMessageQuery = <TData = GetMessageQuery, TError = unknown>(
    variables: GetMessageQueryVariables,
    options?: UseQueryOptions<GetMessageQuery, TError, TData>,
) =>
    useQuery<GetMessageQuery, TError, TData>(
        ["getMessage", variables],
        graphqlFetcher<GetMessageQuery, GetMessageQueryVariables>(
            GetMessageDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetMessageQuery = <TData = GetMessageQuery, TError = unknown>(
    variables: GetMessageQueryVariables,
    options?: UseInfiniteQueryOptions<GetMessageQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetMessageQuery, TError, TData>(
        ["getMessage.infinite", variables],
        metaData =>
            graphqlFetcher<GetMessageQuery, GetMessageQueryVariables>(
                GetMessageDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const SendMessageDocument = `
    mutation sendMessage($message: OutboundMessage!) {
  sendMessage(message: $message) {
    status
    sentMessage {
      id
    }
    accepted
    rejected
    code
  }
}
    `;
export const useSendMessageMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        SendMessageMutation,
        TError,
        SendMessageMutationVariables,
        TContext
    >,
) =>
    useMutation<SendMessageMutation, TError, SendMessageMutationVariables, TContext>(
        ["sendMessage"],
        (variables?: SendMessageMutationVariables) =>
            graphqlFetcher<SendMessageMutation, SendMessageMutationVariables>(
                SendMessageDocument,
                variables,
            )(),
        options,
    );
export const ValidateTemplateDocument = `
    query validateTemplate($emailTemplateId: EmailTemplateId!, $messageType: String!) {
  validateTemplate(emailTemplateId: $emailTemplateId, messageType: $messageType) {
    isValid
    message
    missingTokens {
      tokenKey
      description
      humanFriendlyName
    }
  }
}
    `;
export const useValidateTemplateQuery = <TData = ValidateTemplateQuery, TError = unknown>(
    variables: ValidateTemplateQueryVariables,
    options?: UseQueryOptions<ValidateTemplateQuery, TError, TData>,
) =>
    useQuery<ValidateTemplateQuery, TError, TData>(
        ["validateTemplate", variables],
        graphqlFetcher<ValidateTemplateQuery, ValidateTemplateQueryVariables>(
            ValidateTemplateDocument,
            variables,
        ),
        options,
    );
export const useInfiniteValidateTemplateQuery = <
    TData = ValidateTemplateQuery,
    TError = unknown,
>(
    variables: ValidateTemplateQueryVariables,
    options?: UseInfiniteQueryOptions<ValidateTemplateQuery, TError, TData>,
) => {
    return useInfiniteQuery<ValidateTemplateQuery, TError, TData>(
        ["validateTemplate.infinite", variables],
        metaData =>
            graphqlFetcher<ValidateTemplateQuery, ValidateTemplateQueryVariables>(
                ValidateTemplateDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetMessagingConfigurationDocument = `
    query getMessagingConfiguration {
  getMessagingConfiguration {
    user {
      directSmtp
    }
    tenant {
      customDomains
      trackingLevel
    }
  }
}
    `;
export const useGetMessagingConfigurationQuery = <
    TData = GetMessagingConfigurationQuery,
    TError = unknown,
>(
    variables?: GetMessagingConfigurationQueryVariables,
    options?: UseQueryOptions<GetMessagingConfigurationQuery, TError, TData>,
) =>
    useQuery<GetMessagingConfigurationQuery, TError, TData>(
        variables === undefined
            ? ["getMessagingConfiguration"]
            : ["getMessagingConfiguration", variables],
        graphqlFetcher<
            GetMessagingConfigurationQuery,
            GetMessagingConfigurationQueryVariables
        >(GetMessagingConfigurationDocument, variables),
        options,
    );
export const useInfiniteGetMessagingConfigurationQuery = <
    TData = GetMessagingConfigurationQuery,
    TError = unknown,
>(
    variables?: GetMessagingConfigurationQueryVariables,
    options?: UseInfiniteQueryOptions<GetMessagingConfigurationQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetMessagingConfigurationQuery, TError, TData>(
        variables === undefined
            ? ["getMessagingConfiguration.infinite"]
            : ["getMessagingConfiguration.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetMessagingConfigurationQuery,
                GetMessagingConfigurationQueryVariables
            >(GetMessagingConfigurationDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const CreateNoteDocument = `
    mutation createNote($entityType: String!, $entityId: NoteEntityId!, $params: CreateNoteInput!) {
  createNote(entityType: $entityType, entityId: $entityId, params: $params) {
    ...NoteResponse
  }
}
    ${NoteResponseFragmentDoc}`;
export const useCreateNoteMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateNoteMutation,
        TError,
        CreateNoteMutationVariables,
        TContext
    >,
) =>
    useMutation<CreateNoteMutation, TError, CreateNoteMutationVariables, TContext>(
        ["createNote"],
        (variables?: CreateNoteMutationVariables) =>
            graphqlFetcher<CreateNoteMutation, CreateNoteMutationVariables>(
                CreateNoteDocument,
                variables,
            )(),
        options,
    );
export const UpdateNoteDocument = `
    mutation updateNote($entityId: NoteEntityId!, $noteId: NoteId!, $params: UpdateNoteInput!) {
  updateNote(entityId: $entityId, noteId: $noteId, params: $params) {
    ...NoteResponse
  }
}
    ${NoteResponseFragmentDoc}`;
export const useUpdateNoteMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpdateNoteMutation,
        TError,
        UpdateNoteMutationVariables,
        TContext
    >,
) =>
    useMutation<UpdateNoteMutation, TError, UpdateNoteMutationVariables, TContext>(
        ["updateNote"],
        (variables?: UpdateNoteMutationVariables) =>
            graphqlFetcher<UpdateNoteMutation, UpdateNoteMutationVariables>(
                UpdateNoteDocument,
                variables,
            )(),
        options,
    );
export const DeleteNoteDocument = `
    mutation deleteNote($entityType: String!, $entityId: NoteEntityId!, $noteId: NoteId!) {
  deleteNote(entityType: $entityType, entityId: $entityId, noteId: $noteId) {
    _id
  }
}
    `;
export const useDeleteNoteMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteNoteMutation,
        TError,
        DeleteNoteMutationVariables,
        TContext
    >,
) =>
    useMutation<DeleteNoteMutation, TError, DeleteNoteMutationVariables, TContext>(
        ["deleteNote"],
        (variables?: DeleteNoteMutationVariables) =>
            graphqlFetcher<DeleteNoteMutation, DeleteNoteMutationVariables>(
                DeleteNoteDocument,
                variables,
            )(),
        options,
    );
export const GetNotesForEntityDocument = `
    query getNotesForEntity($entityId: NoteEntityId!, $filter: GetNotesForEntityFilter) {
  getNotesForEntity(entityId: $entityId, filter: $filter) {
    ...NoteResponse
  }
}
    ${NoteResponseFragmentDoc}`;
export const useGetNotesForEntityQuery = <
    TData = GetNotesForEntityQuery,
    TError = unknown,
>(
    variables: GetNotesForEntityQueryVariables,
    options?: UseQueryOptions<GetNotesForEntityQuery, TError, TData>,
) =>
    useQuery<GetNotesForEntityQuery, TError, TData>(
        ["getNotesForEntity", variables],
        graphqlFetcher<GetNotesForEntityQuery, GetNotesForEntityQueryVariables>(
            GetNotesForEntityDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetNotesForEntityQuery = <
    TData = GetNotesForEntityQuery,
    TError = unknown,
>(
    variables: GetNotesForEntityQueryVariables,
    options?: UseInfiniteQueryOptions<GetNotesForEntityQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetNotesForEntityQuery, TError, TData>(
        ["getNotesForEntity.infinite", variables],
        metaData =>
            graphqlFetcher<GetNotesForEntityQuery, GetNotesForEntityQueryVariables>(
                GetNotesForEntityDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetPhotosForEntityDocument = `
    query getPhotosForEntity($entityId: FileEntityId!) {
  getPhotosForEntity(entityId: $entityId) {
    ...PhotoBasics
  }
}
    ${PhotoBasicsFragmentDoc}`;
export const useGetPhotosForEntityQuery = <
    TData = GetPhotosForEntityQuery,
    TError = unknown,
>(
    variables: GetPhotosForEntityQueryVariables,
    options?: UseQueryOptions<GetPhotosForEntityQuery, TError, TData>,
) =>
    useQuery<GetPhotosForEntityQuery, TError, TData>(
        ["getPhotosForEntity", variables],
        graphqlFetcher<GetPhotosForEntityQuery, GetPhotosForEntityQueryVariables>(
            GetPhotosForEntityDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetPhotosForEntityQuery = <
    TData = GetPhotosForEntityQuery,
    TError = unknown,
>(
    variables: GetPhotosForEntityQueryVariables,
    options?: UseInfiniteQueryOptions<GetPhotosForEntityQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetPhotosForEntityQuery, TError, TData>(
        ["getPhotosForEntity.infinite", variables],
        metaData =>
            graphqlFetcher<GetPhotosForEntityQuery, GetPhotosForEntityQueryVariables>(
                GetPhotosForEntityDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const PatchPhotoDocument = `
    mutation patchPhoto($params: PatchPhotoInput!) {
  patchPhoto(params: $params) {
    ...PhotoBasics
  }
}
    ${PhotoBasicsFragmentDoc}`;
export const usePatchPhotoMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        PatchPhotoMutation,
        TError,
        PatchPhotoMutationVariables,
        TContext
    >,
) =>
    useMutation<PatchPhotoMutation, TError, PatchPhotoMutationVariables, TContext>(
        ["patchPhoto"],
        (variables?: PatchPhotoMutationVariables) =>
            graphqlFetcher<PatchPhotoMutation, PatchPhotoMutationVariables>(
                PatchPhotoDocument,
                variables,
            )(),
        options,
    );
export const DeletePhotoDocument = `
    mutation deletePhoto($params: DeletePhotoInput!) {
  deletePhoto(params: $params)
}
    `;
export const useDeletePhotoMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeletePhotoMutation,
        TError,
        DeletePhotoMutationVariables,
        TContext
    >,
) =>
    useMutation<DeletePhotoMutation, TError, DeletePhotoMutationVariables, TContext>(
        ["deletePhoto"],
        (variables?: DeletePhotoMutationVariables) =>
            graphqlFetcher<DeletePhotoMutation, DeletePhotoMutationVariables>(
                DeletePhotoDocument,
                variables,
            )(),
        options,
    );
export const PatchPhotosDocument = `
    mutation patchPhotos($params: [PatchPhotoInput!]!) {
  patchPhotos(params: $params) {
    ...PhotoBasics
  }
}
    ${PhotoBasicsFragmentDoc}`;
export const usePatchPhotosMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        PatchPhotosMutation,
        TError,
        PatchPhotosMutationVariables,
        TContext
    >,
) =>
    useMutation<PatchPhotosMutation, TError, PatchPhotosMutationVariables, TContext>(
        ["patchPhotos"],
        (variables?: PatchPhotosMutationVariables) =>
            graphqlFetcher<PatchPhotosMutation, PatchPhotosMutationVariables>(
                PatchPhotosDocument,
                variables,
            )(),
        options,
    );
export const BulkCreateLegacyQuestionsDocument = `
    mutation bulkCreateLegacyQuestions($payload: QuestionBulkCreatePayload!) {
  bulkCreateLegacyQuestions(bulkPayload: $payload) {
    id
  }
}
    `;
export const useBulkCreateLegacyQuestionsMutation = <
    TError = unknown,
    TContext = unknown,
>(
    options?: UseMutationOptions<
        BulkCreateLegacyQuestionsMutation,
        TError,
        BulkCreateLegacyQuestionsMutationVariables,
        TContext
    >,
) =>
    useMutation<
        BulkCreateLegacyQuestionsMutation,
        TError,
        BulkCreateLegacyQuestionsMutationVariables,
        TContext
    >(
        ["bulkCreateLegacyQuestions"],
        (variables?: BulkCreateLegacyQuestionsMutationVariables) =>
            graphqlFetcher<
                BulkCreateLegacyQuestionsMutation,
                BulkCreateLegacyQuestionsMutationVariables
            >(BulkCreateLegacyQuestionsDocument, variables)(),
        options,
    );
export const GetLegacyQuestionsForTenantDocument = `
    query getLegacyQuestionsForTenant($tenantId: TenantId!) {
  getLegacyQuestionsForTenant(tenantId: $tenantId) {
    id
    key
    order
    category
    clientTitle
    communityTitle
    questionType
    clientInteraction
    clientPdfBehavior
    clientSearchBehavior
    clientAnnotation
    communityInteraction
    communityPdfBehavior
    communitySearchBehavior
    communityAnnotation
    clientRulesString
    communityRulesString
    prompts {
      id
      client
      community
      key
      order
    }
  }
}
    `;
export const useGetLegacyQuestionsForTenantQuery = <
    TData = GetLegacyQuestionsForTenantQuery,
    TError = unknown,
>(
    variables: GetLegacyQuestionsForTenantQueryVariables,
    options?: UseQueryOptions<GetLegacyQuestionsForTenantQuery, TError, TData>,
) =>
    useQuery<GetLegacyQuestionsForTenantQuery, TError, TData>(
        ["getLegacyQuestionsForTenant", variables],
        graphqlFetcher<
            GetLegacyQuestionsForTenantQuery,
            GetLegacyQuestionsForTenantQueryVariables
        >(GetLegacyQuestionsForTenantDocument, variables),
        options,
    );
export const useInfiniteGetLegacyQuestionsForTenantQuery = <
    TData = GetLegacyQuestionsForTenantQuery,
    TError = unknown,
>(
    variables: GetLegacyQuestionsForTenantQueryVariables,
    options?: UseInfiniteQueryOptions<GetLegacyQuestionsForTenantQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetLegacyQuestionsForTenantQuery, TError, TData>(
        ["getLegacyQuestionsForTenant.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetLegacyQuestionsForTenantQuery,
                GetLegacyQuestionsForTenantQueryVariables
            >(GetLegacyQuestionsForTenantDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const ReorderLegacyQuestionsDocument = `
    mutation reorderLegacyQuestions($questionReorderPayload: QuestionReorderPayload!) {
  reorderLegacyQuestions(questionReorderPayload: $questionReorderPayload)
}
    `;
export const useReorderLegacyQuestionsMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        ReorderLegacyQuestionsMutation,
        TError,
        ReorderLegacyQuestionsMutationVariables,
        TContext
    >,
) =>
    useMutation<
        ReorderLegacyQuestionsMutation,
        TError,
        ReorderLegacyQuestionsMutationVariables,
        TContext
    >(
        ["reorderLegacyQuestions"],
        (variables?: ReorderLegacyQuestionsMutationVariables) =>
            graphqlFetcher<
                ReorderLegacyQuestionsMutation,
                ReorderLegacyQuestionsMutationVariables
            >(ReorderLegacyQuestionsDocument, variables)(),
        options,
    );
export const GetReferenceContactDocument = `
    query getReferenceContact($id: ReferenceContactId!) {
  getReferenceContact(referenceContactId: $id) {
    ...ReferenceContactBasics
  }
}
    ${ReferenceContactBasicsFragmentDoc}`;
export const useGetReferenceContactQuery = <
    TData = GetReferenceContactQuery,
    TError = unknown,
>(
    variables: GetReferenceContactQueryVariables,
    options?: UseQueryOptions<GetReferenceContactQuery, TError, TData>,
) =>
    useQuery<GetReferenceContactQuery, TError, TData>(
        ["getReferenceContact", variables],
        graphqlFetcher<GetReferenceContactQuery, GetReferenceContactQueryVariables>(
            GetReferenceContactDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetReferenceContactQuery = <
    TData = GetReferenceContactQuery,
    TError = unknown,
>(
    variables: GetReferenceContactQueryVariables,
    options?: UseInfiniteQueryOptions<GetReferenceContactQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetReferenceContactQuery, TError, TData>(
        ["getReferenceContact.infinite", variables],
        metaData =>
            graphqlFetcher<GetReferenceContactQuery, GetReferenceContactQueryVariables>(
                GetReferenceContactDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetReferenceBusinessDocument = `
    query getReferenceBusiness($id: ReferenceBusinessId!) {
  getReferenceBusiness(referenceBusinessId: $id) {
    ...ReferenceBusinessBasics
  }
}
    ${ReferenceBusinessBasicsFragmentDoc}`;
export const useGetReferenceBusinessQuery = <
    TData = GetReferenceBusinessQuery,
    TError = unknown,
>(
    variables: GetReferenceBusinessQueryVariables,
    options?: UseQueryOptions<GetReferenceBusinessQuery, TError, TData>,
) =>
    useQuery<GetReferenceBusinessQuery, TError, TData>(
        ["getReferenceBusiness", variables],
        graphqlFetcher<GetReferenceBusinessQuery, GetReferenceBusinessQueryVariables>(
            GetReferenceBusinessDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetReferenceBusinessQuery = <
    TData = GetReferenceBusinessQuery,
    TError = unknown,
>(
    variables: GetReferenceBusinessQueryVariables,
    options?: UseInfiniteQueryOptions<GetReferenceBusinessQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetReferenceBusinessQuery, TError, TData>(
        ["getReferenceBusiness.infinite", variables],
        metaData =>
            graphqlFetcher<GetReferenceBusinessQuery, GetReferenceBusinessQueryVariables>(
                GetReferenceBusinessDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const ReferenceContactSearchDocument = `
    query referenceContactSearch($search: ReferenceContactSearchRequest!) {
  referenceContactSearch(search: $search) {
    total
    coordinates {
      lat
      lng
    }
    hits {
      id
      name
      role
      updatedAt
      organizationName
      organizationType
      organizationPhone
      organizationFax
      address
      city
      state
      zip
      email
      cellPhone
      phone
      numberOfReferrals
      mostRecentReferral
      assignedTo
      businessId
      communityId
      summary
      latitude
      longitude
    }
  }
}
    `;
export const useReferenceContactSearchQuery = <
    TData = ReferenceContactSearchQuery,
    TError = unknown,
>(
    variables: ReferenceContactSearchQueryVariables,
    options?: UseQueryOptions<ReferenceContactSearchQuery, TError, TData>,
) =>
    useQuery<ReferenceContactSearchQuery, TError, TData>(
        ["referenceContactSearch", variables],
        graphqlFetcher<ReferenceContactSearchQuery, ReferenceContactSearchQueryVariables>(
            ReferenceContactSearchDocument,
            variables,
        ),
        options,
    );
export const useInfiniteReferenceContactSearchQuery = <
    TData = ReferenceContactSearchQuery,
    TError = unknown,
>(
    variables: ReferenceContactSearchQueryVariables,
    options?: UseInfiniteQueryOptions<ReferenceContactSearchQuery, TError, TData>,
) => {
    return useInfiniteQuery<ReferenceContactSearchQuery, TError, TData>(
        ["referenceContactSearch.infinite", variables],
        metaData =>
            graphqlFetcher<
                ReferenceContactSearchQuery,
                ReferenceContactSearchQueryVariables
            >(ReferenceContactSearchDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const ReferenceContactMapSearchDocument = `
    query referenceContactMapSearch($searchParams: ReferenceContactSearchRequest!, $mapView: CommunitySearchRequestMapComponent!) {
  referenceContactMapSearch(searchParams: $searchParams, mapView: $mapView) {
    referenceContactCoordinates {
      businessId
      communityId
      name
      lat
      lng
      appLink
      contacts {
        id
        name
        appLink
      }
    }
    referenceContactClusters {
      lat
      lng
      count
    }
    recommendedZoom
    coordinates {
      lat
      lng
    }
  }
}
    `;
export const useReferenceContactMapSearchQuery = <
    TData = ReferenceContactMapSearchQuery,
    TError = unknown,
>(
    variables: ReferenceContactMapSearchQueryVariables,
    options?: UseQueryOptions<ReferenceContactMapSearchQuery, TError, TData>,
) =>
    useQuery<ReferenceContactMapSearchQuery, TError, TData>(
        ["referenceContactMapSearch", variables],
        graphqlFetcher<
            ReferenceContactMapSearchQuery,
            ReferenceContactMapSearchQueryVariables
        >(ReferenceContactMapSearchDocument, variables),
        options,
    );
export const useInfiniteReferenceContactMapSearchQuery = <
    TData = ReferenceContactMapSearchQuery,
    TError = unknown,
>(
    variables: ReferenceContactMapSearchQueryVariables,
    options?: UseInfiniteQueryOptions<ReferenceContactMapSearchQuery, TError, TData>,
) => {
    return useInfiniteQuery<ReferenceContactMapSearchQuery, TError, TData>(
        ["referenceContactMapSearch.infinite", variables],
        metaData =>
            graphqlFetcher<
                ReferenceContactMapSearchQuery,
                ReferenceContactMapSearchQueryVariables
            >(ReferenceContactMapSearchDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const ReferenceBusinessSearchDocument = `
    query referenceBusinessSearch($search: ReferenceBusinessSearchRequest!) {
  referenceBusinessSearch(search: $search) {
    total
    coordinates {
      lat
      lng
    }
    hits {
      id
      name
      updatedAt
      summary
      organizationType
      city
      state
      zip
      address
      phone
      fax
      numberOfReferrals
      mostRecentReferral
      assignedTo
      latitude
      longitude
    }
  }
}
    `;
export const useReferenceBusinessSearchQuery = <
    TData = ReferenceBusinessSearchQuery,
    TError = unknown,
>(
    variables: ReferenceBusinessSearchQueryVariables,
    options?: UseQueryOptions<ReferenceBusinessSearchQuery, TError, TData>,
) =>
    useQuery<ReferenceBusinessSearchQuery, TError, TData>(
        ["referenceBusinessSearch", variables],
        graphqlFetcher<
            ReferenceBusinessSearchQuery,
            ReferenceBusinessSearchQueryVariables
        >(ReferenceBusinessSearchDocument, variables),
        options,
    );
export const useInfiniteReferenceBusinessSearchQuery = <
    TData = ReferenceBusinessSearchQuery,
    TError = unknown,
>(
    variables: ReferenceBusinessSearchQueryVariables,
    options?: UseInfiniteQueryOptions<ReferenceBusinessSearchQuery, TError, TData>,
) => {
    return useInfiniteQuery<ReferenceBusinessSearchQuery, TError, TData>(
        ["referenceBusinessSearch.infinite", variables],
        metaData =>
            graphqlFetcher<
                ReferenceBusinessSearchQuery,
                ReferenceBusinessSearchQueryVariables
            >(ReferenceBusinessSearchDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const ReferenceBusinessMapSearchDocument = `
    query referenceBusinessMapSearch($searchParams: ReferenceBusinessSearchRequest!, $mapView: CommunitySearchRequestMapComponent!) {
  referenceBusinessMapSearch(searchParams: $searchParams, mapView: $mapView) {
    referenceBusinessCoordinates {
      id
      name
      lat
      lng
      appLink
    }
    referenceBusinessClusters {
      lat
      lng
      count
    }
    recommendedZoom
    coordinates {
      lat
      lng
    }
  }
}
    `;
export const useReferenceBusinessMapSearchQuery = <
    TData = ReferenceBusinessMapSearchQuery,
    TError = unknown,
>(
    variables: ReferenceBusinessMapSearchQueryVariables,
    options?: UseQueryOptions<ReferenceBusinessMapSearchQuery, TError, TData>,
) =>
    useQuery<ReferenceBusinessMapSearchQuery, TError, TData>(
        ["referenceBusinessMapSearch", variables],
        graphqlFetcher<
            ReferenceBusinessMapSearchQuery,
            ReferenceBusinessMapSearchQueryVariables
        >(ReferenceBusinessMapSearchDocument, variables),
        options,
    );
export const useInfiniteReferenceBusinessMapSearchQuery = <
    TData = ReferenceBusinessMapSearchQuery,
    TError = unknown,
>(
    variables: ReferenceBusinessMapSearchQueryVariables,
    options?: UseInfiniteQueryOptions<ReferenceBusinessMapSearchQuery, TError, TData>,
) => {
    return useInfiniteQuery<ReferenceBusinessMapSearchQuery, TError, TData>(
        ["referenceBusinessMapSearch.infinite", variables],
        metaData =>
            graphqlFetcher<
                ReferenceBusinessMapSearchQuery,
                ReferenceBusinessMapSearchQueryVariables
            >(ReferenceBusinessMapSearchDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const ReferenceCommunitySearchDocument = `
    query referenceCommunitySearch($search: ReferenceCommunitySearchRequest!) {
  referenceCommunitySearch(search: $search) {
    total
    hits {
      id
      name
      updatedAt
      city
      state
      zip
      address
      phone
      fax
      summary
      numberOfReferrals
      mostRecentReferral
      latitude
      longitude
    }
  }
}
    `;
export const useReferenceCommunitySearchQuery = <
    TData = ReferenceCommunitySearchQuery,
    TError = unknown,
>(
    variables: ReferenceCommunitySearchQueryVariables,
    options?: UseQueryOptions<ReferenceCommunitySearchQuery, TError, TData>,
) =>
    useQuery<ReferenceCommunitySearchQuery, TError, TData>(
        ["referenceCommunitySearch", variables],
        graphqlFetcher<
            ReferenceCommunitySearchQuery,
            ReferenceCommunitySearchQueryVariables
        >(ReferenceCommunitySearchDocument, variables),
        options,
    );
export const useInfiniteReferenceCommunitySearchQuery = <
    TData = ReferenceCommunitySearchQuery,
    TError = unknown,
>(
    variables: ReferenceCommunitySearchQueryVariables,
    options?: UseInfiniteQueryOptions<ReferenceCommunitySearchQuery, TError, TData>,
) => {
    return useInfiniteQuery<ReferenceCommunitySearchQuery, TError, TData>(
        ["referenceCommunitySearch.infinite", variables],
        metaData =>
            graphqlFetcher<
                ReferenceCommunitySearchQuery,
                ReferenceCommunitySearchQueryVariables
            >(ReferenceCommunitySearchDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const ReferenceCommunityMapSearchDocument = `
    query referenceCommunityMapSearch($searchParams: ReferenceCommunitySearchRequest!, $mapView: CommunitySearchRequestMapComponent!) {
  referenceCommunityMapSearch(searchParams: $searchParams, mapView: $mapView) {
    communityCoordinates {
      id
      name
      lat
      lng
      appLink
    }
    communityClusters {
      lat
      lng
      count
    }
    recommendedZoom
    coordinates {
      lat
      lng
    }
  }
}
    `;
export const useReferenceCommunityMapSearchQuery = <
    TData = ReferenceCommunityMapSearchQuery,
    TError = unknown,
>(
    variables: ReferenceCommunityMapSearchQueryVariables,
    options?: UseQueryOptions<ReferenceCommunityMapSearchQuery, TError, TData>,
) =>
    useQuery<ReferenceCommunityMapSearchQuery, TError, TData>(
        ["referenceCommunityMapSearch", variables],
        graphqlFetcher<
            ReferenceCommunityMapSearchQuery,
            ReferenceCommunityMapSearchQueryVariables
        >(ReferenceCommunityMapSearchDocument, variables),
        options,
    );
export const useInfiniteReferenceCommunityMapSearchQuery = <
    TData = ReferenceCommunityMapSearchQuery,
    TError = unknown,
>(
    variables: ReferenceCommunityMapSearchQueryVariables,
    options?: UseInfiniteQueryOptions<ReferenceCommunityMapSearchQuery, TError, TData>,
) => {
    return useInfiniteQuery<ReferenceCommunityMapSearchQuery, TError, TData>(
        ["referenceCommunityMapSearch.infinite", variables],
        metaData =>
            graphqlFetcher<
                ReferenceCommunityMapSearchQuery,
                ReferenceCommunityMapSearchQueryVariables
            >(ReferenceCommunityMapSearchDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetReferralsToContactDocument = `
    query getReferralsToContact($referenceContactId: ReferenceContactId!) {
  getReferralsToContact(referenceContactId: $referenceContactId) {
    ...ReferralBasics
  }
}
    ${ReferralBasicsFragmentDoc}`;
export const useGetReferralsToContactQuery = <
    TData = GetReferralsToContactQuery,
    TError = unknown,
>(
    variables: GetReferralsToContactQueryVariables,
    options?: UseQueryOptions<GetReferralsToContactQuery, TError, TData>,
) =>
    useQuery<GetReferralsToContactQuery, TError, TData>(
        ["getReferralsToContact", variables],
        graphqlFetcher<GetReferralsToContactQuery, GetReferralsToContactQueryVariables>(
            GetReferralsToContactDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetReferralsToContactQuery = <
    TData = GetReferralsToContactQuery,
    TError = unknown,
>(
    variables: GetReferralsToContactQueryVariables,
    options?: UseInfiniteQueryOptions<GetReferralsToContactQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetReferralsToContactQuery, TError, TData>(
        ["getReferralsToContact.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetReferralsToContactQuery,
                GetReferralsToContactQueryVariables
            >(GetReferralsToContactDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const CreateReferenceContactDocument = `
    mutation createReferenceContact($regionId: RegionId!) {
  createReferenceContact(regionId: $regionId) {
    id
  }
}
    `;
export const useCreateReferenceContactMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateReferenceContactMutation,
        TError,
        CreateReferenceContactMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateReferenceContactMutation,
        TError,
        CreateReferenceContactMutationVariables,
        TContext
    >(
        ["createReferenceContact"],
        (variables?: CreateReferenceContactMutationVariables) =>
            graphqlFetcher<
                CreateReferenceContactMutation,
                CreateReferenceContactMutationVariables
            >(CreateReferenceContactDocument, variables)(),
        options,
    );
export const PatchReferenceContactDocument = `
    mutation patchReferenceContact($id: ReferenceContactId!, $summary: String, $ownerId: UserId, $businessId: ReferenceBusinessId, $communityId: CommunityId, $contactEntity: ContactInput, $deleted: Boolean, $draftNote: String) {
  patchReferenceContact(
    id: $id
    summary: $summary
    ownerId: $ownerId
    businessId: $businessId
    communityId: $communityId
    contactEntity: $contactEntity
    deleted: $deleted
    draftNote: $draftNote
  ) {
    ...ReferenceContactBasics
  }
}
    ${ReferenceContactBasicsFragmentDoc}`;
export const usePatchReferenceContactMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        PatchReferenceContactMutation,
        TError,
        PatchReferenceContactMutationVariables,
        TContext
    >,
) =>
    useMutation<
        PatchReferenceContactMutation,
        TError,
        PatchReferenceContactMutationVariables,
        TContext
    >(
        ["patchReferenceContact"],
        (variables?: PatchReferenceContactMutationVariables) =>
            graphqlFetcher<
                PatchReferenceContactMutation,
                PatchReferenceContactMutationVariables
            >(PatchReferenceContactDocument, variables)(),
        options,
    );
export const CreateReferenceBusinessDocument = `
    mutation createReferenceBusiness($regionId: RegionId!) {
  createReferenceBusiness(regionId: $regionId) {
    id
  }
}
    `;
export const useCreateReferenceBusinessMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateReferenceBusinessMutation,
        TError,
        CreateReferenceBusinessMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateReferenceBusinessMutation,
        TError,
        CreateReferenceBusinessMutationVariables,
        TContext
    >(
        ["createReferenceBusiness"],
        (variables?: CreateReferenceBusinessMutationVariables) =>
            graphqlFetcher<
                CreateReferenceBusinessMutation,
                CreateReferenceBusinessMutationVariables
            >(CreateReferenceBusinessDocument, variables)(),
        options,
    );
export const PatchReferenceBusinessDocument = `
    mutation patchReferenceBusiness($id: ReferenceBusinessId!, $summary: String, $ownerId: UserId, $businessContactEntity: ContactInput, $deleted: Boolean, $draftNote: String) {
  patchReferenceBusiness(
    id: $id
    summary: $summary
    ownerId: $ownerId
    businessContactEntity: $businessContactEntity
    deleted: $deleted
    draftNote: $draftNote
  ) {
    ...ReferenceBusinessBasics
  }
}
    ${ReferenceBusinessBasicsFragmentDoc}`;
export const usePatchReferenceBusinessMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        PatchReferenceBusinessMutation,
        TError,
        PatchReferenceBusinessMutationVariables,
        TContext
    >,
) =>
    useMutation<
        PatchReferenceBusinessMutation,
        TError,
        PatchReferenceBusinessMutationVariables,
        TContext
    >(
        ["patchReferenceBusiness"],
        (variables?: PatchReferenceBusinessMutationVariables) =>
            graphqlFetcher<
                PatchReferenceBusinessMutation,
                PatchReferenceBusinessMutationVariables
            >(PatchReferenceBusinessDocument, variables)(),
        options,
    );
export const GetReferralsToBusinessDocument = `
    query getReferralsToBusiness($referenceBusinessId: ReferenceBusinessId!) {
  getReferralsToBusiness(referenceBusinessId: $referenceBusinessId) {
    ...ReferralBasics
  }
}
    ${ReferralBasicsFragmentDoc}`;
export const useGetReferralsToBusinessQuery = <
    TData = GetReferralsToBusinessQuery,
    TError = unknown,
>(
    variables: GetReferralsToBusinessQueryVariables,
    options?: UseQueryOptions<GetReferralsToBusinessQuery, TError, TData>,
) =>
    useQuery<GetReferralsToBusinessQuery, TError, TData>(
        ["getReferralsToBusiness", variables],
        graphqlFetcher<GetReferralsToBusinessQuery, GetReferralsToBusinessQueryVariables>(
            GetReferralsToBusinessDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetReferralsToBusinessQuery = <
    TData = GetReferralsToBusinessQuery,
    TError = unknown,
>(
    variables: GetReferralsToBusinessQueryVariables,
    options?: UseInfiniteQueryOptions<GetReferralsToBusinessQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetReferralsToBusinessQuery, TError, TData>(
        ["getReferralsToBusiness.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetReferralsToBusinessQuery,
                GetReferralsToBusinessQueryVariables
            >(GetReferralsToBusinessDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GeocodeReferenceBusinessesDocument = `
    mutation geocodeReferenceBusinesses {
  geocodeReferenceBusinesses
}
    `;
export const useGeocodeReferenceBusinessesMutation = <
    TError = unknown,
    TContext = unknown,
>(
    options?: UseMutationOptions<
        GeocodeReferenceBusinessesMutation,
        TError,
        GeocodeReferenceBusinessesMutationVariables,
        TContext
    >,
) =>
    useMutation<
        GeocodeReferenceBusinessesMutation,
        TError,
        GeocodeReferenceBusinessesMutationVariables,
        TContext
    >(
        ["geocodeReferenceBusinesses"],
        (variables?: GeocodeReferenceBusinessesMutationVariables) =>
            graphqlFetcher<
                GeocodeReferenceBusinessesMutation,
                GeocodeReferenceBusinessesMutationVariables
            >(GeocodeReferenceBusinessesDocument, variables)(),
        options,
    );
export const GetRegionDocument = `
    query getRegion($id: RegionId!, $tenantId: TenantId!) {
  getRegion(regionId: $id, tenantId: $tenantId) {
    id
    key
    name
    regionLicenseMaps {
      id
      autoPropagateEnabled
      identityFilterObjects {
        state
        zip
        county
      }
      licenseMappingObjects {
        licenseIdentifier
        promptId
        questionId
        annotationOnly
      }
      settings {
        mode
        updateAttributes
        closedHandling
      }
    }
  }
}
    `;
export const useGetRegionQuery = <TData = GetRegionQuery, TError = unknown>(
    variables: GetRegionQueryVariables,
    options?: UseQueryOptions<GetRegionQuery, TError, TData>,
) =>
    useQuery<GetRegionQuery, TError, TData>(
        ["getRegion", variables],
        graphqlFetcher<GetRegionQuery, GetRegionQueryVariables>(
            GetRegionDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetRegionQuery = <TData = GetRegionQuery, TError = unknown>(
    variables: GetRegionQueryVariables,
    options?: UseInfiniteQueryOptions<GetRegionQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetRegionQuery, TError, TData>(
        ["getRegion.infinite", variables],
        metaData =>
            graphqlFetcher<GetRegionQuery, GetRegionQueryVariables>(GetRegionDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetStateReportDocument = `
    query getStateReport {
  getStateReport {
    thecount
    totalrlmcount
    state
    regionid
    regionkey
    regionname
    tenantname
    tenantid
  }
}
    `;
export const useGetStateReportQuery = <TData = GetStateReportQuery, TError = unknown>(
    variables?: GetStateReportQueryVariables,
    options?: UseQueryOptions<GetStateReportQuery, TError, TData>,
) =>
    useQuery<GetStateReportQuery, TError, TData>(
        variables === undefined ? ["getStateReport"] : ["getStateReport", variables],
        graphqlFetcher<GetStateReportQuery, GetStateReportQueryVariables>(
            GetStateReportDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetStateReportQuery = <
    TData = GetStateReportQuery,
    TError = unknown,
>(
    variables?: GetStateReportQueryVariables,
    options?: UseInfiniteQueryOptions<GetStateReportQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetStateReportQuery, TError, TData>(
        variables === undefined
            ? ["getStateReport.infinite"]
            : ["getStateReport.infinite", variables],
        metaData =>
            graphqlFetcher<GetStateReportQuery, GetStateReportQueryVariables>(
                GetStateReportDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const PropagateChangesDocument = `
    mutation propagateChanges($tenantId: TenantId!, $regionId: RegionId!) {
  createCommunitiesInRegion(tenantId: $tenantId, regionId: $regionId) {
    propagations {
      regionLicenseMapId
      communitiesCreated
      communitiesMatched
      communitiesWithExistingLinks
    }
  }
}
    `;
export const usePropagateChangesMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        PropagateChangesMutation,
        TError,
        PropagateChangesMutationVariables,
        TContext
    >,
) =>
    useMutation<
        PropagateChangesMutation,
        TError,
        PropagateChangesMutationVariables,
        TContext
    >(
        ["propagateChanges"],
        (variables?: PropagateChangesMutationVariables) =>
            graphqlFetcher<PropagateChangesMutation, PropagateChangesMutationVariables>(
                PropagateChangesDocument,
                variables,
            )(),
        options,
    );
export const CreateOrUpdateRegionLicenseMapDocument = `
    mutation createOrUpdateRegionLicenseMap($regionLicenseMap: RegionLicenseMapUpdateRequest!) {
  createOrUpdateRegionLicenseMap(params: $regionLicenseMap) {
    id
  }
}
    `;
export const useCreateOrUpdateRegionLicenseMapMutation = <
    TError = unknown,
    TContext = unknown,
>(
    options?: UseMutationOptions<
        CreateOrUpdateRegionLicenseMapMutation,
        TError,
        CreateOrUpdateRegionLicenseMapMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateOrUpdateRegionLicenseMapMutation,
        TError,
        CreateOrUpdateRegionLicenseMapMutationVariables,
        TContext
    >(
        ["createOrUpdateRegionLicenseMap"],
        (variables?: CreateOrUpdateRegionLicenseMapMutationVariables) =>
            graphqlFetcher<
                CreateOrUpdateRegionLicenseMapMutation,
                CreateOrUpdateRegionLicenseMapMutationVariables
            >(CreateOrUpdateRegionLicenseMapDocument, variables)(),
        options,
    );
export const DeleteRegionLicenseMapDocument = `
    mutation deleteRegionLicenseMap($id: RegionLicenseMapId!) {
  deleteRegionLicenseMap(regionLicenseMapId: $id)
}
    `;
export const useDeleteRegionLicenseMapMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteRegionLicenseMapMutation,
        TError,
        DeleteRegionLicenseMapMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteRegionLicenseMapMutation,
        TError,
        DeleteRegionLicenseMapMutationVariables,
        TContext
    >(
        ["deleteRegionLicenseMap"],
        (variables?: DeleteRegionLicenseMapMutationVariables) =>
            graphqlFetcher<
                DeleteRegionLicenseMapMutation,
                DeleteRegionLicenseMapMutationVariables
            >(DeleteRegionLicenseMapDocument, variables)(),
        options,
    );
export const GetEntityRegionPermissionsDocument = `
    query getEntityRegionPermissions($params: GetEntityRegionPermissionsParams!) {
  getEntityRegionPermissions(params: $params) {
    editableRegions {
      id
      key
      name
    }
  }
}
    `;
export const useGetEntityRegionPermissionsQuery = <
    TData = GetEntityRegionPermissionsQuery,
    TError = unknown,
>(
    variables: GetEntityRegionPermissionsQueryVariables,
    options?: UseQueryOptions<GetEntityRegionPermissionsQuery, TError, TData>,
) =>
    useQuery<GetEntityRegionPermissionsQuery, TError, TData>(
        ["getEntityRegionPermissions", variables],
        graphqlFetcher<
            GetEntityRegionPermissionsQuery,
            GetEntityRegionPermissionsQueryVariables
        >(GetEntityRegionPermissionsDocument, variables),
        options,
    );
export const useInfiniteGetEntityRegionPermissionsQuery = <
    TData = GetEntityRegionPermissionsQuery,
    TError = unknown,
>(
    variables: GetEntityRegionPermissionsQueryVariables,
    options?: UseInfiniteQueryOptions<GetEntityRegionPermissionsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetEntityRegionPermissionsQuery, TError, TData>(
        ["getEntityRegionPermissions.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetEntityRegionPermissionsQuery,
                GetEntityRegionPermissionsQueryVariables
            >(GetEntityRegionPermissionsDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const ClientPlacementRevenueDocument = `
    query ClientPlacementRevenue($startDate: String!, $endDate: String!, $region: String!) {
  reportClientPlacementRevenue(
    startDate: $startDate
    endDate: $endDate
    region: $region
  ) {
    segments {
      startDate
      revenue
      moves
      users {
        user {
          id
          displayName
        }
        revenue
        moves
      }
    }
  }
}
    `;
export const useClientPlacementRevenueQuery = <
    TData = ClientPlacementRevenueQuery,
    TError = unknown,
>(
    variables: ClientPlacementRevenueQueryVariables,
    options?: UseQueryOptions<ClientPlacementRevenueQuery, TError, TData>,
) =>
    useQuery<ClientPlacementRevenueQuery, TError, TData>(
        ["ClientPlacementRevenue", variables],
        graphqlFetcher<ClientPlacementRevenueQuery, ClientPlacementRevenueQueryVariables>(
            ClientPlacementRevenueDocument,
            variables,
        ),
        options,
    );
export const useInfiniteClientPlacementRevenueQuery = <
    TData = ClientPlacementRevenueQuery,
    TError = unknown,
>(
    variables: ClientPlacementRevenueQueryVariables,
    options?: UseInfiniteQueryOptions<ClientPlacementRevenueQuery, TError, TData>,
) => {
    return useInfiniteQuery<ClientPlacementRevenueQuery, TError, TData>(
        ["ClientPlacementRevenue.infinite", variables],
        metaData =>
            graphqlFetcher<
                ClientPlacementRevenueQuery,
                ClientPlacementRevenueQueryVariables
            >(ClientPlacementRevenueDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const InboundReferralsDocument = `
    query InboundReferrals($startDate: String!, $endDate: String!, $region: String!) {
  reportInboundReferrals(
    region: $region
    startDate: $startDate
    endDate: $endDate
  ) {
    generic {
      referralToken
      count
    }
    businesses {
      count
      business {
        id
        businessContactEntity {
          name
          contactType
        }
      }
    }
    contacts {
      count
      contact {
        id
        contactEntity {
          name
        }
      }
    }
  }
}
    `;
export const useInboundReferralsQuery = <TData = InboundReferralsQuery, TError = unknown>(
    variables: InboundReferralsQueryVariables,
    options?: UseQueryOptions<InboundReferralsQuery, TError, TData>,
) =>
    useQuery<InboundReferralsQuery, TError, TData>(
        ["InboundReferrals", variables],
        graphqlFetcher<InboundReferralsQuery, InboundReferralsQueryVariables>(
            InboundReferralsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteInboundReferralsQuery = <
    TData = InboundReferralsQuery,
    TError = unknown,
>(
    variables: InboundReferralsQueryVariables,
    options?: UseInfiniteQueryOptions<InboundReferralsQuery, TError, TData>,
) => {
    return useInfiniteQuery<InboundReferralsQuery, TError, TData>(
        ["InboundReferrals.infinite", variables],
        metaData =>
            graphqlFetcher<InboundReferralsQuery, InboundReferralsQueryVariables>(
                InboundReferralsDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const ReferralsDocument = `
    query Referrals($startDate: String!, $endDate: String!, $region: String!) {
  reportReferrals(region: $region, startDate: $startDate, endDate: $endDate) {
    generic {
      referralToken
      count
    }
    businesses {
      inboundCount
      outboundCount
      business {
        id
        businessContactEntity {
          name
          contactType
        }
      }
    }
    contacts {
      inboundCount
      outboundCount
      contact {
        id
        contactEntity {
          name
        }
      }
    }
  }
}
    `;
export const useReferralsQuery = <TData = ReferralsQuery, TError = unknown>(
    variables: ReferralsQueryVariables,
    options?: UseQueryOptions<ReferralsQuery, TError, TData>,
) =>
    useQuery<ReferralsQuery, TError, TData>(
        ["Referrals", variables],
        graphqlFetcher<ReferralsQuery, ReferralsQueryVariables>(
            ReferralsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteReferralsQuery = <TData = ReferralsQuery, TError = unknown>(
    variables: ReferralsQueryVariables,
    options?: UseInfiniteQueryOptions<ReferralsQuery, TError, TData>,
) => {
    return useInfiniteQuery<ReferralsQuery, TError, TData>(
        ["Referrals.infinite", variables],
        metaData =>
            graphqlFetcher<ReferralsQuery, ReferralsQueryVariables>(ReferralsDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetSignatureTemplateDocument = `
    query getSignatureTemplate($fileId: FileId!) {
  getFile(fileId: $fileId) {
    originalFilename
  }
  getSignatureTemplate(fileId: $fileId) {
    id
    recipientCount
    emailTemplate {
      subject
      body
      attachments
    }
    signatureTemplateFields {
      defaultTextValue
      defaultBooleanValue
      isRequired
      placeholder
      fieldType
      font
      fontSize
      height
      id
      order
      page
      recipientIndex
      width
      x
      y
    }
  }
}
    `;
export const useGetSignatureTemplateQuery = <
    TData = GetSignatureTemplateQuery,
    TError = unknown,
>(
    variables: GetSignatureTemplateQueryVariables,
    options?: UseQueryOptions<GetSignatureTemplateQuery, TError, TData>,
) =>
    useQuery<GetSignatureTemplateQuery, TError, TData>(
        ["getSignatureTemplate", variables],
        graphqlFetcher<GetSignatureTemplateQuery, GetSignatureTemplateQueryVariables>(
            GetSignatureTemplateDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetSignatureTemplateQuery = <
    TData = GetSignatureTemplateQuery,
    TError = unknown,
>(
    variables: GetSignatureTemplateQueryVariables,
    options?: UseInfiniteQueryOptions<GetSignatureTemplateQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetSignatureTemplateQuery, TError, TData>(
        ["getSignatureTemplate.infinite", variables],
        metaData =>
            graphqlFetcher<GetSignatureTemplateQuery, GetSignatureTemplateQueryVariables>(
                GetSignatureTemplateDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const SetSignatureTemplateDocument = `
    mutation setSignatureTemplate($fileId: FileId!, $signatureTemplate: SignatureTemplateInput!) {
  setSignatureTemplate(fileId: $fileId, signatureTemplate: $signatureTemplate) {
    id
  }
}
    `;
export const useSetSignatureTemplateMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        SetSignatureTemplateMutation,
        TError,
        SetSignatureTemplateMutationVariables,
        TContext
    >,
) =>
    useMutation<
        SetSignatureTemplateMutation,
        TError,
        SetSignatureTemplateMutationVariables,
        TContext
    >(
        ["setSignatureTemplate"],
        (variables?: SetSignatureTemplateMutationVariables) =>
            graphqlFetcher<
                SetSignatureTemplateMutation,
                SetSignatureTemplateMutationVariables
            >(SetSignatureTemplateDocument, variables)(),
        options,
    );
export const GetSignableTemplatesDocument = `
    query getSignableTemplates($entityType: FileEntityType!) {
  getSignableTemplates(entityType: $entityType) {
    originalFilename
    id
  }
}
    `;
export const useGetSignableTemplatesQuery = <
    TData = GetSignableTemplatesQuery,
    TError = unknown,
>(
    variables: GetSignableTemplatesQueryVariables,
    options?: UseQueryOptions<GetSignableTemplatesQuery, TError, TData>,
) =>
    useQuery<GetSignableTemplatesQuery, TError, TData>(
        ["getSignableTemplates", variables],
        graphqlFetcher<GetSignableTemplatesQuery, GetSignableTemplatesQueryVariables>(
            GetSignableTemplatesDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetSignableTemplatesQuery = <
    TData = GetSignableTemplatesQuery,
    TError = unknown,
>(
    variables: GetSignableTemplatesQueryVariables,
    options?: UseInfiniteQueryOptions<GetSignableTemplatesQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetSignableTemplatesQuery, TError, TData>(
        ["getSignableTemplates.infinite", variables],
        metaData =>
            graphqlFetcher<GetSignableTemplatesQuery, GetSignableTemplatesQueryVariables>(
                GetSignableTemplatesDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetSignatureRequestsForEntityDocument = `
    query getSignatureRequestsForEntity($entityId: FileEntityId!) {
  getSignatureRequestsForEntity(entityId: $entityId) {
    id
    createdAt
    updatedAt
    filename
    sourceFileId
    signatureTemplateFileId
    state
    signatureRequestRecipients {
      email
      isComplete
      id
      order
      remindAfter
    }
  }
}
    `;
export const useGetSignatureRequestsForEntityQuery = <
    TData = GetSignatureRequestsForEntityQuery,
    TError = unknown,
>(
    variables: GetSignatureRequestsForEntityQueryVariables,
    options?: UseQueryOptions<GetSignatureRequestsForEntityQuery, TError, TData>,
) =>
    useQuery<GetSignatureRequestsForEntityQuery, TError, TData>(
        ["getSignatureRequestsForEntity", variables],
        graphqlFetcher<
            GetSignatureRequestsForEntityQuery,
            GetSignatureRequestsForEntityQueryVariables
        >(GetSignatureRequestsForEntityDocument, variables),
        options,
    );
export const useInfiniteGetSignatureRequestsForEntityQuery = <
    TData = GetSignatureRequestsForEntityQuery,
    TError = unknown,
>(
    variables: GetSignatureRequestsForEntityQueryVariables,
    options?: UseInfiniteQueryOptions<GetSignatureRequestsForEntityQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetSignatureRequestsForEntityQuery, TError, TData>(
        ["getSignatureRequestsForEntity.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetSignatureRequestsForEntityQuery,
                GetSignatureRequestsForEntityQueryVariables
            >(GetSignatureRequestsForEntityDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetSignatureRequestDocument = `
    query getSignatureRequest($signatureRequestId: SignatureRequestId!) {
  getSignatureRequest(signatureRequestId: $signatureRequestId) {
    id
    createdAt
    updatedAt
    filename
    sourceFileId
    signatureTemplateFileId
    state
    signatureRequestFields {
      id
      page
      recipientIndex
      order
      x
      y
      width
      height
      font
      fontSize
      fieldType
      textValue
      booleanValue
      isRequired
      placeholder
      filledBy
    }
    signatureRequestRecipients {
      id
      order
      remindAfter
      remindersSent
      createdAt
      updatedAt
      completedAt
      email
      name
      isComplete
    }
    ccRecipients {
      email
      name
    }
  }
}
    `;
export const useGetSignatureRequestQuery = <
    TData = GetSignatureRequestQuery,
    TError = unknown,
>(
    variables: GetSignatureRequestQueryVariables,
    options?: UseQueryOptions<GetSignatureRequestQuery, TError, TData>,
) =>
    useQuery<GetSignatureRequestQuery, TError, TData>(
        ["getSignatureRequest", variables],
        graphqlFetcher<GetSignatureRequestQuery, GetSignatureRequestQueryVariables>(
            GetSignatureRequestDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetSignatureRequestQuery = <
    TData = GetSignatureRequestQuery,
    TError = unknown,
>(
    variables: GetSignatureRequestQueryVariables,
    options?: UseInfiniteQueryOptions<GetSignatureRequestQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetSignatureRequestQuery, TError, TData>(
        ["getSignatureRequest.infinite", variables],
        metaData =>
            graphqlFetcher<GetSignatureRequestQuery, GetSignatureRequestQueryVariables>(
                GetSignatureRequestDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const PlaceholderPerspectiveDocument = `
    query placeholderPerspective {
  placeholderPerspective {
    recipientIndex
  }
}
    `;
export const usePlaceholderPerspectiveQuery = <
    TData = PlaceholderPerspectiveQuery,
    TError = unknown,
>(
    variables?: PlaceholderPerspectiveQueryVariables,
    options?: UseQueryOptions<PlaceholderPerspectiveQuery, TError, TData>,
) =>
    useQuery<PlaceholderPerspectiveQuery, TError, TData>(
        variables === undefined
            ? ["placeholderPerspective"]
            : ["placeholderPerspective", variables],
        graphqlFetcher<PlaceholderPerspectiveQuery, PlaceholderPerspectiveQueryVariables>(
            PlaceholderPerspectiveDocument,
            variables,
        ),
        options,
    );
export const useInfinitePlaceholderPerspectiveQuery = <
    TData = PlaceholderPerspectiveQuery,
    TError = unknown,
>(
    variables?: PlaceholderPerspectiveQueryVariables,
    options?: UseInfiniteQueryOptions<PlaceholderPerspectiveQuery, TError, TData>,
) => {
    return useInfiniteQuery<PlaceholderPerspectiveQuery, TError, TData>(
        variables === undefined
            ? ["placeholderPerspective.infinite"]
            : ["placeholderPerspective.infinite", variables],
        metaData =>
            graphqlFetcher<
                PlaceholderPerspectiveQuery,
                PlaceholderPerspectiveQueryVariables
            >(PlaceholderPerspectiveDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const CreateSignatureRequestDocument = `
    mutation createSignatureRequest($signatureRequestPayload: SignatureRequestPayload!) {
  createSignatureRequest(signatureRequestPayload: $signatureRequestPayload) {
    id
    signatureRequestRecipients {
      id
    }
  }
}
    `;
export const useCreateSignatureRequestMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateSignatureRequestMutation,
        TError,
        CreateSignatureRequestMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateSignatureRequestMutation,
        TError,
        CreateSignatureRequestMutationVariables,
        TContext
    >(
        ["createSignatureRequest"],
        (variables?: CreateSignatureRequestMutationVariables) =>
            graphqlFetcher<
                CreateSignatureRequestMutation,
                CreateSignatureRequestMutationVariables
            >(CreateSignatureRequestDocument, variables)(),
        options,
    );
export const GetDraftSignatureRequestDocument = `
    query getDraftSignatureRequest($entityId: FileEntityId!, $fileId: FileId!, $signatureRequestId: SignatureRequestId) {
  getFile(fileId: $fileId) {
    originalFilename
  }
  getDraftSignatureRequest(
    entityId: $entityId
    fileId: $fileId
    signatureRequestId: $signatureRequestId
  ) {
    potentialRecipients {
      name
      email
      source
    }
    signatureTemplate {
      id
      recipientCount
      signatureTemplateFields {
        isRequired
        defaultTextValue
        defaultBooleanValue
        placeholder
        fieldType
        font
        fontSize
        height
        id
        order
        page
        recipientIndex
        width
        x
        y
      }
    }
    actualRecipients {
      name
      email
    }
    draftFields {
      signatureTemplateFieldId
      textValue
      booleanValue
      placeholder
      isRequired
    }
    emailTemplate {
      subject
      body
      attachments
    }
    entityType
    ccRecipients {
      name
      email
    }
  }
}
    `;
export const useGetDraftSignatureRequestQuery = <
    TData = GetDraftSignatureRequestQuery,
    TError = unknown,
>(
    variables: GetDraftSignatureRequestQueryVariables,
    options?: UseQueryOptions<GetDraftSignatureRequestQuery, TError, TData>,
) =>
    useQuery<GetDraftSignatureRequestQuery, TError, TData>(
        ["getDraftSignatureRequest", variables],
        graphqlFetcher<
            GetDraftSignatureRequestQuery,
            GetDraftSignatureRequestQueryVariables
        >(GetDraftSignatureRequestDocument, variables),
        options,
    );
export const useInfiniteGetDraftSignatureRequestQuery = <
    TData = GetDraftSignatureRequestQuery,
    TError = unknown,
>(
    variables: GetDraftSignatureRequestQueryVariables,
    options?: UseInfiniteQueryOptions<GetDraftSignatureRequestQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetDraftSignatureRequestQuery, TError, TData>(
        ["getDraftSignatureRequest.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetDraftSignatureRequestQuery,
                GetDraftSignatureRequestQueryVariables
            >(GetDraftSignatureRequestDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const ResendSignatureRequestDocument = `
    mutation resendSignatureRequest($signatureRequestId: SignatureRequestId!) {
  resendSignatureRequest(signatureRequestId: $signatureRequestId)
}
    `;
export const useResendSignatureRequestMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        ResendSignatureRequestMutation,
        TError,
        ResendSignatureRequestMutationVariables,
        TContext
    >,
) =>
    useMutation<
        ResendSignatureRequestMutation,
        TError,
        ResendSignatureRequestMutationVariables,
        TContext
    >(
        ["resendSignatureRequest"],
        (variables?: ResendSignatureRequestMutationVariables) =>
            graphqlFetcher<
                ResendSignatureRequestMutation,
                ResendSignatureRequestMutationVariables
            >(ResendSignatureRequestDocument, variables)(),
        options,
    );
export const AbandonSignatureRequestDocument = `
    mutation abandonSignatureRequest($signatureRequestId: SignatureRequestId!) {
  abandonSignatureRequest(signatureRequestId: $signatureRequestId)
}
    `;
export const useAbandonSignatureRequestMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        AbandonSignatureRequestMutation,
        TError,
        AbandonSignatureRequestMutationVariables,
        TContext
    >,
) =>
    useMutation<
        AbandonSignatureRequestMutation,
        TError,
        AbandonSignatureRequestMutationVariables,
        TContext
    >(
        ["abandonSignatureRequest"],
        (variables?: AbandonSignatureRequestMutationVariables) =>
            graphqlFetcher<
                AbandonSignatureRequestMutation,
                AbandonSignatureRequestMutationVariables
            >(AbandonSignatureRequestDocument, variables)(),
        options,
    );
export const CreateSmtpCredentialDocument = `
    mutation createSmtpCredential($params: CreateSmtpCredentialRequest!) {
  createSmtpCredential(params: $params) {
    success
    message
  }
}
    `;
export const useCreateSmtpCredentialMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateSmtpCredentialMutation,
        TError,
        CreateSmtpCredentialMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateSmtpCredentialMutation,
        TError,
        CreateSmtpCredentialMutationVariables,
        TContext
    >(
        ["createSmtpCredential"],
        (variables?: CreateSmtpCredentialMutationVariables) =>
            graphqlFetcher<
                CreateSmtpCredentialMutation,
                CreateSmtpCredentialMutationVariables
            >(CreateSmtpCredentialDocument, variables)(),
        options,
    );
export const GetSmtpCredentialForUserDocument = `
    query getSmtpCredentialForUser {
  getSmtpCredentialForUser {
    ...SmtpCredentialBasics
  }
}
    ${SmtpCredentialBasicsFragmentDoc}`;
export const useGetSmtpCredentialForUserQuery = <
    TData = GetSmtpCredentialForUserQuery,
    TError = unknown,
>(
    variables?: GetSmtpCredentialForUserQueryVariables,
    options?: UseQueryOptions<GetSmtpCredentialForUserQuery, TError, TData>,
) =>
    useQuery<GetSmtpCredentialForUserQuery, TError, TData>(
        variables === undefined
            ? ["getSmtpCredentialForUser"]
            : ["getSmtpCredentialForUser", variables],
        graphqlFetcher<
            GetSmtpCredentialForUserQuery,
            GetSmtpCredentialForUserQueryVariables
        >(GetSmtpCredentialForUserDocument, variables),
        options,
    );
export const useInfiniteGetSmtpCredentialForUserQuery = <
    TData = GetSmtpCredentialForUserQuery,
    TError = unknown,
>(
    variables?: GetSmtpCredentialForUserQueryVariables,
    options?: UseInfiniteQueryOptions<GetSmtpCredentialForUserQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetSmtpCredentialForUserQuery, TError, TData>(
        variables === undefined
            ? ["getSmtpCredentialForUser.infinite"]
            : ["getSmtpCredentialForUser.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetSmtpCredentialForUserQuery,
                GetSmtpCredentialForUserQueryVariables
            >(GetSmtpCredentialForUserDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const DeleteSmtpCredentialForUserDocument = `
    mutation deleteSmtpCredentialForUser {
  deleteSmtpCredentialForUser
}
    `;
export const useDeleteSmtpCredentialForUserMutation = <
    TError = unknown,
    TContext = unknown,
>(
    options?: UseMutationOptions<
        DeleteSmtpCredentialForUserMutation,
        TError,
        DeleteSmtpCredentialForUserMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteSmtpCredentialForUserMutation,
        TError,
        DeleteSmtpCredentialForUserMutationVariables,
        TContext
    >(
        ["deleteSmtpCredentialForUser"],
        (variables?: DeleteSmtpCredentialForUserMutationVariables) =>
            graphqlFetcher<
                DeleteSmtpCredentialForUserMutation,
                DeleteSmtpCredentialForUserMutationVariables
            >(DeleteSmtpCredentialForUserDocument, variables)(),
        options,
    );
export const SystemDocument = `
    query system {
  jobStats {
    complete
    pending
    available
    started
    failed
  }
  systemConfiguration {
    fileStorage
  }
}
    `;
export const useSystemQuery = <TData = SystemQuery, TError = unknown>(
    variables?: SystemQueryVariables,
    options?: UseQueryOptions<SystemQuery, TError, TData>,
) =>
    useQuery<SystemQuery, TError, TData>(
        variables === undefined ? ["system"] : ["system", variables],
        graphqlFetcher<SystemQuery, SystemQueryVariables>(SystemDocument, variables),
        options,
    );
export const useInfiniteSystemQuery = <TData = SystemQuery, TError = unknown>(
    variables?: SystemQueryVariables,
    options?: UseInfiniteQueryOptions<SystemQuery, TError, TData>,
) => {
    return useInfiniteQuery<SystemQuery, TError, TData>(
        variables === undefined ? ["system.infinite"] : ["system.infinite", variables],
        metaData =>
            graphqlFetcher<SystemQuery, SystemQueryVariables>(SystemDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetTaskDocument = `
    query getTask($regionId: RegionId!, $taskId: TaskId!) {
  getTask(regionId: $regionId, taskId: $taskId) {
    task {
      ...TaskBasics
    }
    entity {
      displayName
      path
      kind
    }
  }
}
    ${TaskBasicsFragmentDoc}`;
export const useGetTaskQuery = <TData = GetTaskQuery, TError = unknown>(
    variables: GetTaskQueryVariables,
    options?: UseQueryOptions<GetTaskQuery, TError, TData>,
) =>
    useQuery<GetTaskQuery, TError, TData>(
        ["getTask", variables],
        graphqlFetcher<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, variables),
        options,
    );
export const useInfiniteGetTaskQuery = <TData = GetTaskQuery, TError = unknown>(
    variables: GetTaskQueryVariables,
    options?: UseInfiniteQueryOptions<GetTaskQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetTaskQuery, TError, TData>(
        ["getTask.infinite", variables],
        metaData =>
            graphqlFetcher<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetTasksDocument = `
    query getTasks($activeOnly: Boolean!, $regionId: ID!, $page: Int!) {
  getTasks(activeOnly: $activeOnly, regionId: $regionId, page: $page) {
    page
    totalPages
    tasks {
      task {
        ...TaskBasics
      }
      entity {
        displayName
        path
        kind
      }
    }
  }
}
    ${TaskBasicsFragmentDoc}`;
export const useGetTasksQuery = <TData = GetTasksQuery, TError = unknown>(
    variables: GetTasksQueryVariables,
    options?: UseQueryOptions<GetTasksQuery, TError, TData>,
) =>
    useQuery<GetTasksQuery, TError, TData>(
        ["getTasks", variables],
        graphqlFetcher<GetTasksQuery, GetTasksQueryVariables>(
            GetTasksDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetTasksQuery = <TData = GetTasksQuery, TError = unknown>(
    variables: GetTasksQueryVariables,
    options?: UseInfiniteQueryOptions<GetTasksQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetTasksQuery, TError, TData>(
        ["getTasks.infinite", variables],
        metaData =>
            graphqlFetcher<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetTasksv2Document = `
    query getTasksv2($params: GetTasksRequest!) {
  getTasksv2(params: $params) {
    task {
      ...TaskBasics
    }
    entity {
      displayName
      path
      kind
      contact {
        name
        phoneNumber
        contactType
      }
    }
  }
}
    ${TaskBasicsFragmentDoc}`;
export const useGetTasksv2Query = <TData = GetTasksv2Query, TError = unknown>(
    variables: GetTasksv2QueryVariables,
    options?: UseQueryOptions<GetTasksv2Query, TError, TData>,
) =>
    useQuery<GetTasksv2Query, TError, TData>(
        ["getTasksv2", variables],
        graphqlFetcher<GetTasksv2Query, GetTasksv2QueryVariables>(
            GetTasksv2Document,
            variables,
        ),
        options,
    );
export const useInfiniteGetTasksv2Query = <TData = GetTasksv2Query, TError = unknown>(
    variables: GetTasksv2QueryVariables,
    options?: UseInfiniteQueryOptions<GetTasksv2Query, TError, TData>,
) => {
    return useInfiniteQuery<GetTasksv2Query, TError, TData>(
        ["getTasksv2.infinite", variables],
        metaData =>
            graphqlFetcher<GetTasksv2Query, GetTasksv2QueryVariables>(
                GetTasksv2Document,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetNextTasksForParentIdsDocument = `
    query getNextTasksForParentIds($parentIds: [TaskParentId!]!) {
  getNextTasksForParentIds(parentIds: $parentIds) {
    ...TaskBasics
  }
}
    ${TaskBasicsFragmentDoc}`;
export const useGetNextTasksForParentIdsQuery = <
    TData = GetNextTasksForParentIdsQuery,
    TError = unknown,
>(
    variables: GetNextTasksForParentIdsQueryVariables,
    options?: UseQueryOptions<GetNextTasksForParentIdsQuery, TError, TData>,
) =>
    useQuery<GetNextTasksForParentIdsQuery, TError, TData>(
        ["getNextTasksForParentIds", variables],
        graphqlFetcher<
            GetNextTasksForParentIdsQuery,
            GetNextTasksForParentIdsQueryVariables
        >(GetNextTasksForParentIdsDocument, variables),
        options,
    );
export const useInfiniteGetNextTasksForParentIdsQuery = <
    TData = GetNextTasksForParentIdsQuery,
    TError = unknown,
>(
    variables: GetNextTasksForParentIdsQueryVariables,
    options?: UseInfiniteQueryOptions<GetNextTasksForParentIdsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetNextTasksForParentIdsQuery, TError, TData>(
        ["getNextTasksForParentIds.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetNextTasksForParentIdsQuery,
                GetNextTasksForParentIdsQueryVariables
            >(GetNextTasksForParentIdsDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetTasksForEntityDocument = `
    query getTasksForEntity($parentId: TaskParentId!) {
  getTasksForEntity(parentId: $parentId) {
    ...TaskDetails
  }
}
    ${TaskDetailsFragmentDoc}`;
export const useGetTasksForEntityQuery = <
    TData = GetTasksForEntityQuery,
    TError = unknown,
>(
    variables: GetTasksForEntityQueryVariables,
    options?: UseQueryOptions<GetTasksForEntityQuery, TError, TData>,
) =>
    useQuery<GetTasksForEntityQuery, TError, TData>(
        ["getTasksForEntity", variables],
        graphqlFetcher<GetTasksForEntityQuery, GetTasksForEntityQueryVariables>(
            GetTasksForEntityDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetTasksForEntityQuery = <
    TData = GetTasksForEntityQuery,
    TError = unknown,
>(
    variables: GetTasksForEntityQueryVariables,
    options?: UseInfiniteQueryOptions<GetTasksForEntityQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetTasksForEntityQuery, TError, TData>(
        ["getTasksForEntity.infinite", variables],
        metaData =>
            graphqlFetcher<GetTasksForEntityQuery, GetTasksForEntityQueryVariables>(
                GetTasksForEntityDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const CreateTaskDocument = `
    mutation createTask($parentId: TaskParentId!, $params: CreateTaskPayload!) {
  createTask(parentId: $parentId, params: $params) {
    ...TaskDetails
  }
}
    ${TaskDetailsFragmentDoc}`;
export const useCreateTaskMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateTaskMutation,
        TError,
        CreateTaskMutationVariables,
        TContext
    >,
) =>
    useMutation<CreateTaskMutation, TError, CreateTaskMutationVariables, TContext>(
        ["createTask"],
        (variables?: CreateTaskMutationVariables) =>
            graphqlFetcher<CreateTaskMutation, CreateTaskMutationVariables>(
                CreateTaskDocument,
                variables,
            )(),
        options,
    );
export const UpdateTaskDocument = `
    mutation updateTask($taskId: TaskId!, $params: UpdateTaskPayload!) {
  updateTask(taskId: $taskId, params: $params) {
    ...TaskDetails
  }
}
    ${TaskDetailsFragmentDoc}`;
export const useUpdateTaskMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpdateTaskMutation,
        TError,
        UpdateTaskMutationVariables,
        TContext
    >,
) =>
    useMutation<UpdateTaskMutation, TError, UpdateTaskMutationVariables, TContext>(
        ["updateTask"],
        (variables?: UpdateTaskMutationVariables) =>
            graphqlFetcher<UpdateTaskMutation, UpdateTaskMutationVariables>(
                UpdateTaskDocument,
                variables,
            )(),
        options,
    );
export const DeleteTaskDocument = `
    mutation deleteTask($taskId: TaskId!) {
  deleteTask(taskId: $taskId)
}
    `;
export const useDeleteTaskMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteTaskMutation,
        TError,
        DeleteTaskMutationVariables,
        TContext
    >,
) =>
    useMutation<DeleteTaskMutation, TError, DeleteTaskMutationVariables, TContext>(
        ["deleteTask"],
        (variables?: DeleteTaskMutationVariables) =>
            graphqlFetcher<DeleteTaskMutation, DeleteTaskMutationVariables>(
                DeleteTaskDocument,
                variables,
            )(),
        options,
    );
export const GetTaskTypesDocument = `
    query getTaskTypes {
  getTaskTypes {
    id
    name
    isArchived
  }
}
    `;
export const useGetTaskTypesQuery = <TData = GetTaskTypesQuery, TError = unknown>(
    variables?: GetTaskTypesQueryVariables,
    options?: UseQueryOptions<GetTaskTypesQuery, TError, TData>,
) =>
    useQuery<GetTaskTypesQuery, TError, TData>(
        variables === undefined ? ["getTaskTypes"] : ["getTaskTypes", variables],
        graphqlFetcher<GetTaskTypesQuery, GetTaskTypesQueryVariables>(
            GetTaskTypesDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetTaskTypesQuery = <TData = GetTaskTypesQuery, TError = unknown>(
    variables?: GetTaskTypesQueryVariables,
    options?: UseInfiniteQueryOptions<GetTaskTypesQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetTaskTypesQuery, TError, TData>(
        variables === undefined
            ? ["getTaskTypes.infinite"]
            : ["getTaskTypes.infinite", variables],
        metaData =>
            graphqlFetcher<GetTaskTypesQuery, GetTaskTypesQueryVariables>(
                GetTaskTypesDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const CreateTaskTypeDocument = `
    mutation createTaskType($payload: CreateTaskTypePayload!) {
  createTaskType(payload: $payload) {
    id
  }
}
    `;
export const useCreateTaskTypeMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateTaskTypeMutation,
        TError,
        CreateTaskTypeMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateTaskTypeMutation,
        TError,
        CreateTaskTypeMutationVariables,
        TContext
    >(
        ["createTaskType"],
        (variables?: CreateTaskTypeMutationVariables) =>
            graphqlFetcher<CreateTaskTypeMutation, CreateTaskTypeMutationVariables>(
                CreateTaskTypeDocument,
                variables,
            )(),
        options,
    );
export const UpdateTaskTypeDocument = `
    mutation updateTaskType($payload: UpdateTaskTypePayload!) {
  updateTaskType(payload: $payload) {
    id
  }
}
    `;
export const useUpdateTaskTypeMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpdateTaskTypeMutation,
        TError,
        UpdateTaskTypeMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UpdateTaskTypeMutation,
        TError,
        UpdateTaskTypeMutationVariables,
        TContext
    >(
        ["updateTaskType"],
        (variables?: UpdateTaskTypeMutationVariables) =>
            graphqlFetcher<UpdateTaskTypeMutation, UpdateTaskTypeMutationVariables>(
                UpdateTaskTypeDocument,
                variables,
            )(),
        options,
    );
export const GetTemplateDocument = `
    query getTemplate($id: EmailTemplateId!) {
  getTemplate(emailTemplateId: $id) {
    isEditable
    isDefaultSignature
    template {
      id
      body
      description
      isShared
      type
      subject
      migrationVersion
      attachments
      regionVisibility {
        editable
        regions
      }
    }
    author {
      name
      email
    }
  }
}
    `;
export const useGetTemplateQuery = <TData = GetTemplateQuery, TError = unknown>(
    variables: GetTemplateQueryVariables,
    options?: UseQueryOptions<GetTemplateQuery, TError, TData>,
) =>
    useQuery<GetTemplateQuery, TError, TData>(
        ["getTemplate", variables],
        graphqlFetcher<GetTemplateQuery, GetTemplateQueryVariables>(
            GetTemplateDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetTemplateQuery = <TData = GetTemplateQuery, TError = unknown>(
    variables: GetTemplateQueryVariables,
    options?: UseInfiniteQueryOptions<GetTemplateQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetTemplateQuery, TError, TData>(
        ["getTemplate.infinite", variables],
        metaData =>
            graphqlFetcher<GetTemplateQuery, GetTemplateQueryVariables>(
                GetTemplateDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetTemplatesDocument = `
    query getTemplates {
  getTemplates {
    templates {
      isEditable
      isDefaultSignature
      template {
        id
        body
        description
        isShared
        type
        subject
        migrationVersion
        attachments
        regionVisibility {
          editable
          regions
        }
      }
      author {
        name
        email
      }
    }
  }
}
    `;
export const useGetTemplatesQuery = <TData = GetTemplatesQuery, TError = unknown>(
    variables?: GetTemplatesQueryVariables,
    options?: UseQueryOptions<GetTemplatesQuery, TError, TData>,
) =>
    useQuery<GetTemplatesQuery, TError, TData>(
        variables === undefined ? ["getTemplates"] : ["getTemplates", variables],
        graphqlFetcher<GetTemplatesQuery, GetTemplatesQueryVariables>(
            GetTemplatesDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetTemplatesQuery = <TData = GetTemplatesQuery, TError = unknown>(
    variables?: GetTemplatesQueryVariables,
    options?: UseInfiniteQueryOptions<GetTemplatesQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetTemplatesQuery, TError, TData>(
        variables === undefined
            ? ["getTemplates.infinite"]
            : ["getTemplates.infinite", variables],
        metaData =>
            graphqlFetcher<GetTemplatesQuery, GetTemplatesQueryVariables>(
                GetTemplatesDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const CanCreateSignatureTemplateDocument = `
    query canCreateSignatureTemplate($fileId: FileId!) {
  canCreateSignatureTemplate(fileId: $fileId)
}
    `;
export const useCanCreateSignatureTemplateQuery = <
    TData = CanCreateSignatureTemplateQuery,
    TError = unknown,
>(
    variables: CanCreateSignatureTemplateQueryVariables,
    options?: UseQueryOptions<CanCreateSignatureTemplateQuery, TError, TData>,
) =>
    useQuery<CanCreateSignatureTemplateQuery, TError, TData>(
        ["canCreateSignatureTemplate", variables],
        graphqlFetcher<
            CanCreateSignatureTemplateQuery,
            CanCreateSignatureTemplateQueryVariables
        >(CanCreateSignatureTemplateDocument, variables),
        options,
    );
export const useInfiniteCanCreateSignatureTemplateQuery = <
    TData = CanCreateSignatureTemplateQuery,
    TError = unknown,
>(
    variables: CanCreateSignatureTemplateQueryVariables,
    options?: UseInfiniteQueryOptions<CanCreateSignatureTemplateQuery, TError, TData>,
) => {
    return useInfiniteQuery<CanCreateSignatureTemplateQuery, TError, TData>(
        ["canCreateSignatureTemplate.infinite", variables],
        metaData =>
            graphqlFetcher<
                CanCreateSignatureTemplateQuery,
                CanCreateSignatureTemplateQueryVariables
            >(CanCreateSignatureTemplateDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const DeleteSignatureTemplateDocument = `
    mutation deleteSignatureTemplate($signatureTemplateId: SignatureTemplateId!) {
  deleteSignatureTemplate(signatureTemplateId: $signatureTemplateId) {
    success
    message
  }
}
    `;
export const useDeleteSignatureTemplateMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteSignatureTemplateMutation,
        TError,
        DeleteSignatureTemplateMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteSignatureTemplateMutation,
        TError,
        DeleteSignatureTemplateMutationVariables,
        TContext
    >(
        ["deleteSignatureTemplate"],
        (variables?: DeleteSignatureTemplateMutationVariables) =>
            graphqlFetcher<
                DeleteSignatureTemplateMutation,
                DeleteSignatureTemplateMutationVariables
            >(DeleteSignatureTemplateDocument, variables)(),
        options,
    );
export const CreateOrUpdateTemplateDocument = `
    mutation createOrUpdateTemplate($template: TemplateUpdate!) {
  createOrUpdateTemplate(template: $template) {
    template {
      id
    }
  }
}
    `;
export const useCreateOrUpdateTemplateMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateOrUpdateTemplateMutation,
        TError,
        CreateOrUpdateTemplateMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateOrUpdateTemplateMutation,
        TError,
        CreateOrUpdateTemplateMutationVariables,
        TContext
    >(
        ["createOrUpdateTemplate"],
        (variables?: CreateOrUpdateTemplateMutationVariables) =>
            graphqlFetcher<
                CreateOrUpdateTemplateMutation,
                CreateOrUpdateTemplateMutationVariables
            >(CreateOrUpdateTemplateDocument, variables)(),
        options,
    );
export const DeleteTemplateDocument = `
    mutation deleteTemplate($id: EmailTemplateId!) {
  deleteTemplate(emailTemplateId: $id)
}
    `;
export const useDeleteTemplateMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteTemplateMutation,
        TError,
        DeleteTemplateMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteTemplateMutation,
        TError,
        DeleteTemplateMutationVariables,
        TContext
    >(
        ["deleteTemplate"],
        (variables?: DeleteTemplateMutationVariables) =>
            graphqlFetcher<DeleteTemplateMutation, DeleteTemplateMutationVariables>(
                DeleteTemplateDocument,
                variables,
            )(),
        options,
    );
export const GetTenantCredentialsDocument = `
    query getTenantCredentials {
  getTenantCredentials {
    id
    type
    status
    quickBooksDetails {
      realmId
    }
  }
}
    `;
export const useGetTenantCredentialsQuery = <
    TData = GetTenantCredentialsQuery,
    TError = unknown,
>(
    variables?: GetTenantCredentialsQueryVariables,
    options?: UseQueryOptions<GetTenantCredentialsQuery, TError, TData>,
) =>
    useQuery<GetTenantCredentialsQuery, TError, TData>(
        variables === undefined
            ? ["getTenantCredentials"]
            : ["getTenantCredentials", variables],
        graphqlFetcher<GetTenantCredentialsQuery, GetTenantCredentialsQueryVariables>(
            GetTenantCredentialsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetTenantCredentialsQuery = <
    TData = GetTenantCredentialsQuery,
    TError = unknown,
>(
    variables?: GetTenantCredentialsQueryVariables,
    options?: UseInfiniteQueryOptions<GetTenantCredentialsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetTenantCredentialsQuery, TError, TData>(
        variables === undefined
            ? ["getTenantCredentials.infinite"]
            : ["getTenantCredentials.infinite", variables],
        metaData =>
            graphqlFetcher<GetTenantCredentialsQuery, GetTenantCredentialsQueryVariables>(
                GetTenantCredentialsDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const DeleteTenantCredentialDocument = `
    mutation deleteTenantCredential($id: TenantCredentialId!) {
  deleteTenantCredential(id: $id)
}
    `;
export const useDeleteTenantCredentialMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteTenantCredentialMutation,
        TError,
        DeleteTenantCredentialMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteTenantCredentialMutation,
        TError,
        DeleteTenantCredentialMutationVariables,
        TContext
    >(
        ["deleteTenantCredential"],
        (variables?: DeleteTenantCredentialMutationVariables) =>
            graphqlFetcher<
                DeleteTenantCredentialMutation,
                DeleteTenantCredentialMutationVariables
            >(DeleteTenantCredentialDocument, variables)(),
        options,
    );
export const CreateTenantRegionDocument = `
    mutation createTenantRegion($tenantId: TenantId!, $name: String!, $key: String!) {
  createTenantRegion(tenantId: $tenantId, name: $name, key: $key) {
    id
  }
}
    `;
export const useCreateTenantRegionMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateTenantRegionMutation,
        TError,
        CreateTenantRegionMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateTenantRegionMutation,
        TError,
        CreateTenantRegionMutationVariables,
        TContext
    >(
        ["createTenantRegion"],
        (variables?: CreateTenantRegionMutationVariables) =>
            graphqlFetcher<
                CreateTenantRegionMutation,
                CreateTenantRegionMutationVariables
            >(CreateTenantRegionDocument, variables)(),
        options,
    );
export const CreateTenantDocument = `
    mutation createTenant {
  createTenant {
    id
  }
}
    `;
export const useCreateTenantMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateTenantMutation,
        TError,
        CreateTenantMutationVariables,
        TContext
    >,
) =>
    useMutation<CreateTenantMutation, TError, CreateTenantMutationVariables, TContext>(
        ["createTenant"],
        (variables?: CreateTenantMutationVariables) =>
            graphqlFetcher<CreateTenantMutation, CreateTenantMutationVariables>(
                CreateTenantDocument,
                variables,
            )(),
        options,
    );
export const UpdateTenantDocument = `
    mutation updateTenant($id: TenantId!, $name: String, $dateAdded: String, $features: [String!], $sku: String, $billing: TenantBillingPayloadInput, $customDomains: [String!], $activatedAt: String, $deactivatedAt: String, $onboardingStartedAt: String, $demoStartedAt: String, $demoEndedAt: String, $premiumActivatedAt: String, $premiumDeactivatedAt: String, $emailProvider: ExternalEmailProvider, $internalEmailSender: String, $supportEmailAddress: String, $customProductName: String, $customAppDomain: String, $whiteLabelContentMode: WhiteLabelContentMode) {
  updateTenant(
    id: $id
    name: $name
    dateAdded: $dateAdded
    features: $features
    sku: $sku
    billing: $billing
    customDomains: $customDomains
    activatedAt: $activatedAt
    deactivatedAt: $deactivatedAt
    onboardingStartedAt: $onboardingStartedAt
    demoStartedAt: $demoStartedAt
    demoEndedAt: $demoEndedAt
    premiumActivatedAt: $premiumActivatedAt
    premiumDeactivatedAt: $premiumDeactivatedAt
    emailProvider: $emailProvider
    internalEmailSender: $internalEmailSender
    supportEmailAddress: $supportEmailAddress
    customProductName: $customProductName
    customAppDomain: $customAppDomain
    whiteLabelContentMode: $whiteLabelContentMode
  ) {
    id
  }
}
    `;
export const useUpdateTenantMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpdateTenantMutation,
        TError,
        UpdateTenantMutationVariables,
        TContext
    >,
) =>
    useMutation<UpdateTenantMutation, TError, UpdateTenantMutationVariables, TContext>(
        ["updateTenant"],
        (variables?: UpdateTenantMutationVariables) =>
            graphqlFetcher<UpdateTenantMutation, UpdateTenantMutationVariables>(
                UpdateTenantDocument,
                variables,
            )(),
        options,
    );
export const AddTenantLogEntryDocument = `
    mutation addTenantLogEntry($tenantId: TenantId!, $message: String!) {
  tenantInternalUpdate(tenantId: $tenantId, messages: [$message])
}
    `;
export const useAddTenantLogEntryMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        AddTenantLogEntryMutation,
        TError,
        AddTenantLogEntryMutationVariables,
        TContext
    >,
) =>
    useMutation<
        AddTenantLogEntryMutation,
        TError,
        AddTenantLogEntryMutationVariables,
        TContext
    >(
        ["addTenantLogEntry"],
        (variables?: AddTenantLogEntryMutationVariables) =>
            graphqlFetcher<AddTenantLogEntryMutation, AddTenantLogEntryMutationVariables>(
                AddTenantLogEntryDocument,
                variables,
            )(),
        options,
    );
export const UpdateTenantInternalAttributesDocument = `
    mutation updateTenantInternalAttributes($tenantId: TenantId!, $internalAttributes: DictionaryInput!) {
  tenantInternalUpdate(
    tenantId: $tenantId
    internalAttributes: $internalAttributes
  )
}
    `;
export const useUpdateTenantInternalAttributesMutation = <
    TError = unknown,
    TContext = unknown,
>(
    options?: UseMutationOptions<
        UpdateTenantInternalAttributesMutation,
        TError,
        UpdateTenantInternalAttributesMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UpdateTenantInternalAttributesMutation,
        TError,
        UpdateTenantInternalAttributesMutationVariables,
        TContext
    >(
        ["updateTenantInternalAttributes"],
        (variables?: UpdateTenantInternalAttributesMutationVariables) =>
            graphqlFetcher<
                UpdateTenantInternalAttributesMutation,
                UpdateTenantInternalAttributesMutationVariables
            >(UpdateTenantInternalAttributesDocument, variables)(),
        options,
    );
export const UpdateRegionNameDocument = `
    mutation updateRegionName($tenantId: TenantId!, $regionId: RegionId!, $name: String!) {
  updateRegion(tenantId: $tenantId, regionId: $regionId, name: $name) {
    id
  }
}
    `;
export const useUpdateRegionNameMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpdateRegionNameMutation,
        TError,
        UpdateRegionNameMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UpdateRegionNameMutation,
        TError,
        UpdateRegionNameMutationVariables,
        TContext
    >(
        ["updateRegionName"],
        (variables?: UpdateRegionNameMutationVariables) =>
            graphqlFetcher<UpdateRegionNameMutation, UpdateRegionNameMutationVariables>(
                UpdateRegionNameDocument,
                variables,
            )(),
        options,
    );
export const ModifyUserRoleDocument = `
    mutation modifyUserRole($tenantId: TenantId!, $userId: UserId!, $regionKey: ID!, $role: String!) {
  modifyUserRole(
    tenantId: $tenantId
    userId: $userId
    regionKey: $regionKey
    role: $role
  )
}
    `;
export const useModifyUserRoleMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        ModifyUserRoleMutation,
        TError,
        ModifyUserRoleMutationVariables,
        TContext
    >,
) =>
    useMutation<
        ModifyUserRoleMutation,
        TError,
        ModifyUserRoleMutationVariables,
        TContext
    >(
        ["modifyUserRole"],
        (variables?: ModifyUserRoleMutationVariables) =>
            graphqlFetcher<ModifyUserRoleMutation, ModifyUserRoleMutationVariables>(
                ModifyUserRoleDocument,
                variables,
            )(),
        options,
    );
export const GetTenantsDocument = `
    query getTenants {
  getTenants {
    id
    name
    activeUsers
    activeHumanUsers
    sku
  }
}
    `;
export const useGetTenantsQuery = <TData = GetTenantsQuery, TError = unknown>(
    variables?: GetTenantsQueryVariables,
    options?: UseQueryOptions<GetTenantsQuery, TError, TData>,
) =>
    useQuery<GetTenantsQuery, TError, TData>(
        variables === undefined ? ["getTenants"] : ["getTenants", variables],
        graphqlFetcher<GetTenantsQuery, GetTenantsQueryVariables>(
            GetTenantsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetTenantsQuery = <TData = GetTenantsQuery, TError = unknown>(
    variables?: GetTenantsQueryVariables,
    options?: UseInfiniteQueryOptions<GetTenantsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetTenantsQuery, TError, TData>(
        variables === undefined
            ? ["getTenants.infinite"]
            : ["getTenants.infinite", variables],
        metaData =>
            graphqlFetcher<GetTenantsQuery, GetTenantsQueryVariables>(
                GetTenantsDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetTenantDocument = `
    query getTenant($id: TenantId!) {
  getTenant(tenantId: $id) {
    id
    name
    dateAdded
    sku
    activatedAt
    deactivatedAt
    onboardingStartedAt
    demoStartedAt
    demoEndedAt
    premiumActivatedAt
    premiumDeactivatedAt
    billing {
      billingContacts
      effectiveMonthlyRateCents
      monthlyRateCents
      perLocationRateCents
      perUserRateCents
      ratePlan
      ratePlanEstablished
    }
    customDomains
    emailProvider
    internalEmailSender
    supportEmailAddress
    customProductName
    customAppDomain
    whiteLabelContentMode
    users {
      id
      name
      email
      disabled
      hidden
    }
    features {
      name
      enabled
      enabledUpstream
    }
    regions {
      id
      name
      key
      permissions {
        role
        user {
          id
          name
          email
          disabled
          hidden
        }
      }
    }
    internalAttributes {
      entries {
        key
        value
      }
    }
  }
  getGlobalFeatures {
    skus
    features {
      actual {
        name
        enabled
      }
      skus
      key
      friendlyTitle
      description
      createdOn
      intent
    }
  }
}
    `;
export const useGetTenantQuery = <TData = GetTenantQuery, TError = unknown>(
    variables: GetTenantQueryVariables,
    options?: UseQueryOptions<GetTenantQuery, TError, TData>,
) =>
    useQuery<GetTenantQuery, TError, TData>(
        ["getTenant", variables],
        graphqlFetcher<GetTenantQuery, GetTenantQueryVariables>(
            GetTenantDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetTenantQuery = <TData = GetTenantQuery, TError = unknown>(
    variables: GetTenantQueryVariables,
    options?: UseInfiniteQueryOptions<GetTenantQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetTenantQuery, TError, TData>(
        ["getTenant.infinite", variables],
        metaData =>
            graphqlFetcher<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetTenantHistoryDocument = `
    query getTenantHistory($tenantId: TenantId!) {
  getTenantHistory(tenantId: $tenantId) {
    id
    message
    dateAdded
    author {
      preferredName
    }
  }
}
    `;
export const useGetTenantHistoryQuery = <TData = GetTenantHistoryQuery, TError = unknown>(
    variables: GetTenantHistoryQueryVariables,
    options?: UseQueryOptions<GetTenantHistoryQuery, TError, TData>,
) =>
    useQuery<GetTenantHistoryQuery, TError, TData>(
        ["getTenantHistory", variables],
        graphqlFetcher<GetTenantHistoryQuery, GetTenantHistoryQueryVariables>(
            GetTenantHistoryDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetTenantHistoryQuery = <
    TData = GetTenantHistoryQuery,
    TError = unknown,
>(
    variables: GetTenantHistoryQueryVariables,
    options?: UseInfiniteQueryOptions<GetTenantHistoryQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetTenantHistoryQuery, TError, TData>(
        ["getTenantHistory.infinite", variables],
        metaData =>
            graphqlFetcher<GetTenantHistoryQuery, GetTenantHistoryQueryVariables>(
                GetTenantHistoryDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetImportsDocument = `
    query getImports($tenantId: TenantId!) {
  getImports(tenantId: $tenantId) {
    id
    name
    status
    dateAdded
    runAs
    regionId
  }
}
    `;
export const useGetImportsQuery = <TData = GetImportsQuery, TError = unknown>(
    variables: GetImportsQueryVariables,
    options?: UseQueryOptions<GetImportsQuery, TError, TData>,
) =>
    useQuery<GetImportsQuery, TError, TData>(
        ["getImports", variables],
        graphqlFetcher<GetImportsQuery, GetImportsQueryVariables>(
            GetImportsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetImportsQuery = <TData = GetImportsQuery, TError = unknown>(
    variables: GetImportsQueryVariables,
    options?: UseInfiniteQueryOptions<GetImportsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetImportsQuery, TError, TData>(
        ["getImports.infinite", variables],
        metaData =>
            graphqlFetcher<GetImportsQuery, GetImportsQueryVariables>(
                GetImportsDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetImportDocument = `
    query getImport($tenantId: TenantId!, $importId: ImportId!) {
  getImport(tenantId: $tenantId, importId: $importId) {
    id
    name
    status
    dateAdded
    runAs
    regionId
    plan {
      inserts {
        entityType
        records
      }
      errors {
        rowNumber
        message
        sheetName
      }
    }
    result {
      results {
        entityType
        inserts {
          rowNumber
          insertedId
          warnings
        }
        errors {
          rowNumber
          message
          sheetName
        }
      }
      error
    }
  }
}
    `;
export const useGetImportQuery = <TData = GetImportQuery, TError = unknown>(
    variables: GetImportQueryVariables,
    options?: UseQueryOptions<GetImportQuery, TError, TData>,
) =>
    useQuery<GetImportQuery, TError, TData>(
        ["getImport", variables],
        graphqlFetcher<GetImportQuery, GetImportQueryVariables>(
            GetImportDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetImportQuery = <TData = GetImportQuery, TError = unknown>(
    variables: GetImportQueryVariables,
    options?: UseInfiniteQueryOptions<GetImportQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetImportQuery, TError, TData>(
        ["getImport.infinite", variables],
        metaData =>
            graphqlFetcher<GetImportQuery, GetImportQueryVariables>(GetImportDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const ScheduleImportDocument = `
    mutation scheduleImport($tenantId: TenantId!, $importId: ImportId!) {
  scheduleImport(tenantId: $tenantId, importId: $importId) {
    id
    status
  }
}
    `;
export const useScheduleImportMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        ScheduleImportMutation,
        TError,
        ScheduleImportMutationVariables,
        TContext
    >,
) =>
    useMutation<
        ScheduleImportMutation,
        TError,
        ScheduleImportMutationVariables,
        TContext
    >(
        ["scheduleImport"],
        (variables?: ScheduleImportMutationVariables) =>
            graphqlFetcher<ScheduleImportMutation, ScheduleImportMutationVariables>(
                ScheduleImportDocument,
                variables,
            )(),
        options,
    );
export const ResetTenantDocument = `
    mutation resetTenant($options: ResetTenantOptions!) {
  resetTenant(options: $options)
}
    `;
export const useResetTenantMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        ResetTenantMutation,
        TError,
        ResetTenantMutationVariables,
        TContext
    >,
) =>
    useMutation<ResetTenantMutation, TError, ResetTenantMutationVariables, TContext>(
        ["resetTenant"],
        (variables?: ResetTenantMutationVariables) =>
            graphqlFetcher<ResetTenantMutation, ResetTenantMutationVariables>(
                ResetTenantDocument,
                variables,
            )(),
        options,
    );
export const GetTokenForClientDocument = `
    query getTokenForClient($id: ClientId!) {
  getTokenForClient(clientId: $id) {
    id
    clientId
    url
    includeContacts
    includeFinancial
    includeLicensing
    sectionKeys
  }
}
    `;
export const useGetTokenForClientQuery = <
    TData = GetTokenForClientQuery,
    TError = unknown,
>(
    variables: GetTokenForClientQueryVariables,
    options?: UseQueryOptions<GetTokenForClientQuery, TError, TData>,
) =>
    useQuery<GetTokenForClientQuery, TError, TData>(
        ["getTokenForClient", variables],
        graphqlFetcher<GetTokenForClientQuery, GetTokenForClientQueryVariables>(
            GetTokenForClientDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetTokenForClientQuery = <
    TData = GetTokenForClientQuery,
    TError = unknown,
>(
    variables: GetTokenForClientQueryVariables,
    options?: UseInfiniteQueryOptions<GetTokenForClientQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetTokenForClientQuery, TError, TData>(
        ["getTokenForClient.infinite", variables],
        metaData =>
            graphqlFetcher<GetTokenForClientQuery, GetTokenForClientQueryVariables>(
                GetTokenForClientDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const UpdateTokenDocument = `
    mutation updateToken($updateRequest: UpdateTokenRequest!) {
  updateToken(updateRequest: $updateRequest) {
    id
    clientId
    url
    includeContacts
    includeFinancial
    includeLicensing
    sectionKeys
  }
}
    `;
export const useUpdateTokenMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpdateTokenMutation,
        TError,
        UpdateTokenMutationVariables,
        TContext
    >,
) =>
    useMutation<UpdateTokenMutation, TError, UpdateTokenMutationVariables, TContext>(
        ["updateToken"],
        (variables?: UpdateTokenMutationVariables) =>
            graphqlFetcher<UpdateTokenMutation, UpdateTokenMutationVariables>(
                UpdateTokenDocument,
                variables,
            )(),
        options,
    );
export const GetUserGroupsDocument = `
    query getUserGroups {
  getUserGroups {
    id
    name
  }
}
    `;
export const useGetUserGroupsQuery = <TData = GetUserGroupsQuery, TError = unknown>(
    variables?: GetUserGroupsQueryVariables,
    options?: UseQueryOptions<GetUserGroupsQuery, TError, TData>,
) =>
    useQuery<GetUserGroupsQuery, TError, TData>(
        variables === undefined ? ["getUserGroups"] : ["getUserGroups", variables],
        graphqlFetcher<GetUserGroupsQuery, GetUserGroupsQueryVariables>(
            GetUserGroupsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetUserGroupsQuery = <
    TData = GetUserGroupsQuery,
    TError = unknown,
>(
    variables?: GetUserGroupsQueryVariables,
    options?: UseInfiniteQueryOptions<GetUserGroupsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetUserGroupsQuery, TError, TData>(
        variables === undefined
            ? ["getUserGroups.infinite"]
            : ["getUserGroups.infinite", variables],
        metaData =>
            graphqlFetcher<GetUserGroupsQuery, GetUserGroupsQueryVariables>(
                GetUserGroupsDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const UserManagementGetRolesDocument = `
    query userManagementGetRoles {
  userManagementGetRoles {
    key
    humanReadableName
  }
}
    `;
export const useUserManagementGetRolesQuery = <
    TData = UserManagementGetRolesQuery,
    TError = unknown,
>(
    variables?: UserManagementGetRolesQueryVariables,
    options?: UseQueryOptions<UserManagementGetRolesQuery, TError, TData>,
) =>
    useQuery<UserManagementGetRolesQuery, TError, TData>(
        variables === undefined
            ? ["userManagementGetRoles"]
            : ["userManagementGetRoles", variables],
        graphqlFetcher<UserManagementGetRolesQuery, UserManagementGetRolesQueryVariables>(
            UserManagementGetRolesDocument,
            variables,
        ),
        options,
    );
export const useInfiniteUserManagementGetRolesQuery = <
    TData = UserManagementGetRolesQuery,
    TError = unknown,
>(
    variables?: UserManagementGetRolesQueryVariables,
    options?: UseInfiniteQueryOptions<UserManagementGetRolesQuery, TError, TData>,
) => {
    return useInfiniteQuery<UserManagementGetRolesQuery, TError, TData>(
        variables === undefined
            ? ["userManagementGetRoles.infinite"]
            : ["userManagementGetRoles.infinite", variables],
        metaData =>
            graphqlFetcher<
                UserManagementGetRolesQuery,
                UserManagementGetRolesQueryVariables
            >(UserManagementGetRolesDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const UserManagementGetUsersDocument = `
    query userManagementGetUsers {
  userManagementGetUsers(params: {userTypes: [User]}) {
    user {
      id
      type
      email
      name
      hidden
      disabled
      permissions {
        role
        region {
          id
          name
          key
        }
      }
    }
  }
}
    `;
export const useUserManagementGetUsersQuery = <
    TData = UserManagementGetUsersQuery,
    TError = unknown,
>(
    variables?: UserManagementGetUsersQueryVariables,
    options?: UseQueryOptions<UserManagementGetUsersQuery, TError, TData>,
) =>
    useQuery<UserManagementGetUsersQuery, TError, TData>(
        variables === undefined
            ? ["userManagementGetUsers"]
            : ["userManagementGetUsers", variables],
        graphqlFetcher<UserManagementGetUsersQuery, UserManagementGetUsersQueryVariables>(
            UserManagementGetUsersDocument,
            variables,
        ),
        options,
    );
export const useInfiniteUserManagementGetUsersQuery = <
    TData = UserManagementGetUsersQuery,
    TError = unknown,
>(
    variables?: UserManagementGetUsersQueryVariables,
    options?: UseInfiniteQueryOptions<UserManagementGetUsersQuery, TError, TData>,
) => {
    return useInfiniteQuery<UserManagementGetUsersQuery, TError, TData>(
        variables === undefined
            ? ["userManagementGetUsers.infinite"]
            : ["userManagementGetUsers.infinite", variables],
        metaData =>
            graphqlFetcher<
                UserManagementGetUsersQuery,
                UserManagementGetUsersQueryVariables
            >(UserManagementGetUsersDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const UserManagementGetIntegrationUsersDocument = `
    query userManagementGetIntegrationUsers {
  userManagementGetUsers(params: {userTypes: [System]}) {
    user {
      id
      type
      name
      hidden
      disabled
      permissions {
        role
        region {
          id
          name
          key
        }
      }
    }
  }
}
    `;
export const useUserManagementGetIntegrationUsersQuery = <
    TData = UserManagementGetIntegrationUsersQuery,
    TError = unknown,
>(
    variables?: UserManagementGetIntegrationUsersQueryVariables,
    options?: UseQueryOptions<UserManagementGetIntegrationUsersQuery, TError, TData>,
) =>
    useQuery<UserManagementGetIntegrationUsersQuery, TError, TData>(
        variables === undefined
            ? ["userManagementGetIntegrationUsers"]
            : ["userManagementGetIntegrationUsers", variables],
        graphqlFetcher<
            UserManagementGetIntegrationUsersQuery,
            UserManagementGetIntegrationUsersQueryVariables
        >(UserManagementGetIntegrationUsersDocument, variables),
        options,
    );
export const useInfiniteUserManagementGetIntegrationUsersQuery = <
    TData = UserManagementGetIntegrationUsersQuery,
    TError = unknown,
>(
    variables?: UserManagementGetIntegrationUsersQueryVariables,
    options?: UseInfiniteQueryOptions<
        UserManagementGetIntegrationUsersQuery,
        TError,
        TData
    >,
) => {
    return useInfiniteQuery<UserManagementGetIntegrationUsersQuery, TError, TData>(
        variables === undefined
            ? ["userManagementGetIntegrationUsers.infinite"]
            : ["userManagementGetIntegrationUsers.infinite", variables],
        metaData =>
            graphqlFetcher<
                UserManagementGetIntegrationUsersQuery,
                UserManagementGetIntegrationUsersQueryVariables
            >(UserManagementGetIntegrationUsersDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const UserManagementGetUserDocument = `
    query userManagementGetUser($userId: UserId!) {
  userManagementGetUser(userId: $userId) {
    canDisable
    user {
      id
      email
      name
      type
      hidden
      disabled
      permissions {
        role
        region {
          id
          name
          key
        }
      }
    }
  }
}
    `;
export const useUserManagementGetUserQuery = <
    TData = UserManagementGetUserQuery,
    TError = unknown,
>(
    variables: UserManagementGetUserQueryVariables,
    options?: UseQueryOptions<UserManagementGetUserQuery, TError, TData>,
) =>
    useQuery<UserManagementGetUserQuery, TError, TData>(
        ["userManagementGetUser", variables],
        graphqlFetcher<UserManagementGetUserQuery, UserManagementGetUserQueryVariables>(
            UserManagementGetUserDocument,
            variables,
        ),
        options,
    );
export const useInfiniteUserManagementGetUserQuery = <
    TData = UserManagementGetUserQuery,
    TError = unknown,
>(
    variables: UserManagementGetUserQueryVariables,
    options?: UseInfiniteQueryOptions<UserManagementGetUserQuery, TError, TData>,
) => {
    return useInfiniteQuery<UserManagementGetUserQuery, TError, TData>(
        ["userManagementGetUser.infinite", variables],
        metaData =>
            graphqlFetcher<
                UserManagementGetUserQuery,
                UserManagementGetUserQueryVariables
            >(UserManagementGetUserDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const UserManagementGetApiKeyDocument = `
    query userManagementGetApiKey($userId: UserId!) {
  userManagementGetApiKey(userId: $userId) {
    apiKey
  }
}
    `;
export const useUserManagementGetApiKeyQuery = <
    TData = UserManagementGetApiKeyQuery,
    TError = unknown,
>(
    variables: UserManagementGetApiKeyQueryVariables,
    options?: UseQueryOptions<UserManagementGetApiKeyQuery, TError, TData>,
) =>
    useQuery<UserManagementGetApiKeyQuery, TError, TData>(
        ["userManagementGetApiKey", variables],
        graphqlFetcher<
            UserManagementGetApiKeyQuery,
            UserManagementGetApiKeyQueryVariables
        >(UserManagementGetApiKeyDocument, variables),
        options,
    );
export const useInfiniteUserManagementGetApiKeyQuery = <
    TData = UserManagementGetApiKeyQuery,
    TError = unknown,
>(
    variables: UserManagementGetApiKeyQueryVariables,
    options?: UseInfiniteQueryOptions<UserManagementGetApiKeyQuery, TError, TData>,
) => {
    return useInfiniteQuery<UserManagementGetApiKeyQuery, TError, TData>(
        ["userManagementGetApiKey.infinite", variables],
        metaData =>
            graphqlFetcher<
                UserManagementGetApiKeyQuery,
                UserManagementGetApiKeyQueryVariables
            >(UserManagementGetApiKeyDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const UserManagementCreateUserDocument = `
    mutation userManagementCreateUser($userEmail: String!, $roleKey: String!, $regionId: RegionId!) {
  userManagementCreateUser(
    userEmail: $userEmail
    roleKey: $roleKey
    regionId: $regionId
  )
}
    `;
export const useUserManagementCreateUserMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UserManagementCreateUserMutation,
        TError,
        UserManagementCreateUserMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UserManagementCreateUserMutation,
        TError,
        UserManagementCreateUserMutationVariables,
        TContext
    >(
        ["userManagementCreateUser"],
        (variables?: UserManagementCreateUserMutationVariables) =>
            graphqlFetcher<
                UserManagementCreateUserMutation,
                UserManagementCreateUserMutationVariables
            >(UserManagementCreateUserDocument, variables)(),
        options,
    );
export const UserManagementCreateSystemUserDocument = `
    mutation userManagementCreateSystemUser($name: String!, $roleKey: String!, $regionId: RegionId!) {
  userManagementCreateSystemUser(
    name: $name
    roleKey: $roleKey
    regionId: $regionId
  )
}
    `;
export const useUserManagementCreateSystemUserMutation = <
    TError = unknown,
    TContext = unknown,
>(
    options?: UseMutationOptions<
        UserManagementCreateSystemUserMutation,
        TError,
        UserManagementCreateSystemUserMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UserManagementCreateSystemUserMutation,
        TError,
        UserManagementCreateSystemUserMutationVariables,
        TContext
    >(
        ["userManagementCreateSystemUser"],
        (variables?: UserManagementCreateSystemUserMutationVariables) =>
            graphqlFetcher<
                UserManagementCreateSystemUserMutation,
                UserManagementCreateSystemUserMutationVariables
            >(UserManagementCreateSystemUserDocument, variables)(),
        options,
    );
export const UserManagementUpdatePermissionDocument = `
    mutation userManagementUpdatePermission($userId: UserId!, $regionId: RegionId!, $roleKey: String) {
  userManagementUpdatePermission(
    userId: $userId
    regionId: $regionId
    roleKey: $roleKey
  )
}
    `;
export const useUserManagementUpdatePermissionMutation = <
    TError = unknown,
    TContext = unknown,
>(
    options?: UseMutationOptions<
        UserManagementUpdatePermissionMutation,
        TError,
        UserManagementUpdatePermissionMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UserManagementUpdatePermissionMutation,
        TError,
        UserManagementUpdatePermissionMutationVariables,
        TContext
    >(
        ["userManagementUpdatePermission"],
        (variables?: UserManagementUpdatePermissionMutationVariables) =>
            graphqlFetcher<
                UserManagementUpdatePermissionMutation,
                UserManagementUpdatePermissionMutationVariables
            >(UserManagementUpdatePermissionDocument, variables)(),
        options,
    );
export const UserManagementDisableUserDocument = `
    mutation userManagementDisableUser($userId: UserId!) {
  userManagementDisableUser(userId: $userId)
}
    `;
export const useUserManagementDisableUserMutation = <
    TError = unknown,
    TContext = unknown,
>(
    options?: UseMutationOptions<
        UserManagementDisableUserMutation,
        TError,
        UserManagementDisableUserMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UserManagementDisableUserMutation,
        TError,
        UserManagementDisableUserMutationVariables,
        TContext
    >(
        ["userManagementDisableUser"],
        (variables?: UserManagementDisableUserMutationVariables) =>
            graphqlFetcher<
                UserManagementDisableUserMutation,
                UserManagementDisableUserMutationVariables
            >(UserManagementDisableUserDocument, variables)(),
        options,
    );
export const UserManagementEnableUserDocument = `
    mutation userManagementEnableUser($userId: UserId!) {
  userManagementEnableUser(userId: $userId)
}
    `;
export const useUserManagementEnableUserMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UserManagementEnableUserMutation,
        TError,
        UserManagementEnableUserMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UserManagementEnableUserMutation,
        TError,
        UserManagementEnableUserMutationVariables,
        TContext
    >(
        ["userManagementEnableUser"],
        (variables?: UserManagementEnableUserMutationVariables) =>
            graphqlFetcher<
                UserManagementEnableUserMutation,
                UserManagementEnableUserMutationVariables
            >(UserManagementEnableUserDocument, variables)(),
        options,
    );
export const UserManagementHideUserDocument = `
    mutation userManagementHideUser($userId: UserId!) {
  userManagementHideUser(userId: $userId)
}
    `;
export const useUserManagementHideUserMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UserManagementHideUserMutation,
        TError,
        UserManagementHideUserMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UserManagementHideUserMutation,
        TError,
        UserManagementHideUserMutationVariables,
        TContext
    >(
        ["userManagementHideUser"],
        (variables?: UserManagementHideUserMutationVariables) =>
            graphqlFetcher<
                UserManagementHideUserMutation,
                UserManagementHideUserMutationVariables
            >(UserManagementHideUserDocument, variables)(),
        options,
    );
export const UserManagementShowUserDocument = `
    mutation userManagementShowUser($userId: UserId!) {
  userManagementShowUser(userId: $userId)
}
    `;
export const useUserManagementShowUserMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UserManagementShowUserMutation,
        TError,
        UserManagementShowUserMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UserManagementShowUserMutation,
        TError,
        UserManagementShowUserMutationVariables,
        TContext
    >(
        ["userManagementShowUser"],
        (variables?: UserManagementShowUserMutationVariables) =>
            graphqlFetcher<
                UserManagementShowUserMutation,
                UserManagementShowUserMutationVariables
            >(UserManagementShowUserDocument, variables)(),
        options,
    );
export const GetUsersDocument = `
    query getUsers {
  getUsers {
    id
    email
    type
    name
    firstName
    disabled
    hidden
  }
}
    `;
export const useGetUsersQuery = <TData = GetUsersQuery, TError = unknown>(
    variables?: GetUsersQueryVariables,
    options?: UseQueryOptions<GetUsersQuery, TError, TData>,
) =>
    useQuery<GetUsersQuery, TError, TData>(
        variables === undefined ? ["getUsers"] : ["getUsers", variables],
        graphqlFetcher<GetUsersQuery, GetUsersQueryVariables>(
            GetUsersDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetUsersQuery = <TData = GetUsersQuery, TError = unknown>(
    variables?: GetUsersQueryVariables,
    options?: UseInfiniteQueryOptions<GetUsersQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetUsersQuery, TError, TData>(
        variables === undefined
            ? ["getUsers.infinite"]
            : ["getUsers.infinite", variables],
        metaData =>
            graphqlFetcher<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const CreateUserDocument = `
    mutation createUser($tenantId: TenantId!, $email: String!, $password: String!) {
  createUser(tenantId: $tenantId, email: $email, password: $password) {
    id
    email
  }
}
    `;
export const useCreateUserMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateUserMutation,
        TError,
        CreateUserMutationVariables,
        TContext
    >,
) =>
    useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>(
        ["createUser"],
        (variables?: CreateUserMutationVariables) =>
            graphqlFetcher<CreateUserMutation, CreateUserMutationVariables>(
                CreateUserDocument,
                variables,
            )(),
        options,
    );
export const CloneUserDocument = `
    mutation cloneUser($tenantId: TenantId!, $userId: UserId!) {
  cloneUser(tenantId: $tenantId, userId: $userId)
}
    `;
export const useCloneUserMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CloneUserMutation,
        TError,
        CloneUserMutationVariables,
        TContext
    >,
) =>
    useMutation<CloneUserMutation, TError, CloneUserMutationVariables, TContext>(
        ["cloneUser"],
        (variables?: CloneUserMutationVariables) =>
            graphqlFetcher<CloneUserMutation, CloneUserMutationVariables>(
                CloneUserDocument,
                variables,
            )(),
        options,
    );
export const SuperuserUpdateUserDocument = `
    mutation superuserUpdateUser($userId: UserId!, $disabled: Boolean!, $hidden: Boolean!, $email: String!, $firstName: String, $name: String) {
  superuserUpdateUser(
    userId: $userId
    disabled: $disabled
    hidden: $hidden
    email: $email
    firstName: $firstName
    name: $name
  ) {
    id
  }
}
    `;
export const useSuperuserUpdateUserMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        SuperuserUpdateUserMutation,
        TError,
        SuperuserUpdateUserMutationVariables,
        TContext
    >,
) =>
    useMutation<
        SuperuserUpdateUserMutation,
        TError,
        SuperuserUpdateUserMutationVariables,
        TContext
    >(
        ["superuserUpdateUser"],
        (variables?: SuperuserUpdateUserMutationVariables) =>
            graphqlFetcher<
                SuperuserUpdateUserMutation,
                SuperuserUpdateUserMutationVariables
            >(SuperuserUpdateUserDocument, variables)(),
        options,
    );
export const AdminUpdateUserDocument = `
    mutation adminUpdateUser($userId: UserId!, $name: String!) {
  adminUpdateUser(userId: $userId, name: $name) {
    id
  }
}
    `;
export const useAdminUpdateUserMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        AdminUpdateUserMutation,
        TError,
        AdminUpdateUserMutationVariables,
        TContext
    >,
) =>
    useMutation<
        AdminUpdateUserMutation,
        TError,
        AdminUpdateUserMutationVariables,
        TContext
    >(
        ["adminUpdateUser"],
        (variables?: AdminUpdateUserMutationVariables) =>
            graphqlFetcher<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>(
                AdminUpdateUserDocument,
                variables,
            )(),
        options,
    );
export const GetUserDocument = `
    query getUser($id: UserId!) {
  getUser(userId: $id) {
    id
    name
    firstName
    type
    email
    disabled
    hidden
    token
    tenant {
      id
      name
      regions {
        key
        name
        permissions {
          role
          userId
        }
      }
      features {
        name
        enabled
        enabledUpstream
      }
    }
  }
}
    `;
export const useGetUserQuery = <TData = GetUserQuery, TError = unknown>(
    variables: GetUserQueryVariables,
    options?: UseQueryOptions<GetUserQuery, TError, TData>,
) =>
    useQuery<GetUserQuery, TError, TData>(
        ["getUser", variables],
        graphqlFetcher<GetUserQuery, GetUserQueryVariables>(GetUserDocument, variables),
        options,
    );
export const useInfiniteGetUserQuery = <TData = GetUserQuery, TError = unknown>(
    variables: GetUserQueryVariables,
    options?: UseInfiniteQueryOptions<GetUserQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetUserQuery, TError, TData>(
        ["getUser.infinite", variables],
        metaData =>
            graphqlFetcher<GetUserQuery, GetUserQueryVariables>(GetUserDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetUserApiTokenDocument = `
    query getUserApiToken($id: UserId!) {
  getUser(userId: $id) {
    id
    apiToken
  }
}
    `;
export const useGetUserApiTokenQuery = <TData = GetUserApiTokenQuery, TError = unknown>(
    variables: GetUserApiTokenQueryVariables,
    options?: UseQueryOptions<GetUserApiTokenQuery, TError, TData>,
) =>
    useQuery<GetUserApiTokenQuery, TError, TData>(
        ["getUserApiToken", variables],
        graphqlFetcher<GetUserApiTokenQuery, GetUserApiTokenQueryVariables>(
            GetUserApiTokenDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetUserApiTokenQuery = <
    TData = GetUserApiTokenQuery,
    TError = unknown,
>(
    variables: GetUserApiTokenQueryVariables,
    options?: UseInfiniteQueryOptions<GetUserApiTokenQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetUserApiTokenQuery, TError, TData>(
        ["getUserApiToken.infinite", variables],
        metaData =>
            graphqlFetcher<GetUserApiTokenQuery, GetUserApiTokenQueryVariables>(
                GetUserApiTokenDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetRolesWithActionsDocument = `
    query getRolesWithActions {
  getRolesWithActions {
    key
    humanReadableName
    userAssignable
    actions
  }
}
    `;
export const useGetRolesWithActionsQuery = <
    TData = GetRolesWithActionsQuery,
    TError = unknown,
>(
    variables?: GetRolesWithActionsQueryVariables,
    options?: UseQueryOptions<GetRolesWithActionsQuery, TError, TData>,
) =>
    useQuery<GetRolesWithActionsQuery, TError, TData>(
        variables === undefined
            ? ["getRolesWithActions"]
            : ["getRolesWithActions", variables],
        graphqlFetcher<GetRolesWithActionsQuery, GetRolesWithActionsQueryVariables>(
            GetRolesWithActionsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetRolesWithActionsQuery = <
    TData = GetRolesWithActionsQuery,
    TError = unknown,
>(
    variables?: GetRolesWithActionsQueryVariables,
    options?: UseInfiniteQueryOptions<GetRolesWithActionsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetRolesWithActionsQuery, TError, TData>(
        variables === undefined
            ? ["getRolesWithActions.infinite"]
            : ["getRolesWithActions.infinite", variables],
        metaData =>
            graphqlFetcher<GetRolesWithActionsQuery, GetRolesWithActionsQueryVariables>(
                GetRolesWithActionsDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetCalendarSubscriptionDocument = `
    query getCalendarSubscription {
  getCalendarSubscription {
    calendarUrl
  }
}
    `;
export const useGetCalendarSubscriptionQuery = <
    TData = GetCalendarSubscriptionQuery,
    TError = unknown,
>(
    variables?: GetCalendarSubscriptionQueryVariables,
    options?: UseQueryOptions<GetCalendarSubscriptionQuery, TError, TData>,
) =>
    useQuery<GetCalendarSubscriptionQuery, TError, TData>(
        variables === undefined
            ? ["getCalendarSubscription"]
            : ["getCalendarSubscription", variables],
        graphqlFetcher<
            GetCalendarSubscriptionQuery,
            GetCalendarSubscriptionQueryVariables
        >(GetCalendarSubscriptionDocument, variables),
        options,
    );
export const useInfiniteGetCalendarSubscriptionQuery = <
    TData = GetCalendarSubscriptionQuery,
    TError = unknown,
>(
    variables?: GetCalendarSubscriptionQueryVariables,
    options?: UseInfiniteQueryOptions<GetCalendarSubscriptionQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetCalendarSubscriptionQuery, TError, TData>(
        variables === undefined
            ? ["getCalendarSubscription.infinite"]
            : ["getCalendarSubscription.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetCalendarSubscriptionQuery,
                GetCalendarSubscriptionQueryVariables
            >(GetCalendarSubscriptionDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetUserTenantRegionOptionsDocument = `
    query getUserTenantRegionOptions {
  getUserTenantRegionOptions {
    options {
      regionId
      regionName
      regionKey
      tenantId
      tenantName
      userId
    }
  }
}
    `;
export const useGetUserTenantRegionOptionsQuery = <
    TData = GetUserTenantRegionOptionsQuery,
    TError = unknown,
>(
    variables?: GetUserTenantRegionOptionsQueryVariables,
    options?: UseQueryOptions<GetUserTenantRegionOptionsQuery, TError, TData>,
) =>
    useQuery<GetUserTenantRegionOptionsQuery, TError, TData>(
        variables === undefined
            ? ["getUserTenantRegionOptions"]
            : ["getUserTenantRegionOptions", variables],
        graphqlFetcher<
            GetUserTenantRegionOptionsQuery,
            GetUserTenantRegionOptionsQueryVariables
        >(GetUserTenantRegionOptionsDocument, variables),
        options,
    );
export const useInfiniteGetUserTenantRegionOptionsQuery = <
    TData = GetUserTenantRegionOptionsQuery,
    TError = unknown,
>(
    variables?: GetUserTenantRegionOptionsQueryVariables,
    options?: UseInfiniteQueryOptions<GetUserTenantRegionOptionsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetUserTenantRegionOptionsQuery, TError, TData>(
        variables === undefined
            ? ["getUserTenantRegionOptions.infinite"]
            : ["getUserTenantRegionOptions.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetUserTenantRegionOptionsQuery,
                GetUserTenantRegionOptionsQueryVariables
            >(GetUserTenantRegionOptionsDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetUsersForRegionDocument = `
    query getUsersForRegion($regionId: RegionId!) {
  getUsersForRegion(regionId: $regionId) {
    id
    disabled
    hidden
    preferredName
  }
}
    `;
export const useGetUsersForRegionQuery = <
    TData = GetUsersForRegionQuery,
    TError = unknown,
>(
    variables: GetUsersForRegionQueryVariables,
    options?: UseQueryOptions<GetUsersForRegionQuery, TError, TData>,
) =>
    useQuery<GetUsersForRegionQuery, TError, TData>(
        ["getUsersForRegion", variables],
        graphqlFetcher<GetUsersForRegionQuery, GetUsersForRegionQueryVariables>(
            GetUsersForRegionDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetUsersForRegionQuery = <
    TData = GetUsersForRegionQuery,
    TError = unknown,
>(
    variables: GetUsersForRegionQueryVariables,
    options?: UseInfiniteQueryOptions<GetUsersForRegionQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetUsersForRegionQuery, TError, TData>(
        ["getUsersForRegion.infinite", variables],
        metaData =>
            graphqlFetcher<GetUsersForRegionQuery, GetUsersForRegionQueryVariables>(
                GetUsersForRegionDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetWorkflowsForTenantDocument = `
    query getWorkflowsForTenant($tenantId: TenantId!) {
  getWorkflowsForTenant(tenantId: $tenantId) {
    ...WorkflowDefaults
  }
}
    ${WorkflowDefaultsFragmentDoc}`;
export const useGetWorkflowsForTenantQuery = <
    TData = GetWorkflowsForTenantQuery,
    TError = unknown,
>(
    variables: GetWorkflowsForTenantQueryVariables,
    options?: UseQueryOptions<GetWorkflowsForTenantQuery, TError, TData>,
) =>
    useQuery<GetWorkflowsForTenantQuery, TError, TData>(
        ["getWorkflowsForTenant", variables],
        graphqlFetcher<GetWorkflowsForTenantQuery, GetWorkflowsForTenantQueryVariables>(
            GetWorkflowsForTenantDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetWorkflowsForTenantQuery = <
    TData = GetWorkflowsForTenantQuery,
    TError = unknown,
>(
    variables: GetWorkflowsForTenantQueryVariables,
    options?: UseInfiniteQueryOptions<GetWorkflowsForTenantQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetWorkflowsForTenantQuery, TError, TData>(
        ["getWorkflowsForTenant.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetWorkflowsForTenantQuery,
                GetWorkflowsForTenantQueryVariables
            >(GetWorkflowsForTenantDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const GetWorkflowsDocument = `
    query getWorkflows {
  getWorkflows {
    ...WorkflowDefaults
  }
}
    ${WorkflowDefaultsFragmentDoc}`;
export const useGetWorkflowsQuery = <TData = GetWorkflowsQuery, TError = unknown>(
    variables?: GetWorkflowsQueryVariables,
    options?: UseQueryOptions<GetWorkflowsQuery, TError, TData>,
) =>
    useQuery<GetWorkflowsQuery, TError, TData>(
        variables === undefined ? ["getWorkflows"] : ["getWorkflows", variables],
        graphqlFetcher<GetWorkflowsQuery, GetWorkflowsQueryVariables>(
            GetWorkflowsDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetWorkflowsQuery = <TData = GetWorkflowsQuery, TError = unknown>(
    variables?: GetWorkflowsQueryVariables,
    options?: UseInfiniteQueryOptions<GetWorkflowsQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetWorkflowsQuery, TError, TData>(
        variables === undefined
            ? ["getWorkflows.infinite"]
            : ["getWorkflows.infinite", variables],
        metaData =>
            graphqlFetcher<GetWorkflowsQuery, GetWorkflowsQueryVariables>(
                GetWorkflowsDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const GetWorkflowDocument = `
    query getWorkflow($tenantId: TenantId!, $workflowId: WorkflowId!) {
  getWorkflow(tenantId: $tenantId, workflowId: $workflowId) {
    ...WorkflowDefaults
  }
}
    ${WorkflowDefaultsFragmentDoc}`;
export const useGetWorkflowQuery = <TData = GetWorkflowQuery, TError = unknown>(
    variables: GetWorkflowQueryVariables,
    options?: UseQueryOptions<GetWorkflowQuery, TError, TData>,
) =>
    useQuery<GetWorkflowQuery, TError, TData>(
        ["getWorkflow", variables],
        graphqlFetcher<GetWorkflowQuery, GetWorkflowQueryVariables>(
            GetWorkflowDocument,
            variables,
        ),
        options,
    );
export const useInfiniteGetWorkflowQuery = <TData = GetWorkflowQuery, TError = unknown>(
    variables: GetWorkflowQueryVariables,
    options?: UseInfiniteQueryOptions<GetWorkflowQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetWorkflowQuery, TError, TData>(
        ["getWorkflow.infinite", variables],
        metaData =>
            graphqlFetcher<GetWorkflowQuery, GetWorkflowQueryVariables>(
                GetWorkflowDocument,
                { ...variables, ...(metaData.pageParam ?? {}) },
            )(),
        options,
    );
};

export const CreateWorkflowForTenantDocument = `
    mutation createWorkflowForTenant($tenantId: TenantId!, $workflow: WorkflowPayload!) {
  createWorkflowForTenant(tenantId: $tenantId, workflow: $workflow) {
    ...WorkflowDefaults
  }
}
    ${WorkflowDefaultsFragmentDoc}`;
export const useCreateWorkflowForTenantMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateWorkflowForTenantMutation,
        TError,
        CreateWorkflowForTenantMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateWorkflowForTenantMutation,
        TError,
        CreateWorkflowForTenantMutationVariables,
        TContext
    >(
        ["createWorkflowForTenant"],
        (variables?: CreateWorkflowForTenantMutationVariables) =>
            graphqlFetcher<
                CreateWorkflowForTenantMutation,
                CreateWorkflowForTenantMutationVariables
            >(CreateWorkflowForTenantDocument, variables)(),
        options,
    );
export const CreateWorkflowDocument = `
    mutation createWorkflow($workflow: WorkflowPayload!) {
  createWorkflow(workflow: $workflow) {
    ...WorkflowDefaults
  }
}
    ${WorkflowDefaultsFragmentDoc}`;
export const useCreateWorkflowMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CreateWorkflowMutation,
        TError,
        CreateWorkflowMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CreateWorkflowMutation,
        TError,
        CreateWorkflowMutationVariables,
        TContext
    >(
        ["createWorkflow"],
        (variables?: CreateWorkflowMutationVariables) =>
            graphqlFetcher<CreateWorkflowMutation, CreateWorkflowMutationVariables>(
                CreateWorkflowDocument,
                variables,
            )(),
        options,
    );
export const UpdateWorkflowForTenantDocument = `
    mutation updateWorkflowForTenant($tenantId: TenantId!, $workflowId: WorkflowId!, $workflow: WorkflowPayload!) {
  updateWorkflowForTenant(
    tenantId: $tenantId
    workflowId: $workflowId
    workflow: $workflow
  ) {
    ...WorkflowDefaults
  }
}
    ${WorkflowDefaultsFragmentDoc}`;
export const useUpdateWorkflowForTenantMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpdateWorkflowForTenantMutation,
        TError,
        UpdateWorkflowForTenantMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UpdateWorkflowForTenantMutation,
        TError,
        UpdateWorkflowForTenantMutationVariables,
        TContext
    >(
        ["updateWorkflowForTenant"],
        (variables?: UpdateWorkflowForTenantMutationVariables) =>
            graphqlFetcher<
                UpdateWorkflowForTenantMutation,
                UpdateWorkflowForTenantMutationVariables
            >(UpdateWorkflowForTenantDocument, variables)(),
        options,
    );
export const UpdateWorkflowDocument = `
    mutation updateWorkflow($workflowId: WorkflowId!, $workflow: WorkflowPayload!) {
  updateWorkflow(workflowId: $workflowId, workflow: $workflow) {
    ...WorkflowDefaults
  }
}
    ${WorkflowDefaultsFragmentDoc}`;
export const useUpdateWorkflowMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        UpdateWorkflowMutation,
        TError,
        UpdateWorkflowMutationVariables,
        TContext
    >,
) =>
    useMutation<
        UpdateWorkflowMutation,
        TError,
        UpdateWorkflowMutationVariables,
        TContext
    >(
        ["updateWorkflow"],
        (variables?: UpdateWorkflowMutationVariables) =>
            graphqlFetcher<UpdateWorkflowMutation, UpdateWorkflowMutationVariables>(
                UpdateWorkflowDocument,
                variables,
            )(),
        options,
    );
export const DeleteWorkflowForTenantDocument = `
    mutation deleteWorkflowForTenant($tenantId: TenantId!, $workflowId: WorkflowId!) {
  deleteWorkflowForTenant(tenantId: $tenantId, workflowId: $workflowId)
}
    `;
export const useDeleteWorkflowForTenantMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteWorkflowForTenantMutation,
        TError,
        DeleteWorkflowForTenantMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteWorkflowForTenantMutation,
        TError,
        DeleteWorkflowForTenantMutationVariables,
        TContext
    >(
        ["deleteWorkflowForTenant"],
        (variables?: DeleteWorkflowForTenantMutationVariables) =>
            graphqlFetcher<
                DeleteWorkflowForTenantMutation,
                DeleteWorkflowForTenantMutationVariables
            >(DeleteWorkflowForTenantDocument, variables)(),
        options,
    );
export const DeleteWorkflowDocument = `
    mutation deleteWorkflow($workflowId: WorkflowId!) {
  deleteWorkflow(workflowId: $workflowId)
}
    `;
export const useDeleteWorkflowMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        DeleteWorkflowMutation,
        TError,
        DeleteWorkflowMutationVariables,
        TContext
    >,
) =>
    useMutation<
        DeleteWorkflowMutation,
        TError,
        DeleteWorkflowMutationVariables,
        TContext
    >(
        ["deleteWorkflow"],
        (variables?: DeleteWorkflowMutationVariables) =>
            graphqlFetcher<DeleteWorkflowMutation, DeleteWorkflowMutationVariables>(
                DeleteWorkflowDocument,
                variables,
            )(),
        options,
    );
export const GetWorkflowExecutionsForEntityDocument = `
    query getWorkflowExecutionsForEntity($workflowTargetId: WorkflowTargetId!) {
  getWorkflowExecutionsForEntity(workflowTargetId: $workflowTargetId) {
    id
    scheduledAt
    cancelledAt
    isCancelRequested
    finishedAt
    result
    workflow {
      name
      id
    }
  }
}
    `;
export const useGetWorkflowExecutionsForEntityQuery = <
    TData = GetWorkflowExecutionsForEntityQuery,
    TError = unknown,
>(
    variables: GetWorkflowExecutionsForEntityQueryVariables,
    options?: UseQueryOptions<GetWorkflowExecutionsForEntityQuery, TError, TData>,
) =>
    useQuery<GetWorkflowExecutionsForEntityQuery, TError, TData>(
        ["getWorkflowExecutionsForEntity", variables],
        graphqlFetcher<
            GetWorkflowExecutionsForEntityQuery,
            GetWorkflowExecutionsForEntityQueryVariables
        >(GetWorkflowExecutionsForEntityDocument, variables),
        options,
    );
export const useInfiniteGetWorkflowExecutionsForEntityQuery = <
    TData = GetWorkflowExecutionsForEntityQuery,
    TError = unknown,
>(
    variables: GetWorkflowExecutionsForEntityQueryVariables,
    options?: UseInfiniteQueryOptions<GetWorkflowExecutionsForEntityQuery, TError, TData>,
) => {
    return useInfiniteQuery<GetWorkflowExecutionsForEntityQuery, TError, TData>(
        ["getWorkflowExecutionsForEntity.infinite", variables],
        metaData =>
            graphqlFetcher<
                GetWorkflowExecutionsForEntityQuery,
                GetWorkflowExecutionsForEntityQueryVariables
            >(GetWorkflowExecutionsForEntityDocument, {
                ...variables,
                ...(metaData.pageParam ?? {}),
            })(),
        options,
    );
};

export const CancelWorkflowExecutionDocument = `
    mutation cancelWorkflowExecution($workflowExecutionId: WorkflowExecutionId!) {
  cancelWorkflowExecution(workflowExecutionId: $workflowExecutionId) {
    id
  }
}
    `;
export const useCancelWorkflowExecutionMutation = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<
        CancelWorkflowExecutionMutation,
        TError,
        CancelWorkflowExecutionMutationVariables,
        TContext
    >,
) =>
    useMutation<
        CancelWorkflowExecutionMutation,
        TError,
        CancelWorkflowExecutionMutationVariables,
        TContext
    >(
        ["cancelWorkflowExecution"],
        (variables?: CancelWorkflowExecutionMutationVariables) =>
            graphqlFetcher<
                CancelWorkflowExecutionMutation,
                CancelWorkflowExecutionMutationVariables
            >(CancelWorkflowExecutionDocument, variables)(),
        options,
    );
